import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Booking, Cart } from '../../stores/models/models';
import { BookingStore, UserStore, CartStore, CurrentUserStore, UIStore, LoadingStore } from '../../stores/stores';

import moment from 'moment';

import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Notifications } from 'core/notifications/notifications';

import LoadingSpinner from './LoadingSpinner';

interface IDeleteAccountComponent {
  userId: number;
}

@inject('rootStore')
@observer
export default class DeleteAccountComponent extends React.Component<IDeleteAccountComponent, {}> {
  @observable modalOpen: boolean = false;
  private bookingStore: BookingStore;
  private userStore: UserStore;
  private currentUserStore: CurrentUserStore;
  cartStore: CartStore;
  private uiStore: UIStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.bookingStore = props.rootStore.stores.booking;
    this.userStore = props.rootStore.stores.user;
    this.cartStore = props.rootStore.stores.cart;
    this.currentUserStore = props.rootStore.stores.currentUser;
    this.uiStore = props.rootStore.stores.ui;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Booking[], Cart[]]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const userConfirmedCartIds: number[] = this.cartStore.carts.filter((cart: Cart) => cart.dateConfirmed !== null && cart.userId === this.props.userId).map((cart: Cart) => cart.id);

    const futureBookings: Booking[] = this.bookingStore.bookings.filter((booking: Booking) => moment(booking.dateFrom).isSameOrAfter(moment()) && userConfirmedCartIds.indexOf(booking.cartId) > -1);
    const canDeleteAccount: boolean = futureBookings.length === 0;

    return (
      <Container>
        <Row className="sjp-darkgrey-background sjp-pt-1 sjp-pb-1">
          <Col>
            <div className="sjp-white-title-text sjp-text-uppercase sjp-vertical-align">Delete My Account</div>
          </Col>
        </Row>

        <Row className="sjp-background-card-color sjp-pt-1 sjp-pb-1">
          <Col>
            <h5 className="sjp-card-title sjp-text-uppercase sjp-mt-2">Disclaimer</h5>
            <p className="sjp-description-text sjp-mt-1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
              aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <Button className="sjp-button sjp-button-text sjp-text-uppercase sjp-no-border-radius sjp-mt-2 sjp-mb-1 sjp-pr-2 sjp-pl-2 sjp-pt-1 sjp-pb-1" type="primary" onClick={this.toggleModal.bind(this)}>
              Delete My Account
            </Button>
            {canDeleteAccount ? (
              <Modal isOpen={this.modalOpen} toggle={this.toggleModal.bind(this)} centered>
                <ModalHeader toggle={this.toggleModal.bind(this)}>Account Deletion</ModalHeader>
                <ModalBody>Are you sure you want to delete your account? Please note that this acction is irreversible</ModalBody>
                <ModalFooter>
                  <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button-grey" onClick={this.toggleModal.bind(this)}>
                    No
                  </Button>
                  <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button" onClick={this.handleDeleteAccount.bind(this)}>
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>
            ) : (
              <Modal isOpen={this.modalOpen} toggle={this.toggleModal.bind(this)} centered>
                <ModalHeader toggle={this.toggleModal.bind(this)}>Unable to delete account</ModalHeader>
                <ModalBody>You cannot delete your account as you have future bookings.</ModalBody>
                <ModalFooter>
                  <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button-grey" onClick={this.toggleModal.bind(this)}>
                    Ok
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  private handleDeleteAccount(): void {
    this.userStore
      .deleteUserGdpr(this.props.userId)
      .then(() => {
        const target: string = `/`;
        this.currentUserStore.logoutFromWebsite().then(() => {
          this.uiStore.initiateRedirect({
            target: target,
            reason: `User Self Deletion`
          });
        });
      })
      .catch((err) => Notifications.displayError(err));
  }

  @action('Toggle the can delete modal')
  private toggleModal(): void {
    this.modalOpen = !this.modalOpen;
  }

  private getDataFromApi(): Promise<[Booking[], Cart[]]> {
    this.loadingStore.setLoadingStateToLoading();
    return Promise.all([ this.bookingStore.fetchBookingsByUserId(this.props.userId), this.cartStore.fetchConfirmedCartsByUserId(this.props.userId) ]);
  }
}
