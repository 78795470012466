import { JsonObject, JsonProperty, JsonConvert, Any } from 'json2typescript';
import { isUndefined } from 'util';
import { USER_ROLE_ADMINISTRATOR, USER_ROLE_VOLUNTEER, USER_ROLE_CLIENT } from './UserRole';

@JsonObject
class User {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('id_card', String)
  idCard: string = '';
  @JsonProperty('can_login', Boolean)
  canLogin: boolean = false;
  @JsonProperty('name', String)
  name: string = '';
  @JsonProperty('surname', String)
  surname: string = '';
  @JsonProperty('dob', Any)
  dateOfBirth: any = null;
  @JsonProperty('contact_number', String)
  contactNumber: string = '';
  @JsonProperty('email_address', String)
  emailAddress: string = '';
  @JsonProperty('postal_address', String)
  postalAddress: string = '';
  @JsonProperty('details', String)
  details: string = '';
  @JsonProperty('user_type', String)
  userType: string = '';
  @JsonProperty('roles', String)
  roles: string = '';
  @JsonProperty('company_name', String)
  companyName: string = '';
  @JsonProperty('company_contact_number', String)
  companyContactNumber: string = '';
  @JsonProperty('company_postal_address', String)
  companyPostalAddress: string = '';
  @JsonProperty('vat', String)
  vat: string = '';
  @JsonProperty('active', Boolean)
  active: boolean = false;
  @JsonProperty('email_confirmed', Boolean)
  emailConfirmed: boolean = false;
  @JsonProperty('token', Any)
  token: string | null = null;
  @JsonProperty('discount', Number)
  discount: number = 0;

  public get fullName(): string {
    return `${this.name} ${this.surname}`;
  }

  // Has Role wrappers
  public isAdmin(): boolean {
    return this.hasRole(USER_ROLE_ADMINISTRATOR);
  }

  public isVolunteer(): boolean {
    return this.hasRole(USER_ROLE_VOLUNTEER);
  }

  public isClient(): boolean {
    return this.hasRole(USER_ROLE_CLIENT);
  }

  public hasRole(role: string): boolean {
    return this.roles.includes(role);
  }
}

@JsonObject
class UserPassword {
  @JsonProperty('user', User, true)
  user: User = new User();
  @JsonProperty('password_one', String)
  passwordOne: string = '';
  @JsonProperty('password_two', String)
  passwordTwo: string = '';
}

const exampleUserDetails = {
  id: 34,
  id_card: '32434f',
  can_login: true,
  name: 'John',
  surname: 'Doe',
  dob: null,
  contact_number: '12345678',
  email_address: 'john.doe@example.com',
  postal_address: '54 Pjazza San Nikola',
  details: 'Details',
  user_type: 'admin',
  roles: 'Administrator',
  company_name: 'Right Brain',
  company_contact_number: '12345678',
  company_postal_address: '45 triq Santa Cecilia',
  vat: '0987654321',
  active: false,
  email_confirmed: true,
  token: '',
  discount: 0
};

const userFromForm = (formData: {}) => {
  const user = new User();
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      let value = formData[key];
      if (isUndefined(value)) {
        value = user[key];
      }
      if (key === 'active') {
        if (value === 1) {
          value = true;
        } else if (value === 0) {
          value = false;
        }
      }
      user[key] = value;
    }
  }
  return user;
};

const userPasswordFromForm = (formData: {}) => {
  const user: User = new User();
  const userPassword: UserPassword = new UserPassword();
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      let value = formData[key];
      if (isUndefined(value)) {
        if (user.hasOwnProperty(key)) {
          value = user[key];
        } else if (userPassword.hasOwnProperty(key)) {
          value = userPassword[key];
        }
      }
      if (key === 'active') {
        if (value === 1) {
          value = true;
        } else if (value === 0) {
          value = false;
        }
      }
      if (user.hasOwnProperty(key)) {
        user[key] = value;
      }
      if (userPassword.hasOwnProperty(key)) {
        userPassword[key] = value;
      }
    }
  }
  userPassword.user = user;
  return userPassword;
};

const jsonConvert: JsonConvert = new JsonConvert();
const exampleUser: User = jsonConvert.deserialize(exampleUserDetails, User);

export { User, UserPassword, exampleUserDetails, exampleUser, userFromForm, userPasswordFromForm };
