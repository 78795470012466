import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { RootStore } from '../../stores/RootStore';

import 'bootstrap/dist/css/bootstrap.min.css';

import RegistrationForm from '../../components/website/RegistrationForm';

@inject('rootStore')
@observer
export default class RegistrationScreen extends React.Component<{}, {}> {
  private rootStore: RootStore;

  constructor(props: any) {
    super(props);
    this.rootStore = props.rootStore;
  }

  public render() {
    return <RegistrationForm rootStore={this.rootStore} />;
  }
}
