import { observable, action } from 'mobx';
import { RootStore } from './RootStore';

interface IRedirectData {
  reason: string;
  data?: any;
  target: string;
}

interface IScreenProps {
  title: string;
}

class UIStore {
  @observable public pageTitle: string = 'Loading';
  @observable public isSideMenuCollapsed: boolean;
  @observable public needsRedirect: boolean;
  @observable public redirectData: any;

  constructor(rootStore?: RootStore) {
    this.isSideMenuCollapsed = false;
  }

  @action('Set Screen Properties')
  public setScreenProperties(properties: IScreenProps) {
    this.pageTitle = properties.title;
  }

  @action('Toggle Side Menu')
  public toggleSideMenu = () => {
    this.isSideMenuCollapsed = !this.isSideMenuCollapsed;
  };

  @action('Initiate Redirect')
  public initiateRedirect(data: IRedirectData) {
    this.redirectData = data;
    this.needsRedirect = true;
  }

  // This will also delete any redirect data we passed to avoid constant redirects
  @action('Complete Redirect')
  public completeRedirect() {
    this.redirectData = null;
    this.needsRedirect = false;
  }
}

export { UIStore };
