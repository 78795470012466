import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Input, Button } from 'antd';
import { Container, Row, Col } from 'reactstrap';

import UserService from '../../services/UserService';
import { LoadingStore } from '../../stores/stores';

import { Notifications } from '../../../../core/notifications/notifications';
import Alerts from '../../../../core/alerts/alerts';

import './styles/forgotPasswordComponentStyle.css';

import { Lock } from '../svgIcons/icons';

interface IForgotPasswordProps {
  toggleForgotPassword: () => void;
}

@inject('rootStore')
@observer
export default class ForgotPasswordComponent extends React.Component<IForgotPasswordProps, {}> {
  @observable private idCard: string = '';
  private requestLoadingStore: LoadingStore = new LoadingStore();
  private userService: UserService = new UserService();

  constructor(props: any) {
    super(props);
    this.requestLoadingStore.setLoadingStateToDone();
  }

  public render() {
    return (
      <Container className="sjp-forgot-container">
        <Row className="sjp-pt-1 sjp-pb-1 sjp-background-primary">
          <Col>
            <div className="sjp-white-title-text sjp-text-uppercase">Forgot Password?</div>
          </Col>
        </Row>
        <Row className="sjp-background-card-color">
          <Col xs="8" sm="8" md="8" lg="8" xl="8">
            <Row>
              <Col>
                <div className="sjp-mt-4 sjp-label sjp-text-uppercase">We need your ID number to be able to request a new password.</div>
              </Col>
            </Row>
            <Row className="sjp-mt-2">
              <Col>
                <div className="sjp-label sjp-text-uppercase">* ID Number</div>
                <Input className="sjp-mt-1 sjp-no-border-radius" placeholder="Enter your ID Card Number here" value={this.idCard} onChange={this.handleSetIdCard.bind(this)} />
              </Col>
            </Row>
            <Row className="sjp-mt-2 sjp-pb-2">
              <Col>
                <Button
                  className="sjp-button sjp-pl-2 sjp-pr-2 sjp-button-text sjp-text-uppercase sjp-no-border-radius sjp-new-password-button"
                  disabled={this.idCard.length === 0 || this.requestLoadingStore.stillLoading}
                  loading={this.requestLoadingStore.stillLoading}
                  onClick={this.handlePasswordReset.bind(this)}
                >
                  <div className="sjp-button-text sjp-text-uppercase sjp-pr-1 sjp-pl-1 ">Request New Password</div>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs="4" sm="4" md="4" lg="4" xl="4">
            <Lock className="sjp-forgot-image sjp-primary-fill" />
          </Col>
        </Row>
        <Row>{this.requestLoadingStore.loadingError ? <div style={{ margin: '1em 0' }}>{this.showErrorAlert()} </div> : null}</Row>
        <Row className="sjp-background-card-color sjp-forgot-divider-margin sjp-pt-1 sjp-pb-1">
          <Col>
            <Button className="sjp-full-width sjp-card-button sjp-no-border-radius" onClick={this.props.toggleForgotPassword.bind(this)}>
              <div className="sjp-label sjp-text-uppercase">ALTERNATIVELY GO BACK TO LOG IN</div>
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  private showErrorAlert(): JSX.Element {
    return Alerts.showError('An Error has occured!');
  }

  private handleSetIdCard(e: any): void {
    this.setIdCard(e.target.value);
  }

  @action('Setting the ID Card')
  private setIdCard(idCard: string): void {
    this.idCard = idCard;
  }

  private handlePasswordReset(): Promise<void> {
    this.requestLoadingStore.setLoadingStateToLoading();
    return this.userService
      .forgotPassword(this.idCard, false)
      .then(() => {
        Notifications.showSuccess('Forgot Password Successfully', 'You will shortly receive an email with instructions to reset your password');
        // Clear out the field
        this.setIdCard('');
        this.requestLoadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        Notifications.showError('Forgot Password Error', 'An Error has occured. The provided ID Card may not be to any users within the system or is not a valid email');
        this.requestLoadingStore.setLoadingStateToError();
      });
  }
}
