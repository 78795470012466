import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Config } from '../../stores/models/models';
import { ConfigStore } from '../../stores/stores';

@inject('rootStore')
@observer
export default class ShowAsEventComponent extends React.Component<{}, {}> {
  private configStore: ConfigStore;

  constructor(props: any) {
    super(props);
    this.configStore = props.rootStore.stores.config;
  }

  public render() {
    const config: Config = this.configStore.configuration;
    if (config.events) {
      return this.props.children;
    }
    return null;
  }
}
