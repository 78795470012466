import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { CurrentUserStore, LoadingStore } from '../../stores/stores';
import { User } from '../../stores/models/models';

import Main from './Main';

import RegistrationScreen from './RegistrationScreen';
import AccountScreen from './AccountScreen';
import LoginScreen from './LoginScreen';
import ResetPasswordScreen from './ResetPasswordScreen';
import LocationScreen from './LocationScreen';
import SuccessfulRegistrationScreen from './SuccessfulRegistrationScreen';

import '../../../../../../go/pkg/booking_system/static/css/style.css';

import { Notifications } from 'core/notifications/notifications';

interface RootProps {}

@inject('rootStore')
@observer
class Root extends React.Component<RootProps, {}> {
  private currentUserStore: CurrentUserStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.currentUserStore = props.rootStore.stores.currentUser;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then(() => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        if (err.body && err.body.message.includes('not logged in')) {
          this.loadingStore.setLoadingStateToDone();
        } else {
          this.loadingStore.setLoadingStateToError();
          Notifications.displayError(err);
        }
      });
  }

  public render() {
    return (
      <Switch>
        <Route exact={true} key={'registration'} path={'/register'} component={RegistrationScreen} />
        <Route exact={true} key={'registration-success'} path={'/registration-success'} component={SuccessfulRegistrationScreen} />
        <Route exact={true} key={'account'} path={'/account'} component={AccountScreen} />
        <Route exact={true} key={'location'} path={'/location'} component={LocationScreen} />
        <Route exact={true} key={'login'} path={'/login'} component={LoginScreen} />
        <Route exact={true} key={'forgot'} path={'/forgot'} component={() => this.getForgotPasswordScreen()} />
        <Route exact={true} key={'reset'} path={'/reset'} component={ResetPasswordScreen} />
        <Route key={'main'} path={'/'} component={Main} />
      </Switch>
    );
  }

  private getForgotPasswordScreen(): JSX.Element {
    return <LoginScreen showForgotPassword={true} />;
  }

  private getDataFromApi(): Promise<User> {
    this.loadingStore.setLoadingStateToLoading();
    return this.currentUserStore.fetchUserData();
  }
}

export default withRouter(Root);
