import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Link } from 'react-router-dom';

import { Node, NodeBookable, Booking, User, Cart, NodeBookableException, NodeEventType, EventType } from '../../stores/models/models';
import { NodeStore, CurrentUserStore, CartStore, BookingStore, BookingEditStore, EventTypeStore, UIStore, LoadingStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';

import './styles/nodeTimesStyle.css';

import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { Notifications } from '../../../../core/notifications/notifications';

import { Clock, Calendar, List } from '../svgIcons/icons';

const dateFormat: string = 'DD-MM-YYYY';
const dateTimeDb: string = 'YYYY-MM-DD HH:mm:ss';
const timeFormatLong: string = 'HH:mm:ss';
const timeFormatShort: string = 'HH:mm';

const Option = Select.Option;

interface INodeTimesProps {
  nodeId: number;
}

type BookableFromTo = {
  id: number;
  from: string;
  to: string;
  disabled: boolean;
};

@inject('rootStore')
@observer
export default class NodeTimes extends React.Component<INodeTimesProps, {}> {
  @observable private selectedTimes: BookableFromTo[] = [];
  @observable private modalOpen: boolean = false;
  private bookableFromTos: BookableFromTo[] = [];

  private clickCount: number = 0;
  private isLoggedIn: boolean = false;

  private currentUserStore: CurrentUserStore;
  private nodeStore: NodeStore;
  private cartStore: CartStore;
  private eventTypeStore: EventTypeStore;
  private bookingStore: BookingStore;
  private bookingEditStore: BookingEditStore;
  private loadingStore: LoadingStore = new LoadingStore();
  private uiStore: UIStore;

  constructor(props: any) {
    super(props);
    this.currentUserStore = props.rootStore.stores.currentUser;
    this.nodeStore = props.rootStore.stores.node;
    this.cartStore = props.rootStore.stores.cart;
    this.eventTypeStore = props.rootStore.stores.eventType;
    this.bookingStore = props.rootStore.stores.booking;
    this.bookingEditStore = props.rootStore.stores.bookingEdit;
    this.uiStore = props.rootStore.stores.ui;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [User, Node, NodeBookable[], Booking[], NodeBookableException[], EventType[]]) => {
        const user: User = data[0];
        const node: Node = data[1];

        if (user !== undefined) {
          this.isLoggedIn = true;

          if (this.bookingEditStore.booking.id !== 0) {
            this.setBookableFromTos();
          }

          this.bookingEditStore.setNodeId(node.id);
          this.bookingStore
            .fetchProvisionalBookingsByUserId(user.id)
            .then((bookings: Booking[]) => {
              this.cartStore
                .fetchProvisionalCartByUserId(user.id)
                .then((cart: Cart) => {
                  this.bookingEditStore.setCartId(cart.id);
                  this.loadingStore.setLoadingStateToDone();
                })
                .catch((err) => {
                  Notifications.displayError(err);
                  this.loadingStore.setLoadingStateToError();
                });
            })
            .catch((err) => {
              this.loadingStore.setLoadingStateToError();
              Notifications.displayError(err);
            });
        } else {
          this.loadingStore.setLoadingStateToDone();
        }
      })
      .catch((err) => {
        if (err.body.message.includes('not logged in')) {
          this.loadingStore.setLoadingStateToDone();
        } else {
          this.loadingStore.setLoadingStateToError();
          Notifications.displayError(err);
        }
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const node: Node | undefined = this.nodeStore.get(this.props.nodeId);
    if (node === undefined) {
      throw new Error(`Error: Node with ID ${this.props.nodeId} does not exist`);
    }

    return (
      <Row>
        <Col className="sjp-background-card-color">
          <Row className="sjp-background-primary sjp-padding-1">
            <Col className="sjp-text-center sjp-vertical-align">
              <div className="sjp-white-title-text sjp-text-uppercase">Book This Site</div>
            </Col>
          </Row>
          <Col className="sjp-pl-0 sjp-pr-0">
            <Row className="sjp-mb-1 sjp-mt-1">
              <Col>
                <h6 className="sjp-pt-1 sjp-field-text sjp-text-uppercase sjp-vertical-align">SELECT DATE</h6>
              </Col>
              <Col className="sjp-time-panel-icon-col sjp-justify-end">
                <Calendar className="sjp-time-panel-icon sjp-primary-fill sjp-height-2" />
              </Col>
            </Row>
            <Row className="sjp-pb-1">
              <Col>
                <DatePicker
                  defaultValue={this.bookingEditStore.booking.dateFrom !== '' ? moment(this.bookingEditStore.booking.dateFrom) : undefined}
                  style={{ width: '100%', borderRadius: 0 }}
                  format={dateFormat}
                  className="sjp-text-center sjp-no-border-radius"
                  placeholder="Select a date"
                  onChange={this.handleDateChange.bind(this)}
                  disabledDate={this.disabledDates.bind(this)}
                  allowClear={false}
                />
              </Col>
            </Row>

            <Modal isOpen={this.modalOpen} toggle={this.toggleModal.bind(this)} centered>
              <ModalHeader toggle={this.toggleModal.bind(this)}> {this.currentUserStore.currentUser && !this.currentUserStore.currentUser.active ? 'Your account is being verified!' : 'Login/Register to book this site!'}</ModalHeader>
              <ModalBody>
                {this.currentUserStore.currentUser && !this.currentUserStore.currentUser.active ? 'Once an administrator has confirmed your account, you will be able to make a booking.' : 'Please Login/Register to make a booking.'}
              </ModalBody>
              {!this.currentUserStore.currentUser ? (
                <ModalFooter>
                  <Link to="/register">
                    <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button-grey">Register</Button>
                  </Link>
                  <Link to="/login">
                    <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button">Login</Button>
                  </Link>
                </ModalFooter>
              ) : (
                undefined
              )}
            </Modal>

            {this.bookingEditStore.booking.dateFrom !== '' ? (
              <div>
                <Row className="sjp-mb-1 sjp-mt-1">
                  <Col>
                    <h6 className="sjp-pt-1 sjp-field-text sjp-text-uppercase sjp-vertical-align">SELECT EVENT</h6>
                  </Col>
                  <Col className="sjp-time-panel-icon-col sjp-justify-end">
                    <List className="sjp-time-panel-icon sjp-primary-fill sjp-height-2" />
                  </Col>
                </Row>
                <Row className="sjp-pb-1">
                  <Col>
                    <Select
                      defaultValue={this.bookingEditStore.booking.eventTypeId ? this.bookingEditStore.booking.eventTypeId : undefined}
                      style={{ width: '100%', borderRadius: 0 }}
                      className="sjp-text-center sjp-no-border-radius"
                      placeholder="Select an event type"
                      onChange={this.handleSelectEventType.bind(this)}
                    >
                      {this.getEventTypeOptions()}
                    </Select>
                  </Col>
                </Row>
              </div>
            ) : (
              undefined
            )}

            {this.bookingEditStore.booking.eventTypeId !== 0 ? this.bookableFromTos.length > 0 ? (
              <div>
                <Row className="sjp-mb-1 sjp-mt-1">
                  <Col>
                    <h6 className="sjp-pt-1 sjp-field-text sjp-text-uppercase sjp-vertical-align">SELECT TIME</h6>
                  </Col>
                  <Col className="sjp-text-right sjp-max-height-full sjp-time-panel-icon-col sjp-justify-end">
                    <Clock className="sjp-time-panel-icon sjp-primary-fill sjp-height-2" />
                  </Col>
                </Row>
                <div className="sjp-vertical-scrollable sjp-node-times-scrollable-area">
                  {this.isBookableByDay() && !this.disabledPresent() ? (
                    <Row className="sjp-mt-1">
                      <Button
                        onClick={this.handleSelectAllDay.bind(this)}
                        active={this.selectedTimes.length === this.bookableFromTos.length}
                        className={`sjp-full-width sjp-pl-1 sjp-pr-1 sjp-no-border-radius
                          ${this.currentUserStore.currentUser === undefined || !this.currentUserStore.currentUser.active ? 'sjp-time-button-available-cannot-book' : 'sjp-time-button-available'}`}
                      >
                        FULL DAY
                      </Button>
                    </Row>
                  ) : (
                    undefined
                  )}
                  {this.bookableFromTos.map((bookableFromTo: BookableFromTo) => {
                    return (
                      <Row className="sjp-mt-1" key={bookableFromTo.id}>
                        <Button
                          onClick={this.handleClickTimeSlot.bind(this, bookableFromTo)}
                          onMouseOver={this.handleHoverSelected.bind(this, bookableFromTo)}
                          onMouseLeave={this.handleHoverRemoved.bind(this)}
                          className={`sjp-full-width sjp-pl-1 sjp-no-border-radius ${bookableFromTo.disabled
                            ? 'sjp-time-button-not-available'
                            : `${this.currentUserStore.currentUser === undefined || !this.currentUserStore.currentUser.active ? 'sjp-time-button-available-cannot-book' : 'sjp-time-button-available'}`}`}
                          active={this.selectedTimes.map((bookableFromTo: BookableFromTo) => bookableFromTo.id).indexOf(bookableFromTo.id) > -1 && this.isLoggedIn}
                        >
                          {moment(bookableFromTo.from, timeFormatLong).format(timeFormatShort)} - {moment(bookableFromTo.to, timeFormatLong).format(timeFormatShort)}
                        </Button>
                      </Row>
                    );
                  })}
                </div>
                <div className="sjp-mt-1 sjp-text-center">
                  {this.currentUserStore.currentUser === undefined ? (
                    <h5 className="sjp-small-warning-text sjp-mb-1">Log In to make a booking</h5>
                  ) : !this.currentUserStore.currentUser.active ? (
                    <h5 className="sjp-small-warning-text sjp-mb-1">Your account has not been activated by an Administrator yet</h5>
                  ) : this.selectedTimes.length < node.minBookingTime / 60 / 30 ? (
                    <h5 className="sjp-small-warning-text sjp-mb-1">
                      Your booking must be for at least {Math.floor(node.minBookingTime / 60 / 60) > 0 ? `${Math.floor(node.minBookingTime / 60 / 60)} hour${node.minBookingTime / 60 / 60 > 1 ? 's ' : ''}` : undefined}
                      {(node.minBookingTime / 60) % 60 > 0 ? `${(node.minBookingTime / 60) % 60} minute${(node.minBookingTime / 60) % 60 > 1 ? 's ' : ''}` : undefined}
                    </h5>
                  ) : this.bookableFromTos.length > 0 ? (
                    <Row>
                      <Button active={this.isLoggedIn} onClick={this.handleAddToCart.bind(this)} className="sjp-button sjp-button-text sjp-full-width sjp-padding-1 sjp-no-border-radius button-one-line-text">
                        {this.bookingEditStore.booking.id === 0 ? 'ADD TO CART' : 'EDIT BOOKING'}
                      </Button>
                    </Row>
                  ) : (
                    undefined
                  )}
                </div>
              </div>
            ) : (
              <div className="sjp-bottom-padding-1 sjp-text-center sjp-mb-1">
                <h5 className="sjp-small-warning-text">No Availabilities</h5>
              </div>
            ) : (
              undefined
            )}
          </Col>
        </Col>
      </Row>
    );
  }

  // Functions to set the possible time-slots and the greyed out ones
  private setBookableFromTos(): void {
    this.clearBookableFromTos();
    const bookableFromTos: BookableFromTo[] = this.deactivateBookedTimeSlots(this.findBookableTimeSlots());
    this.bookableFromTos = bookableFromTos;

    this.handleBookingSelectedTimes();
  }

  // Finding every 30min slot for the opening times, and disabling any closed times
  private findBookableTimeSlots(): BookableFromTo[] {
    const dayOfWeek: number = moment(this.bookingEditStore.booking.dateFrom).day();

    if (this.isAClosedDay(moment(this.bookingEditStore.booking.dateFrom, dateTimeDb))) {
      return [];
    }

    const bookableFromTos: BookableFromTo[] = [];

    let count: number = 0;
    const bookables: NodeBookable[] = this.nodeStore.nodeBookables.filter((nodeBookable: NodeBookable) => nodeBookable.nodeId === this.props.nodeId && nodeBookable.dayOfWeek === dayOfWeek);
    for (let i: number = 0; i < bookables.length; i++) {
      let start: moment.Moment = moment(bookables[i].fromTo.from, timeFormatLong);
      let end: moment.Moment = moment(bookables[i].fromTo.to, timeFormatLong);

      while (end.isAfter(start)) {
        bookableFromTos.push({
          id: count,
          from: start.format(timeFormatLong),
          to: start.add(30, 'minutes').format(timeFormatLong),
          disabled: false
        });

        count++;
      }

      if (i < bookables.length - 1) {
        start = moment(bookables[i].fromTo.to, timeFormatLong);
        end = moment(bookables[i + 1].fromTo.from, timeFormatLong);
        while (end.isAfter(start)) {
          bookableFromTos.push({
            id: count,
            from: start.format(timeFormatLong),
            to: start.add(30, 'minutes').format(timeFormatLong),
            disabled: true
          });
          count++;
        }
      }
    }
    return bookableFromTos;
  }

  private isAClosedDay(date: moment.Moment): boolean {
    const nodeBookableExceptions: NodeBookableException[] = this.nodeStore.nodeBookableExceptions.filter((nodeBookableException: NodeBookableException) => nodeBookableException.nodeId === this.props.nodeId);
    let isAClosedDay: boolean = false;
    nodeBookableExceptions.forEach((nodeBookableException: NodeBookableException) => {
      if (!nodeBookableException.recurring) {
        if (moment(nodeBookableException.date, dateTimeDb).isSame(date, 'day')) {
          isAClosedDay = true;
        }
      } else {
        if (moment(nodeBookableException.date, dateTimeDb).month() === date.month() && moment(nodeBookableException.date, dateTimeDb).date() === date.date()) {
          isAClosedDay = true;
        }
      }
    });
    return isAClosedDay;
  }

  // Takes the list of bookable from tos and deactivates the ones that are already booked, then returns the new array
  private deactivateBookedTimeSlots(bookableFromTos: BookableFromTo[]): BookableFromTo[] {
    const modifiedBookableFromTos: BookableFromTo[] = bookableFromTos;
    const node: Node | undefined = this.nodeStore.get(this.props.nodeId);

    if (node === undefined) {
      throw new Error(`Error: Node with ID ${this.props.nodeId} does not exist`);
    }

    const bookings: Booking[] = this.bookingStore.bookings.filter(
      (booking: Booking) => booking.nodeId === this.props.nodeId && moment(booking.dateFrom, dateTimeDb).isSame(moment(this.bookingEditStore.booking.dateFrom), 'day') && booking.id !== this.bookingEditStore.booking.id
    );

    bookings.forEach((booking: Booking) => {
      const otherBookingStartTime: moment.Moment = moment(booking.dateFrom, dateTimeDb);
      const otherBookingEndTime: moment.Moment = moment(booking.dateTo, dateTimeDb);

      modifiedBookableFromTos.forEach((bookableFromTo: BookableFromTo) => {
        const startBookableFromTo: moment.Moment = moment(bookableFromTo.from, timeFormatLong);
        const endBookableFromTo: moment.Moment = moment(bookableFromTo.to, timeFormatLong);

        startBookableFromTo.set({
          date: otherBookingStartTime.date(),
          month: otherBookingStartTime.month(),
          year: otherBookingStartTime.year()
        });
        endBookableFromTo.set({
          date: otherBookingEndTime.date(),
          month: otherBookingEndTime.month(),
          year: otherBookingEndTime.year()
        });

        const otherBookingEventType: EventType | undefined = this.eventTypeStore.eventTypes.find((eventType: EventType) => eventType.id === booking.eventTypeId);
        if (otherBookingEventType === undefined) {
          throw new Error(`Error: Event Type of ID ${booking.eventTypeId} does not exist`);
        }
        const currentBookingEventType: EventType | undefined = this.eventTypeStore.eventTypes.find((eventType: EventType) => eventType.id === this.bookingEditStore.booking.eventTypeId);
        if (currentBookingEventType === undefined) {
          throw new Error(`Error: Event Type of ID ${this.bookingEditStore.booking.eventTypeId} does not exist`);
        }

        const otherBookingTurnAroundTime: number = otherBookingEventType.turnAroundTime;
        const currentBookingTurnAroundTime: number = currentBookingEventType.turnAroundTime;

        const otherBookingStartTimeWithTurnaround: moment.Moment = moment(otherBookingStartTime.format()).add(-1 * currentBookingTurnAroundTime, 'minutes');
        const otherBookingEndTimeWithTurnaround: moment.Moment = moment(otherBookingEndTime.format()).add(otherBookingTurnAroundTime, 'minutes');

        if (
          !(
            (otherBookingStartTimeWithTurnaround.isSameOrBefore(startBookableFromTo) && otherBookingEndTimeWithTurnaround.isSameOrBefore(startBookableFromTo)) ||
            (otherBookingStartTimeWithTurnaround.isSameOrAfter(endBookableFromTo) && otherBookingEndTimeWithTurnaround.isSameOrAfter(endBookableFromTo))
          )
        ) {
          bookableFromTo.disabled = true;
        }
      });
    });
    return modifiedBookableFromTos;
  }

  private clearBookableFromTos(): void {
    this.bookableFromTos = [];
  }

  // Functions to handle the selection of a date
  private handleDateChange(date: moment.Moment, dateString: string): void {
    const bookedStartTime: moment.Moment = (this.bookingEditStore.booking !== undefined && this.bookingEditStore.booking.dateFrom !== '') || date === null ? moment(this.bookingEditStore.booking.dateFrom, dateTimeDb) : date;
    const bookedEndTime: moment.Moment = (this.bookingEditStore.booking !== undefined && this.bookingEditStore.booking.dateTo !== '') || date === null ? moment(this.bookingEditStore.booking.dateTo, dateTimeDb) : date;

    const dateStringsArray: string[] = dateString.split('-');

    bookedStartTime.set({
      date: parseInt(dateStringsArray[0]),
      month: parseInt(dateStringsArray[1]) - 1,
      year: parseInt(dateStringsArray[2])
    });

    bookedEndTime.set({
      date: parseInt(dateStringsArray[0]),
      month: parseInt(dateStringsArray[1]) - 1,
      year: parseInt(dateStringsArray[2])
    });

    if (this.bookingEditStore.booking.dateFrom === '' || this.bookingEditStore.booking.dateTo === '') {
      bookedStartTime.set({
        hours: 0,
        minutes: 0,
        seconds: 0
      });
      bookedEndTime.set({
        hours: 0,
        minutes: 0,
        seconds: 0
      });
    }

    this.bookingEditStore.setDateFrom(bookedStartTime.format());
    this.bookingEditStore.setDateTo(bookedEndTime.format());
    this.clearSelectedTimeSlots();
    if (this.bookingEditStore.booking.eventTypeId !== 0) {
      this.setBookableFromTos();
    }
    this.bookingEditStore.setBookingForRerender();
  }

  // Functions to handle selection of all day
  private handleSelectAllDay(): void {
    if (this.currentUserStore.currentUser !== undefined && this.currentUserStore.currentUser.active) {
      this.clearSelectedTimeSlots();
      this.bookableFromTos.forEach((bookableFromTo: BookableFromTo) => {
        this.addSelectedTimeSlot(bookableFromTo);
        this.saveTimeSlots();
      });
    } else if (!this.currentUserStore.currentUser || (this.currentUserStore.currentUser && !this.currentUserStore.currentUser.active)) {
      this.toggleModal();
    }
  }

  private isBookableByDay(): boolean {
    const nodeBookables: NodeBookable[] = this.nodeStore.nodeBookables.filter((nodeBookable: NodeBookable) => nodeBookable.nodeId === this.props.nodeId && nodeBookable.dayOfWeek === moment(this.bookingEditStore.booking.dateFrom).day());
    return nodeBookables.length === 1;
  }

  // Functions to handle the selecting/deselecting of time slots
  private handleClickTimeSlot(bookableFromTo: BookableFromTo): void {
    if (this.currentUserStore.currentUser !== undefined && this.currentUserStore.currentUser.active) {
      if (this.clickCount % 2 === 0) {
        this.clearSelectedTimeSlots();
        this.addSelectedTimeSlot(bookableFromTo);
      } else if (this.selectedTimes[0].id > bookableFromTo.id) {
        this.clearSelectedTimeSlots();
        this.addSelectedTimeSlot(bookableFromTo);
      } else if (this.disabledTimesBefore(bookableFromTo)) {
        this.clearSelectedTimeSlots();
        this.addSelectedTimeSlot(bookableFromTo);
      }
      this.saveTimeSlots();
      this.clickCount++;
    } else if (!this.currentUserStore.currentUser || (this.currentUserStore.currentUser && !this.currentUserStore.currentUser.active)) {
      this.toggleModal();
    }
  }

  private handleHoverSelected(bookableFromTo: BookableFromTo): void {
    if (this.currentUserStore.currentUser !== undefined && this.currentUserStore.currentUser.active && this.selectedTimes.length > 0 && this.clickCount % 2 !== 0) {
      if (this.selectedTimes[this.selectedTimes.length - 1].id < bookableFromTo.id && !this.disabledTimesBefore(bookableFromTo)) {
        this.addTimeSlotsInBetween(bookableFromTo);
      } else if (this.selectedTimes[this.selectedTimes.length - 1].id > bookableFromTo.id && bookableFromTo.id >= this.selectedTimes[0].id) {
        this.clearSelectedTimeSlotsAfterGiven(bookableFromTo);
      }
    }
  }

  private handleHoverRemoved(): void {
    if (this.currentUserStore.currentUser !== undefined && this.currentUserStore.currentUser.active && this.selectedTimes.length > 0 && this.clickCount % 2 !== 0) {
      this.clearSelectedTimeSlotsAfterGiven(this.selectedTimes[0]);
    }
  }

  private handleBookingSelectedTimes(): void {
    const bookingStartTime: moment.Moment = moment(this.bookingEditStore.booking.dateFrom);
    const bookingEndTime: moment.Moment = moment(this.bookingEditStore.booking.dateTo);

    let count: number = 0;
    const previouslySelectedTimes: BookableFromTo[] = [];

    while (bookingEndTime.isAfter(bookingStartTime)) {
      previouslySelectedTimes.push({
        id: count,
        from: bookingStartTime.format(timeFormatLong),
        to: bookingStartTime.add(30, 'minutes').format(timeFormatLong),
        disabled: false
      });

      count++;
    }

    previouslySelectedTimes.forEach((selectedTime: BookableFromTo) => {
      this.bookableFromTos.forEach((bookableFromTo: BookableFromTo) => {
        if (selectedTime.from === bookableFromTo.from && selectedTime.to === bookableFromTo.to) {
          this.addSelectedTimeSlot(bookableFromTo);
        }
      });
    });
  }

  private disabledTimesBefore(bookableFromTo: BookableFromTo): boolean {
    let disabledPresent: boolean = false;
    for (let i: number = bookableFromTo.id; i > this.selectedTimes[0].id; i--) {
      if (this.bookableFromTos[i].disabled) {
        disabledPresent = true;
      }
    }
    return disabledPresent;
  }

  private getEventTypeOptions(): JSX.Element[] {
    const options: JSX.Element[] = [];
    this.nodeStore.nodeEventTypes.filter((nodeEventType: NodeEventType) => nodeEventType.nodeId === this.props.nodeId).forEach((nodeEventType) => {
      const eventType: EventType | undefined = this.eventTypeStore.eventTypes.find((eventType: EventType) => eventType.id === nodeEventType.eventTypeId);
      if (eventType === undefined) {
        throw new Error(`Error: Event Type of ID ${nodeEventType.eventTypeId} does not exist`);
      }
      options.push(
        <Option key={eventType.id} value={eventType.id}>
          {eventType.name}
        </Option>
      );
    });
    return options;
  }

  private handleSelectEventType(eventTypeId): void {
    this.bookingEditStore.setEventTypeId(eventTypeId);
    this.setBookableFromTos();
    this.bookingEditStore.setBookingForRerender();
  }

  @action('Add to selected times')
  private addSelectedTimeSlot(bookableFromTo: BookableFromTo): void {
    this.selectedTimes.push(bookableFromTo);
  }

  @action('Fill seleted times')
  private addTimeSlotsInBetween(givenBookableFromTo: BookableFromTo): void {
    const lastSelectedTimeSlot: BookableFromTo = this.selectedTimes[this.selectedTimes.length - 1];
    const indexOfLastSelectedTimeSlotInBookableFromTosArray: number = this.bookableFromTos.findIndex((bookableFromTo: BookableFromTo) => bookableFromTo.id === lastSelectedTimeSlot.id);
    const indexInBookableFromTosArray: number = this.bookableFromTos.findIndex((bookableFromTo: BookableFromTo) => bookableFromTo.id === givenBookableFromTo.id);

    for (let i: number = indexOfLastSelectedTimeSlotInBookableFromTosArray + 1; i <= indexInBookableFromTosArray; i++) {
      this.selectedTimes.push(this.bookableFromTos[i]);
    }
  }

  @action('Clear selected time slots')
  private clearSelectedTimeSlots(): void {
    this.selectedTimes = [];
    this.bookingEditStore.setDateTimeFrom('00:00:00');
    this.bookingEditStore.setDateTimeTo('00:00:00');
    this.clickCount = 0;
  }

  @action('Clear selected time slots that are after a certain time slot')
  private clearSelectedTimeSlotsAfterGiven(givenBookableFromTo: BookableFromTo): void {
    const indexOfBookableFromTo: number = this.selectedTimes.findIndex((bookableFromTo: BookableFromTo) => bookableFromTo.id === givenBookableFromTo.id);
    if (indexOfBookableFromTo < this.selectedTimes.length) {
      this.selectedTimes.splice(indexOfBookableFromTo + 1);
    }
  }

  @action('Save the time slots in the booking')
  private saveTimeSlots(): void {
    const from: moment.Moment = moment(this.selectedTimes[0].from, timeFormatLong);
    const fromHours: number = from.hours();
    const fromMinutes: number = from.minutes();

    const to: moment.Moment = moment(this.selectedTimes[this.selectedTimes.length - 1].to, timeFormatLong);
    const toHours: number = to.hours();
    const toMinutes: number = to.minutes();

    const dateAndTimeFrom = moment(this.bookingEditStore.booking.dateFrom);
    dateAndTimeFrom.set({
      hours: fromHours,
      minutes: fromMinutes,
      seconds: 0
    });

    const dateAndTimeTo = moment(this.bookingEditStore.booking.dateTo);
    dateAndTimeTo.set({
      hours: toHours,
      minutes: toMinutes,
      seconds: 0
    });

    if (this.checkIfAllDay(dateAndTimeFrom, dateAndTimeTo)) {
      this.bookingEditStore.setAllDay(true);
    } else {
      this.bookingEditStore.setAllDay(false);
    }

    this.bookingEditStore.setDateTimeFrom(dateAndTimeFrom.format(timeFormatLong));
    this.bookingEditStore.setDateTimeTo(dateAndTimeTo.format(timeFormatLong));
  }

  private checkIfAllDay(dateFrom: moment.Moment, dateTo: moment.Moment): boolean {
    const nodeBookables: NodeBookable[] = this.nodeStore.nodeBookables.filter((nodeBookable: NodeBookable) => nodeBookable.nodeId === this.props.nodeId && nodeBookable.dayOfWeek === dateFrom.day());
    if (nodeBookables.length !== 1) {
      return false;
    }
    const openingTime: moment.Moment = moment(nodeBookables[0].fromTo.from, timeFormatLong);
    const closingTime: moment.Moment = moment(nodeBookables[0].fromTo.to, timeFormatLong);

    openingTime.set({
      date: dateFrom.date(),
      month: dateFrom.month(),
      year: dateFrom.year()
    });

    closingTime.set({
      date: dateFrom.date(),
      month: dateFrom.month(),
      year: dateFrom.year()
    });

    if (openingTime.isSame(dateFrom) && closingTime.isSame(dateTo)) {
      return true;
    }

    return false;
  }

  // Function to add booking to cart
  private handleAddToCart(): void {
    this.bookingStore
      .createOrUpdateBooking(this.bookingEditStore.booking)
      .then((booking: Booking) => {
        const redirectData = {
          reason: 'Go to Cart',
          data: null,
          target: `/cart`
        };
        this.uiStore.initiateRedirect(redirectData);
      })
      .catch((err) => Notifications.displayError(err));
  }

  // Function to check if there are any disabled times during the day
  private disabledPresent(): boolean {
    let disabledPresent: boolean = false;
    this.bookableFromTos.forEach((bookableFromTo: BookableFromTo) => {
      if (bookableFromTo.disabled) {
        disabledPresent = true;
      }
    });
    return disabledPresent;
  }

  private disabledDates(current: moment.Moment): boolean {
    const daysOfTheWeek: number[] = [ 0, 1, 2, 3, 4, 5, 6 ];
    const nodeBookables: NodeBookable[] = this.nodeStore.nodeBookables.filter((nodeBookable: NodeBookable) => nodeBookable.nodeId === this.props.nodeId);
    const closedDaysOfTheWeeks: number[] = [];
    daysOfTheWeek.forEach((day: number) => {
      if (nodeBookables.filter((nodeBookable: NodeBookable) => nodeBookable.dayOfWeek === day).length === 0) {
        closedDaysOfTheWeeks.push(day);
      }
    });

    return (current && this.isAClosedDay(current)) || current < moment().startOf('day') || (current && closedDaysOfTheWeeks.findIndex((day: number) => day === current.day()) > -1);
  }

  @action('Toggle modal')
  private toggleModal(): void {
    this.modalOpen = !this.modalOpen;
  }

  // Function to get data from API
  private getDataFromApi(): Promise<[User, Node, NodeBookable[], Booking[], NodeBookableException[], EventType[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const nodeEventTypesPromise: Promise<NodeEventType[]> = this.nodeStore.fetchNodeEventTypesByNodeId(this.props.nodeId);
    const eventTypesPromise: Promise<EventType[]> = nodeEventTypesPromise.then((nodeEventTypes: NodeEventType[]) => {
      const eventTypesPromises: Promise<EventType>[] = nodeEventTypes.map((nodeEventType: NodeEventType) => {
        return this.eventTypeStore.fetchEventTypeById(nodeEventType.eventTypeId);
      });
      return Promise.all(eventTypesPromises);
    });
    return Promise.all([
      this.currentUserStore.fetchUserData(),
      this.nodeStore.fetchNodeById(this.props.nodeId),
      this.nodeStore.fetchNodeBookablesByNodeId(this.props.nodeId),
      this.bookingStore.fetchBookingsByNodeId(this.props.nodeId),
      this.nodeStore.fetchNodeBookableExceptionsByNodeId(this.props.nodeId),
      eventTypesPromise
    ]);
  }
}
