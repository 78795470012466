import * as React from 'react';
import queryString from 'query-string';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Node, Config } from '../../stores/models/models';
import { NodeStore, ConfigStore, LoadingStore } from '../../stores/stores';
import { RootStore } from '../../stores/RootStore';

import LoadingSpinner from '../../components/website/LoadingSpinner';

import { Row, Container } from 'reactstrap';

import NodeCard from '../../components/website/NodeCard';
import SearchBar from '../../components/website/SearchBar';

import './styles/nodeScreenStyle.css';

import moment from 'moment';
import { Notifications } from 'core/notifications/notifications';

const dateFormat: string = 'YYYY-MM-DD';
const timeFormatShort: string = 'HH:mm';
const timeFormatLong: string = 'HH:mm:ss';

interface SearchParams {
  type?: number;
  date?: moment.Moment;
  start?: moment.Moment;
  end?: moment.Moment;
  attendees?: number;
  seated?: boolean;
}

@inject('rootStore')
@observer
export default class NodesScreen extends React.Component<{}, {}> {
  @observable nodes: Node[] = [];
  private searchParams: SearchParams;
  private nodeStore: NodeStore;
  private configStore: ConfigStore;
  private rootStore: RootStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    const searchParams: any = queryString.parse(props.location.search);
    this.searchParams = this.parseParams(searchParams);
    this.nodeStore = props.rootStore.stores.node;
    this.configStore = props.rootStore.stores.config;
    this.rootStore = props.rootStore;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Node[], Config]) => {
        const nodes: Node[] = data[0];
        this.loadingStore.setLoadingStateToDone();
        this.setNodes(nodes);
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    return (
      <div className="sjp-mb-3">
        {this.configStore.configuration.searchBar ? (
          <Container fluid={true} className="sjp-meeting-search-card shift-up sjp-mb-3">
            <Container className="sjp-pl-0 sjp-pr-0">
              <SearchBar rootStore={this.rootStore} handleSearch={this.handleSearch.bind(this)} />
            </Container>
          </Container>
        ) : (
          undefined
        )}
        {this.nodes.length === 0 ? (
          <Container className="sjp-text-center sjp-mt-1">
            <h5 className="sjp-warning-text">There are no sites available that correspond to your search criteria</h5>
          </Container>
        ) : (
          <div>
            <Container>
              <Row className="sjp-mt-5 sjp-text-container-center-small-devices">
                <h4 className="sjp-title-text">Sites</h4>
              </Row>
            </Container>
            {this.nodes.map((node: Node) => {
              return (
                <Container className="sjp-mt-2" key={node.id}>
                  <div key={node.id}>
                    <NodeCard nodeId={node.id} />
                  </div>
                </Container>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  private handleSearch(nodes: Node[]): void {
    this.setNodes(nodes);
  }

  private parseParams(params: any): SearchParams {
    const searchParams: SearchParams = {};
    for (var key in params) {
      let value = params[key];

      if (key === 'type' || key === 'attendees') {
        value = parseInt(value);
      } else if (key === 'seated') {
        value = value === 'true';
      } else if (key === 'start' || key === 'end') {
        value = moment(key, timeFormatShort);
      } else if (key === 'date') {
        value = moment(key, dateFormat);
      }
      searchParams[key] = value;
    }
    return searchParams;
  }

  @action('Set Nodes')
  private setNodes(nodes: Node[]): void {
    const activeNodes: Node[] = nodes.filter((node: Node) => node.bookable);
    this.nodes = activeNodes;
  }

  private getDataFromApi(): Promise<[Node[], Config]> {
    this.loadingStore.setLoadingStateToLoading();
    if (Object.keys(this.searchParams).length !== 0) {
      const date: string | undefined = this.searchParams.date ? this.searchParams.date.format(dateFormat) : undefined;
      this.searchParams.start ? this.searchParams.start.seconds(0) : undefined;
      const start: string | undefined = this.searchParams.start ? this.searchParams.start.format(timeFormatLong) : undefined;
      this.searchParams.end ? this.searchParams.end.seconds(0) : undefined;
      const end: string | undefined = this.searchParams.end ? this.searchParams.end.format(timeFormatLong) : undefined;
      const nodesPromise: Promise<Node[]> = this.nodeStore.fetchNodesBySearchBarParameters(this.searchParams.seated ? this.searchParams.seated : false, this.searchParams.type, date, start, end, this.searchParams.attendees).then((nodes: Node[]) => {
        return nodes;
      });
      return Promise.all([ nodesPromise, this.configStore.fetchConfiguration() ]);
    }

    return Promise.all([ this.nodeStore.fetchNodes(), this.configStore.fetchConfiguration() ]);
  }
}
