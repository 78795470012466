import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { User } from '../../stores/models/models';
import { UserStore, CheckoutStore, LoadingStore } from '../../stores/stores';

import { Container, Row, Col, Button } from 'reactstrap';

import LoadingSpinner from './LoadingSpinner';

import './styles/userDetailsStyle.css';
import { Notifications } from 'core/notifications/notifications';

interface IUserDetails {
  userId: number;
}

@inject('rootStore')
@observer
export default class UserDetails extends React.Component<IUserDetails, {}> {
  private userStore: UserStore;
  private checkoutStore: CheckoutStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.userStore = props.rootStore.stores.user;
    this.checkoutStore = props.rootStore.stores.checkout;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: User) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const user: User | undefined = this.userStore.get(this.props.userId);

    if (user === undefined) {
      throw new Error(`Error: User with ID ${this.props.userId} does not exist`);
    }

    return (
      <Container>
        <Row className="sjp-darkgrey-background">
          <Col className="sjp-pt-1 sjp-pb-1 sjp-white-title-text sjp-text-uppercase">Client Details</Col>
        </Row>
        <Row className="sjp-background-card-color sjp-text-align">
          <Col className="sjp-ml-1 sjp-mr-1 sjp-mt-1 sjp-mb-1 sjp-white-background">
            <Row className="sjp-pt-3 sjp-pl-3">
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-label sjp-text-uppercase">FIRST NAME</Row>
                <Row className="sjp-value sjp-text-uppercase">{user.name}</Row>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-label sjp-text-uppercase">LAST NAME</Row>
                <Row className="sjp-value sjp-text-uppercase">{user.surname}</Row>
              </Col>
            </Row>
            <Row className="sjp-pt-1 sjp-pl-3">
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-label sjp-text-uppercase">ID NUMBER</Row>
                <Row className="sjp-value sjp-text-uppercase">{user.idCard}</Row>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-label sjp-text-uppercase">CONTACT NUMBER</Row>
                <Row className="sjp-value sjp-text-uppercase">{user.contactNumber}</Row>
              </Col>
            </Row>
            <Row className="sjp-pt-1 sjp-pl-3">
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-label sjp-text-uppercase">EMAIL ADDRESS</Row>
                <Row className="sjp-value sjp-text-uppercase">{user.emailAddress}</Row>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-label sjp-text-uppercase">POSTAL ADDRESS</Row>
                <Row className="sjp-value sjp-text-uppercase">{user.postalAddress !== '' ? user.postalAddress : 'N/A'}</Row>
              </Col>
            </Row>
            <Row className="sjp-pt-1 sjp-pl-3">
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-label sjp-text-uppercase">COMPANY NAME</Row>
                <Row className="sjp-value sjp-text-uppercase">{user.companyName !== '' ? user.companyName : 'N/A'}</Row>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-label sjp-text-uppercase">VAT NUMBER</Row>
                <Row className="sjp-value sjp-text-uppercase">{user.vat !== '' ? user.vat : 'N/A'}</Row>
              </Col>
            </Row>
            <Row className="sjp-pt-1 sjp-pl-3">
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-label sjp-text-uppercase">CONTACT NUMBER</Row>
                <Row className="sjp-value sjp-text-uppercase">{user.companyContactNumber !== '' ? user.companyContactNumber : 'N/A'}</Row>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-label sjp-text-uppercase">COMPANY ADDRESS</Row>
                <Row className="sjp-value sjp-text-uppercase">{user.companyPostalAddress !== '' ? user.companyPostalAddress : 'N/A'}</Row>
              </Col>
            </Row>
            <Row className="sjp-pt-2 sjp-full-width sjp-no-margin">
              <Button className="sjp-edit-button sjp-no-border-radius sjp-mb-1 sjp-full-width" onClick={this.handleEditDetails.bind(this)}>
                EDIT DETAILS
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  private handleEditDetails(): void {
    this.checkoutStore.setIsBeingEdited(true);
  }

  private getDataFromApi(): Promise<User> {
    this.loadingStore.setLoadingStateToLoading();
    return this.userStore.fetchUnscopedUserById(this.props.userId);
  }
}
