import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Row, Col } from 'reactstrap';
const LogoImage: string = '/static-images/logo.png';

import { Config } from '../../stores/models/Config';
import { ConfigStore, LoadingStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';

import { Notifications } from 'core/notifications/notifications';
import './styles/topMostRowStyles.css';

@inject('rootStore')
@observer
export default class TopMostRow extends React.Component<{}, {}> {
  private loadingStore: LoadingStore = new LoadingStore();
  private configStore: ConfigStore;

  constructor(props: any) {
    super(props);
    this.configStore = props.rootStore.stores.config;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((config: Config) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    if (!this.configStore.configuration.topMostRow) {
      return <div />;
    }

    return (
      <Row className="align-items-end sjp-top-most-row sjp-mt-n1">
        <Col md={4} className="">
          <Row>
            {' '}
            <h2 className="sjp-title-text m-0 p-0 sjp-main-title ">Parish Meeting Rooms</h2>
          </Row>
        </Col>
        <Col md={{ size: 4, offset: 4 }} className="pr-0">
          <img className="rounded float-right" src={LogoImage} alt="LOGO" />
        </Col>
      </Row>
    );
  }

  private getDataFromApi(): Promise<Config> {
    this.loadingStore.setLoadingStateToLoading();
    return this.configStore.fetchConfiguration();
  }
}
