import ApiService from './ApiService';

import { Node, NodeEventType, NodeFacility, NodeBookable, NodeBookableException, NodeImage } from '../stores/models/models';

class NodeService extends ApiService {
  constructor() {
    super();
  }

  // Nodes

  public fetchNodes(): Promise<Node[]> {
    const url: string = this.getUrl('/nodes');
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, Node);
      });
  }

  public fetchNodesWithLonLat(): Promise<Node[]> {
    const url: string = this.getUrl('/nodes/lonlat');
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, Node);
      });
  }

  public fetchNodeById(nodeId: number): Promise<Node> {
    const url: string = this.getUrl(`/node/${nodeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Node);
      });
  }

  public fetchNodesByParentId(parentId: number): Promise<Node[]> {
    const url: string = this.getUrl(`/nodesbyparent/${parentId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, Node);
      });
  }

  public fetchNodesByNodeTypeId(nodeTypeId: number): Promise<Node[]> {
    const url: string = this.getUrl(`/nodeByNodeType/${nodeTypeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, Node);
      });
  }

  public fetchNodesBySearchBarParameters(seated: boolean, nodeTypeId?: number, date?: string, startTime?: string, endTime?: string, attendees?: number): Promise<Node[]> {
    let url: string = this.getUrl(`/nodes/website?seated=${seated}`);
    if (nodeTypeId) {
      url = `${url}&nodeTypeId=${nodeTypeId}`;
    }
    if (date) {
      url = `${url}&date=${date}`;
    }
    if (startTime) {
      url = `${url}&startTime=${startTime}`;
    }
    if (endTime) {
      url = `${url}&endTime=${endTime}`;
    }
    if (attendees) {
      url = `${url}&attendees=${attendees}`;
    }
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Node);
        }
        return [];
      });
  }

  public createOrUpdateNode(node: Node): Promise<Node> {
    const baseUrl: string = `/node`;
    const isNew: boolean = node.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/${node.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonNode = this.jsonConvert.serialize(node);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNode)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Node);
      });
  }

  public deleteNode(nodeId: number): Promise<void> {
    const url: string = this.getUrl(`/node/${nodeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders,
      method: 'DELETE'
    }).then(this.handleResponse);
  }

  // Node Facilities

  public fetchNodeFacilities(): Promise<NodeFacility[]> {
    const url: string = this.getUrl(`/nodesfacilities`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, NodeFacility);
        }
        return [];
      });
  }

  public fetchNodeFacilitiesByNodeId(nodeId: number): Promise<NodeFacility[]> {
    const url: string = this.getUrl(`/nodesfacility/node/${nodeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.lenth === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, NodeFacility);
      });
  }

  public fetchNodeFacilitiesByFacilityId(facilityId: number): Promise<NodeFacility[]> {
    const url: string = this.getUrl(`/nodesfacility/facility/${facilityId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, NodeFacility);
        }
        return [];
      });
  }

  public createNodeFacility(nodeFacility: NodeFacility): Promise<NodeFacility> {
    const url: string = this.getUrl(`/nodesfacility`);
    const jsonNodeFacility = this.jsonConvert.serialize(nodeFacility);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeFacility)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, NodeFacility));
  }

  public deleteNodeFacility(nodeFacilityId: number): Promise<void> {
    const url: string = this.getUrl(`/nodesfacility/info/${nodeFacilityId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  // Node Event Types

  public fetchNodeEventTypes(): Promise<NodeEventType[]> {
    const url: string = this.getUrl('NodeEventTypes');
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, NodeEventType);
        }
        return [];
      });
  }

  public fetchNodeEventTypesByNodeId(nodeId: number): Promise<NodeEventType[]> {
    const url: string = this.getUrl(`/NodeEventType/node/${nodeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.lenth === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, NodeEventType);
      });
  }

  public createNodeEventType(nodeEventType: NodeEventType): Promise<NodeEventType> {
    const url: string = this.getUrl(`/NodeEventType`);
    const jsonNodeEventType = this.jsonConvert.serialize(nodeEventType);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeEventType)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, NodeEventType));
  }

  public deleteNodeEventType(nodeEventTypeId: number): Promise<void> {
    const url: string = this.getUrl(`/NodeEventType/info/${nodeEventTypeId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  // Node Bookables

  public fetchNodeBookables(): Promise<NodeBookable[]> {
    const url: string = this.getUrl('/nodebookables');
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, NodeBookable);
        }
        return [];
      });
  }

  public fetchNodeBookablesByNodeId(nodeId: number): Promise<NodeBookable[]> {
    const url: string = this.getUrl(`/nodebookables/node/${nodeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, NodeBookable);
        }
        return [];
      });
  }

  public createOrUpdateNodeBookable(nodeBookable: NodeBookable): Promise<NodeBookable> {
    const baseUrl: string = `/nodeBookable`;
    const isNew: boolean = nodeBookable.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/${nodeBookable.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonNodeBookable = this.jsonConvert.serialize(nodeBookable);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeBookable)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, NodeBookable));
  }

  public createNodeBookables(nodeBookables: NodeBookable[], nodeId: number, dayOfWeek: number): Promise<NodeBookable[]> {
    const url: string = this.getUrl(`/nodeBookable?nodeId=${nodeId}&dayOfWeek=${dayOfWeek}`);
    const jsonNodeBookables = this.jsonConvert.serialize(nodeBookables);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeBookables)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, NodeBookable);
        }
        return [];
      });
  }

  public updateNodeBookable(nodeBookable: NodeBookable): Promise<NodeBookable> {
    const url: string = this.getUrl(`/nodeBookable/${nodeBookable.id}`);
    const jsonNodeBookable = this.jsonConvert.serialize(nodeBookable);
    return fetch(url, {
      method: 'PUT',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeBookable)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, NodeBookable));
  }

  public deleteNodeBookable(nodeBookableId: number): Promise<void> {
    const url: string = this.getUrl(`/nodeBookable/${nodeBookableId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  // Node Bookable Exception

  public fetchNodeBookableExceptions(): Promise<NodeBookableException[]> {
    const url: string = this.getUrl(`/nodeDateException`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, NodeBookableException);
        }
        return [];
      });
  }

  public fetchNodeBookableExceptionsByNodeId(nodeId: number): Promise<NodeBookableException[]> {
    const url: string = this.getUrl(`/nodeDateExceptions/node/${nodeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, NodeBookableException);
        }
        return [];
      });
  }

  public createNodeBookableException(nodeBookableException: NodeBookableException): Promise<NodeBookableException> {
    const url: string = this.getUrl(`/nodeDateException`);
    const jsonNodeBookableException = this.jsonConvert.serialize(nodeBookableException);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeBookableException)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, NodeBookableException);
      });
  }

  public updateNodeBookableException(nodeBookableException: NodeBookableException): Promise<NodeBookableException> {
    const url: string = this.getUrl(`/nodeDateException/${nodeBookableException.id}`);
    const jsonNodeBookableException = this.jsonConvert.serialize(nodeBookableException);
    return fetch(url, {
      method: 'PUT',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeBookableException)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, NodeBookableException);
      });
  }

  public deleteNodeBookableException(nodeBookableExceptionId: number): Promise<void> {
    const url: string = this.getUrl(`/nodeDateException/${nodeBookableExceptionId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  // Node Image

  public fetchNodeImages(): Promise<NodeImage[]> {
    const url: string = this.getUrl('/node-images');
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, NodeImage);
        }
        return [];
      });
  }

  public fetchNodeImagesByNodeId(nodeId: number, featured: boolean): Promise<NodeImage[]> {
    const url: string = this.getUrl(`/node-images/node/${nodeId}?featured=${featured}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, NodeImage);
        }
        return [];
      });
  }

  public createNodeImage(nodeImage: NodeImage): Promise<NodeImage> {
    const url: string = this.getUrl(`/node-image`);
    const jsonNodeImage = this.jsonConvert.serialize(nodeImage);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeImage)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, NodeImage));
  }

  public deleteNodeImage(nodeImageId: number): Promise<void> {
    const url: string = this.getUrl(`/node-image/${nodeImageId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  public deleteNodeImageByNodeIdAndImageId(nodeId: number, imageId: number): Promise<void> {
    const url: string = this.getUrl(`/node-image/node/${nodeId}/image/${imageId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => responseJson.data);
  }
}

export default NodeService;
