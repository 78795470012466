import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Row, Col } from 'reactstrap';

import './styles/accountTabsStyle.css';

import ShowAsBookingComponent from '../UI/ShowAsBookingComponent';

import { Profile } from '../svgIcons/icons';

interface IAccountTabsProps {
  currentlySelectedTab: number;
  selectTabCallback: (tab: number) => void;
}

@inject('rootStore')
@observer
export default class AccountTabs extends React.Component<IAccountTabsProps, {}> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <div>
        <Row className="account-tabs-tab sjp-darkgrey-background">
          <Profile className="account-tabs-profile-image sjp-white-fill" />
        </Row>
        <Row className="sjp-mt-1">
          <Col className={`account-tabs-tab sjp-pl-0 sjp-pr-0 ${this.props.currentlySelectedTab !== 2 && this.props.currentlySelectedTab !== 3 && this.props.currentlySelectedTab !== 4 ? 'sjp-sidebar-red' : 'sjp-sidebar-grey'}`} />
          <Col className="sjp-pl-0 sjp-pr-0">
            <Button
              active={this.props.currentlySelectedTab !== 2 && this.props.currentlySelectedTab !== 3 && this.props.currentlySelectedTab !== 4 && this.props.currentlySelectedTab !== 5}
              className={`account-tabs-tab sjp-no-border-radius sjp-full-width sjp-button-text sjp-text-uppercase sjp-account-tab-button `}
              onClick={this.handleTabClick.bind(this, 1)}
            >
              My details
            </Button>
          </Col>
        </Row>
        <ShowAsBookingComponent>
          <Row className="sjp-mt-1">
            <Col className={`account-tabs-tab sjp-pl-0 sjp-pr-0 ${this.props.currentlySelectedTab == 3 ? 'sjp-sidebar-red' : 'sjp-sidebar-grey'}`} />
            <Col className="sjp-pl-0 sjp-pr-0">
              <Button active={this.props.currentlySelectedTab === 3} className={`account-tabs-tab sjp-no-border-radius sjp-full-width sjp-button-text sjp-text-uppercase sjp-account-tab-button`} onClick={this.handleTabClick.bind(this, 3)}>
                My bookings
              </Button>
            </Col>
          </Row>
          <Row className="sjp-mt-1">
            <Col className={`account-tabs-tab sjp-pl-0 sjp-pr-0 ${this.props.currentlySelectedTab == 2 ? 'sjp-sidebar-red' : 'sjp-sidebar-grey'}`} />
            <Col className="sjp-pl-0 sjp-pr-0">
              <Button active={this.props.currentlySelectedTab === 2} className={`account-tabs-tab sjp-no-border-radius sjp-full-width sjp-button-text sjp-text-uppercase sjp-account-tab-button`} onClick={this.handleTabClick.bind(this, 2)}>
                invoices
              </Button>
            </Col>
          </Row>
        </ShowAsBookingComponent>
        <Row className="sjp-mt-1">
          <Col className={`account-tabs-tab sjp-pl-0 sjp-pr-0 ${this.props.currentlySelectedTab == 4 ? 'sjp-sidebar-red' : 'sjp-sidebar-grey'}`} />
          <Col className="sjp-pl-0 sjp-pr-0">
            <Button active={this.props.currentlySelectedTab === 4} className={`account-tabs-tab sjp-no-border-radius sjp-full-width sjp-button-text sjp-text-uppercase sjp-account-tab-button`} onClick={this.handleTabClick.bind(this, 4)}>
              change password
            </Button>
          </Col>
        </Row>
        <Row className="sjp-mt-1">
          <Col className={`account-tabs-tab sjp-pl-0 sjp-pr-0 ${this.props.currentlySelectedTab == 5 ? 'sjp-sidebar-red' : 'sjp-sidebar-grey'}`} />
          <Col className="sjp-pl-0 sjp-pr-0">
            <Button active={this.props.currentlySelectedTab === 5} className={`account-tabs-tab sjp-no-border-radius sjp-full-width sjp-button-text sjp-text-uppercase sjp-account-tab-button`} onClick={this.handleTabClick.bind(this, 5)}>
              delete my account
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  // My details is 1, My Bookings is 2, Invoices is 3, Change Password is 4
  private handleTabClick(tab: number): void {
    this.props.selectTabCallback(tab);
  }
}
