import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { TypeCanHave } from './models/models';

class TypeCanHaveStore extends GenericStore<TypeCanHave> {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  public get typeCanHaves(): TypeCanHave[] {
    return this.contents;
  }

  public fetchTypeCanHaves(): Promise<TypeCanHave[]> {
    return this.rootStore.services.typeCanHave.fetchTypeCanHaves().then((typeCanHaves: TypeCanHave[]) => {
      this.addOrUpdateAll(typeCanHaves);
      return typeCanHaves;
    });
  }

  public fetchTypeCanHavesByTypeId(nodeTypeId: number): Promise<TypeCanHave[]> {
    return this.rootStore.services.typeCanHave.fetchTypeCanHavesByTypeId(nodeTypeId).then((typeCanHaves: TypeCanHave[]) => {
      this.addOrUpdateAll(typeCanHaves);
      return typeCanHaves;
    });
  }

  public createTypeCanHave(typeCanHave: TypeCanHave): Promise<TypeCanHave> {
    return this.rootStore.services.typeCanHave.createTypeCanHave(typeCanHave).then((typeCanHave: TypeCanHave) => {
      this.addOrUpdate(typeCanHave);
      return typeCanHave;
    });
  }

  public deleteTypeCanHaveByTypeIdAndCanHaveId(typeId: number, canHaveId: number): Promise<number> {
    return this.rootStore.services.typeCanHave.deleteTypeCanHaveByTypeIdAndCanHaveId(typeId, canHaveId).then((typeCanHaveId: number) => {
      this.remove(typeCanHaveId);
      return typeCanHaveId;
    });
  }
}

export { TypeCanHaveStore };
