import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { CartCode, RedeemCode } from './models/models';

class RedeemCodeStore extends GenericStore<RedeemCode> {
  private rootStore: RootStore;
  private cartCodeStore = new GenericStore<CartCode>();

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  // Redeem Code Store

  public get redeemCodes(): RedeemCode[] {
    return this.contents;
  }

  public fetchRedeemCodes(): Promise<RedeemCode[]> {
    return this.rootStore.services.redeemCode.fetchRedeemCodes().then((redeemCodes: RedeemCode[]) => {
      this.addOrUpdateAll(redeemCodes);
      return redeemCodes;
    });
  }

  public fetchRedeemCodeById(redeemCodeId: number): Promise<RedeemCode> {
    return this.rootStore.services.redeemCode.fetchRedeemCodeById(redeemCodeId).then((redeemCode: RedeemCode) => {
      this.addOrUpdate(redeemCode);
      return redeemCode;
    });
  }

  public createOrUpdateRedeemCode(redeemCode: RedeemCode): Promise<RedeemCode> {
    return this.rootStore.services.redeemCode.createOrUpdateRedeemCode(redeemCode).then((redeemCode: RedeemCode) => {
      this.addOrUpdate(redeemCode);
      return redeemCode;
    });
  }

  public deleteRedeemCode(redeemCodeId: number): Promise<void> {
    return this.rootStore.services.redeemCode.deleteRedeemCode(redeemCodeId).then(() => {
      this.remove(redeemCodeId);
    });
  }

  // Cart Code Store
  public get cartCodes(): CartCode[] {
    return this.cartCodeStore.contents;
  }

  public fetchCartCodesByCartId(cartId: number): Promise<CartCode[]> {
    return this.rootStore.services.redeemCode.fetchCartCodesByCartId(cartId).then((cartCodes: CartCode[]) => {
      this.cartCodeStore.addOrUpdateAll(cartCodes);
      return cartCodes;
    });
  }

  public createCartCode(cartCode: CartCode): Promise<CartCode> {
    return this.rootStore.services.redeemCode.createCartCode(cartCode).then((cartCode: CartCode) => {
      this.cartCodeStore.addOrUpdate(cartCode);
      return cartCode;
    });
  }

  public deleteCartCode(cartCodeId: number): Promise<void> {
    return this.rootStore.services.redeemCode.deleteCartCode(cartCodeId).then(() => {
      this.cartCodeStore.remove(cartCodeId);
    });
  }
}

export { RedeemCodeStore };
