import { observable, action } from 'mobx';

import { Booking, BulkBooking, BookingFacility, BulkBookingResponse } from './models/models';
import { RootStore } from './RootStore';

import moment from 'moment';

class BookingEditStore {
  @observable public booking: Booking;
  @observable public bulkBooking: BulkBooking | null;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  // Main Booking

  @action('Fetch booking to be edited')
  public fetchBookingForEditing(bookingId: number): Promise<Booking> {
    if (bookingId === 0) {
      const newBooking: Booking = new Booking();
      this.setCurrentBooking(newBooking);
      return Promise.resolve(newBooking);
    }
    return this.rootStore.stores.booking.fetchBookingById(bookingId).then((booking: Booking) => {
      if (!booking) {
        throw new Error(`Booking with ID: ${bookingId} does not exist`);
      }
      this.setCurrentBooking(booking);
      return booking;
    });
  }

  public fetchBookingFacilitiesByBookingId(): Promise<BookingFacility[]> {
    if (this.booking.id === 0) {
      return Promise.resolve([]);
    }
    return this.rootStore.stores.booking.fetchBookingFacilitiesByBookingId(this.booking.id).then((bookingFacilities: BookingFacility[]) => {
      return bookingFacilities;
    });
  }

  @action('Saving or Updating the current booking with new details')
  public createOrUpdateCurrentBooking(booking: Booking): Promise<Booking> {
    if (!this.booking) {
      throw new Error(`Unable to update a booking if one does not exist`);
    }
    return this.rootStore.stores.booking.createOrUpdateBooking(booking).then((newBooking: Booking) => {
      if (!newBooking) {
        throw new Error(`Unable to update booking with ID: ${booking.id}`);
      }
      this.setCurrentBooking(newBooking);
      return newBooking;
    });
  }

  @action('Set Booking that is currently being edited')
  private setCurrentBooking(booking: Booking): void {
    this.booking = booking;
  }

  @action('Set Cart ID of the booking')
  public setCartId(cartId: number): void {
    this.booking.cartId = cartId;
  }

  @action('Set Node ID of the booking')
  public setNodeId(nodeId: number): void {
    this.booking.nodeId = nodeId;
  }

  @action('Set Event Type Id of the booking')
  public setEventTypeId(eventTypeId: number): void {
    this.booking.eventTypeId = eventTypeId;
  }

  @action('Set Date From of the booking')
  public setDateFrom(dateFrom: string): void {
    this.booking.dateFrom = dateFrom;
  }

  @action('Set Date From from a time format')
  public setDateTimeFrom(timeFrom: string): void {
    const dateFrom: moment.Moment = moment(this.booking.dateFrom);
    const timeStrings: string[] = timeFrom.split(':');
    dateFrom.set({
      hours: parseInt(timeStrings[0]),
      minutes: parseInt(timeStrings[1]),
      seconds: parseInt(timeStrings[2])
    });
    this.setDateFrom(dateFrom.format());
  }

  @action('Set Date To of the booking')
  public setDateTo(dateTo: string): void {
    this.booking.dateTo = dateTo;
  }

  @action('Set Date To from a time format')
  public setDateTimeTo(timeTo: string): void {
    const dateTo: moment.Moment = moment(this.booking.dateTo);
    const timeStrings: string[] = timeTo.split(':');
    dateTo.set({
      hours: parseInt(timeStrings[0]),
      minutes: parseInt(timeStrings[1]),
      seconds: parseInt(timeStrings[2])
    });
    this.setDateTo(dateTo.format());
  }

  @action('Set All Day for the booking')
  public setAllDay(allDay: boolean): void {
    this.booking.allDay = allDay;
  }

  // TODO: find a better way to do this
  // This just resets the booking so that other components can rerender (when just a variable of booking is changed, it doesn't rerender)
  @action('Set booking for rerendering')
  public setBookingForRerender(): void {
    const tempBooking: Booking = this.booking;
    this.booking = new Booking();
    this.booking = tempBooking;
  }

  @action('Set description')
  public setDescription(description: string): void {
    this.booking.description = description;
  }

  // Bulk Booking

  @action('Fetch bulk booking to be edited')
  public fetchBulkBookingForEditing(bulkBookingId: number): Promise<BulkBooking> {
    if (bulkBookingId > 0) {
      return this.rootStore.stores.booking.fetchBulkBookingById(bulkBookingId).then((bulkBooking: BulkBooking) => {
        if (!bulkBooking) {
          throw new Error(`Bulk Booking with ID: ${bulkBookingId} does not exist`);
        }
        this.setCurrentBulkBooking(bulkBooking);
        return bulkBooking;
      });
    }
    const newBulkBooking: BulkBooking = new BulkBooking();
    this.setCurrentBulkBooking(newBulkBooking);
    return Promise.resolve(newBulkBooking);
  }

  @action('Saving or Updating the current bulk booking with new details')
  public createOrUpdateCurrentBulkBooking(booking: Booking, bulkBooking: BulkBooking): Promise<BulkBookingResponse[]> {
    if (!this.bulkBooking) {
      throw new Error(`Unable to update a bulk booking if one does not exist`);
    }
    return this.rootStore.stores.booking.createOrUpdateBulkBooking(booking, bulkBooking).then((bulkBookingResponses: BulkBookingResponse[]) => {
      return bulkBookingResponses;
    });
  }

  @action('Set current bulk booking')
  private setCurrentBulkBooking(bulkBooking: BulkBooking): void {
    this.bulkBooking = bulkBooking;
  }

  @action('Set date from of bulk booking')
  public setBulkBookingDateFrom(date: moment.Moment): void {
    date.set({
      hours: 0,
      minutes: 0,
      seconds: 0
    });

    if (this.bulkBooking) {
      this.bulkBooking.dateFrom = date.format();
    }
  }

  @action('Set date to of bulk booking')
  public setBulkBookingDateTo(date: moment.Moment): void {
    date.set({
      hours: 23,
      minutes: 59,
      seconds: 59
    });

    if (this.bulkBooking) {
      this.bulkBooking.dateTo = date.format();
    }
  }

  @action('Set Recurrence Type (MonthlyWeek) of Bulk Booking')
  public setBulkBookingRecurrenceType(type: number): void {
    if (this.bulkBooking) {
      this.bulkBooking.monthlyWeek = type === -1 ? null : type === 1;
    }
  }

  @action('Set Days of Week of Bulk Booking')
  public setBulkBookingDaysOfWeek(days: string): void {
    if (this.bulkBooking) {
      this.bulkBooking.daysOfWeek = days;
    }
  }

  @action('Instantiate a bulk booking')
  public instantiateBulkBooking(): void {
    this.bulkBooking = new BulkBooking();
  }
}

export { BookingEditStore };
