import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';

import LoadingSpinner from '../../components/website/LoadingSpinner';

import { ConfigStore, CurrentUserStore, LoadingStore, UIStore } from '../../stores/stores';
import ErrorStore from '../../../../core/stores/ErrorStore';

import { Notifications } from 'core/notifications/notifications';

import EventsScreen from './EventsScreen';
import EventViewScreen from './EventViewScreen';
import EventTicketScreen from './EventTicketScreen';
import EventCartScreen from './EventCartScreen';
import EventCheckoutScreen from './EventCheckoutScreen';
import NodeViewScreen from './NodeViewScreen';
import CartScreen from './CartScreen';
import CheckoutScreen from './CheckoutScreen';
import NodesScreen from './NodesScreen';

import moment = require('moment');
moment.locale('en', {
  week: {
    dow: 1,
    doy: 1
  }
});

interface Package {
  exact: boolean;
  key: string;
  path: string;
  component: any;
}

const packages: Package[] = [
  {
    exact: true,
    key: 'nodes',
    path: '/nodes',
    component: NodesScreen
  },
  {
    exact: true,
    key: 'node-view',
    path: '/node/:id',
    component: NodeViewScreen
  },
  {
    exact: true,
    key: 'cart',
    path: '/cart',
    component: CartScreen
  },
  {
    exact: true,
    key: 'checkout',
    path: '/checkout/:cartid',
    component: CheckoutScreen
  },
  {
    exact: true,
    key: 'events',
    path: '/events',
    component: EventsScreen
  },
  {
    exact: true,
    key: 'event',
    path: '/event/:id',
    component: EventViewScreen
  },
  {
    exact: true,
    key: 'event-ticket',
    path: '/event-tickets/:eventid',
    component: EventTicketScreen
  },
  {
    exact: true,
    key: 'event-cart',
    path: '/event-cart',
    component: EventCartScreen
  },
  {
    exact: true,
    key: 'event-checkout',
    path: '/event-checkout/:cartid',
    component: EventCheckoutScreen
  }
];

@inject('rootStore')
@observer
class Main extends React.Component<{}, {}> {
  private uiStore: UIStore;
  private errorStore: ErrorStore;
  private currentUserStore: CurrentUserStore;
  private configStore: ConfigStore;
  private loadingStore: LoadingStore = new LoadingStore();
  private routeSwitch: JSX.Element | null;

  constructor(props: any) {
    super(props);
    this.uiStore = props.rootStore.stores.ui;
    this.errorStore = props.rootStore.stores.error;
    this.currentUserStore = props.rootStore.stores.currentUser;
    this.configStore = props.rootStore.stores.config;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: any) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        if (err.body && err.body.message.includes('not logged in')) {
          this.loadingStore.setLoadingStateToDone();
        } else {
          this.loadingStore.setLoadingStateToError();
          Notifications.displayError(err);
        }
      });
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.errorStore.logError(error, errorInfo);
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    if (this.uiStore.needsRedirect) {
      const { location }: any = this.props;
      const target: string = this.uiStore.redirectData.target;
      let pathName: string = location.pathname;
      // Append any query parameters which may be present
      if (location.search !== '') {
        pathName = `${pathName}${location.search}`;
      }
      if (pathName === target) {
        // Do not continue redirecting and complete it to avoid loops
        this.uiStore.completeRedirect();
      } else {
        return <Redirect to={target} />;
      }
    }

    if (!this.routeSwitch) {
      this.generateRouteSwitch();
    }

    return <div>{this.routeSwitch}</div>;
  }

  private generateRouteSwitch(): void {
    console.log('[BOOKING SYSTEM WEBSITE] Generating Routes...');
    const routes: JSX.Element[] = [];
    packages.filter((pkg: Package) => this.packageIsActivated(pkg)).forEach((entry: Package) => {
      routes.push(<Route key={entry.key} exact={entry.exact} path={entry.path} component={entry.component} />);
    });
    if (!this.configStore.configuration.home) {
      if (this.configStore.configuration.nodes) {
        routes.push(<Route key="home" path="/" component={NodesScreen} />);
      } else {
        routes.push(<Route key="home" path="/" component={EventsScreen} />);
      }
    }
    this.routeSwitch = <Switch>{routes}</Switch>;
  }

  private packageIsActivated(pkg: Package): boolean {
    if (pkg.key === 'node-view' || pkg.key === 'cart' || pkg.key === 'checkout') {
      return this.configStore.configuration.nodes;
    }

    if (pkg.key === 'events' || pkg.key === 'event' || pkg.key === 'event-ticket' || pkg.key === 'event-cart' || pkg.key === 'event-checkout') {
      return this.configStore.configuration.events;
    }
    return true;
  }

  private getDataFromApi(): Promise<any> {
    this.loadingStore.setLoadingStateToLoading();
    return this.configStore.fetchConfiguration().then(() => this.currentUserStore.fetchUserData());
  }
}

export default withRouter(Main);
