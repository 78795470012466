import { observable, action } from 'mobx';

class NodesListStore {
  @observable public parentId: number = 1;

  @action('Set parent ID')
  public setParentId(parentId: number): void {
    this.parentId = parentId;
  }
}

export { NodesListStore };
