import * as React from 'react';
import { SVG } from './/SVG';

const Minus = (props: SVG) => (
  <div className={props.className}>
    <svg version="1.1" id="Capa_1" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 22.354 22.354">
      <g>
        <rect y="8.61" width="22.354" height="5.133" />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  </div>
);

export default Minus;
