import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { TicketCartItem, Ticket, Event, EventImage, Image } from '../../stores/models/models';
import { TicketStore, EventStore, ImageStore, CartStore, LoadingStore } from '../../stores/stores';

import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import LoadingSpinner from './LoadingSpinner';

import moment from 'moment';
import { Notifications } from 'core/notifications/notifications';

import './styles/eventDetailsCartCardStyle.css';

const dateTimeDb: string = 'YYYY-MM-DD HH:mm:ss';
const dateFancyFormat: string = 'MMMM Do YYYY';
const dateTimeFancyFormat: string = 'MMMM Do YYYY, HH:mm';

import { Cross } from '../svgIcons/icons';

interface IEventDetailsCartCard {
  eventId: number;
  cartId: number;
}

@inject('rootStore')
@observer
export default class EventDetailsCartCard extends React.Component<IEventDetailsCartCard, {}> {
  @observable private deletionModalOpen: boolean = false;
  private ticketStore: TicketStore;
  private eventStore: EventStore;
  private cartStore: CartStore;
  private imageStore: ImageStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.ticketStore = props.rootStore.stores.ticket;
    this.eventStore = props.rootStore.stores.event;
    this.cartStore = props.rootStore.stores.cart;
    this.imageStore = props.rootStore.stores.image;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Ticket[], Event | Image[]]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const event: Event | undefined = this.eventStore.get(this.props.eventId);
    if (event === undefined) {
      throw new Error(`Error: Event with ID ${this.props.eventId} does not exist`);
    }

    const eventImage: EventImage | undefined = this.eventStore.eventImages.find((eventImage: EventImage) => eventImage.eventId === event.id && eventImage.featured);

    let image: Image | undefined;
    if (eventImage !== undefined) {
      image = this.imageStore.get(eventImage.imageId);
    }

    let imagePath;
    if (image !== undefined) {
      imagePath = `/assets/${image.fileName}`;
    }

    const fallbackImagePath: string = '/static-images/event.jpg';

    const eventTickets: Ticket[] = this.ticketStore.tickets.filter((ticket: Ticket) => ticket.eventId === this.props.eventId);

    const ticketCartItems: TicketCartItem[] = this.ticketStore.ticketCartItems.filter(
      (ticketCartItem: TicketCartItem) => ticketCartItem.cartId === this.props.cartId && eventTickets.findIndex((ticket: Ticket) => ticket.id === ticketCartItem.ticketId) > -1
    );

    const relevantTickets: Ticket[] = eventTickets.filter((ticket: Ticket) => ticketCartItems.findIndex((ticketCartItem: TicketCartItem) => ticketCartItem.ticketId === ticket.id) > -1);

    return (
      <Col>
        <Row className="sjp-mb-1">
          <Col className="sjp-background-card-color">
            <Row className="sjp-darkgrey-background sjp-pt-1 sjp-pb-1">
              <Col>
                <div className="sjp-white-title-text sjp-text-uppercase">{event.name}</div>
              </Col>
              <Col className="sjp-align-right sjp-text-right">
                <a href="#" className="sjp-align-right sjp-justify-end " onClick={this.toggleDeletionModal.bind(this)}>
                  <Cross className="sjp-width-1 sjp-white-fill sjp-height-1" />
                </a>
                <Modal isOpen={this.deletionModalOpen} toggle={this.toggleDeletionModal.bind(this)} centered>
                  <ModalHeader toggle={this.toggleDeletionModal.bind(this)}>Booking Deletion</ModalHeader>
                  <ModalBody>
                    Are you sure you want to delete your tickets for {event.name} on the {moment(event.fromTo[0].from, dateTimeDb).format(dateFancyFormat)}?
                  </ModalBody>
                  <ModalFooter>
                    <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button-grey" onClick={this.toggleDeletionModal.bind(this)}>
                      No
                    </Button>{' '}
                    <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button" onClick={this.handleDeleteTickets.bind(this)}>
                      Yes
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
            <Row className="sjp-mt-1 sjp-mb-1 sjp-mr-0 sjp-ml-0 sjp-event-details-cart-card-height">
              <Col xs="12" sm="12" md="4" lg="4" xl="4" className="sjp-event-details-cart-card-height">
                <Row>
                  <img src={imagePath ? imagePath : fallbackImagePath} className="sjp-mx-auto sjp-pl-0 sjp-pr-0 sjp-d-block sjp-event-details-card-img sjp-image-fit" alt="Cover Photo" />
                </Row>
              </Col>
              <Col xs="12" sm="12" md="8" lg="8" xl="8" className="sjp-white-background sjp-mr-0 sjp-ml-0 sjp-event-details-cart-card-height">
                <Row className="sjp-mt-5 sjp-ml-1 sjp-mr-1">
                  <Col>
                    <Row>
                      <Col xs="12" sm="12" md="3" lg="3" xl="3" className="sjp-label sjp-text-uppercase sjp-mb-1">
                        Start
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6" xl="6" className="sjp-value sjp-text-uppercase sjp-mb-1">
                        {moment(event.fromTo[0].from, dateTimeDb).format(dateTimeFancyFormat)}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="12" md="3" lg="3" xl="3" className="sjp-label sjp-text-uppercase sjp-mb-1">
                        End
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6" xl="6" className="sjp-value sjp-text-uppercase sjp-mb-1">
                        {moment(event.fromTo[0].to, dateTimeDb).format(dateTimeFancyFormat)}
                      </Col>
                    </Row>
                    <Row className="sjp-mt-1">
                      <Col xs="12" sm="12" md="3" lg="3" xl="3" className="sjp-label sjp-text-uppercase sjp-mb-1">
                        Tickets
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6" xl="6" className="sjp-value sjp-text-uppercase sjp-mb-1">
                        {relevantTickets.length === 0 ? (
                          'N/A'
                        ) : (
                          relevantTickets.map((ticket: Ticket) => {
                            const ticketCartItem: TicketCartItem | undefined = this.ticketStore.ticketCartItems.find((ticketCartItem: TicketCartItem) => ticketCartItem.cartId === this.props.cartId && ticketCartItem.ticketId === ticket.id);
                            if (ticketCartItem === undefined) {
                              throw new Error(`Error: Ticket Cart Item of Cart ID ${this.props.cartId} and Ticket ID ${ticket.id} does not exist`);
                            }
                            return (
                              <p key={ticket.id}>
                                {ticketCartItem.quantity}x {ticket.name}
                              </p>
                            );
                          })
                        )}
                      </Col>
                    </Row>
                    <Row className="sjp-mt-1 sjp-edit-details-card-cart-button-row">
                      <a href={`/event-tickets/${event.id}`} className="sjp-full-width">
                        <Button className="sjp-event-card-cart-details-button-position sjp-edit-button sjp-no-border-radius sjp-text-uppercase sjp-full-width">edit tickets</Button>
                      </a>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }

  private handleDeleteTickets(): void {
    this.toggleDeletionModal();
    this.ticketStore
      .deleteTicketCartItemByCartIdAndEventId(this.props.cartId, this.props.eventId)
      .then(() => {
        this.cartStore.fetchCartById(this.props.cartId);
      })
      .catch((err) => {
        Notifications.displayError(err);
      });
  }

  @action('Toggle the deletionModalOpen variable')
  private toggleDeletionModal(): void {
    this.deletionModalOpen = !this.deletionModalOpen;
  }

  private getDataFromApi(): Promise<[Ticket[], Event | Image[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const ticketPromsies: Promise<Ticket[]> = this.ticketStore.fetchTicketCartItemsByCartIdAndEventId(this.props.cartId, this.props.eventId).then((ticketCartItems: TicketCartItem[]) => {
      return Promise.all(ticketCartItems.map((ticketCartItem: TicketCartItem) => this.ticketStore.fetchTicketById(ticketCartItem.ticketId)));
    });
    const eventPromise: Promise<Event | Image[]> = this.eventStore.fetchEventById(this.props.eventId).then((event: Event) => {
      return this.eventStore.fetchEventImagesByEventIdWithParams(event.id, true, false).then((eventImages: EventImage[]) => {
        return Promise.all(eventImages.map((eventImage: EventImage) => this.imageStore.fetchImageById(eventImage.imageId)));
      });
    });
    return Promise.all([ ticketPromsies, eventPromise ]);
  }
}
