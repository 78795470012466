import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Event, Ticket } from '../../stores/models/models';
import { EventStore, TicketStore, LoadingStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';
import TicketCard from './TicketCard';
import { Notifications } from 'core/notifications/notifications';

interface IEventTicketsSelectorProps {
  eventId: number;
  cartId: number;
}

@inject('rootStore')
@observer
export default class EventTicketsSelector extends React.Component<IEventTicketsSelectorProps, {}> {
  private eventStore: EventStore;
  private ticketStore: TicketStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.eventStore = props.rootStore.stores.event;
    this.ticketStore = props.rootStore.stores.ticket;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Event, Ticket[]]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const event: Event | undefined = this.eventStore.get(this.props.eventId);

    if (event === undefined) {
      throw new Error(`Error: Event with ID ${this.props.eventId} does not exist`);
    }

    const tickets: Ticket[] = this.ticketStore.tickets.filter((ticket: Ticket) => ticket.eventId === this.props.eventId);

    return (
      <div className="sjp-mb-3">
        {tickets.map((ticket: Ticket) => (
          <div key={ticket.id}>
            <TicketCard ticketId={ticket.id} cartId={this.props.cartId} />
          </div>
        ))}
      </div>
    );
  }

  private getDataFromApi(): Promise<[Event, Ticket[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const eventPromise: Promise<Event> = this.eventStore.fetchEventById(this.props.eventId);
    const ticketsPromise: Promise<Ticket[]> = this.ticketStore.fetchTicketsByEventId(this.props.eventId);
    return Promise.all([ eventPromise, ticketsPromise ]);
  }
}
