import * as React from 'react';
import { notification, Icon } from 'antd';
import { NetworkError, FormValidationError } from '../errors';
import WarningError from '../libraries/network/Warning';

interface INotification {
  title: string;
  description?: string;
  duration?: number;
  icon?: string;
}

const successProps = {
  duration: 2.5,
  icon: 'check'
};

const warningProps = {
  duration: 6,
  icon: 'exclamation'
};

const errorProps = {
  duration: 0, // Do not automatically close error notifications
  icon: 'warning'
};

notification.config({ placement: 'bottomRight' });

class Notifications {
  public static showNotification(options: INotification) {
    notification.open({
      message: options.title,
      description: options.description,
      duration: options.duration,
      icon: options.icon ? <Icon type={options.icon} /> : null
    });
  }

  public static showSuccess(title: string, description: string) {
    const props = Object.assign({ title, description }, successProps);
    this.showNotification(props);
  }

  public static showWarning(title: string, description: string) {
    const props = Object.assign({ title, description }, warningProps);
    this.showNotification(props);
  }

  public static showError(title: string, description: string) {
    const props = Object.assign({ title, description }, errorProps);
    this.showNotification(props);
  }

  // This function should replace showError as it will be aware of the types of Errors passed
  public static displayError(error: Error): void {
    // tslint:disable-next-line
    console.error(error.stack);
    if (error instanceof NetworkError) {
      // TODO: Best not rely on message - better if we send a payload such as {logged_in: false}
      if (!error.body.message.includes('not logged in')) {
        this.showNetworkError(error);
      }
    } else if (error instanceof WarningError) {
      this.showApiWarning(error);
    } else if (error instanceof FormValidationError) {
      this.showFormValidationWarning(error);
    } else if (error.message === 'record not found') {
      this.showError('Database Error', 'The Record you requested may have been deleted from the Database. Please try again.');
    } else {
      this.showError('Uncaught Error', error.message);
    }
  }

  public static showNetworkError(error: any) {
    const errorMessage = `Error contacting server: ${error.body.message}`;
    this.showError(`Server Error (${error.status}) (${error.statusText})`, errorMessage);
  }

  private static showFormValidationWarning(error: FormValidationError) {
    this.showWarning('Validation Error', error.message);
  }

  private static showApiWarning(error: WarningError) {
    this.showWarning('Warning', error.message);
  }
}

export { Notifications };
