import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import { Col, Row, Button, Container } from 'reactstrap';

import { Booking, Cart, User, Config } from '../../stores/models/models';
import { BookingStore, CartStore, UIStore, LoadingStore, CurrentUserStore, ConfigStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';

import './styles/yourCartCardStyle.css';

import CartCardItem from './CartCardItem';
import { Notifications } from 'core/notifications/notifications';

interface IYourCartCardProps {
  cartId: number;
  cart: boolean;
}

@inject('rootStore')
@observer
export default class YourCartCard extends React.Component<IYourCartCardProps, {}> {
  private bookingStore: BookingStore;
  private cartStore: CartStore;
  private uiStore: UIStore;
  private currentUserStore: CurrentUserStore;
  private configStore: ConfigStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.bookingStore = props.rootStore.stores.booking;
    this.cartStore = props.rootStore.stores.cart;
    this.uiStore = props.rootStore.stores.ui;
    this.currentUserStore = props.rootStore.stores.currentUser;
    this.configStore = props.rootStore.stores.config;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Cart, Booking[], Config]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const currentUser: User | undefined = this.currentUserStore.currentUser;

    if (currentUser === undefined) {
      return <Redirect to="/" />;
    }

    const bookings: Booking[] = this.bookingStore.bookings.filter((booking: Booking) => booking.cartId === this.props.cartId);

    const cart: Cart | undefined = this.cartStore.get(this.props.cartId);

    if (cart === undefined) {
      throw new Error(`Error: Cart of ID ${this.props.cartId} does not exist`);
    }

    const calculatedPrice: string = (Math.round(cart.priceIncludingVat * 100) / 100).toFixed(2);

    return (
      <Container>
        <Row>
          <Col className="sjp-text-center">
            <Row>
              <Col className="sjp-background-primary sjp-pt-1 sjp-pb-1">
                <div className="sjp-no-margin sjp-white-title-text sjp-text-uppercase">Your Cart</div>
              </Col>
            </Row>
            <Row className="sjp-background-card-color">
              {bookings.map((booking: Booking) => {
                return <CartCardItem key={booking.id} bookingId={booking.id} />;
              })}
            </Row>
            <Row className="sjp-background-card-color sjp-pt-1 sjp-pb-1">
              <Col xs="6" sm="6" md="6" lg="6" xl="6" className="sjp-text-align-left">
                <h6 className="sjp-card-title sjp-text-uppercase sjp-mb-0">total</h6>
              </Col>
              <Col xs="6" sm="6" md="6" lg="6" xl="6">
                <h6 className="sjp-card-title sjp-text-uppercase">€ {(Math.round(cart.priceIncludingVat * 100) / 100).toFixed(2)}</h6>
              </Col>
            </Row>
            <Row className="sjp-white-spacer" />
            <Row className="sjp-background-card-color sjp-pt-1 sjp-pb-1">
              <Col>
                <h6 className="sjp-description-text">Prices include VAT. Cancellation policy: Full refund of fees for cancellation up to 24 hours prior to event</h6>
              </Col>
            </Row>
            <Row>
              {this.props.cart ? (
                <Button className="sjp-button sjp-button-text sjp-no-border-radius button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1" type="submit" onClick={this.handleProceedToCheckout.bind(this)}>
                  PROCEED TO CHECKOUT
                </Button>
              ) : cart.priceIncludingVat === 0 ? (
                <Button className="sjp-button sjp-button-text sjp-no-border-radius button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1" type="submit" onClick={this.handleBook.bind(this)}>
                  BOOK
                </Button>
              ) : (
                <Button className="sjp-button sjp-button-text sjp-no-border-radius button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1" type="submit" onClick={this.handleProceedToPayment.bind(this)}>
                  PROCEED TO PAYMENT
                </Button>
              )}
            </Row>
            {/* Hidden Form */}
            <Row style={{ backgroundColor: 'red', display: 'none' }}>
              <p style={{ color: 'orange' }}>THIS MUST BE HIDDEN</p>
              <form method="post" action="https://www.cardpaydirect.com/MerchantPages/secure/RightBrain/paymentGateway2.jsp">
                <input name="amount" value={calculatedPrice} />
                <input name="uuid" value={cart.uuid} />
                <input name="useremail" value={currentUser.emailAddress} />
                <input name="redirecturl" value={this.configStore.configuration.redirectUrl} />
                <input name="logo" value={this.configStore.configuration.logo} />
                <input name="referer" value={this.configStore.configuration.referer} />
                <input id="form-submit" type="submit" value="Submit" />
              </form>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  private handleProceedToCheckout(): void {
    let redirectData = {
      reason: 'Proceed to Checkout',
      data: null,
      target: `/checkout/${this.props.cartId}`
    };
    this.uiStore.initiateRedirect(redirectData);
  }

  private handleProceedToPayment(): void {
    const submitButton: HTMLElement | null = document.getElementById('form-submit');
    if (submitButton === null) {
      alert('ERROR: Unable to submit form');
      return;
    }
    submitButton.click();
  }

  private handleBook(): void {
    this.cartStore
      .confirmCart(this.props.cartId)
      .then((carts: Cart[]) => {
        window.location.assign(`${window.location.origin}/success`);
      })
      .catch((err) => {
        window.location.assign(`${window.location.origin}/notsuccess`);
      });
  }

  private getDataFromApi(): Promise<[Cart, Booking[], Config]> {
    this.loadingStore.setLoadingStateToLoading();
    const cartPromise: Promise<Cart> = this.cartStore.fetchCartById(this.props.cartId);
    const bookingPromise: Promise<Booking[]> = this.bookingStore.fetchBookingsByCartId(this.props.cartId).then((bookings: Booking[]) => {
      return bookings;
    });
    const configPromise: Promise<Config> = this.configStore.fetchConfiguration();
    return Promise.all([ cartPromise, bookingPromise, configPromise ]);
  }
}
