import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { configure } from 'mobx';

import TopMostRowApp from './TopMostRowApp';

// MobX configuration
configure({
  enforceActions: true
});

ReactDOM.render(<TopMostRowApp />, document.getElementById('top'));
