import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { Ticket, TicketCartItem, TicketsSold } from './models/models';

class TicketStore extends GenericStore<Ticket> {
  private rootStore: RootStore;
  private ticketCartItemStore = new GenericStore<TicketCartItem>();

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  // Ticket

  public get tickets(): Ticket[] {
    return this.contents;
  }

  public fetchTickets(): Promise<Ticket[]> {
    return this.rootStore.services.ticket.fetchTickets().then((tickets: Ticket[]) => {
      this.addOrUpdateAll(tickets);
      return tickets;
    });
  }

  public fetchTicketById(ticketId: number): Promise<Ticket> {
    return this.rootStore.services.ticket.fetchTicketById(ticketId).then((ticket: Ticket) => {
      this.addOrUpdate(ticket);
      return ticket;
    });
  }

  public fetchTicketsByEventId(eventId: number): Promise<Ticket[]> {
    return this.rootStore.services.ticket.fetchTicketsByEventId(eventId).then((tickets: Ticket[]) => {
      this.addOrUpdateAll(tickets);
      return tickets;
    });
  }

  public fetchSoldTickets(ticketId: number): Promise<TicketsSold> {
    return this.rootStore.services.ticket.fetchSoldTickets(ticketId);
  }

  public createOrUpdateTicket(ticket: Ticket): Promise<Ticket> {
    return this.rootStore.services.ticket.createOrUpdateTicket(ticket).then((ticket: Ticket) => {
      this.addOrUpdate(ticket);
      return ticket;
    });
  }

  public deleteTicket(ticketId: number): Promise<void> {
    return this.rootStore.services.ticket.deleteTicket(ticketId).then(() => {
      this.remove(ticketId);
    });
  }

  // Ticket Cart Item

  public get ticketCartItems(): TicketCartItem[] {
    return this.ticketCartItemStore.contents;
  }

  public fetchTicketCartItems(): Promise<TicketCartItem[]> {
    return this.rootStore.services.ticket.fetchTicketCartItems().then((ticketCartItems: TicketCartItem[]) => {
      this.ticketCartItemStore.addOrUpdateAll(ticketCartItems);
      return ticketCartItems;
    });
  }

  public fetchConfirmedTicketCartItems(): Promise<TicketCartItem[]> {
    return this.rootStore.services.ticket.fetchConfirmedTicketCartItems().then((ticketCartItems: TicketCartItem[]) => {
      this.ticketCartItemStore.addOrUpdateAll(ticketCartItems);
      return ticketCartItems;
    });
  }

  public fetchTicketCartItemById(ticketCartItemId: number): Promise<TicketCartItem> {
    return this.rootStore.services.ticket.fetchTicketCartItemById(ticketCartItemId).then((ticketCartItem: TicketCartItem) => {
      this.ticketCartItemStore.addOrUpdate(ticketCartItem);
      return ticketCartItem;
    });
  }

  public fetchTicketCartItemsByCartId(cartId: number): Promise<TicketCartItem[]> {
    return this.rootStore.services.ticket.fetchTicketCartItemsByCartId(cartId).then((ticketCartItems: TicketCartItem[]) => {
      this.ticketCartItemStore.addOrUpdateAll(ticketCartItems);
      return ticketCartItems;
    });
  }

  public fetchTicketCartItemsByCartIdAndEventId(cartId: number, eventId: number): Promise<TicketCartItem[]> {
    return this.rootStore.services.ticket.fetchTicketCartItemsByCartIdAndEventId(cartId, eventId).then((ticketCartItems: TicketCartItem[]) => {
      this.ticketCartItemStore.addOrUpdateAll(ticketCartItems);
      return ticketCartItems;
    });
  }

  public fetchTicketCartItemsByTicketId(ticketId: number): Promise<TicketCartItem[]> {
    return this.rootStore.services.ticket.fetchTicketCartItemsByTicketId(ticketId).then((ticketCartItems: TicketCartItem[]) => {
      this.ticketCartItemStore.addOrUpdateAll(ticketCartItems);
      return ticketCartItems;
    });
  }

  public fetchTicketCartItemTicketPdfsByCartId(cartId: number): Promise<any> {
    return this.rootStore.services.ticket.fetchTicketCartItemTicketPdfsByCartId(cartId);
  }

  public createOrUpdateTicketCartItem(ticketCartItem: TicketCartItem): Promise<TicketCartItem> {
    return this.rootStore.services.ticket.createOrUpdateTicketCartItem(ticketCartItem).then((ticketCartItem: TicketCartItem) => {
      this.ticketCartItemStore.addOrUpdate(ticketCartItem);
      return ticketCartItem;
    });
  }

  public deleteTicketCartItem(ticketCartItemId: number): Promise<void> {
    return this.rootStore.services.ticket.deleteTicketCartItem(ticketCartItemId).then(() => {
      this.ticketCartItemStore.remove(ticketCartItemId);
    });
  }

  public deleteTicketCartItemByCartIdAndEventId(cartId: number, eventId: number): Promise<TicketCartItem[]> {
    return this.rootStore.services.ticket.deleteTicketCartItemByCartIdAndEventId(cartId, eventId).then((ticketCartItems: TicketCartItem[]) => {
      ticketCartItems.forEach((ticketCartItem: TicketCartItem) => this.ticketCartItemStore.remove(ticketCartItem.id));
    });
  }
}

export { TicketStore };
