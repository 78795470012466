import { observable, action } from 'mobx';

import { Ticket } from './models/models';
import { RootStore } from './RootStore';

class TicketEditStore {
  @observable public ticket: Ticket;
  @observable public unlimitedChecked: boolean = false;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action('Fetching Ticket to be edited')
  public fetchTicketForEditing(ticketId: number): Promise<Ticket> {
    if (ticketId === 0) {
      const newTicket: Ticket = new Ticket();
      this.setCurrentTicket(newTicket);
      return Promise.resolve(newTicket);
    }
    return this.rootStore.stores.ticket.fetchTicketById(ticketId).then((ticket: Ticket) => {
      if (!ticket) {
        throw new Error(`Ticket with ID ${ticketId} does not exist`);
      }
      this.setCurrentTicket(ticket);
      return ticket;
    });
  }

  @action('Creating or Updating the current ticket with new details')
  public createOrUpdateCurrentTicket(newTicket: Ticket): Promise<Ticket> {
    if (!this.ticket) {
      throw new Error(`Unable to update a ticket if one does not exist`);
    }
    return this.rootStore.stores.ticket.createOrUpdateTicket(newTicket).then((ticket: Ticket) => {
      if (!ticket) {
        throw new Error(`Unable to update ticket with ID ${this.ticket.id}`);
      }
      this.setCurrentTicket(ticket);
      return ticket;
    });
  }

  @action('Set Ticket that is currently being edited')
  private setCurrentTicket(ticket: Ticket): void {
    this.ticket = ticket;
  }

  @action('Toggle the unlimitedChecked observable')
  public toggleUnlimitedChecked(): void {
    this.unlimitedChecked = !this.unlimitedChecked;
  }

  @action('Set unlimitedChecked observable')
  public setUnlimitedChecked(value: boolean): void {
    this.unlimitedChecked = value;
  }
}

export { TicketEditStore };
