import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { Roster, RosterNode } from './models/models';

class RosterStore extends GenericStore<Roster> {
  private rootStore: RootStore;
  private rosterNodeStore = new GenericStore<RosterNode>();

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  // Rosters

  public get rosters(): Roster[] {
    return this.contents;
  }

  public fetchRosters(): Promise<Roster[]> {
    return this.rootStore.services.roster.fetchRosters().then((rosters: Roster[]) => {
      this.addOrUpdateAll(rosters);
      return rosters;
    });
  }

  public fetchRostersByUserId(userId: number): Promise<Roster[]> {
    return this.rootStore.services.roster.fetchRostersByUserId(userId).then((rosters: Roster[]) => {
      this.addOrUpdateAll(rosters);
      return rosters;
    });
  }

  public createOrUpdateRoster(roster: Roster): Promise<Roster> {
    return this.rootStore.services.roster.createOrUpdateRoster(roster).then((roster: Roster) => {
      this.addOrUpdate(roster);
      return roster;
    });
  }

  public createRosters(rosters: Roster[], userId: number, dayOfWeek: number): Promise<Roster[]> {
    return this.rootStore.services.roster.createRosters(rosters, userId, dayOfWeek).then((rosters: Roster[]) => {
      this.addOrUpdateAll(rosters);
      return rosters;
    });
  }

  public deleteRoster(rosterId: number): Promise<void> {
    return this.rootStore.services.roster.deleteRoster(rosterId).then(() => {
      this.remove(rosterId);
    });
  }

  // Roster Nodes

  public get rosterNodes(): RosterNode[] {
    return this.rosterNodeStore.contents;
  }

  public fetchRosterNodes(): Promise<RosterNode[]> {
    return this.rootStore.services.roster.fetchRosterNodes().then((rosterNodes: RosterNode[]) => {
      this.rosterNodeStore.addOrUpdateAll(rosterNodes);
      return rosterNodes;
    });
  }
}

export { RosterStore };
