import { JsonObject, JsonProperty, JsonConvert, Any } from 'json2typescript';
import { isUndefined } from 'util';

@JsonObject
class BookingFacility {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('facilityID', Number)
  facilityId: number = 0;
  @JsonProperty('cartItemID', Number)
  bookingId: number = 0;
  @JsonProperty('price', Number)
  price: number = 0;
  @JsonProperty('price_inc_vat', Number)
  priceIncludingVat: number = 0;
  @JsonProperty('quantity', Number)
  quantity: number = 0;
  @JsonProperty('per_hour_from', Any)
  perHourFrom: string | null = null;
  @JsonProperty('per_hour_to', Any)
  perHourTo: string | null = null;
  @JsonProperty('per_day', Any)
  perDay: null = null;
  @JsonProperty('per_unit', Any)
  perUnit: number | null = null;
}

@JsonObject
class Booking {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('cart_id', Number)
  cartId: number = 0;
  @JsonProperty('item_id', Number)
  nodeId: number = 0;
  @JsonProperty('event_type', Number)
  eventTypeId: number = 0;
  @JsonProperty('description', String)
  description: string = '';
  @JsonProperty('all_day', Boolean)
  allDay: boolean = false;
  @JsonProperty('date_from', Any)
  dateFrom: string = '';
  @JsonProperty('date_to', Any)
  dateTo: string = '';
  @JsonProperty('price', Number)
  price: number = 0;
  @JsonProperty('price_inc_vat', Number)
  priceIncludingVat: number = 0;
  @JsonProperty('bulk_booking_id', Number)
  bulkBookingId: number = 0;
}

const exampleBookingDetails = {
  id: 3,
  item_id: 45,
  cart_id: 5,
  event_type: 3,
  description: 'This is a description',
  all_day: false,
  date_from: '2018-12-12',
  date_to: '2018-12-17',
  price: 45,
  price_inc_vat: 50,
  bulk_booking_id: 0
};

const bookingFromForm = (formData: {}) => {
  const booking = new Booking();
  for (const key in formData) {
    let value = formData[key];
    if (isUndefined(value)) {
      value = booking[key];
    }
    if (key === 'dateFrom' || key === 'dateTo') {
      value = value ? value.format() : '';
    }
    booking[key] = value;
  }
  return booking;
};

const jsonConvert: JsonConvert = new JsonConvert();
const exampleBooking: Booking = jsonConvert.deserialize(exampleBookingDetails, Booking);

export { BookingFacility, Booking, exampleBookingDetails, exampleBooking, bookingFromForm };
