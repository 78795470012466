import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';

import { Col, Row, Button, Container } from 'reactstrap';

import { TicketCartItem, Cart, User, Event, Ticket, Config } from '../../stores/models/models';
import { TicketStore, CartStore, LoadingStore, CurrentUserStore, EventStore, UIStore, ConfigStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';
import EventCartCardItem from './EventCartCardItem';
import { Notifications } from 'core/notifications/notifications';
// import { observable, action } from 'mobx';

interface IEventCartCardProps {
  cartId: number;
  cart: boolean;
}

@inject('rootStore')
@observer
export default class EventCartCard extends React.Component<IEventCartCardProps, {}> {
  private ticketStore: TicketStore;
  private cartStore: CartStore;
  private uiStore: UIStore;
  private currentUserStore: CurrentUserStore;
  private eventStore: EventStore;
  private configStore: ConfigStore;
  private loadingStore: LoadingStore = new LoadingStore();
  // @observable private shouldRedirect: boolean = false;

  constructor(props: any) {
    super(props);
    this.ticketStore = props.rootStore.stores.ticket;
    this.cartStore = props.rootStore.stores.cart;
    this.uiStore = props.rootStore.stores.ui;
    this.currentUserStore = props.rootStore.stores.currentUser;
    this.eventStore = props.rootStore.stores.event;
    this.configStore = props.rootStore.stores.config;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Cart, TicketCartItem[], Ticket[], Event[], Config]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch(err => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const currentUser: User | undefined = this.currentUserStore.currentUser;
    if (currentUser === undefined) {
      return <Redirect to="/" />;
    }

    const events: Event[] = this.listOfEventsIncludedInThisCart();

    const cart: Cart | undefined = this.cartStore.get(this.props.cartId);

    if (cart === undefined) {
      throw new Error(`Error: Cart with ID ${this.props.cartId} does not exist`);
    }

    // if (this.shouldRedirect) {
    //   return this.handleProceedToPayment(this.props.cartId);
    // }
    return (
      <Container>
        <Row>
          <Col className="sjp-text-center">
            <Row>
              <Col className="sjp-background-primary sjp-pt-1 sjp-pb-1">
                <div className="sjp-no-margin sjp-white-title-text sjp-text-uppercase">Your Cart</div>
              </Col>
            </Row>

            {events.length > 0 ? (
              <Row className="sjp-background-card-color">
                {events.map((event: Event) => {
                  return <EventCartCardItem key={event.id} eventId={event.id} cartId={this.props.cartId} />;
                })}
              </Row>
            ) : (
              <div>
                <Row className="sjp-background-card-color sjp-pt-1 sjp-pb-1">
                  <Col className="sjp-full-width sjp-text-center">
                    <div className="sjp-description-text sjp-mb-1 sjp-mt-1">There are currently no tickets in your cart</div>
                  </Col>
                </Row>
                <Row className="sjp-white-spacer" />
              </div>
            )}
            <Row className="sjp-background-card-color sjp-pt-1-point-5 sjp-pb-1">
              <Col xs="6" sm="6" md="6" lg="6" xl="6" className="sjp-text-align-left">
                <h6 className="sjp-card-title sjp-text-uppercase sjp-mb-0">total</h6>
              </Col>
              <Col xs="6" sm="6" md="6" lg="6" xl="6">
                <h6 className="sjp-card-title sjp-text-uppercase">€ {(Math.round(cart.priceIncludingVat * 100) / 100).toFixed(2)}</h6>
              </Col>
            </Row>
            <Row className="sjp-white-spacer" />
            <Row className="sjp-background-card-color sjp-pt-1 sjp-pb-1">
              <Col>
                <h6 className="sjp-description-text">Prices include VAT. Cancellation policy: Full refund of fees for cancellation up to 24 hours prior to event</h6>
              </Col>
            </Row>
            <Row>
              {this.props.cart ? (
                <Button className="sjp-button sjp-button-text sjp-no-border-radius button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1" type="submit" onClick={this.handleProceedToCheckout.bind(this)}>
                  PROCEED TO CHECKOUT
                </Button>
              ) : cart.priceIncludingVat === 0 ? (
                <Button className="sjp-button sjp-button-text sjp-no-border-radius button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1" type="submit" onClick={this.handleConfirmTickets.bind(this)}>
                  CONFIRM
                </Button>
              ) : (
                // <Button className="sjp-button sjp-button-text sjp-no-border-radius button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1" type="submit" href="#" onClick={this.setShouldRedirect.bind(this, true)}>
                <Button className="sjp-button sjp-button-text sjp-no-border-radius button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1" type="submit" href={`/paymentApcoPay?cartID=${this.props.cartId}`}>
                  PROCEED TO PAYMENT
                </Button>
              )}
            </Row>
            {/* Hidden Form */}
            {/* <Row style={{ backgroundColor: 'red', display: 'none' }}>
              <p style={{ color: 'orange' }}>THIS MUST BE HIDDEN</p>
              <form method="post" action="https://www.cardpaydirect.com/MerchantPages/secure/RightBrain/paymentGateway2.jsp">
                <input name="amount" value={cart.priceIncludingVat} />
                <input name="uuid" value={cart.uuid} />
                <input name="useremail" value={currentUser.emailAddress} />
                <input name="redirecturl" value={this.configStore.configuration.redirectUrl} />
                <input name="logo" value={this.configStore.configuration.logo} />
                <input name="referer" value={this.configStore.configuration.referer} />
                <input id="form-submit" type="submit" value="Submit" />
              </form>
            </Row> */}
          </Col>
        </Row>
      </Container>
    );
  }

  // @action('Set shouldRedirect')
  // private setShouldRedirect(shouldRedirect: boolean): void {
  //   this.shouldRedirect = shouldRedirect;
  // }
  private listOfEventsIncludedInThisCart(): Event[] {
    const events: Event[] = [];
    const ticketCartItems: TicketCartItem[] = this.ticketStore.ticketCartItems.filter((ticketCartItem: TicketCartItem) => ticketCartItem.cartId === this.props.cartId);
    const tickets: Ticket[] = [];
    ticketCartItems.forEach((ticketCartItem: TicketCartItem) => {
      const ticket: Ticket | undefined = this.ticketStore.get(ticketCartItem.ticketId);
      if (ticket === undefined) {
        throw new Error(`Error: Ticket with ID ${ticketCartItem.ticketId} does not exist`);
      }
      tickets.push(ticket);
    });
    tickets.forEach((ticket: Ticket) => {
      const event: Event | undefined = this.eventStore.get(ticket.eventId ? ticket.eventId : 0);
      if (event === undefined) {
        throw new Error(`Error: Event with ID ${ticket.eventId} does not exist`);
      }
      if (events.find((eventFromList: Event) => eventFromList.id === event.id) === undefined) {
        events.push(event);
      }
    });
    return events;
  }

  private handleProceedToCheckout(): void {
    let redirectData = {
      reason: 'Proceed to Checkout',
      data: null,
      target: `/event-checkout/${this.props.cartId}`
    };
    this.uiStore.initiateRedirect(redirectData);
  }

  // private handleProceedToPayment(cartID: number): JSX.Element {
  //   console.log('payment apcopay');
  //   const redirectUrl: string = `/paymentApcoPay?cartID=${cartID}`;
  //   return <Redirect to={redirectUrl} />;
  //   // const submitButton: HTMLElement | null = document.getElementById('form-submit');
  //   // if (submitButton === null) {
  //   //   alert('ERROR: Unable to submit form');
  //   //   return;
  //   // }
  //   // submitButton.click();
  // }

  private handleConfirmTickets(): void {
    this.cartStore
      .confirmCart(this.props.cartId)
      .then((carts: Cart[]) => {
        window.location.assign(`${window.location.origin}/success`);
      })
      .catch(err => {
        window.location.assign(`${window.location.origin}/notsuccess`);
      });
  }

  private getDataFromApi(): Promise<[Cart, TicketCartItem[], Ticket[], Event[], Config]> {
    this.loadingStore.setLoadingStateToLoading();
    const cartPromise: Promise<Cart> = this.cartStore.fetchCartById(this.props.cartId);
    const ticketCartItemPromise: Promise<TicketCartItem[]> = this.ticketStore.fetchTicketCartItemsByCartId(this.props.cartId);
    const ticketsPromise: Promise<Ticket[]> = this.ticketStore.fetchTickets();
    const eventsPromise: Promise<Event[]> = this.eventStore.fetchEvents();
    const configPromise: Promise<Config> = this.configStore.fetchConfiguration();
    return Promise.all([cartPromise, ticketCartItemPromise, ticketsPromise, eventsPromise, configPromise]);
  }
}
