import ApiService from './ApiService';

import { EventType } from '../stores/models/models';

class EventTypeService extends ApiService {
  constructor() {
    super();
  }

  public fetchEventTypes(): Promise<EventType[]> {
    const url: string = this.getUrl(`/EventTypes`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, EventType);
        }
        return [];
      });
  }

  public fetchEventTypeById(eventTypeId: number): Promise<EventType> {
    const url: string = this.getUrl(`/EventType/info/${eventTypeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, EventType);
      });
  }

  public createOrUpdateEventType(eventType: EventType): Promise<EventType> {
    const baseUrl: string = `/EventType`;
    const isNew: boolean = eventType.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/info/${eventType.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonEventType = this.jsonConvert.serialize(eventType);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonEventType)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, EventType);
      });
  }

  public deleteEventType(eventTypeId: number): Promise<void> {
    const url: string = this.getUrl(`/EventType/info/${eventTypeId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }
}

export default EventTypeService;
