import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Col, Row, Button } from 'reactstrap';

import { Booking, Node, NodeType, NodeFacility, NodeImage, Image, Facility, BookingFacility } from '../../stores/models/models';
import { BookingStore, NodeStore, NodeTypeStore, ImageStore, FacilityStore, LoadingStore } from '../../stores/stores';

import { Notifications } from 'core/notifications/notifications';

import LoadingSpinner from './LoadingSpinner';

import moment from 'moment';

import './styles/bookingCardStyle.css';

const dateTimeDb: string = 'YYYY-MM-DD HH:mm:ss';
const dateDisplay: string = 'MMMM Do YYYY';
const timeDisplay: string = 'HH:mm';

interface IBookingCardProps {
  bookingId: number;
  withButton?: boolean;
}

@inject('rootStore')
@observer
export default class BookingCards extends React.Component<IBookingCardProps, {}> {
  private bookingStore: BookingStore;
  private nodeStore: NodeStore;
  private nodeTypeStore: NodeTypeStore;
  private facilityStore: FacilityStore;
  private imageStore: ImageStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.bookingStore = props.rootStore.stores.booking;
    this.nodeStore = props.rootStore.stores.node;
    this.nodeTypeStore = props.rootStore.stores.nodeType;
    this.facilityStore = props.rootStore.stores.facility;
    this.imageStore = props.rootStore.stores.image;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((booking: Booking) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const booking: Booking | undefined = this.bookingStore.get(this.props.bookingId);

    if (booking === undefined) {
      throw new Error(`Booking with ID ${this.props.bookingId} does not exist`);
    }

    const node: Node | undefined = this.nodeStore.get(booking.nodeId);

    if (node === undefined) {
      throw new Error(`Node with ID ${booking.nodeId} does not exist`);
    }

    const nodeImage: NodeImage | undefined = this.nodeStore.nodeImages.find((nodeImage: NodeImage) => nodeImage.nodeId === node.id && nodeImage.featured);

    let image: Image | undefined;
    if (nodeImage !== undefined) {
      image = this.imageStore.get(nodeImage.imageId);
    }

    let imagePath;
    if (image !== undefined) {
      imagePath = `/assets/${image.fileName}`;
    }

    const fallbackImagePath: string = '/static-images/meeting.jpg';

    const nodeType: NodeType | undefined = this.nodeTypeStore.get(node.typeId);

    if (nodeType === undefined) {
      throw new Error(`Node Type with ID ${node.typeId} does not exist`);
    }

    const includedNodeFacilities: NodeFacility[] = this.nodeStore.nodeFacilities.filter((nodeFacility: NodeFacility) => nodeFacility.nodeId === node.id && nodeFacility.included);
    const includedFacilities: Facility[] = [];
    includedNodeFacilities.forEach((nodeFacility: NodeFacility) => {
      const facility: Facility | undefined = this.facilityStore.get(nodeFacility.facilityId);
      if (facility !== undefined) {
        includedFacilities.push(facility);
      }
    });

    const additionalNodeFacilities: NodeFacility[] = this.nodeStore.nodeFacilities.filter((nodeFacility: NodeFacility) => nodeFacility.nodeId === node.id && !nodeFacility.included);
    const additionalFacilities: Facility[] = [];
    additionalNodeFacilities.forEach((nodeFacility: NodeFacility) => {
      const facility: Facility | undefined = this.facilityStore.get(nodeFacility.facilityId);
      if (facility !== undefined) {
        additionalFacilities.push(facility);
      }
    });

    const bookingFacilities: BookingFacility[] = [];
    additionalFacilities.forEach((facility: Facility) => {
      const bookingFacility: BookingFacility | undefined = this.bookingStore.bookingFacilities.find((bookingFacility: BookingFacility) => bookingFacility.bookingId === this.props.bookingId && bookingFacility.facilityId === facility.id);
      if (bookingFacility !== undefined) {
        bookingFacilities.push(bookingFacility);
      }
    });

    return (
      <Col>
        <Row>
          <Col xs="12" sm="12" md="4" lg="4" xl="4" className="sjp-pr-0 sjp-pl-0">
            <Row>
              <Col>
                <img src={imagePath ? imagePath : fallbackImagePath} className="sjp-mx-auto sjp-d-block sjp-card-details-img sjp-image-fit" alt="Cover Photo" />
              </Col>
            </Row>
          </Col>
          <Col xs="12" sm="12" md="8" lg="8" xl="8" className="sjp-white-background sjp-details-card-shift sjp-mr-0 sjp-ml-0 sjp-card-details-img">
            <Row className="sjp-mt-3 sjp-ml-1 sjp-mr-1">
              <Col>
                <Row>
                  <Col className="sjp-label sjp-text-uppercase sjp-mb-1">SITE TYPE</Col>
                  <Col className="sjp-value sjp-text-uppercase">{nodeType.name}</Col>
                </Row>
                <Row>
                  <Col className="sjp-label sjp-text-uppercase sjp-mb-1">date selected</Col>
                  <Col className="sjp-value sjp-text-uppercase">{moment(booking.dateFrom, dateTimeDb).format(dateDisplay)}</Col>
                </Row>
                <Row>
                  <Col className="sjp-label sjp-text-uppercase sjp-mb-1">check in time</Col>
                  <Col className="sjp-value sjp-text-uppercase">{moment(booking.dateFrom, dateTimeDb).format(timeDisplay)}</Col>
                </Row>
                <Row>
                  <Col className="sjp-label sjp-text-uppercase sjp-mb-1">check out time</Col>
                  <Col className="sjp-value sjp-text-uppercase">{moment(booking.dateTo, dateTimeDb).format(timeDisplay)}</Col>
                </Row>
                <Row>
                  <Col className="sjp-label sjp-text-uppercase sjp-mb-1">duration</Col>
                  <Col className="sjp-value sjp-text-uppercase">{Math.round(moment.duration(moment(booking.dateTo, dateTimeDb).diff(moment(booking.dateFrom, dateTimeDb))).asHours() * 100) / 100} hours</Col>
                </Row>
                <Row>
                  <Col className="sjp-label sjp-text-uppercase sjp-mb-1">booking includes</Col>
                  <Col className="sjp-value sjp-text-uppercase">{includedFacilities.length === 0 ? 'N/A' : includedFacilities.map((facility: Facility) => facility.name).join(' / ')}</Col>
                </Row>
                <Row>
                  <Col className="sjp-label sjp-text-uppercase sjp-mb-1">extra amenities</Col>
                  <Col className="sjp-value sjp-text-uppercase">
                    {bookingFacilities.length === 0 ? (
                      'N/A'
                    ) : (
                      bookingFacilities
                        .map((bookingFacility: BookingFacility) => {
                          const facility: Facility | undefined = this.facilityStore.get(bookingFacility.facilityId);
                          if (facility === undefined) {
                            throw new Error(`Error: Facility of ID ${bookingFacility.facilityId} does not exist`);
                          }
                          return `${bookingFacility.quantity}x ${facility.name}`;
                        })
                        .join(' / ')
                    )}
                  </Col>
                </Row>
                {this.props.withButton ? (
                  <Row className="sjp-mt-1 sjp-mb-1">
                    <a href={`/node/${node.id}?booking=${booking.id}`} className="sjp-full-width">
                      <Button className="sjp-booking-card-button-position sjp-edit-button sjp-no-border-radius sjp-text-uppercase sjp-full-width">edit booking</Button>
                    </a>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }

  private getDataFromApi(): Promise<Booking> {
    this.loadingStore.setLoadingStateToLoading();
    return this.bookingStore.fetchBookingById(this.props.bookingId).then((booking: Booking) => {
      return this.nodeStore.fetchNodeById(booking.nodeId).then((node: Node) => {
        return this.nodeTypeStore.fetchNodeTypeById(node.typeId).then(() => {
          return this.nodeStore.fetchNodeFacilitiesByNodeId(node.id).then((nodeFacilities: NodeFacility[]) => {
            return this.bookingStore.fetchBookingFacilitiesByBookingId(this.props.bookingId).then((bookingFacilities: BookingFacility[]) => {
              return this.nodeStore
                .fetchNodeImagesByNodeId(node.id, true)
                .then((nodeImages: NodeImage[]) => {
                  return nodeImages.map((nodeImage: NodeImage) => {
                    return this.imageStore.fetchImageById(nodeImage.imageId);
                  });
                })
                .then(() => {
                  nodeFacilities.forEach((nodeFacility: NodeFacility) => {
                    this.facilityStore.fetchFacilityById(nodeFacility.facilityId);
                  });
                  return booking;
                });
            });
          });
        });
      });
    });
  }
}
