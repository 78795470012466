import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { isUndefined } from 'util';

@JsonObject
class CartCode {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('cart_id', Number)
  cartId: number = 0;
  @JsonProperty('code', String)
  code: string = '';
}

@JsonObject
class RedeemCode {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('code', String)
  code: string = '';
  @JsonProperty('valid_from', Any)
  validFrom: string | null = null;
  @JsonProperty('valid_to', Any)
  validTo: string | null = null;
  @JsonProperty('discount', Number)
  discount: number = 0;
  @JsonProperty('disc_type', String)
  discountType: string = '';
  @JsonProperty('quantity', Number)
  quantity: number = 0;
}

const redeemCodeFromForm = (formData: any) => {
  const redeemCode: RedeemCode = new RedeemCode();
  for (const key in formData) {
    let value = formData[key];
    if (isUndefined(value)) {
      value = redeemCode[key];
    } else if (key === 'unlimitedQuantity') {
      if (value === true) {
        redeemCode.quantity = -1;
      }
    } else if (key === 'validFrom') {
      value.set({
        hours: 0,
        minutes: 0,
        seconds: 0
      });
      value = value ? value.format() : '';
    } else if (key === 'validTo') {
      value.set({
        hours: 23,
        minutes: 59,
        seconds: 59
      });
      value = value ? value.format() : '';
    } else if (key === 'discount' && formData.discountType === 'Percentage') {
      value = value / 100;
    } else if (key === 'quantity') {
      if (value === '' || value === null) {
        value = 0;
      } else {
        value = parseInt(value);
      }
    }
    redeemCode[key] = value;
  }
  return redeemCode;
};

export { CartCode, RedeemCode, redeemCodeFromForm };
