import { User } from './models/User';

import { RootStore } from './RootStore';
import { observable, action } from 'mobx';

export default class CurrentUserStore {
  @observable private user: User | undefined;
  private rootStore: RootStore;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public get currentUser(): User | undefined {
    return this.user;
  }

  public fetchUserData(): Promise<User> {
    return this.rootStore.stores.user.fetchLoggedInUser().then((user: User) => {
      this.setCurrentUser(user);
      return user;
    });
  }

  public login(idCard: string, password: string): Promise<User> {
    return this.rootStore.services.user.loginUser(idCard, password).then(() => {
      return this.fetchUserData();
    });
  }

  public logout() {
    return this.rootStore.services.user.logoutCurrentUser().then(() => {
      this.setCurrentUser(undefined);
      this.rootStore.stores.ui.initiateRedirect({ target: '/admin/logout', reason: 'logout' });
    });
  }

  public logoutFromWebsite() {
    return this.rootStore.services.user.logoutCurrentUser().then(() => {
      this.setCurrentUser(undefined);
      window.location.assign(`${window.location.origin}/home`);
      return;
    });
  }

  @action('Setting the Current User')
  private setCurrentUser(user: User | undefined) {
    this.user = user;
  }
}
