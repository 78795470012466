import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Row, Col, Button, Alert } from 'reactstrap';

import { Node, NodeImage, Image } from '../../stores/models/models';
import { NodeStore, ImageStore, LoadingStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';

import './styles/nodeCardStyle.css';
import { Notifications } from 'core/notifications/notifications';

interface INodesCardProps {
  nodeId: number;
}

@inject('rootStore')
@observer
export default class NodesCard extends React.Component<INodesCardProps, {}> {
  private nodeStore: NodeStore;
  private imageStore: ImageStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.nodeStore = props.rootStore.stores.node;
    this.imageStore = props.rootStore.stores.image;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Node, NodeImage[]]) => {
        const nodeImages: NodeImage[] = data[1];
        if (nodeImages.length > 0) {
          this.imageStore
            .fetchImageById(nodeImages[0].imageId)
            .then((image: Image) => {
              this.loadingStore.setLoadingStateToDone();
            })
            .catch((err) => {
              this.loadingStore.setLoadingStateToError();
              Notifications.displayError(err);
            });
        } else {
          this.loadingStore.setLoadingStateToDone();
        }
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const node: Node | undefined = this.nodeStore.get(this.props.nodeId);

    if (node === undefined) {
      return <Alert color="danger">{`Error: site with ID ${this.props.nodeId} does not exist!`}</Alert>;
    }

    let nodeImage: NodeImage | undefined = this.nodeStore.nodeImages.find((nodeImage: NodeImage) => nodeImage.nodeId === this.props.nodeId && nodeImage.featured);

    if (nodeImage === undefined) {
      nodeImage = this.nodeStore.nodeImages.find((nodeImage: NodeImage) => nodeImage.nodeId === this.props.nodeId);
    }

    let image: Image | undefined;
    if (nodeImage !== undefined) {
      image = this.imageStore.get(nodeImage.imageId);
    }

    let imagePath;
    if (image !== undefined) {
      imagePath = `/assets/${image.fileName}`;
    }

    const fallbackImagePath: string = '/static-images/meeting.jpg';

    return (
      <Row className="sjp-mt-1 sjp-background-card-color sjp-node-card-height">
        <Col xs="12" sm="12" md="6" lg="6" xl="6">
          <Row>
            <img src={imagePath ? imagePath : fallbackImagePath} className="sjp-node-card-height sjp-d-block sjp-node-card-image sjp-no-margin-left" alt="Cover Photo" />
          </Row>
        </Col>
        <Col className="sjp-node-details-col sjp-node-card-height sjp-background-card-color" xs="12" sm="12" md="6" lg="6" xl="6">
          <Col className="sjp-node-col">
            <Row>
              <Col>
                <p className="sjp-card-title mt-5">{node.name}</p>
              </Col>
            </Row>
            <Row className="sjp-description-height">
              <Col>
                <p className="sjp-description-text sjp-mb-0 sjp-word-wrap">{node.description}</p>
              </Col>
            </Row>
            <Row className="sjp-mt-2 sjp-node-card-padding">
              <p className="sjp-label sjp-mb-0 sjp-pr-1">HOURLY RATE</p>
              <p className="sjp-value sjp-mb-0 sjp-text-uppercase">€{(Math.round(node.hourlyPrice * (node.vat + 1) * 100) / 100).toFixed(2)}</p>
            </Row>
            <Row className="sjp-node-card-padding">
              <p className="sjp-label sjp-mb-0 sjp-pr-1">DAILY RATE</p>
              <p className="sjp-value sjp-mb-0 sjp-text-uppercase">€{(Math.round(node.dailyPrice * (node.vat + 1) * 100) / 100).toFixed(2)}</p>
            </Row>
            <Row className="sjp-node-card-padding">
              <Col xs="8" sm="8" md="8" lg="8" xl="8">
                <Row>
                  <p className="sjp-label sjp-mb-0 sjp-pr-1">STANDING CAPACITY</p>
                  <p className="sjp-value sjp-mb-0 sjp-text-uppercase">{node.standingCapacity} Persons</p>
                </Row>
                <Row>
                  <p className="sjp-label sjp-mb-0 sjp-pr-1">SEATING CAPACITY</p>
                  <p className="sjp-value sjp-mb-0 sjp-text-uppercase">{node.seatingCapacity} Persons</p>
                </Row>
              </Col>
              <Row className="sjp-full-width sjp-no-margin sjp-node-button-row">
                <Link className="sjp-node-card-button-link" to={`/node/${this.props.nodeId}`}>
                  <Button color="primary" className="sjp-mt-1 sjp-full-width sjp-button sjp-button-text sjp-no-border-radius" type="submit">
                    VIEW THIS SITE
                  </Button>
                </Link>
              </Row>
            </Row>
          </Col>
        </Col>
      </Row>
    );
  }

  private getDataFromApi(): Promise<[Node, NodeImage[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const nodePromise: Promise<Node> = this.nodeStore.fetchNodeById(this.props.nodeId);
    const nodeImagesPromise: Promise<NodeImage[]> = this.nodeStore.fetchNodeImagesByNodeId(this.props.nodeId, true).then((nodeImages: NodeImage[]) => {
      if (nodeImages.length === 0) {
        return this.nodeStore.fetchNodeImagesByNodeId(this.props.nodeId, false);
      } else {
        return nodeImages;
      }
    });
    return Promise.all([ nodePromise, nodeImagesPromise ]);
  }
}
