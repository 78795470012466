import * as React from 'react';
import { observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';

import { observable, action } from 'mobx';

import { Container, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { User } from '../../stores/models/models';
import { UserEditStore, CheckoutStore, LoadingStore } from '../../stores/stores';

import { Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import { userFromForm } from '../../stores/models/User';

import LoadingSpinner from './LoadingSpinner';

import './styles/userDetailsFormStyle.css';
import { Notifications } from 'core/notifications/notifications';

const FormItem = Form.Item;

interface IUserDetailsProps {
  userId: number;
  rootStore: RootStore;
  atCheckout: boolean;
}

@observer
class UserDetailsForm extends React.Component<IUserDetailsProps & FormComponentProps, {}> {
  @observable warningModalOpen: boolean = false;
  private userEditStore: UserEditStore;
  private checkoutStore: CheckoutStore;
  private rootStore: RootStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: IUserDetailsProps & FormComponentProps) {
    super(props);
    this.userEditStore = props.rootStore.stores.userEdit;
    this.checkoutStore = props.rootStore.stores.checkout;
    this.rootStore;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((user: User) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    return this.getForm();
  }

  private getForm(): JSX.Element {
    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colon: false
    };

    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Container>
        <Row className="sjp-darkgrey-background">
          <Col className="sjp-pt-1 sjp-pb-1 sjp-white-title-text sjp-text-uppercase">Contact Details</Col>
        </Row>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Row className="sjp-pt-1 sjp-background-card-color">
            <Col>
              <Row>
                <Col>
                  <Row>
                    <Col md={4}>
                      <Row>
                        <Col className="sjp-mt-1">
                          <FormItem {...formItemLayout}>
                            <h4 className="sjp-label sjp-text-uppercase">* First Name</h4>
                            {getFieldDecorator('name', {
                              initialValue: this.userEditStore.user.name ? this.userEditStore.user.name : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please enter your first name'
                                }
                              ]
                            })(<Input type="text" name="firstName" className="sjp-width-full sjp-no-border-radius" />)}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <Row>
                        <Col className="sjp-mt-1">
                          <FormItem {...formItemLayout}>
                            <h4 className="sjp-label sjp-text-uppercase">* Last Name</h4>
                            {getFieldDecorator('surname', {
                              initialValue: this.userEditStore.user.surname ? this.userEditStore.user.surname : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please enter your last name'
                                }
                              ]
                            })(<Input type="text" name="lastName" className="sjp-width-full sjp-no-border-radius" />)}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col md={4}>
                      <Row className="sjp-mt-1">
                        <Col>
                          <FormItem {...formItemLayout}>
                            <h4 className="sjp-label sjp-text-uppercase">* Id number</h4>
                            {getFieldDecorator('idCard', {
                              initialValue: this.userEditStore.user.idCard ? this.userEditStore.user.idCard : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please enter your Id Number'
                                }
                              ]
                            })(<Input type="text" name="idNumber" className="sjp-width-full sjp-no-border-radius" />)}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <Row className="sjp-mt-1">
                        <Col>
                          <FormItem {...formItemLayout}>
                            <h4 className="sjp-label sjp-text-uppercase">* Contact Number</h4>
                            {getFieldDecorator('contactNumber', {
                              initialValue: this.userEditStore.user.contactNumber ? this.userEditStore.user.contactNumber : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please enter your contact number'
                                }
                              ]
                            })(<Input type="text" name="contactNumber" className="sjp-width-full sjp-no-border-radius" />)}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col md={4}>
                      <Row className="sjp-mt-1">
                        <Col>
                          <FormItem {...formItemLayout}>
                            <h4 className="sjp-label sjp-text-uppercase">* Email Address</h4>
                            {getFieldDecorator('emailAddress', {
                              initialValue: this.userEditStore.user.emailAddress ? this.userEditStore.user.emailAddress : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please enter your Email Address'
                                }
                              ]
                            })(<Input type="text" name="emailAddress" className="sjp-width-full sjp-no-border-radius" />)}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={8}>
                      <Row className="sjp-mt-1">
                        <Col>
                          <FormItem {...formItemLayout}>
                            <h4 className="sjp-label sjp-text-uppercase">* Postal Address</h4>
                            {getFieldDecorator('postalAddress', {
                              initialValue: this.userEditStore.user.postalAddress ? this.userEditStore.user.postalAddress : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please enter your postal address'
                                }
                              ]
                            })(<Input type="text" name="postalAddress" className="sjp-width-full sjp-no-border-radius" />)}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Row>
                        <Col className="sjp-mt-1">
                          <FormItem {...formItemLayout}>
                            <h4 className="sjp-label sjp-text-uppercase">Company Name</h4>
                            {getFieldDecorator('companyName', {
                              initialValue: this.userEditStore.user.companyName ? this.userEditStore.user.companyName : undefined
                            })(<Input type="text" name="companyName" className="sjp-width-full sjp-no-border-radius" />)}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <Row>
                        <Col className="sjp-mt-1">
                          <FormItem {...formItemLayout}>
                            <h4 className="sjp-label sjp-text-uppercase">VAT Number</h4>
                            {getFieldDecorator('vat', {
                              initialValue: this.userEditStore.user.vat ? this.userEditStore.user.vat : undefined
                            })(<Input type="text" name="vat" className="sjp-width-full  sjp-no-border-radius" />)}
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col>
                      <Row className="sjp-mt-1">
                        <Col md={4}>
                          <FormItem {...formItemLayout}>
                            <h4 className="sjp-label sjp-text-uppercase">Contact Number</h4>
                            {getFieldDecorator('companyContactNumber', {
                              initialValue: this.userEditStore.user.companyContactNumber ? this.userEditStore.user.companyContactNumber : undefined
                            })(<Input type="text" name="companyContactNumber" className="sjp-width-full sjp-no-border-radius" />)}
                          </FormItem>
                        </Col>
                        <Col md={8}>
                          <FormItem {...formItemLayout}>
                            <h4 className="sjp-label sjp-text-uppercase">Postal Address</h4>
                            {getFieldDecorator('companyPostalAddress', {
                              initialValue: this.userEditStore.user.companyPostalAddress ? this.userEditStore.user.companyPostalAddress : undefined
                            })(<Input type="text" name="emailAddress" className="sjp-width-full sjp-no-border-radius" />)}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row className="sjp-mb-1">
                        <Col md={4} className="text-left">
                          <Button className="sjp-auto-height sjp-button sjp-no-border-radius sjp-button-text sjp-text-uppercase sjp-mt-1" onClick={this.handleSubmit.bind(this)}>
                            save changes
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <Modal isOpen={this.warningModalOpen} toggle={this.toggleWarningModal.bind(this)} centered>
          <ModalHeader toggle={this.toggleWarningModal.bind(this)}>Validation Error</ModalHeader>
          <ModalBody>You are missing some fields. Please make sure you fill in the required fields (marked by *)</ModalBody>
          <ModalFooter>
            <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button sjp-button-text" onClick={this.toggleWarningModal.bind(this)}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }

  private handleSubmit(e: any): void {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((error, values) => {
      if (error) {
        this.toggleWarningModal();
        return;
      }

      const originalUser: User = this.userEditStore.user;
      const userValues = Object.assign(originalUser, values);
      const editedUser: User = userFromForm(userValues);
      return this.userEditStore
        .createOrUpdateCurrentUser(editedUser)
        .then((user: User) => {
          if (user) {
            if (this.props.atCheckout) {
              this.checkoutStore.setIsBeingEdited(false);
            }
          }
          return Promise.resolve(user);
        })
        .catch((err) => {
          Notifications.displayError(err);
          Promise.reject(err);
        });
    });
  }

  @action('Toggle the warning modal')
  private toggleWarningModal(): void {
    this.warningModalOpen = !this.warningModalOpen;
  }

  private getDataFromApi(): Promise<User> {
    this.loadingStore.setLoadingStateToLoading();
    return this.userEditStore.fetchUserForEditing(this.props.userId);
  }
}

export default Form.create<{}>()(UserDetailsForm);
