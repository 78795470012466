import ApiService from './ApiService';

import { Roster, RosterNode } from '../stores/models/models';

class RosterService extends ApiService {
  constructor() {
    super();
  }

  // Rosters

  public fetchRosters(): Promise<Roster[]> {
    const url: string = this.getUrl(`/rosters`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data === undefined || responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, Roster);
      });
  }

  public fetchRostersByUserId(userId: number): Promise<Roster[]> {
    const url: string = this.getUrl(`/roster/user/${userId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data === undefined || responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, Roster);
      });
  }

  public createOrUpdateRoster(roster: Roster): Promise<Roster> {
    const baseUrl: string = `/roster`;
    const isNew: boolean = roster.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/info/${roster.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const body = this.jsonConvert.serialize(roster);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(body)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Roster);
      });
  }

  public createRosters(rosters: Roster[], userId: number, dayOfWeek: number): Promise<Roster[]> {
    const url: string = this.getUrl(`/roster?userId=${userId}&dayOfWeek=${dayOfWeek}`);
    const jsonRoster = this.jsonConvert.serialize(rosters);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonRoster)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Roster);
        }
        return [];
      });
  }

  public deleteRoster(rosterId: number): Promise<void> {
    const url: string = this.getUrl(`/roster/info/${rosterId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  // Roster Nodes

  public fetchRosterNodes(): Promise<RosterNode[]> {
    const url: string = this.getUrl(`/rosternodes`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data === undefined || responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, RosterNode);
      });
  }
}

export default RosterService;
