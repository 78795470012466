import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Container } from 'reactstrap';
import { Config } from '../../stores/models/Config';
import { ConfigStore, LoadingStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';

import './styles/bottomMostRowStyle.css';
import { Notifications } from 'core/notifications/notifications';

@inject('rootStore')
@observer
export default class BottomMostRow extends React.Component<{}, {}> {
  private loadingStore: LoadingStore = new LoadingStore();
  private configStore: ConfigStore;

  constructor(props: any) {
    super(props);
    this.configStore = props.rootStore.stores.config;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((config: Config) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    if (!this.configStore.configuration.bottomMostRow) {
      return <div />;
    }
    return <Container fluid className="sjp-shift-down-2 sjp-bottom-most-row" />;
  }

  private getDataFromApi(): Promise<Config> {
    this.loadingStore.setLoadingStateToLoading();
    return this.configStore.fetchConfiguration();
  }
}
