import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { configure } from 'mobx';

import LocationScreen from './screens/website/LocationScreen';
import NavBarApp from './NavBarApp';

// MobX configuration
configure({
  enforceActions: true
});

interface ComponentToMount {
  component: any;
  rootElement: HTMLElement | null;
}

const componentsToMount: ComponentToMount[] = [ { component: NavBarApp, rootElement: document.getElementById('navApp') }, { component: LocationScreen, rootElement: document.getElementById('map') } ];

componentsToMount.forEach((componentToMount: ComponentToMount) => {
  if (componentToMount.rootElement !== null) {
    ReactDOM.render(<componentToMount.component />, componentToMount.rootElement);
  }
});
