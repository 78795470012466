import { observable, action } from 'mobx';

import { Node } from './models/models';
import { RootStore } from './RootStore';

class NodeEditStore {
  @observable public node: Node;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action('Fetching Node to be edited')
  public fetchNodeForEditing(nodeId: number): Promise<Node> {
    if (nodeId === 0) {
      const newNode = new Node();
      this.setCurrentNode(newNode);
      return Promise.resolve(newNode);
    }
    return this.rootStore.stores.node.fetchNodeById(nodeId).then((node: Node) => {
      if (!node) {
        throw new Error(`Node with ID: ${nodeId} does not exist!`);
      }
      this.setCurrentNode(node);
      return node;
    });
  }

  @action('Saving or Updating the current node with new details')
  public createOrUpdateCurrentNode(newNode: Node): Promise<Node | undefined> {
    if (!this.node) {
      throw new Error(`Unable to update a node if one does not exist`);
    }
    return this.rootStore.stores.node.createOrUpdateNode(newNode).then((node: Node) => {
      if (!node) {
        throw new Error(`Unable to update node with ID: ${this.node.id}`);
      }
      this.setCurrentNode(node);
      return node;
    });
  }

  @action('Set Node that is currently being edited')
  private setCurrentNode(node: Node): void {
    this.node = node;
  }
}

export { NodeEditStore };
