import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Container } from 'reactstrap';

import BookingCardsList from './BookingCardsList';

import './styles/myBookingsStyle.css';

interface IMyBookingsProps {
  userId: number;
}

@inject('rootStore')
@observer
export default class MyBookings extends React.Component<IMyBookingsProps, {}> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <Container className="sjp-ml-0 sjp-mr-0 sjp-pr-0 sjp-pl-0">
        <BookingCardsList userId={this.props.userId} upcoming={true} />
        <BookingCardsList userId={this.props.userId} upcoming={false} />
      </Container>
    );
  }
}
