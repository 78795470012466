import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { User, UserPassword } from './models/models';

class UserStore extends GenericStore<User> {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  public get users(): User[] {
    return this.contents;
  }

  public fetchUsers(): Promise<User[]> {
    return this.rootStore.services.user.fetchUsers().then((users: User[]) => {
      this.addOrUpdateAll(users);
      return users;
    });
  }

  public fetchUserById(userId: number): Promise<User> {
    return this.rootStore.services.user.fetchUserById(userId).then((user: User) => {
      this.addOrUpdate(user);
      return user;
    });
  }

  public fetchUnscopedUserById(userId: number): Promise<User> {
    return this.rootStore.services.user.fetchUnscopedUserById(userId).then((user: User) => {
      this.addOrUpdate(user);
      return user;
    });
  }

  public createOrUpdateUser(user: User): Promise<User> {
    return this.rootStore.services.user.createOrUpdateUser(user).then((user: User) => {
      this.addOrUpdate(user);
      return user;
    });
  }

  public registerClient(userPassword: UserPassword): Promise<User> {
    return this.rootStore.services.user.registerClient(userPassword).then((user: User) => {
      this.addOrUpdate(user);
      return user;
    });
  }

  public deleteUser(userId: number): Promise<void> {
    return this.rootStore.services.user.deleteUser(userId).then(() => {
      this.remove(userId);
    });
  }

  public deleteUserGdpr(userId: number): Promise<void> {
    return this.rootStore.services.user.deleteUserGdpr(userId).then(() => {
      this.remove(userId);
    });
  }

  public fetchLoggedInUser(): Promise<User> {
    return this.rootStore.services.user.fetchLoggedInUser().then((user: User | undefined) => {
      if (user !== undefined) {
        this.addOrUpdate(user);
      }
      return user;
    });
  }

  public updateUserPassword(user: User, passwordA: string, passwordB: string): Promise<void> {
    return this.rootStore.services.user.updateUserPassword(user.id, passwordA, passwordB).then(() => {
      this.addOrUpdate(user);
      return user;
    });
  }

  public updateUserPasswordWithOldPassword(user: User, oldPassword: string, passwordA: string, passwordB: string): Promise<void> {
    return this.rootStore.services.user.updateUserPasswordWithOldPassword(user.id, oldPassword, passwordA, passwordB).then(() => {
      this.addOrUpdate(user);
      return user;
    });
  }
}

export { UserStore };
