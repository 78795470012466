import * as React from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Col, Row, Container, Button } from 'reactstrap';

import { Booking, NodeImage, Image, Node, Config } from '../../stores/models/models';
import { BookingEditStore, NodeStore, ImageStore, ConfigStore, LoadingStore } from '../../stores/stores';

import NodeDetails from '../../components/website/NodeDetails';
import NodeTimes from '../../components/website/NodeTimes';

import LoadingSpinner from '../../components/website/LoadingSpinner';

import './styles/nodeViewScreenStyle.css';
import { Notifications } from 'core/notifications/notifications';

@inject('rootStore')
@observer
export default class NodeViewScreen extends React.Component<{}, {}> {
  private nodeId: number;
  private bookingId?: number;

  private bookingEditStore: BookingEditStore;
  private nodeStore: NodeStore;
  private imageStore: ImageStore;
  private configStore: ConfigStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.bookingEditStore = props.rootStore.stores.bookingEdit;
    this.nodeStore = props.rootStore.stores.node;
    this.imageStore = props.rootStore.stores.image;
    this.configStore = props.rootStore.stores.config;

    const parameter = props.match.params.id;
    this.nodeId = parseInt(parameter);

    const searchParams: any = queryString.parse(props.location.search);
    this.bookingId = searchParams.booking ? parseInt(searchParams.booking) : undefined;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then(() => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const nodeImage: NodeImage | undefined = this.nodeStore.nodeImages.find((nodeImage: NodeImage) => nodeImage.nodeId === this.nodeId && nodeImage.featured);

    let featuredImageLocation: string = '';
    if (nodeImage !== undefined) {
      const image: Image | undefined = this.imageStore.get(nodeImage.imageId);
      if (image !== undefined) {
        featuredImageLocation = `/assets/${image.fileName}`;
      }
    }

    const node: Node | undefined = this.nodeStore.get(this.nodeId);
    if (node === undefined) {
      throw new Error(`Error: Node with ID ${this.nodeId} does not exist`);
    }

    return (
      <div>
        {featuredImageLocation !== '' ? (
          <Container fluid className="sjp-no-padding">
            <Row className="sjp-mb-1 sjp-shift-up-2-point-5">
              <Col className="sjp-no-padding">
                <img src={featuredImageLocation} alt="Featured Image" className="mx-auto d-block sjp-feature-image" />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container fluid className="sjp-no-padding sjp-background-card-color sjp-node-view-placeholder-height">
            <Row className="sjp-mb-1 sjp-shift-up-2-point-5" />
          </Container>
        )}
        <Container className="sjp-mb-3">
          <Row>
            <Col xs="12" sm="12" md={`${node.internal ? '12' : '9'}`} lg={`${node.internal ? '12' : '9'}`} xl={`${node.internal ? '12' : '9'}`} className="sjp-back-to-rooms-button-padding">
              <Row>
                <NodeDetails nodeId={this.nodeId} />
              </Row>
              <Row className="sjp-full-width sjp-back-to-meeting-rooms-button-row sjp-mb-1">
                <Col className="sjp-full-width sjp-no-padding" xs="12" sm="12" md={`${node.internal ? '6' : '12'}`} lg={`${node.internal ? '6' : '12'}`} xl={`${node.internal ? '6' : '12'}`}>
                  <Link to="/nodes " className="sjp-full-width">
                    <Button color="primary" className="sjp-button sjp-no-border-radius sjp-button-text sjp-mt-5 sjp-view-room-button sjp-back-to-sites-button" type="submit">
                      BACK TO SITES
                    </Button>
                  </Link>
                </Col>

                {node.internal ? (
                  <Col className="sjp-full-width sjp-no-padding sjp-text-right" xs="12" sm="12" md="6" lg="6" xl="6">
                    <a href={this.configStore.configuration.contactUsLink} className="sjp-full-width">
                      <Button color="primary" className="sjp-button-grey sjp-no-border-radius sjp-button-text sjp-mt-book-site-button sjp-view-room-button" type="submit">
                        BOOK THIS SITE? CONTACT US
                      </Button>
                    </a>
                  </Col>
                ) : (
                  undefined
                )}
              </Row>
            </Col>
            {!node.internal ? (
              <Col xs="12" sm="12" md="3" lg="3" xl="3" className="sjp-node-times-margin">
                <NodeTimes nodeId={this.nodeId} />
              </Col>
            ) : (
              undefined
            )}
          </Row>
        </Container>
      </div>
    );
  }

  private getDataFromApi(): Promise<[Config, Node, Booking, (NodeImage[] | Promise<Image>[])[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const configPromise: Promise<Config> = this.configStore.fetchConfiguration();
    const nodePromise: Promise<Node> = this.nodeStore.fetchNodeById(this.nodeId);
    const bookingEditPromise: Promise<Booking> = this.bookingEditStore.fetchBookingForEditing(this.bookingId ? this.bookingId : 0);
    const featuredNodeImagePromise: Promise<(NodeImage[] | Promise<Image>[])[]> = this.nodeStore.fetchNodeImagesByNodeId(this.nodeId, true).then((nodeImages: NodeImage[]) => {
      const images: Promise<Image>[] = nodeImages.map((nodeImage: NodeImage) => {
        return this.imageStore.fetchImageById(nodeImage.imageId);
      });
      return [ nodeImages, images ];
    });
    return Promise.all([ configPromise, nodePromise, bookingEditPromise, featuredNodeImagePromise ]);
  }
}
