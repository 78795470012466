import * as React from 'react';

import { Container, Row, Col } from 'reactstrap';

export default class SuccessfulRegistrationScreen extends React.Component<{}, {}> {
  public render() {
    return (
      <Container>
        <Row className="sjp-mt-5 sjp-mb-2">
        <Col className="sjp-text-center sjp-small-vector-image">
          <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" className="sjp-secondary-fill" height="100%" width="100%">
            <g>
              <g>
                <path d="M499.161,170.733L341.257,12.828c-17.091-17.091-44.901-17.091-61.992,0L32.839,259.255
                  c-8.545,8.546-12.818,19.771-12.818,30.996c0,11.225,4.273,22.45,12.818,30.996l39.619,39.619l-43.503,43.503
                  c-1.953,1.953-2.929,4.512-2.929,7.071s0.976,5.118,2.929,7.071c3.905,3.905,10.237,3.906,14.142,0L86.6,375.008l18.119,18.12
                  L2.929,494.919C0.976,496.871,0,499.431,0,501.99c0,2.559,0.976,5.119,2.929,7.071c3.905,3.905,10.237,3.905,14.143,0
                  L118.863,407.27l71.88,71.881c17.092,17.09,44.901,17.09,61.992,0l246.426-246.427C507.44,224.446,512,213.437,512,201.729
                  C512,190.02,507.44,179.012,499.161,170.733z M214.473,470.833c-3.508-1.117-6.811-3.046-9.589-5.824L46.981,307.105
                  c-6.231-6.232-8.263-15.079-6.138-23.036h173.63V470.833z M178.326,264.069l29.653-29.653c3.905-3.905,3.906-10.237,0-14.142
                  c-3.906-3.905-10.237-3.906-14.142,0l-43.796,43.795h-29.135l90.406-90.406c3.904-3.905,3.905-10.237,0-14.143
                  c-3.905-3.905-10.237-3.905-14.143,0L92.622,264.069H56.31l227.769-227.77v203.935c0,13.143-10.692,23.835-23.835,23.835H178.326z
                  M485.02,218.583L238.593,465.009c-1.271,1.271-2.659,2.352-4.119,3.276V284.069h25.771c24.17,0,43.835-19.664,43.835-43.835
                  v-25.771h114.624l-57.867,57.868c-1.952,1.952-2.929,4.512-2.929,7.071c0,2.56,0.976,5.118,2.929,7.071
                  c3.905,3.905,10.237,3.905,14.143,0l72.002-72.002c0.002-0.002,0.004-0.005,0.006-0.007h41.328
                  C487.381,215.937,486.284,217.32,485.02,218.583z M304.079,194.463V20.833c7.958-2.125,16.805-0.093,23.036,6.138l157.904,157.904
                  c2.732,2.731,4.713,6.01,5.853,9.588H304.079z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M235.79,135.05c-1.86-1.87-4.44-2.93-7.07-2.93c-2.64,0-5.21,1.06-7.07,2.93c-1.86,1.86-2.93,4.43-2.93,7.07
                  c0,2.63,1.07,5.21,2.93,7.07c1.86,1.86,4.43,2.93,7.07,2.93c2.63,0,5.21-1.07,7.07-2.93s2.93-4.44,2.93-7.07
                  C238.72,139.48,237.65,136.91,235.79,135.05z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M17.11,430.35c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07
                  c1.86,1.86,4.44,2.93,7.07,2.93c2.64,0,5.21-1.07,7.07-2.93c1.87-1.86,2.93-4.44,2.93-7.07S18.98,432.21,17.11,430.35z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M127.018,449.497c-3.906-3.905-10.238-3.905-14.143,0l-45.416,45.416c-1.952,1.952-2.929,4.512-2.929,7.071
                  c0,2.559,0.976,5.119,2.929,7.072c3.906,3.905,10.238,3.905,14.143,0l45.416-45.416
                  C130.922,459.734,130.922,453.402,127.018,449.497z"/>
              </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            </svg>
          </Col>
        </Row>
        <Row>
          <Col className="sjp-text-center">
            <h2 className="sjp-main-title-text">Successful Registration</h2>
          </Col>
        </Row>
        <Row>
          <Col className="sjp-text-center">
            <h3 className="sjp-title-text">You will shortly receive an email to confirm your registration.</h3>
          </Col>
        </Row>
        <Row className="sjp-mt-3">
          <Col className="sjp-text-center">
            <a href="/home">
              <button className="btn sjp-button sjp-button-text sjp-no-border-radius sjp-pr-2 sjp-pl-2 sjp-pt-1 sjp-pb-1">GO TO HOME PAGE</button>
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}
