import * as React from 'react';
import { Alert } from 'antd';

export default class Alerts {
  public static showError(message: string): JSX.Element {
    return this.showAlert(message, 'error');
  }

  public static showInfo(message: string): JSX.Element {
    return this.showAlert(message, 'info');
  }

  public static showWarning(message: string): JSX.Element {
    return this.showAlert(message, 'warning');
  }

  public static showSuccess(message: string): JSX.Element {
    return this.showAlert(message, 'success');
  }

  private static showAlert(message: string, type: any): JSX.Element {
    return <Alert message={message} type={type} showIcon={true} />;
  }
}
