import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { Cart } from './models/models';

class CartStore extends GenericStore<Cart> {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  public get carts(): Cart[] {
    return this.contents;
  }

  public fetchCarts(): Promise<Cart[]> {
    return this.rootStore.services.cart.fetchCarts().then((carts: Cart[]) => {
      this.addOrUpdateAll(carts);
      return carts;
    });
  }

  public fetchCartById(cartId: number): Promise<Cart> {
    return this.rootStore.services.cart.fetchCartById(cartId).then((cart: Cart) => {
      this.addOrUpdate(cart);
      return cart;
    });
  }

  public fetchProvisionalCartByUserId(userId: number): Promise<Cart> {
    return this.rootStore.services.cart.fetchProvisionalCartByUserId(userId).then((cart: Cart) => {
      this.addOrUpdate(cart);
      return cart;
    });
  }

  public fetchConfirmedCartsByUserId(userId: number): Promise<Cart[]> {
    return this.rootStore.services.cart.fetchConfirmedCartsByUserId(userId).then((carts: Cart[]) => {
      this.addOrUpdateAll(carts);
      return carts;
    });
  }

  public confirmCart(cartId: number): Promise<Cart[]> {
    return this.rootStore.services.cart.confirmCart(cartId).then((carts: Cart[]) => {
      const confirmedCart: Cart = carts[0];
      const newEmptyCart: Cart = carts[1];
      this.addOrUpdate(confirmedCart);
      this.addOrUpdate(newEmptyCart);
      return carts;
    });
  }
}

export { CartStore };
