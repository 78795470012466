import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import 'bootstrap/dist/css/bootstrap.min.css';

import { User } from '../../stores/models/models';
import { CurrentUserStore, LoadingStore } from '../../stores/stores';
import { RootStore } from '../../stores/RootStore';

import AccountTabs from '../../components/website/AccountTabs';
import UserDetailsForm from '../../components/website/UserDetailsForm';
import MyBookings from '../../components/website/MyBookings';
import InvoicesComponent from '../../components/website/InvoicesComponent';
import EditPasswordComponent from '../../components/website/EditPasswordComponent';
import DeleteAccountComponent from '../../components/website/DeleteAccountComponent';

import { Row, Container, Col } from 'reactstrap';

import LoadingSpinner from '../../components/website/LoadingSpinner';

import './styles/accountScreenStyle.css';
import { Notifications } from 'core/notifications/notifications';

@inject('rootStore')
@observer
export default class AccountScreen extends React.Component<{}, {}> {
  @observable private selectedTab: number = 1;

  private currentUserStore: CurrentUserStore;
  private rootStore: RootStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.rootStore = props.rootStore;
    this.currentUserStore = props.rootStore.stores.currentUser;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((user: User) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const currentUser: User | undefined = this.currentUserStore.currentUser;

    if (currentUser === undefined) {
      return <h5 className="sjp-text-center sjp-warning-text sjp-mt-1">Please login to view your account</h5>;
    }

    return (
      <div>
        <Container>
          <Row className="sjp-mt-5 sjp-mb-1 sjp-text-container-center-small-devices">
            <h2 className="sjp-title-text">My Account</h2>
          </Row>
          <Row className="sjp-mb-3">
            <Col xs="12" sm="12" md="3" lg="3" xl="3" className="sjp-mb-1">
              <AccountTabs currentlySelectedTab={this.selectedTab} selectTabCallback={this.setSelectedTab.bind(this)} />
            </Col>
            <Col xs="12" sm="12" md="9" lg="9" xl="9" className="sjp-pr-0 sjp-account-details-panel">
              {this.selectedTab === 3 ? (
                <MyBookings userId={currentUser.id} />
              ) : this.selectedTab === 2 ? (
                <InvoicesComponent userId={currentUser.id} />
              ) : this.selectedTab === 4 ? (
                <EditPasswordComponent userId={currentUser.id} rootStore={this.rootStore} />
              ) : this.selectedTab === 5 ? (
                <DeleteAccountComponent userId={currentUser.id} />
              ) : (
                <UserDetailsForm userId={currentUser.id} rootStore={this.rootStore} atCheckout={false} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  @action('Set currently selected tab')
  private setSelectedTab(tab: number): void {
    this.selectedTab = tab;
  }

  private getDataFromApi(): Promise<User> {
    this.loadingStore.setLoadingStateToLoading();
    return this.currentUserStore.fetchUserData();
  }
}
