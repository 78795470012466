import { observable, action } from 'mobx';

import { Facility } from './models/models';
import { RootStore } from './RootStore';

class FacilityEditStore {
  @observable public facility: Facility;
  @observable public unlimitedChecked: boolean = false;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action('Fetching Facility to be edited')
  public fetchFacilityForEditing(facilityId: number): Promise<Facility> {
    if (facilityId === 0) {
      const newFacility = new Facility();
      this.setCurrentFacility(newFacility);
      return Promise.resolve(newFacility);
    }
    return this.rootStore.stores.facility.fetchFacilityById(facilityId).then((facility: Facility) => {
      if (!facility) {
        throw new Error(`Amenity with ID: ${facilityId} does not exist`);
      }
      this.setCurrentFacility(facility);
      return facility;
    });
  }

  @action('Saving or Updating the current facility with new details')
  public createOrUpdateCurrentFacility(newFacility: Facility): Promise<Facility | undefined> {
    if (!this.facility) {
      throw new Error(`Unable to update an amenity if one does not exist`);
    }
    return this.rootStore.stores.facility.createOrUpdateFacility(newFacility).then((facility: Facility) => {
      if (!facility) {
        throw new Error(`Unable to update facility with ID: ${this.facility.id}`);
      }
      this.setCurrentFacility(facility);
      return facility;
    });
  }

  @action('Set Facility that is currently being edited')
  private setCurrentFacility(facility: Facility): void {
    this.facility = facility;
  }

  @action('Toggle the unlimitedChecked observable')
  public toggleUnlimitedChecked(): void {
    this.unlimitedChecked = !this.unlimitedChecked;
  }

  @action('Set unlimitedChecked observable')
  public setUnlimitedChecked(value: boolean): void {
    this.unlimitedChecked = value;
  }
}

export { FacilityEditStore };
