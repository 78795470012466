import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Ticket, TicketCartItem, TicketsSold } from '../../stores/models/models';
import { TicketStore, CartStore, CurrentUserStore, LoadingStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';

import { Container, Row, Col } from 'reactstrap';
import { Notifications } from 'core/notifications/notifications';

import './styles/ticketCardStyle.css';

import { Plus, Minus } from '../svgIcons/icons';

interface ITicketCardProps {
  ticketId: number;
  cartId: number;
}

@inject('rootStore')
@observer
export default class TicketCard extends React.Component<ITicketCardProps, {}> {
  @observable private ticketsSold: TicketsSold;
  private ticketStore: TicketStore;
  private cartStore: CartStore;
  private currentUserStore: CurrentUserStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.ticketStore = props.rootStore.stores.ticket;
    this.cartStore = props.rootStore.stores.cart;
    this.currentUserStore = props.rootStore.stores.currentUser;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [TicketsSold, TicketCartItem[]]) => {
        const ticketsSold: TicketsSold = data[0];
        this.setTicketsSold(ticketsSold);
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const ticket: Ticket | undefined = this.ticketStore.get(this.props.ticketId);

    if (ticket === undefined) {
      throw new Error(`Error: Ticket with ID ${this.props.ticketId} does not exist`);
    }

    const ticketCartItem: TicketCartItem | undefined = this.ticketStore.ticketCartItems.find((ticketCartItem: TicketCartItem) => ticketCartItem.cartId === this.props.cartId && ticketCartItem.ticketId === this.props.ticketId);

    const ticketsSoldExcludingOnesInThisCart: number = this.ticketsSold ? this.ticketsSold.sold - (ticketCartItem ? ticketCartItem.quantity : 0) : 0;

    const remainingTicketsOtherThanCart: number = ticket.unlimitedQuantity ? -1 : ticket.quantity ? ticket.quantity - ticketsSoldExcludingOnesInThisCart : 0;

    return (
      <Container className="sjp-mb-1 sjp-ticket-card-height">
        <Row className="sjp-ticket-card-height">
          <Col>
            <Row className="sjp-full-height">
              <Col xs="12" sm="12" md="1" lg="1" xl="1" className="sjp-background-card-color  sjp-pl-0 sjp-pr-0">
                <Col className="sjp-ticket-card-colour sjp-no-padding" style={{ backgroundColor: ticket.colour ? ticket.colour : '#898787', borderColour: ticket.colour ? ticket.colour : '#898787' }} />
              </Col>
              <Col xs="12" sm="12" md="9" lg="9" xl="9" className="sjp-full-height sjp-background-card-color sjp-pl-2">
                <Row className="sjp-pt-1 sjp-mt-2">
                  <h5 className="sjp-label sjp-text-uppercase sjp-pl-1 sjp-mb-1">
                    {ticket.name} - €{(Math.round(ticket.price * (1 + ticket.vat) * 100) / 100).toFixed(2)}
                  </h5>
                </Row>
                <Row>
                  <p className="sjp-description-text sjp-pl-1 sjp-pr-1">{ticket.description}</p>
                </Row>
              </Col>

              <Col xs="12" sm="12" md="2" lg="2" xl="2" className="sjp-full-height sjp-text-center sjp-background-card-color sjp-qty-col">
                {this.props.cartId > 0 && this.currentUserStore.currentUser && this.currentUserStore.currentUser.active ? remainingTicketsOtherThanCart === 0 ? (
                  <div>
                    <h5 className="sjp-pt-1 sjp-mt-5 sjp-field-text sjp-mt-1 sjp-text-uppercase">Sold out</h5>
                  </div>
                ) : (
                  <div>
                    <Row className="sjp-pt-1 sjp-mt-2 sjp-text-center">
                      <h5 className="sjp-label sjp-text-uppercase sjp-text-center sjp-mb-2 sjp-full-width">QTY</h5>
                    </Row>
                    <Row className="sjp-flex sjp-mt-1">
                      <a className="sjp-mr-1" onClick={this.handleRemoveTicketCartItem.bind(this, ticketCartItem)}>
                        <Minus className={`${ticketCartItem && ticketCartItem.quantity > 0 ? '' : 'sjp-greyed-out '} sjp-height-1 sjp-primary-fill sjp-width-1 sjp-height-1`} />
                      </a>
                      <div className="sjp-facility-quantity">{ticketCartItem !== undefined ? ticketCartItem.quantity : 0}</div>
                      <a className="sjp-ml-1" onClick={this.handleAddTicketCartItem.bind(this, ticketCartItem, ticket)}>
                        <Plus className={`${!this.canBeAdded(ticketCartItem ? ticketCartItem.quantity : 0) ? 'sjp-greyed-out ' : ''} sjp-height-1 sjp-primary-fill sjp-width-1 sjp-height-1`} />
                      </a>
                    </Row>
                  </div>
                ) : (
                  undefined
                )}
                <Row />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  private handleRemoveTicketCartItem(ticketCartItem: TicketCartItem | undefined): void {
    const newTicketCartItem: TicketCartItem = ticketCartItem ? ticketCartItem : new TicketCartItem();

    if (newTicketCartItem.quantity === 1 && newTicketCartItem.id !== 0) {
      this.ticketStore
        .deleteTicketCartItem(newTicketCartItem.id)
        .then(() => {
          const ticketsSoldPromise: Promise<TicketsSold> = this.ticketStore.fetchSoldTickets(newTicketCartItem.ticketId).then((ticketsSold: TicketsSold) => {
            this.setTicketsSold(ticketsSold);
            return ticketsSold;
          });
          Promise.all([ this.cartStore.fetchCartById(newTicketCartItem.cartId), ticketsSoldPromise ]);
        })
        .catch((err) => Notifications.displayError(err));
      return;
    }

    if (this.canBeRemoved(newTicketCartItem.quantity)) {
      newTicketCartItem.cartId = this.props.cartId;
      newTicketCartItem.ticketId = this.props.ticketId;
      newTicketCartItem.quantity--;
      this.ticketStore
        .createOrUpdateTicketCartItem(newTicketCartItem)
        .then((ticketCartItem: TicketCartItem) => {
          const ticketsSoldPromise: Promise<TicketsSold> = this.ticketStore.fetchSoldTickets(ticketCartItem.ticketId).then((ticketsSold: TicketsSold) => {
            this.setTicketsSold(ticketsSold);
            return ticketsSold;
          });
          Promise.all([ this.cartStore.fetchCartById(ticketCartItem.cartId), ticketsSoldPromise ]);
        })
        .catch((err) => Notifications.displayError(err));
    }
  }

  private handleAddTicketCartItem(ticketCartItem: TicketCartItem | undefined, ticket: Ticket): void {
    const newTicketCartItem: TicketCartItem = ticketCartItem ? ticketCartItem : new TicketCartItem();
    if (this.canBeAdded(newTicketCartItem.quantity)) {
      newTicketCartItem.cartId = this.props.cartId;
      newTicketCartItem.ticketId = this.props.ticketId;
      newTicketCartItem.quantity++;
      this.ticketStore
        .createOrUpdateTicketCartItem(newTicketCartItem)
        .then((ticketCartItem: TicketCartItem) => {
          const ticketsSoldPromise: Promise<TicketsSold> = this.ticketStore.fetchSoldTickets(ticketCartItem.ticketId).then((ticketsSold: TicketsSold) => {
            this.setTicketsSold(ticketsSold);
            return ticketsSold;
          });
          Promise.all([ this.cartStore.fetchCartById(ticketCartItem.cartId), ticketsSoldPromise ]);
        })
        .catch((err) => Notifications.displayError(err));
    }
  }

  private canBeAdded(quantityRequired: number): boolean {
    const ticket: Ticket | undefined = this.ticketStore.get(this.props.ticketId);
    if (ticket === undefined) {
      throw new Error(`Error: Ticket with ID ${this.props.ticketId} does not exist`);
    }

    if (ticket.unlimitedQuantity) {
      return true;
    }

    const ticketCartItem: TicketCartItem | undefined = this.ticketStore.ticketCartItems.find((ticketCartItem: TicketCartItem) => ticketCartItem.cartId === this.props.cartId && ticketCartItem.ticketId === this.props.ticketId);

    const ticketsSoldExcludingOnesInThisCart: number = this.ticketsSold ? this.ticketsSold.sold - (ticketCartItem ? ticketCartItem.quantity : 0) : 0;

    const remainingTicketsOtherThanCart: number = ticket.unlimitedQuantity ? -1 : ticket.quantity ? ticket.quantity - ticketsSoldExcludingOnesInThisCart : 0;

    if (ticket.quantity !== null && quantityRequired < remainingTicketsOtherThanCart) {
      return true;
    }

    return false;
  }

  private canBeRemoved(quantityRequired: number): boolean {
    if (quantityRequired > 1) {
      return true;
    }
    return false;
  }

  @action('Setting the tickets sold observable')
  private setTicketsSold(ticketsSold: TicketsSold): void {
    this.ticketsSold = ticketsSold;
  }

  private getDataFromApi(): Promise<[TicketsSold, TicketCartItem[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const ticketsSoldPromise: Promise<TicketsSold> = this.ticketStore.fetchSoldTickets(this.props.ticketId);
    const ticketCartItemsPromise: Promise<TicketCartItem[]> = this.props.cartId ? this.ticketStore.fetchTicketCartItemsByCartId(this.props.cartId) : Promise.resolve([]);
    return Promise.all([ ticketsSoldPromise, ticketCartItemsPromise ]);
  }
}
