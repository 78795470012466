import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Booking, Cart } from '../../stores/models/models';
import { BookingStore, CartStore, LoadingStore } from '../../stores/stores';

import { Row, Col } from 'reactstrap';

import LoadingSpinner from './LoadingSpinner';
import BookingCard from './BookingCard';

import './styles/bookingCardsListStyle.css';

import moment from 'moment';
import { Notifications } from 'core/notifications/notifications';

const dateTimeDb: string = 'YYYY-MM-DD HH:mm:ss';

interface IBookingCardProps {
  userId: number;
  upcoming: boolean;
}

@inject('rootStore')
@observer
export default class BookingCardsList extends React.Component<IBookingCardProps, {}> {
  private bookingStore: BookingStore;
  private cartStore: CartStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.bookingStore = props.rootStore.stores.booking;
    this.cartStore = props.rootStore.stores.cart;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Cart[], Booking[]]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const relevantCarts: Cart[] = this.cartStore.carts.filter((cart: Cart) => cart.dateConfirmed !== null && cart.userId === this.props.userId);
    const relevantCartIds: number[] = relevantCarts.map((cart: Cart) => cart.id);
    const allBookings: Booking[] = this.bookingStore.bookings;
    const allConfirmedBookings: Booking[] = [];
    allBookings.forEach((booking: Booking) => {
      if (relevantCartIds.indexOf(booking.cartId) !== -1) {
        allConfirmedBookings.push(booking);
      }
    });

    const relevantBookings: Booking[] = this.getRelevantBookings(allConfirmedBookings);

    return (
      <div className="sjp-mb-1">
        <Col>
          <Row className="sjp-darkgrey-background sjp-pt-1 sjp-pb-1">
            <Col>
              <div className="sjp-white-title-text sjp-text-uppercase">{this.props.upcoming ? 'Upcoming Bookings' : 'Previous Bookings'}</div>
            </Col>
          </Row>
          {relevantBookings.length !== 0 ? (
            <Row className="sjp-pt-1 sjp-background-card-color sjp-mb-1">
              {relevantBookings.map((booking: Booking) => (
                <Row key={booking.id} className="sjp-mb-1 sjp-mr-1 sjp-ml-1">
                  <BookingCard key={booking.id} bookingId={booking.id} />
                </Row>
              ))}
            </Row>
          ) : (
            <Row className="sjp-background-card-color sjp-flex">
              <h5 className="sjp-warning-text sjp-text-center sjp-mt-1 sjp-mb-1">You currently do not have any {this.props.upcoming ? 'Upcoming Bookings' : 'Previous Bookings'}</h5>
            </Row>
          )}
        </Col>
      </div>
    );
  }

  private getRelevantBookings(allConfirmedBookings: Booking[]): Booking[] {
    const relevantBookings: Booking[] = [];
    if (this.props.upcoming) {
      allConfirmedBookings.forEach((booking: Booking) => {
        if (moment(booking.dateFrom, dateTimeDb).isSameOrAfter(moment())) {
          relevantBookings.push(booking);
        }
      });
    } else {
      allConfirmedBookings.forEach((booking: Booking) => {
        if (!moment(booking.dateFrom, dateTimeDb).isSameOrAfter(moment())) {
          relevantBookings.push(booking);
        }
      });
    }

    return relevantBookings;
  }

  private getDataFromApi(): Promise<[Cart[], Booking[]]> {
    this.loadingStore.setLoadingStateToLoading();
    return Promise.all([ this.cartStore.fetchConfirmedCartsByUserId(this.props.userId), this.bookingStore.fetchBookingsByUserId(this.props.userId) ]);
  }
}
