import { JsonProperty, JsonObject, JsonConvert } from 'json2typescript';

@JsonObject
class NodeType {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('name', String)
  name: string = '';
  @JsonProperty('description', String)
  description: string = '';
}

const exampleNodeTypeData = {
  id: 47,
  name: 'Building',
  description: 'This is big'
};

const jsonConvert: JsonConvert = new JsonConvert();
const exampleNodeType = jsonConvert.deserialize(exampleNodeTypeData, NodeType);

export { NodeType, exampleNodeTypeData, exampleNodeType };
