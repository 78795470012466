import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/eventViewScreenStyle.css';

import { Container, Row, Button } from 'reactstrap';

import { Ticket, User } from '../../stores/models/models';
import { TicketStore, CurrentUserStore, LoadingStore } from '../../stores/stores';

import EventDetailsCard from '../../components/website/EventDetailsCard';
import EventDetails from '../../components/website/EventDetails';

import LoadingSpinner from '../../components/website/LoadingSpinner';
import { Notifications } from 'core/notifications/notifications';
@inject('rootStore')
@observer
export default class EventViewScreen extends React.Component<{}, {}> {
  private eventId: number;
  private ticketStore: TicketStore;
  private currentUserStore: CurrentUserStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.ticketStore = props.rootStore.stores.ticket;
    this.currentUserStore = props.rootStore.stores.currentUser;

    const parameter = props.match.params.id;
    this.eventId = parseInt(parameter);
  }

  public componentDidMount() {
    this.getDataFromApi().then((tickets: Ticket[]) => this.loadingStore.setLoadingStateToDone()).catch((err) => {
      Notifications.displayError(err);
      this.loadingStore.setLoadingStateToError();
    });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const currentUser: User | undefined = this.currentUserStore.currentUser;

    const tickets: Ticket[] = this.ticketStore.tickets.filter((ticket: Ticket) => ticket.eventId === this.eventId);

    return (
      <div>
        <Container className="sjp-mt-5">
          <EventDetailsCard eventId={this.eventId} />
        </Container>
        {tickets.length ? currentUser && currentUser.active ? (
          <Container className="sjp-full-width sjp-pr-0 sjp-pl-0 sjp-mt-1">
            <Link to={`/event-tickets/${this.eventId}`}>
              <Button className="sjp-button sjp-no-border-radius sjp-button-text button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1 sjp-mb-3 sjp-text-uppercase">Buy Tickets</Button>
            </Link>
          </Container>
        ) : (
          <Container className="sjp-full-width sjp-pr-0 sjp-pl-0 sjp-mt-1">
            <Link to={`/event-tickets/${this.eventId}`}>
              <Button className="sjp-button sjp-no-border-radius sjp-button-text button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1 sjp-mb-3 sjp-text-uppercase">View Tickets</Button>
            </Link>
          </Container>
        ) : (
          <h5 className="sjp-warning-text sjp-text-center sjp-mt-1 sjp-mb-1">This event currently has no tickets on sale</h5>
        )}

        <Container>
          <Row>
            <EventDetails eventId={this.eventId} />
          </Row>
        </Container>
        <Container className="sjp-full-width sjp-pr-0 sjp-pl-0 sjp-mt-1">
          <Link to={`/events`}>
            <Button className="sjp-button sjp-no-border-radius sjp-button-text button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1 sjp-mb-3 sjp-text-uppercase">Back to Events</Button>
          </Link>
        </Container>
      </div>
    );
  }

  private getDataFromApi(): Promise<Ticket[]> {
    this.loadingStore.setLoadingStateToDone();
    return this.ticketStore.fetchTicketsByEventId(this.eventId);
  }
}
