import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Event, EventImage, Ticket, Image, User, Cart } from '../../stores/models/models';
import { EventStore, TicketStore, ImageStore, LoadingStore, CurrentUserStore, CartStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';

import { Col, Row, Button } from 'reactstrap';

import './styles/eventTicketsDetailsStyle.css';
import { Notifications } from 'core/notifications/notifications';

import EventTicketSelector from '../../components/website/EventTicketsSelector';
import EventCartCard from '../../components/website/EventCartCard';

import ModalImage from 'react-modal-image';

interface IEventTicketDetailsProps {
  eventId: number;
}

@inject('rootStore')
@observer
export default class EventTicketsDetails extends React.Component<IEventTicketDetailsProps, {}> {
  private eventStore: EventStore;
  private ticketStore: TicketStore;
  private imageStore: ImageStore;
  private currentUserStore: CurrentUserStore;
  private cartStore: CartStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.eventStore = props.rootStore.stores.event;
    this.ticketStore = props.rootStore.stores.ticket;
    this.imageStore = props.rootStore.stores.image;
    this.currentUserStore = props.rootStore.stores.currentUser;
    this.cartStore = props.rootStore.stores.cart;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Event, EventImage[], Ticket[], User]) => {
        const eventImages: EventImage[] | undefined = data[1];

        if (eventImages[0] !== undefined) {
          this.imageStore
            .fetchImageById(eventImages[0].imageId)
            .then((image: Image) => {
              this.loadingStore.setLoadingStateToDone();
            })
            .catch((err) => {
              this.loadingStore.setLoadingStateToError();
              Notifications.displayError(err);
            });
        } else {
          this.loadingStore.setLoadingStateToDone();
        }
      })
      .catch((err) => {
        if (err.body && err.body.message && err.body.message.includes('not logged in')) {
          this.loadingStore.setLoadingStateToDone();
        } else {
          this.loadingStore.setLoadingStateToError();
          Notifications.displayError(err);
        }
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const event: Event | undefined = this.eventStore.get(this.props.eventId);

    if (event === undefined) {
      throw new Error(`Error: Event with ID ${this.props.eventId} does not exist`);
    }

    const eventImage: EventImage | undefined = this.eventStore.eventImages.find((eventImage: EventImage) => eventImage.eventId === this.props.eventId && eventImage.seating);

    let image: Image | undefined;
    if (eventImage !== undefined) {
      image = this.imageStore.get(eventImage.imageId);
    }

    let imagePath: string | undefined;
    if (image !== undefined) {
      imagePath = `/assets/${image.fileName}`;
    }

    const currentUser: User | undefined = this.currentUserStore.currentUser;

    let cart: Cart | undefined;
    if (currentUser !== undefined) {
      cart = this.cartStore.carts.find((cart: Cart) => cart.userId === currentUser.id && cart.dateConfirmed === null);
    }

    const tickets: Ticket[] = this.ticketStore.tickets.filter((ticket: Ticket) => ticket.eventId === this.props.eventId);

    return (
      <div className="sjp-mb-5">
        {tickets.length > 0 ? (
          <div>
            <Row className="sjp-text-container-center-small-devices">
              <h5 className="sjp-sub-title-text sjp-mt-2">{`Ticket Types & Seating`}</h5>
            </Row>
            {imagePath !== undefined ? (
              <Row>
                <ModalImage className="sjp-d-block sjp-full-width sjp-no-margin-left sjp-image-fit sjp-mb-5 sjp-mt-2" small={imagePath} large={imagePath} alt="Seating Photo" />
                <Col xs="12" sm="12" md="12" lg="9" xl="9" className="sjp-pl-0 sjp-pr-0">
                  <EventTicketSelector eventId={this.props.eventId} cartId={cart ? cart.id : 0} />
                </Col>
                {cart !== undefined ? this.currentUserStore.currentUser && this.currentUserStore.currentUser.active ? (
                  <Col xs="12" sm="12" md="12" lg="3" xl="3" className="sjp-pr-0 sjp-event-cart-card-padding">
                    <EventCartCard cartId={cart ? cart.id : 0} cart={true} />
                  </Col>
                ) : (
                  <Col xs="12" sm="12" md="12" lg="3" xl="3" className="sjp-pr-0">
                    <h5 className="sjp-small-warning-text">Your account has not been activated by an Administrator yet</h5>
                  </Col>
                ) : (
                  <Col xs="12" sm="12" md="12" lg="3" xl="3">
                    <Link className="sjp-full-width" to="/login">
                      <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button sjp-full-width sjp-pt-1 sjp-pb-1">Login to buy tickets</Button>
                    </Link>
                    <Link className="sjp-full-width" to="/register">
                      <h5 className="sjp-warning-text sjp-mt-1">Not registered yet?</h5>
                    </Link>
                  </Col>
                )}
              </Row>
            ) : (
              <Row>
                <Col xs="12" sm="12" md="12" lg="9" xl="9" className="sjp-pl-0 sjp-pr-0">
                  <EventTicketSelector eventId={this.props.eventId} cartId={cart ? cart.id : 0} /> {/* 0 means no cart so don't show numbers */}
                </Col>
                {cart !== undefined ? this.currentUserStore.currentUser && this.currentUserStore.currentUser.active ? (
                  <Col xs="12" sm="12" md="12" lg="3" xl="3" className="sjp-pr-0 sjp-event-cart-card-padding">
                    <EventCartCard cartId={cart.id} cart={true} />
                  </Col>
                ) : (
                  <Col xs="12" sm="12" md="12" lg="3" xl="3" className="sjp-pr-0">
                    <h5 className="sjp-small-warning-text">Your account has not been activated by an Administrator yet</h5>
                  </Col>
                ) : (
                  <Col xs="12" sm="12" md="12" lg="3" xl="3">
                    <Link className="sjp-full-width" to="/login">
                      <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button sjp-full-width sjp-pt-1 sjp-pb-1">Login to buy tickets</Button>
                    </Link>
                    <Link className="sjp-full-width" to="/register">
                      <h5 className="sjp-warning-text sjp-mt-1">Not registered yet?</h5>
                    </Link>
                  </Col>
                )}
              </Row>
            )}
          </div>
        ) : (
          undefined
        )}
      </div>
    );
  }

  private getDataFromApi(): Promise<[Event, EventImage[], Ticket[], User]> {
    this.loadingStore.setLoadingStateToLoading();
    const eventPromise: Promise<Event> = this.eventStore.fetchEventById(this.props.eventId);
    const eventImagesPromise: Promise<EventImage[]> = this.eventStore.fetchEventImagesByEventIdWithParams(this.props.eventId, false, true);
    const ticketsPromise: Promise<Ticket[]> = this.ticketStore.fetchTicketsByEventId(this.props.eventId);
    const currentUserPromise: Promise<User> = this.currentUserStore.fetchUserData().then((user: User) => {
      if (user !== undefined) {
        return this.cartStore.fetchProvisionalCartByUserId(user.id).then((cart: Cart) => user);
      }
      return user;
    });
    return Promise.all([ eventPromise, eventImagesPromise, ticketsPromise, currentUserPromise ]);
  }
}
