import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import { User, Cart, TicketCartItem, Ticket, Event } from '../../stores/models/models';
import { CurrentUserStore, CartStore, TicketStore, EventStore, LoadingStore } from '../../stores/stores';

import EventDetailsCartCard from '../../components/website/EventDetailsCartCard';
import EventCartCard from '../../components/website/EventCartCard';
import LoadingSpinenr from '../../components/website/LoadingSpinner';

import { Col, Row, Container, Button } from 'reactstrap';
import './styles/eventCartScreenStyle.css';

import { Notifications } from 'core/notifications/notifications';

@inject('rootStore')
@observer
export default class EventCartScreen extends React.Component<{}, {}> {
  private currentUserStore: CurrentUserStore;
  private cartStore: CartStore;
  private ticketStore: TicketStore;
  private eventStore: EventStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.currentUserStore = props.rootStore.stores.currentUser;
    this.cartStore = props.rootStore.stores.cart;
    this.ticketStore = props.rootStore.stores.ticket;
    this.eventStore = props.rootStore.stores.event;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then(() => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        Notifications.displayError(err);
        this.loadingStore.setLoadingStateToError();
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinenr />;
    }

    const currentUser: User | undefined = this.currentUserStore.currentUser;

    if (currentUser === undefined) {
      return <h5 className="sjp-cart-undefined-warning">Please log in to view your cart</h5>;
    }

    const cart: Cart | undefined = this.cartStore.carts.find((cart: Cart) => cart.userId === currentUser.id);

    if (cart === undefined) {
      throw new Error(`Error: Cart with User ID ${currentUser.id} does not exist`);
    }

    const ticketCartItems: TicketCartItem[] = this.ticketStore.ticketCartItems.filter((ticketCartItem: TicketCartItem) => ticketCartItem.cartId === cart.id);

    const events: Event[] = [];

    ticketCartItems.forEach((ticketCartItem: TicketCartItem) => {
      const ticket: Ticket | undefined = this.ticketStore.get(ticketCartItem.ticketId);
      if (ticket === undefined) {
        throw new Error(`Error: Ticket with ID ${ticketCartItem.ticketId} does not exist`);
      }
      if (ticket.eventId === null) {
        throw new Error(`Error: Ticket with ID ${ticketCartItem.ticketId} does not have an event ID`);
      }

      const event: Event | undefined = this.eventStore.get(ticket.eventId);
      if (event === undefined) {
        throw new Error(`Error: Event with ID ${ticket.eventId} does not exist`);
      }

      if (events.findIndex((arrayEvent: Event) => arrayEvent.id === event.id) < 0) {
        events.push(event);
      }
    });

    return (
      <div>
        {ticketCartItems.length === 0 ? (
          <Container className="sjp-mb-3">
            <Row className="sjp-mt-5 sjp-text-container-center-small-devices">
              <h5 className="sjp-text-center sjp-warning-text sjp-mt-1">There are currently no tickets in you cart</h5>
            </Row>
            <Link to="/events">
              <Container className="sjp-white-background sjp-mb-3">
                <Button className="sjp-mt-1 sjp-button sjp-full-width sjp-button-text button-one-line-text sjp-no-border-radius sjp-pt-1 sjp-pb-1" type="submit">
                  BUY A TICKET?
                </Button>
              </Container>
            </Link>
          </Container>
        ) : (
          <Container className="sjp-mb-3">
            <Row className="sjp-mt-5 sjp-text-container-center-small-devices">
              <h4 className="sjp-title-text">Tickets Details</h4>
            </Row>
            <Row>
              <Col xs="12" sm="12" md="12" lg="9" xl="9" className="sjp-pl-0 sjp-pr-0">
                {events.map((event: Event) => {
                  return <EventDetailsCartCard key={event.id} eventId={event.id} cartId={cart.id} />;
                })}
                <Link to="/events" className="sjp-full-width sjp-mt2">
                  <Row className="sjp-mt-1 sjp-mb-1">
                    <Col>
                      <Button className="sjp-button sjp-no-border-radius sjp-button-text button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1" type="submit">
                        BUY ANOTHER TICKET?
                      </Button>
                    </Col>
                  </Row>
                </Link>
              </Col>

              <Col xs="12" sm="12" md="12" lg="3" xl="3" className="sjp-pr-0 sjp-event-cart-card-padding">
                <EventCartCard cartId={cart.id} cart={true} />
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }

  private getDataFromApi(): Promise<Event[]> {
    this.loadingStore.setLoadingStateToLoading();
    return this.currentUserStore.fetchUserData().then((user: User) => {
      return this.cartStore.fetchProvisionalCartByUserId(user.id).then((cart: Cart) => {
        return this.ticketStore.fetchTicketCartItemsByCartId(cart.id).then((ticketCartItems: TicketCartItem[]) => {
          const ticketsPromises: Promise<Ticket>[] = ticketCartItems.map((ticketCartItem: TicketCartItem) => this.ticketStore.fetchTicketById(ticketCartItem.ticketId));
          return Promise.all(ticketsPromises).then((tickets: Ticket[]) => {
            const eventPromises: Promise<Event>[] = tickets.map((ticket: Ticket) => this.eventStore.fetchEventById(ticket.eventId ? ticket.eventId : 0));
            return Promise.all(eventPromises);
          });
        });
      });
    });
  }
}
