import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Row, Col, Container } from 'reactstrap';

import './styles/additionalFacilityStyle.css';

import { Facility, Booking, BookingFacility } from '../../stores/models/models';
import { FacilityStore, BookingStore, CartStore, LoadingStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';

import { Plus, Minus } from '../svgIcons/icons';

interface IAdditionalFacilityCardProps {
  facilityId: number;
  bookingId: number;
}

@inject('rootStore')
@observer
export default class AdditionalFacilityCard extends React.Component<IAdditionalFacilityCardProps, {}> {
  private facilityStore: FacilityStore;
  private bookingStore: BookingStore;
  private cartStore: CartStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.facilityStore = props.rootStore.stores.facility;
    this.bookingStore = props.rootStore.stores.booking;
    this.cartStore = props.rootStore.stores.cart;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((date: [Facility, BookingFacility[]]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const facility: Facility | undefined = this.facilityStore.get(this.props.facilityId);

    if (facility === undefined) {
      throw new Error(`Error: Facility with ID ${this.props.facilityId} does not exist`);
    }

    const bookingFacility: BookingFacility | undefined = this.bookingStore.bookingFacilities.find((bookingFacility: BookingFacility) => bookingFacility.facilityId === this.props.facilityId && bookingFacility.bookingId === this.props.bookingId);

    return (
      <Container className="sjp-mb-1 sjp-additional-facility-height">
        <Row className="sjp-additional-facility-height">
          <Col>
            <Row className="sjp-full-height sjp-white-background">
              <Col xs="12" sm="12" md="1" lg="1" xl="1" className="sjp-white-background sjp-pl-0 sjp-pr-0">
                <Col className={`sjp-no-padding sjp-additional-facility-card-colour sjp-no-margin ${bookingFacility === undefined || bookingFacility.quantity === 0 ? 'sjp-darkgrey-background' : 'sjp-background-primary'}`} />
              </Col>
              <Col xs="12" sm="12" md="9" lg="9" xl="9" className="sjp-full-height sjp-white-background sjp-pl-2 sjp-pr-2 sjp-additional-facility-description-col">
                <Row className="sjp-pt-1 sjp-mt-2">
                  <h5 className="sjp-label sjp-text-uppercase sjp-pl-1 sjp-pr-1 sjp-mb-1 ">{facility.name}</h5>
                </Row>
                <Row>
                  <p className="sjp-description-text sjp-pl-1 sjp-pr-1">{facility.description}</p>
                </Row>
              </Col>
              <Col xs="12" sm="12" md="2" lg="2" xl="2" className="sjp-full-height sjp-text-center sjp-white-background sjp-qty-col">
                <Row className="sjp-pt-1 sjp-mt-2 sjp-text-center">
                  <h5 className="sjp-label sjp-text-uppercase sjp-text-center sjp-mb-2 sjp-full-width">QTY</h5>
                </Row>
                <Row className="sjp-flex sjp-mt-1">
                  <a className="sjp-mr-1" onClick={this.handleRemoveBookingFacility.bind(this, bookingFacility)}>
                    <Minus className={`${bookingFacility && bookingFacility.quantity > 0 ? '' : 'sjp-greyed-out'} sjp-height-1  sjp-width-1 sjp-primary-fill`} />
                  </a>
                  <div className="sjp-facility-quantity">{bookingFacility ? bookingFacility.quantity : 0}</div>
                  <a className="sjp-ml-1" onClick={this.handleAddBookingFacility.bind(this, bookingFacility, facility)}>
                    <Plus className={`${!this.canBeAdded(bookingFacility ? bookingFacility.quantity : 0, facility.quantityAvailable) ? 'sjp-greyed-out' : ''} sjp-height-1  sjp-width-1 sjp-primary-fill`} />
                  </a>
                </Row>
                <Row />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  private handleRemoveBookingFacility(bookingFacility: BookingFacility | undefined): void {
    const newBookingFacility: BookingFacility = bookingFacility ? bookingFacility : new BookingFacility();

    if (newBookingFacility.quantity === 1 && newBookingFacility.id !== 0) {
      this.bookingStore.deleteBookingFacility(newBookingFacility.id).then(() => {
        this.bookingStore.fetchBookingById(newBookingFacility.bookingId).then((booking: Booking) => {
          this.cartStore.fetchCartById(booking.cartId);
        });
      });
      return;
    }
    if (this.canBeRemoved(newBookingFacility.quantity)) {
      newBookingFacility.bookingId = this.props.bookingId;
      newBookingFacility.facilityId = this.props.facilityId;
      newBookingFacility.quantity--;
      this.bookingStore.createOrUpdateBookingFacility(newBookingFacility).then((bookingFacility: BookingFacility) => {
        this.bookingStore.fetchBookingById(bookingFacility.bookingId).then((booking: Booking) => {
          this.cartStore.fetchCartById(booking.cartId);
        });
      });
    }
  }

  private handleAddBookingFacility(bookingFacility: BookingFacility | undefined, additionalFacility: Facility): void {
    const newBookingFacility: BookingFacility = bookingFacility ? bookingFacility : new BookingFacility();
    if (this.canBeAdded(newBookingFacility.quantity, additionalFacility.quantityAvailable)) {
      newBookingFacility.bookingId = this.props.bookingId;
      newBookingFacility.facilityId = this.props.facilityId;
      newBookingFacility.quantity++;
      this.bookingStore.createOrUpdateBookingFacility(newBookingFacility).then((bookingFacility: BookingFacility) => {
        this.bookingStore.fetchBookingById(bookingFacility.bookingId).then((booking: Booking) => {
          this.cartStore.fetchCartById(booking.cartId);
        });
      });
    }
  }

  private canBeAdded(quantityRequired: number, quantityAvailable: number): boolean {
    if (quantityAvailable === -1) {
      return true;
    }
    if (quantityRequired < quantityAvailable) {
      return true;
    }
    return false;
  }

  private canBeRemoved(quantityRequired: number): boolean {
    if (quantityRequired > 1) {
      return true;
    }
    return false;
  }

  private getDataFromApi(): Promise<[Facility, BookingFacility[]]> {
    this.loadingStore.setLoadingStateToLoading();
    return Promise.all([ this.facilityStore.fetchFacilityById(this.props.facilityId), this.bookingStore.fetchBookingFacilitiesByBookingId(this.props.bookingId) ]);
  }
}
