import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Cart } from '../../stores/models/models';

import { RootStore } from '../../stores/RootStore';
import { CartStore, LoadingStore, CheckoutStore } from '../../stores/stores';

import UserDetails from '../../components/website/UserDetails';
import UserDetailsForm from '../../components/website/UserDetailsForm';
import RedeemCodeCard from '../../components/website/RedeemCodeCard';
import YourCartCard from '../../components/website/YourCartCard';

import { Container, Col, Row, Button } from 'reactstrap';

import LoadingSpinner from '../../components/website/LoadingSpinner';

import './styles/checkoutScreenStyle.css';
import { Notifications } from 'core/notifications/notifications';

@inject('rootStore')
@observer
export default class CheckoutScreen extends React.Component<{}, {}> {
  private cartId: number;

  private rootStore: RootStore;
  private checkoutStore: CheckoutStore;
  private cartStore: CartStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    const parameter = props.match.params.cartid;
    this.cartId = parseInt(parameter);

    this.rootStore = props.rootStore;
    this.checkoutStore = props.rootStore.stores.checkout;
    this.cartStore = props.rootStore.stores.cart;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((cart: Cart) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const cart: Cart | undefined = this.cartStore.get(this.cartId);
    if (cart === undefined) {
      throw new Error(`Error: Cart with ID ${this.cartId} does not exist!`);
    }

    return (
      <div>
        <Container className="sjp-mb-3">
          <Row className="sjp-mt-5 sjp-text-container-center-small-devices">
            <h3 className="sjp-title-text">Finalise Booking</h3>
          </Row>
          <Row className="sjp-mt-1">
            <Col xs="12" sm="12" md="12" lg="9" xl="9" className="sjp-pl-0 sjp-pr-0">
              {this.checkoutStore.isBeingEdited ? (
                <Row className="sjp-mb-1 sjp-your-cart-card-padding">
                  <Col>
                    <UserDetailsForm userId={cart.userId} rootStore={this.rootStore} atCheckout={true} />
                  </Col>
                </Row>
              ) : (
                <div>
                  <Row className="sjp-your-cart-card-padding">
                    <Col>
                      <UserDetails userId={cart.userId} />
                    </Col>
                  </Row>
                  <Row className="sjp-mt-1 sjp-mb-1 sjp-your-cart-card-padding">
                    <Col>
                      <RedeemCodeCard cartId={this.cartId} />
                    </Col>
                  </Row>
                </div>
              )}
              <Link to="/cart">
                <Button color="primary" className="sjp-button sjp-no-border-radius sjp-button-text sjp-full-width sjp-mb-1" type="submit">
                  BACK TO CART
                </Button>
              </Link>
              <div style={{ height: '500px' }} />
            </Col>
            <Col xs="12" sm="12" md="12" lg="3" xl="3">
              <Row>
                <Col className="sjp-your-cart-card-padding">
                  <YourCartCard cartId={this.cartId} cart={false} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  private getDataFromApi(): Promise<Cart> {
    this.loadingStore.setLoadingStateToLoading();
    return this.cartStore.fetchCartById(this.cartId);
  }
}
