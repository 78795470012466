import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Input, Button } from 'antd';
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';

import { CartCode } from '../../stores/models/models';
import { RedeemCodeStore, CartStore, LoadingStore } from '../../stores/stores';

import LoadingSpinner from '../../components/website/LoadingSpinner';

import './styles/redeemCodeCardStyle.css';
import { Notifications } from 'core/notifications/notifications';

import { Check, Cancel } from '../svgIcons/icons';

interface IRedeemCodeCardProps {
  cartId: number;
}

@inject('rootStore')
@observer
export default class RedeemCodeCard extends React.Component<IRedeemCodeCardProps, {}> {
  @observable private code: string = '';
  @observable private warningModalOpen = false;
  @observable private errorModalOpen = false;
  private redeemCodeStore: RedeemCodeStore;
  private cartStore: CartStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.cartStore = props.rootStore.stores.cart;
    this.redeemCodeStore = props.rootStore.stores.redeemCode;
  }

  public componentDidMount() {
    this.loadingStore.setLoadingStateToDone();
    this.getDataFromApi()
      .then((cartCodes: CartCode[]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const cartCodes: CartCode[] = this.redeemCodeStore.cartCodes.filter((cartCode: CartCode) => cartCode.cartId === this.props.cartId);

    return (
      <Container>
        <Row className="sjp-pt-1 sjp-pb-1 sjp-darkgrey-background">
          <Col className="sjp-white-title-text sjp-text-uppercase">Coupon</Col>
        </Row>
        <Row className="sjp-pb-2 sjp-background-card-color">
          <Col>
            <Row className="sjp-mt-2">
              <Col>
                <h5 className="sjp-label sjp-text-uppercase">ADD COUPON CODE</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="sjp-description-text">Add coupon code here in order to benefit from the discount before check out.</h5>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Input className="sjp-no-border-radius" value={this.code} type="text" onChange={this.handleCouponChange.bind(this)} />
              </Col>
              <Col xs="12" sm="12" md="2" lg="2" xl="2" className="">
                <Button className="sjp-button sjp-button-text sjp-no-border-radius sjp-apply-coupon-button" onClick={this.handleApplyCoupon.bind(this)}>
                  Apply
                </Button>
              </Col>
            </Row>
            <Row className="sjp-mt-1">
              <Col>
                <Col>
                  {cartCodes.map((cartCode: CartCode) => {
                    return (
                      <Row key={cartCode.id}>
                        <Check className="sjp-width-1 sjp-height-1 sjp-primary-fill" />

                        <h5 className="sjp-label sjp-text-uppercase sjp-ml-1">COUPON {cartCode.code} HAS BEEN ADDED</h5>

                        <a onClick={this.handleCancelCartCode.bind(this, cartCode)}>
                          <Cancel className="sjp-width-1 sjp-height-1 sjp-ml-2 sjp-darkgrey-fill" />
                        </a>
                      </Row>
                    );
                  })}
                </Col>
              </Col>
            </Row>
            <Modal className="sjp-full-width" isOpen={this.warningModalOpen} toggle={this.toggleWarningModal.bind(this)} centered>
              <ModalHeader toggle={this.toggleWarningModal.bind(this)}>Code already used</ModalHeader>
              <ModalBody>You have already used the code: {this.code}</ModalBody>
            </Modal>
            <Modal isOpen={this.errorModalOpen} toggle={this.toggleErrorModal.bind(this)} centered>
              <ModalHeader toggle={this.toggleErrorModal.bind(this)}>Code does not exist</ModalHeader>
              <ModalBody>The code {this.code} does not exist</ModalBody>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }

  private handleCouponChange(e: any): void {
    const code: string = e.target.value;
    this.setCode(code);
  }

  private handleCancelCartCode(cartCode: CartCode): void {
    this.redeemCodeStore
      .deleteCartCode(cartCode.id)
      .then(() => {
        this.clearCode();
        this.cartStore.fetchCartById(this.props.cartId);
      })
      .catch((err) => Notifications.displayError(err));
  }

  @action('Set the code of the redeem code observable')
  private setCode(code: string): void {
    this.code = code;
  }

  private handleApplyCoupon() {
    if (this.code !== '') {
      const cartCodes: CartCode[] = this.redeemCodeStore.cartCodes.filter((cartCodes: CartCode) => cartCodes.cartId === this.props.cartId);
      const cartCode: CartCode = new CartCode();
      cartCode.cartId = this.props.cartId;
      cartCode.code = this.code;
      let cartCodeExists: boolean = false;
      cartCodes.forEach((cartCode: CartCode) => {
        if (cartCode.code === this.code) {
          this.toggleWarningModal();
          cartCodeExists = true;
          return;
        }
      });
      if (cartCodeExists) {
        return;
      }
      return this.redeemCodeStore
        .createCartCode(cartCode)
        .then((cartCode: CartCode) => {
          this.clearCode();
          return this.cartStore.fetchCartById(cartCode.cartId);
        })
        .catch((err) => {
          this.toggleErrorModal();
          return;
        });
    }
  }

  @action('Clear code observable')
  private clearCode(): void {
    this.code = '';
  }

  @action('Toggle warning modal')
  private toggleWarningModal(): void {
    this.warningModalOpen = !this.warningModalOpen;
  }

  @action('Toggle error modal')
  private toggleErrorModal(): void {
    this.errorModalOpen = !this.errorModalOpen;
  }

  private getDataFromApi(): Promise<any> {
    this.loadingStore.setLoadingStateToLoading();
    return this.redeemCodeStore.fetchCartCodesByCartId(this.props.cartId);
  }
}
