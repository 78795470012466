import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Booking, Node, NodeType, NodeFacility, Facility } from '../../stores/models/models';
import { BookingStore, CartStore, NodeStore, NodeTypeStore, FacilityStore, LoadingStore } from '../../stores/stores';

import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

import LoadingSpinner from './LoadingSpinner';
import AdditionalFacilityCard from './AdditionalFacilityCard';
import BookingCard from './BookingCard';

import moment from 'moment';
import { Notifications } from 'core/notifications/notifications';

import './styles/bookingDetailsStyle.css';

import { Cross } from '../svgIcons/icons';

const dateTimeDb: string = 'YYYY-MM-DD HH:mm:ss';
const dateFancyFormat: string = 'MMMM Do YYYY';
const WAIT_INTERVAL = 1000;

interface IBookingDetailsProps {
  bookingId: number;
}

@inject('rootStore')
@observer
export default class BookingDetails extends React.Component<IBookingDetailsProps, {}> {
  @observable private deletionModalOpen: boolean = false;
  @observable private booking: Booking;
  private timer;
  private bookingStore: BookingStore;
  private nodeStore: NodeStore;
  private nodeTypeStore: NodeTypeStore;
  private facilityStore: FacilityStore;
  private cartStore: CartStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.bookingStore = props.rootStore.stores.booking;
    this.nodeStore = props.rootStore.stores.node;
    this.nodeTypeStore = props.rootStore.stores.nodeType;
    this.facilityStore = props.rootStore.stores.facility;
    this.cartStore = props.rootStore.stores.cart;
  }

  public componentDidMount() {
    this.timer = null;
    this.getDataFromApi()
      .then((booking: Booking) => {
        this.booking = booking;
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const booking: Booking | undefined = this.bookingStore.get(this.props.bookingId);

    if (booking === undefined) {
      throw new Error(`Booking with ID ${this.props.bookingId} does not exist`);
    }

    const node: Node | undefined = this.nodeStore.get(booking.nodeId);

    if (node === undefined) {
      throw new Error(`Node with ID ${booking.nodeId} does not exist`);
    }

    const nodeType: NodeType | undefined = this.nodeTypeStore.get(node.typeId);

    if (nodeType === undefined) {
      throw new Error(`Node Type with ID ${node.typeId} does not exist`);
    }

    const includedNodeFacilities: NodeFacility[] = this.nodeStore.nodeFacilities.filter((nodeFacility: NodeFacility) => nodeFacility.nodeId === node.id && nodeFacility.included);
    const includedFacilities: Facility[] = [];
    includedNodeFacilities.forEach((nodeFacility: NodeFacility) => {
      const facility: Facility | undefined = this.facilityStore.get(nodeFacility.facilityId);
      if (facility !== undefined) {
        includedFacilities.push(facility);
      }
    });

    const additionalNodeFacilities: NodeFacility[] = this.nodeStore.nodeFacilities.filter((nodeFacility: NodeFacility) => nodeFacility.nodeId === node.id && !nodeFacility.included);
    const additionalFacilities: Facility[] = [];
    additionalNodeFacilities.forEach((nodeFacility: NodeFacility) => {
      const facility: Facility | undefined = this.facilityStore.get(nodeFacility.facilityId);
      if (facility !== undefined) {
        additionalFacilities.push(facility);
      }
    });

    return (
      <Col>
        <Row className="sjp-mb-1">
          <Col className="sjp-background-card-color sjp-pb-4">
            <Row className="sjp-darkgrey-background sjp-pt-1 sjp-pb-1">
              <Col>
                <div className="sjp-white-title-text sjp-text-uppercase">{node.name}</div>
              </Col>
              <Col className="sjp-align-right sjp-justify-end ">
                <a href="#" onClick={this.toggleDeletionModal.bind(this)}>
                  <Cross className="sjp-width-1 sjp-white-fill sjp-height-1" />
                </a>
                <Modal isOpen={this.deletionModalOpen} toggle={this.toggleDeletionModal.bind(this)} centered>
                  <ModalHeader toggle={this.toggleDeletionModal.bind(this)}>Booking Deletion</ModalHeader>
                  <ModalBody>
                    Are you sure you want to delete your booking of {node.name} on the {moment(booking.dateFrom, dateTimeDb).format(dateFancyFormat)}?
                  </ModalBody>
                  <ModalFooter>
                    <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button-grey" onClick={this.toggleDeletionModal.bind(this)}>
                      No
                    </Button>
                    <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button" onClick={this.handleDeleteBooking.bind(this)}>
                      Yes
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>

            <Row className="sjp-mt-1">
              <Col>
                <BookingCard bookingId={this.props.bookingId} withButton={true} />
              </Col>
            </Row>

            {additionalFacilities.length !== 0 ? (
              <Row className="sjp-text-align-left sjp-text-container-center-small-devices">
                <Col className="sjp-text-container-center-small-devices">
                  <h6 className="sjp-warning-text sjp-mb-1 sjp-mt-2 sjp-text-container-center-small-devices">Add Extra Amenities To Booking</h6>
                </Col>
              </Row>
            ) : (
              undefined
            )}

            <div>
              {additionalFacilities.map((additionaFacility: Facility) => {
                return <AdditionalFacilityCard key={additionaFacility.id} facilityId={additionaFacility.id} bookingId={this.props.bookingId} />;
              })}
            </div>

            <Row className="sjp-description-box-height sjp-mt-2 ">
              <Col className="">
                <h5 className="sjp-warning-text sjp-mb-1 ">Additional Comments</h5>
                <Input onChange={this.handleDescriptionChange.bind(this)} defaultValue={booking.description} className="sjp-description-box-height sjp-full-height sjp-no-border-radius sjp-no-border" type="textarea" name="text" id="exampleText" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }

  private handleDeleteBooking(): void {
    this.toggleDeletionModal();
    const booking: Booking | undefined = this.bookingStore.get(this.props.bookingId);

    if (booking === undefined) {
      throw new Error(`Error: Booking with ID ${this.props.bookingId} does not exist`);
    }

    this.bookingStore
      .deleteBooking(this.props.bookingId)
      .then(() => {
        this.cartStore.fetchCartById(booking.cartId);
      })
      .catch((err) => {
        Notifications.displayError(err);
      });
  }

  private handleDescriptionChange(e: any): void {
    const newDescription: string = e.target.value;
    const booking: Booking = this.booking;
    booking.description = newDescription;
    this.setBooking(booking);
    clearTimeout(this.timer);
    this.timer = setTimeout(this.handleUpdateBookingFromApi.bind(this), WAIT_INTERVAL);
  }

  private handleUpdateBookingFromApi(): void {
    this.bookingStore.createOrUpdateBooking(this.booking).catch((err) => {
      Notifications.displayError(err);
    });
  }

  @action('Set the booking observable')
  private setBooking(booking: Booking): void {
    this.booking = booking;
  }

  @action('Toggle the deletionModalOpen variable')
  private toggleDeletionModal(): void {
    this.deletionModalOpen = !this.deletionModalOpen;
  }

  private getDataFromApi(): Promise<Booking> {
    this.loadingStore.setLoadingStateToLoading();
    return this.bookingStore.fetchBookingById(this.props.bookingId).then((booking: Booking) => {
      return this.nodeStore.fetchNodeById(booking.nodeId).then((node: Node) => {
        return this.nodeTypeStore.fetchNodeTypeById(node.typeId).then(() => {
          return this.nodeStore.fetchNodeFacilitiesByNodeId(node.id).then((nodeFacilities: NodeFacility[]) => {
            nodeFacilities.forEach((nodeFacility: NodeFacility) => {
              this.facilityStore.fetchFacilityById(nodeFacility.facilityId);
            });
            return booking;
          });
        });
      });
    });
  }
}
