import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Container, Button } from 'reactstrap';

import EventDetailsCard from '../../components/website/EventDetailsCard';
import EventTicketDetails from '../../components/website/EventTicketsDetails';

@inject('rootStore')
@observer
export default class EventTicketScreen extends React.Component<{}, {}> {
  private eventId: number;

  constructor(props: any) {
    super(props);

    const parameter = props.match.params.eventid;
    this.eventId = parseInt(parameter);
  }

  public render() {
    return (
      <div>
        <Container className="sjp-mt-5">
          <EventDetailsCard eventId={this.eventId} />
        </Container>
        <Container className="sjp-mt-1">
          <EventTicketDetails eventId={this.eventId} />
        </Container>
        <Container className="sjp-full-width sjp-pr-0 sjp-pl-0">
          <Link to={`/event/${this.eventId}`}>
            <Button className="sjp-button sjp-no-border-radius sjp-button-text button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1 sjp-mb-3 sjp-text-uppercase">Back to Event Details</Button>
          </Link>
        </Container>
      </div>
    );
  }
}
