import ApiService from './ApiService';

import { NodeType } from '../stores/models/models';

class NodeTypeService extends ApiService {
  constructor() {
    super();
  }

  public fetchNodeTypes(): Promise<NodeType[]> {
    const url: string = this.getUrl('/nodetypes');
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, NodeType);
      });
  }

  public fetchNodeTypeById(nodeTypeId: number): Promise<NodeType> {
    const url: string = this.getUrl(`/nodetype/${nodeTypeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, NodeType);
      });
  }

  public createNodeType(nodeType: NodeType): Promise<NodeType> {
    const url: string = this.getUrl('/nodetype');
    const jsonNodeType = this.jsonConvert.serialize(nodeType);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeType)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, NodeType);
      });
  }

  public updateNodeType(nodeType: NodeType): Promise<NodeType> {
    const url: string = this.getUrl(`/nodetype/${nodeType.id}`);
    const jsonNodeType = this.jsonConvert.serialize(nodeType);
    return fetch(url, {
      method: 'PUT',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeType)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, NodeType);
      });
  }

  public createOrUpdateNodeType(nodeType: NodeType): Promise<NodeType> {
    const baseUrl: string = '/nodetype';
    const isNew: boolean = nodeType.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/${nodeType.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonNodeType = this.jsonConvert.serialize(nodeType);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonNodeType)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, NodeType));
  }

  public deleteNodeType(nodeTypeId: number): Promise<void> {
    const url: string = this.getUrl(`/nodetype/${nodeTypeId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }
}

export default NodeTypeService;
