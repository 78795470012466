import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Link } from 'react-router-dom';
import { observable, action } from 'mobx';

import { Input, Button } from 'antd';

import UserService from '../../services/UserService';
import { LoadingStore } from '../../stores/stores';

import { Container, Row, Col } from 'reactstrap';
import { Notifications } from '../../../../core/notifications/notifications';

import './styles/resetPasswordScreenStyle.css';

import { Lock } from '../../components/svgIcons/icons';

@inject('rootStore')
@observer
export default class ResetPasswordScreen extends React.Component<{}, {}> {
  @observable private passwordOne: string = '';
  @observable private passwordTwo: string = '';
  @observable private resetComplete: boolean = false;
  private token: string = '';
  private userService: UserService = new UserService();
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const tokenParam: string | null = params.get('token');
    this.token = tokenParam ? tokenParam : '';
    this.loadingStore.setLoadingStateToDone();
  }

  public render() {
    if (this.resetComplete) {
      return <Redirect to="/login" />;
    }

    return <Container>{this.token === '' ? this.getNoTokenWarning() : this.getResetForm()}</Container>;
  }

  private getNoTokenWarning(): JSX.Element {
    return <Row>{<div className="sjp-small-warning-text sjp-text-uppercase">No token available! Make sure to follow the URL sent to you in the email.</div>}</Row>;
  }

  private getResetForm(): JSX.Element {
    return (
      <Container className="sjp-reset-container sjp-mt-5 sjp-mb-3">
        <Row className="sjp-pt-1 sjp-pb-1 sjp-background-primary">
          <Col>
            <div className="sjp-white-title-text sjp-text-uppercase">Reset Password</div>
          </Col>
        </Row>
        <Row className="sjp-background-card-color sjp-pt-3">
          <Col xs="8" sm="8" md="8" lg="8" xl="8">
            <Row>
              <Col md={12}>
                <div className="sjp-label sjp-text-uppercase">* enter password</div>
                <Input className="sjp-mt-1 sjp-no-border-radius" type="password" placeholder="Enter your new password here..." value={this.passwordOne} onChange={this.handleSetPassword.bind(this, true)} />
              </Col>
            </Row>
            <Row className="sjp-mt-2">
              <Col>
                <div className="sjp-label sjp-text-uppercase">* confirm password</div>
                <Input className="sjp-mt-1 sjp-no-border-radius" type="password" placeholder="Confirm your new password here..." value={this.passwordTwo} onChange={this.handleSetPassword.bind(this, false)} />
              </Col>
            </Row>
            <Row className="sjp-mt-1">{this.passwordOne !== this.passwordTwo ? <Col>{this.showNotMatchingMessage()}</Col> : null}</Row>
            <Row className="sjp-mt-1">{this.passwordOne.length < 8 || !this.hasLowerCase(this.passwordOne) || !this.hasUpperCase(this.passwordOne) ? <Col>{this.showMissingCharacterTypeMessage()}</Col> : null}</Row>
            <Row>
              <Col>
                <Button
                  className="sjp-button sjp-pl-2 sjp-pr-2 sjp-button-text sjp-text-uppercase sjp-no-border-radius sjp-reset-button sjp-mb-1 sjp-mt-1"
                  type="primary"
                  onClick={this.handleReset.bind(this)}
                  loading={this.loadingStore.stillLoading}
                  disabled={this.resetDisabled()}
                >
                  <div className="sjp-button-text sjp-text-uppercase sjp-pr-1 sjp-pl-1 ">Reset Password</div>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs="4" sm="4" md="4" lg="4" xl="4">
            <Lock className="sjp-reset-image sjp-primary-fill" />
          </Col>
        </Row>
        <Row className="sjp-background-card-color sjp-reset-divider-margin sjp-pt-1 sjp-pb-1">
          <Col>
            <Link to="/login">
              <Button className="sjp-full-width sjp-card-button sjp-no-border-radius">
                <div className="sjp-label sjp-text-uppercase">ALTERNATIVELY GO BACK TO LOG IN</div>
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  private showNotMatchingMessage(): JSX.Element {
    return <div className="sjp-small-warning-text sjp-text-uppercase">The passwords do not match!</div>;
  }

  private showMissingCharacterTypeMessage(): JSX.Element {
    return <div className="sjp-small-warning-text sjp-text-uppercase">Please make sure your password contains at least one lowercase letter, one uppercase letter and has at least 8 characters</div>;
  }

  private resetDisabled(): boolean {
    return this.passwordOne.length < 8 || this.passwordTwo.length < 8 || this.passwordOne !== this.passwordTwo || !this.hasLowerCase(this.passwordOne) || !this.hasUpperCase(this.passwordOne);
  }

  private hasLowerCase(string: string): boolean {
    return /[a-z]/.test(string);
  }

  private hasUpperCase(string: string): boolean {
    return /[A-Z]/.test(string);
  }

  private handleReset(): void {
    this.userService
      .resetPassword(this.token, this.passwordOne, this.passwordTwo, false)
      .then(() => {
        this.loadingStore.setLoadingStateToDone();
        Notifications.showSuccess('Password reset succesful', 'You can now login with your new password');
        // Clear the form
        this.setPassword(true, '');
        this.setPassword(false, '');
        // Redirect to the login screen
        this.completeReset();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  private handleSetPassword(first: boolean, e: any): void {
    this.setPassword(first, e.target.value);
  }

  @action('Setting password')
  private setPassword(first: boolean, password: string): void {
    if (first) {
      this.passwordOne = password;
    } else {
      this.passwordTwo = password;
    }
  }

  @action('Completing the reset')
  private completeReset(): void {
    this.resetComplete = true;
  }
}
