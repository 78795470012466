import ApiService from './ApiService';

import { TypeCanHave } from '../stores/models/models';

class TypeCanHaveService extends ApiService {
  constructor() {
    super();
  }

  public fetchTypeCanHaves(): Promise<TypeCanHave[]> {
    const url: string = this.getUrl(`/typecanhaves`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, TypeCanHave);
      });
  }

  public fetchTypeCanHavesByTypeId(nodeTypeId: number): Promise<TypeCanHave[]> {
    const url: string = this.getUrl(`/typecanhave/type/${nodeTypeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length > 0) {
          return this.jsonConvert.deserialize(responseJson.data, TypeCanHave);
        }
        return [];
      });
  }

  public createTypeCanHave(typeCanHave: TypeCanHave): Promise<TypeCanHave> {
    const url: string = this.getUrl(`/typecanhave`);
    const jsonTypeCanHave = this.jsonConvert.serialize(typeCanHave);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonTypeCanHave)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, TypeCanHave));
  }

  public deleteTypeCanHaveByTypeIdAndCanHaveId(typeId: number, canHaveId: number): Promise<void> {
    const url: string = this.getUrl(`/typecanhave/type/${typeId}/can-have/${canHaveId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => responseJson.data);
  }
}

export default TypeCanHaveService;
