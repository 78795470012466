import { observable, action, runInAction } from 'mobx';
import { isUndefined } from 'util';

// This store does not accept duplicates
export default class GenericPrimitiveStore<T> {
  @observable public contents: T[] = [];

  public addAll(values: T[]): void {
    runInAction('Adding all given values to the store', () => {
      values.forEach((value) => {
        this.add(value);
      });
    });
  }

  @action('Adding a single value to the store')
  public add(value: T): void {
    if (!this.contains(value)) {
      this.contents.push(value);
    }
  }

  public contains(value: T): boolean {
    return !isUndefined(this.contents.find((e) => e === value));
  }

  @action('Removing a single value from the store')
  public remove(value: T): void {
    const index = this.contents.findIndex((e) => e === value);
    if (index !== -1) {
      this.contents.splice(index, 1);
    }
  }

  @action('Clearing the store content')
  public clear(): void {
    this.contents = [];
  }
}
