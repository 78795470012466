import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Event, EventImage, Image, Node } from '../../stores/models/models';
import { EventStore, ImageStore, NodeStore, LoadingStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';

import { Row, Col, Button } from 'reactstrap';

import moment from 'moment';

import './styles/eventCardStyle.css';
import { Notifications } from 'core/notifications/notifications';

const dateFormat: string = 'DD MMMM YYYY';

interface IEventCardProps {
  eventId: number;
}

@inject('rootStore')
@observer
export default class EventCard extends React.Component<IEventCardProps, {}> {
  private eventStore: EventStore;
  private imageStore: ImageStore;
  private nodeStore: NodeStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.eventStore = props.rootStore.stores.event;
    this.imageStore = props.rootStore.stores.image;
    this.nodeStore = props.rootStore.stores.node;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Event, EventImage[]]) => {
        const eventImages: EventImage[] = data[1];
        if (eventImages[0] !== undefined) {
          this.imageStore
            .fetchImageById(eventImages[0].imageId)
            .then((image: Image) => {
              this.loadingStore.setLoadingStateToDone();
            })
            .catch((err) => {
              this.loadingStore.setLoadingStateToError();
              Notifications.displayError(err);
            });
        } else {
          this.loadingStore.setLoadingStateToDone();
        }
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const event: Event | undefined = this.eventStore.get(this.props.eventId);

    if (event === undefined) {
      <h5 className="sjp-warning-text sjp-text-center sjp-mt-1 sjp-mb-1">Event with ID {this.props.eventId} does not exist.</h5>;
      return;
    }

    const eventImage: EventImage | undefined = this.eventStore.eventImages.find((eventImage: EventImage) => eventImage.eventId === this.props.eventId);

    let image: Image | undefined;
    if (eventImage !== undefined) {
      image = this.imageStore.get(eventImage.imageId);
    }

    let imagePath;
    if (image !== undefined) {
      imagePath = `/assets/${image.fileName}`;
    }

    const fallbackImagePath: string = '/static-images/meeting.jpg';

    const node: Node | undefined = this.nodeStore.nodes.find((node: Node) => node.id === event.nodeId);

    if (node === undefined) {
      throw new Error(`Error: Node with ID: ${event.nodeId} does not exist`);
    }

    return (
      <Row className="sjp-mt-1 sjp-background-card-color sjp-event-card-height">
        <Col xs="12" sm="12" md="6" lg="6" xl="6">
          <Row>
            <img src={imagePath ? imagePath : fallbackImagePath} className="sjp-d-block sjp-event-card-height sjp-event-card-image sjp-no-margin-left" alt="Cover Photo" />
          </Row>
        </Col>
        <Col xs="12" sm="12" md="6" lg="6" xl="6" className="sjp-event-details-col sjp-event-card-height sjp-background-card-color">
          <Col className="sjp-event-col">
            <Row className="sjp-event-card-title">
              <Col>
                <p className="sjp-card-title mt-5 sjp-mb-0">{event.name}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="sjp-value sjp-mt-1 sjp-mb-0 sjp-text-uppercase">
                  {this.isSameDay(event.fromTo[0].from, event.fromTo[0].to) ? moment(event.fromTo[0].from).format(dateFormat) : `${moment(event.fromTo[0].from).format(dateFormat)} - ${moment(event.fromTo[0].to).format(dateFormat)}`}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="sjp-value sjp-mb-0 sjp-mt-0 sjp-text-uppercase">{node.name}</p>
              </Col>
            </Row>
            <Row className="sjp-event-description-height sjp-mb-1 sjp-mt-1">
              <Col>
                <p className="sjp-description-text sjp-mb-0 sjp-word-wrap">{event.description}</p>
              </Col>
            </Row>
            <Row className="sjp-full-width sjp-no-margin sjp-event-button-row">
              <Link className="sjp-event-button-link" to={`/event/${this.props.eventId}`}>
                <Button color="primary" className="sjp-mt-1 sjp-full-width sjp-button sjp-button-text sjp-no-border-radius" type="submit">
                  VIEW THIS EVENT
                </Button>
              </Link>
            </Row>
          </Col>
        </Col>
      </Row>
    );
  }

  private isSameDay(from: string, to: string): boolean {
    return moment(from).isSame(moment(to), 'date');
  }

  private getDataFromApi(): Promise<[Event, EventImage[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const eventPromise: Promise<Event> = this.eventStore.fetchEventById(this.props.eventId).then((event: Event) => {
      return this.nodeStore.fetchNodeById(event.nodeId).then((node: Node) => {
        return event;
      });
    });
    const eventImagesPromise: Promise<EventImage[]> = this.eventStore.fetchEventImagesByEventIdWithParams(this.props.eventId, true, false);
    return Promise.all([ eventPromise, eventImagesPromise ]);
  }
}
