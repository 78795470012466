import { observable, action } from 'mobx';

interface IRedirectData {
  reason: string;
  data?: any;
  target: string;
}

class WebsiteUIStore {
  @observable public needsRedirect: boolean;
  @observable public redirectData: any;

  @action('Initiate Redirect')
  public initiateRedirect(data: IRedirectData) {
    this.redirectData = data;
    this.needsRedirect = true;
  }

  // This will also delete any redirect data we passed to avoid constant redirects
  @action('Complete Redirect')
  public completeRedirect() {
    this.redirectData = null;
    this.needsRedirect = false;
  }
}

export { WebsiteUIStore };
