import { observable, action } from 'mobx';

class CheckoutStore {
  @observable public isBeingEdited: boolean = false;

  @action('Set isBeingEdited')
  public setIsBeingEdited(isBeingEdited: boolean): void {
    this.isBeingEdited = isBeingEdited;
  }
}

export { CheckoutStore };
