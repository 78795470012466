import { JsonConvert } from 'json2typescript';

import { BaseService } from '../../../core/libraries/network/BaseService';
import { handleNetworkErrors } from '../../../core/libraries/network/Network';

const defaultApiRoute = '/api';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};
const credentials = 'same-origin';

class ApiService extends BaseService {
  protected jsonConvert: JsonConvert = new JsonConvert();

  constructor(apiRoute?: string) {
    super(apiRoute ? apiRoute : defaultApiRoute, headers, credentials);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleNetworkErrors = this.handleNetworkErrors.bind(this);
    this.convertResponseToJson = this.convertResponseToJson.bind(this);
    this.handleUnsuccessfulResponse = this.handleUnsuccessfulResponse.bind(this);
  }

  protected handleResponse(response: any): Promise<any> {
    const self = this;
    return Promise.resolve(
      self
        .handleNetworkErrors(response)
        .then(self.convertResponseToJson)
        .then(self.handleUnsuccessfulResponse)
    );
  }

  protected convertResponseToJson(response: any): any {
    return response.json();
  }

  protected handleUnsuccessfulResponse(responseJson: any): any {
    if (!responseJson) {
      throw new Error(`Non-standard response body returned: ${responseJson}`);
    }
    //TODO: uncomment followinf 3 lines and delete the 3 lines after
    // if (!responseJson.success) {
    //   throw new Error(responseJson.message);
    // }
    if (responseJson.success === false) {
      throw new Error(responseJson.message);
    }
    return responseJson;
  }

  protected handleNetworkErrors(responseJson: any): Promise<any> {
    return Promise.resolve(handleNetworkErrors(responseJson));
  }

  protected getFakeUrl(url: string) {
    return `/fake${url}`;
  }
}

export default ApiService;
