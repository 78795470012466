import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { Event, EventImage } from './models/models';

class EventStore extends GenericStore<Event> {
  private rootStore: RootStore;
  private eventImageStore = new GenericStore<EventImage>();

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  // Event

  public get events(): Event[] {
    return this.contents;
  }

  public fetchEvents(): Promise<Event[]> {
    return this.rootStore.services.event.fetchEvents().then((events: Event[]) => {
      this.addOrUpdateAll(events);
      return events;
    });
  }

  public fetchActiveEvents(): Promise<Event[]> {
    return this.rootStore.services.event.fetchActiveEvents().then((events: Event[]) => {
      this.addOrUpdateAll(events);
      return events;
    });
  }

  public fetchFutureActiveEvents(): Promise<Event[]> {
    return this.rootStore.services.event.fetchFutureActiveEvents().then((events: Event[]) => {
      this.addOrUpdateAll(events);
      return events;
    });
  }

  public fetchEventById(eventId: number): Promise<Event> {
    return this.rootStore.services.event.fetchEventById(eventId).then((event: Event) => {
      this.addOrUpdate(event);
      return event;
    });
  }

  public fetchEventsBySearchBarParameters(location?: number, from?: string, to?: string) {
    return this.rootStore.services.event.fetchEventsBySearchBarParameters(location, from, to).then((events: Event[]) => {
      this.addOrUpdateAll(events);
      return events;
    });
  }

  public createOrUpdateEvent(event: Event): Promise<Event> {
    return this.rootStore.services.event.createOrUpdateEvent(event).then((event: Event) => {
      this.addOrUpdate(event);
      return event;
    });
  }

  public deleteEvent(eventId: number): Promise<void> {
    return this.rootStore.services.event.deleteEvent(eventId).then(() => {
      this.remove(eventId);
    });
  }

  // Event Image

  public get eventImages(): EventImage[] {
    return this.eventImageStore.contents;
  }

  public fetchEventImages(): Promise<EventImage[]> {
    return this.rootStore.services.event.fetchEventImages().then((eventImages: EventImage[]) => {
      this.eventImageStore.addOrUpdateAll(eventImages);
      return eventImages;
    });
  }

  public fetchEventImagesByEventIdWithParams(eventId: number, featured: boolean, seating: boolean): Promise<EventImage[]> {
    return this.rootStore.services.event.fetchEventImagesByEventIdWithParams(eventId, featured, seating).then((eventImages: EventImage[]) => {
      this.eventImageStore.addOrUpdateAll(eventImages);
      return eventImages;
    });
  }

  public fetchEventImagesByEventId(eventId: number): Promise<EventImage[]> {
    return this.rootStore.services.event.fetchEventImagesByEventId(eventId).then((eventImages: EventImage[]) => {
      this.eventImageStore.addOrUpdateAll(eventImages);
      return eventImages;
    });
  }

  public createEventImage(eventImage: EventImage): Promise<EventImage> {
    return this.rootStore.services.event.createEventImage(eventImage).then((eventImage: EventImage) => {
      this.eventImageStore.addOrUpdate(eventImage);
      return eventImage;
    });
  }

  public deleteEventImage(eventImageId: number): Promise<void> {
    return this.rootStore.services.event.deleteEventImage(eventImageId).then(() => {
      this.eventImageStore.remove(eventImageId);
    });
  }

  public deleteEventImageByEventIdAndImageId(eventId: number, imageId: number): Promise<void> {
    return this.rootStore.services.event.deleteEventImageByEventIdAndImageId(eventId, imageId).then((eventImage: EventImage) => {
      this.eventImageStore.remove(eventImage.id);
    });
  }
}

export { EventStore };
