import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Booking, Node, BookingFacility, Facility } from '../../stores/models/models';
import { BookingStore, NodeStore, FacilityStore, LoadingStore, CartStore } from '../../stores/stores';

import { Row, Col } from 'reactstrap';

import LoadingSpinner from './LoadingSpinner';

import moment from 'moment';

import './styles/cartCardItemStyle.css';
import { Notifications } from 'core/notifications/notifications';

import { Cancel } from '../svgIcons/icons';

const dateTimeDb: string = 'YYYY-MM-DD HH:mm:ss';

interface ICartCardItemProps {
  bookingId: number;
}

@inject('rootStore')
@observer
export default class CardCartItem extends React.Component<ICartCardItemProps, {}> {
  private bookingStore: BookingStore;
  private nodeStore: NodeStore;
  private facilityStore: FacilityStore;
  private cartStore: CartStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.bookingStore = props.rootStore.stores.booking;
    this.nodeStore = props.rootStore.stores.node;
    this.facilityStore = props.rootStore.stores.facility;
    this.cartStore = props.rootStore.stores.cart;
  }

  public componentDidMount() {
    this.getDataFromApi().then((booking: Promise<Booking>[]) => this.loadingStore.setLoadingStateToDone()).catch((err) => {
      this.loadingStore.setLoadingStateToError();
      Notifications.displayError(err);
    });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const booking: Booking | undefined = this.bookingStore.get(this.props.bookingId);

    if (booking === undefined) {
      throw new Error(`Error: Booking with ID ${this.props.bookingId} does not exist`);
    }

    const node: Node | undefined = this.nodeStore.get(booking.nodeId);

    if (node === undefined) {
      throw new Error(`Error: Node with ID ${booking.nodeId} does not exist`);
    }

    const bookingFacilities: BookingFacility[] = this.bookingStore.bookingFacilities.filter((bookingFacility: BookingFacility) => bookingFacility.bookingId === booking.id);

    const facilities: Facility[] = [];
    bookingFacilities.forEach((bookingFacility: BookingFacility) => {
      const facility: Facility | undefined = this.facilityStore.get(bookingFacility.facilityId);
      if (facility !== undefined) {
        facilities.push(facility);
      }
    });

    return (
      <div key={booking.id} className="sjp-pt-2 sjp-full-width">
        <Col>
          <Row key={booking.id}>
            <Col xs="6" sm="6" md="6" lg="6" xl="6" className="sjp-text-align-left">
              <div className="sjp-value sjp-text-uppercase sjp-mb-1">{node.name}</div>
            </Col>
            <Col xs="6" sm="6" md="6" lg="6" xl="6">
              <div className="sjp-value sjp-mb-1">€ {(Math.round(booking.priceIncludingVat * 100) / 100).toFixed(2)}</div>
            </Col>
          </Row>
          <Row>
            <Col xs="6" sm="6" md="6" lg="6" xl="6" className="sjp-text-align-left sjp-mb-1">
              <div className="sjp-label sjp-text-uppercase">{Math.round(moment.duration(moment(booking.dateTo, dateTimeDb).diff(moment(booking.dateFrom, dateTimeDb))).asHours() * 100) / 100} hours</div>
            </Col>
          </Row>
          <Row className="sjp-pt-1 sjp-mb-1">
            <Col>
              <Row>
                <Col xs="6" sm="6" md="6" lg="6" xl="6" className="sjp-text-align-left sjp-mb-1">
                  <div className="sjp-value sjp-text-uppercase sjp-mb-1">Extra Amenities</div>
                </Col>
                <Col xs="6" sm="6" md="6" lg="6" xl="6">
                  <div className="sjp-value">€ {(Math.round(this.calculateTotalFacilitiesPrice(bookingFacilities) * 100) / 100).toFixed(2)}</div>
                </Col>
              </Row>
              {facilities.map((facility: Facility) => {
                const bookingFacility: BookingFacility | undefined = bookingFacilities.find((bookingFacility: BookingFacility) => bookingFacility.facilityId === facility.id && bookingFacility.bookingId === this.props.bookingId);
                if (bookingFacility === undefined) {
                  throw new Error(`Error: Booking Facility of Facility ID ${facility.id} does not exist`);
                }
                return (
                  <Row key={facility.id}>
                    <Col xs="6" sm="6" md="6" lg="6" xl="6" className="sjp-text-align-left sjp-mb-1" key={facility.id}>
                      <h6 className="sjp-label sjp-text-uppercase">
                        {bookingFacility.quantity}x {facility.name}
                      </h6>
                    </Col>
                    <Col className="sjp-center-content">
                      <a onClick={this.handleDeleteAdditionalFacility.bind(this, bookingFacility.id, booking)}>
                        <Cancel className="sjp-height-1 sjp-width-1 sjp-darkgrey-fill" />
                      </a>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Col>
        <Row className="sjp-white-spacer" />
      </div>
    );
  }

  private calculateTotalFacilitiesPrice(bookingFacilities: BookingFacility[]): number {
    let total: number = 0;
    bookingFacilities.forEach((bookingFacility: BookingFacility) => {
      total += bookingFacility.priceIncludingVat;
    });

    return total;
  }

  private handleDeleteAdditionalFacility(bookingFacilityId: number, booking: Booking): void {
    this.bookingStore
      .deleteBookingFacility(bookingFacilityId)
      .then(() => {
        this.bookingStore
          .fetchBookingById(booking.id)
          .then((booking: Booking) => {
            this.cartStore.fetchCartById(booking.cartId);
          })
          .catch((err) => {
            Notifications.displayError(err);
          });
      })
      .catch((err) => {
        Notifications.displayError(err);
      });
  }

  private getDataFromApi(): Promise<Promise<Booking>[]> {
    this.loadingStore.setLoadingStateToLoading();
    return this.bookingStore.fetchBookingById(this.props.bookingId).then((booking: Booking) => {
      return this.nodeStore.fetchNodeById(booking.nodeId).then((node: Node) => {
        return this.bookingStore.fetchBookingFacilitiesByBookingId(booking.id).then((bookingFacilities: BookingFacility[]) => {
          return bookingFacilities.map((bookingFacility: BookingFacility) => {
            return this.facilityStore.fetchFacilityById(bookingFacility.facilityId).then(() => {
              return booking;
            });
          });
        });
      });
    });
  }
}
