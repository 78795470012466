import * as React from 'react';
import { observer } from 'mobx-react';

import { NodeType, Node } from '../../stores/models/models';
import { NodeTypeStore, NodeStore, LoadingStore } from '../../stores/stores';
import { RootStore } from '../../stores/RootStore';

import { Row, Col, Form, Select, DatePicker, TimePicker, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import moment from 'moment';

import './styles/searchBarStyle.less';
import { Notifications } from 'core/notifications/notifications';

const Option = Select.Option;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  colon: false
};

const timeFormat: string = 'HH:mm';
const dateFormat: string = 'DD-MM-YYYY';

interface ISearchBarProps {
  rootStore: RootStore;
  handleSearch: (nodes: Node[]) => void;
}

@observer
class SearchBar extends React.Component<ISearchBarProps & FormComponentProps, {}> {
  private nodeTypeStore: NodeTypeStore;
  private nodeStore: NodeStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: ISearchBarProps & FormComponentProps) {
    super(props);
    this.nodeTypeStore = props.rootStore.stores.nodeType;
    this.nodeStore = props.rootStore.stores.node;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: NodeType[][]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div>
        <Row className="sjp-mt-3">
          <h4 className="sjp-title-text sjp-no-margin">Book a Site</h4>
        </Row>
        <Row className="sjp-mt-3 sjp-mb-1">
          <Form layout="inline" onSubmit={this.handleSubmit.bind(this)}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem {...formItemLayout} className="sjp-full-width">
                <div className="sjp-label sjp-no-margin">SITE TYPE</div>
                {getFieldDecorator('nodeType', {})(
                  <Select className="sjp-search-bar-item" placeholder="" allowClear>
                    {this.getRoomTypeOptions()}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem {...formItemLayout} className="sjp-full-width">
                <div className="sjp-label sjp-no-margin">DATE</div>
                {getFieldDecorator('date', {})(<DatePicker className="sjp-search-bar-item" placeholder="" format={dateFormat} disabledDate={this.disabledDates.bind(this)} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem {...formItemLayout} className="sjp-full-width">
                <div className="sjp-label sjp-no-margin">START TIME</div>
                {getFieldDecorator('startTime', {})(<TimePicker className="sjp-search-bar-item" placeholder="" defaultOpenValue={moment('00:00', 'HH:mm')} format={timeFormat} minuteStep={30} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem {...formItemLayout} className="sjp-full-width">
                <div className="sjp-label sjp-no-margin">END TIME</div>
                {getFieldDecorator('endTime', {})(<TimePicker className="sjp-search-bar-item" placeholder="" defaultOpenValue={moment('00:00', 'HH:mm')} format={timeFormat} minuteStep={30} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem {...formItemLayout} className="sjp-full-width">
                <Button className="sjp-search-bar-item sjp-search-bar-button sjp-search-btn sjp-button-text sjp-search-button-text-size " type="primary" htmlType="submit">
                  SEARCH
                </Button>
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem {...formItemLayout} className="sjp-full-width">
                <Button className="sjp-search-bar-item sjp-search-bar-button sjp-clear-search-btn sjp-button-text sjp-search-button-text-size " onClick={this.handleClearSearch.bind(this)}>
                  CLEAR
                </Button>
              </FormItem>
            </Col>
          </Form>
        </Row>
      </div>
    );
  }

  private handleSubmit(e): void {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((error, values) => {
      const date: string | undefined = values.date ? values.date.format('YYYY-MM-DD') : undefined;
      values.startTime ? values.startTime.seconds(0) : undefined;
      const startTime: string | undefined = values.startTime ? values.startTime.format('HH:mm:ss') : undefined;
      values.endTime ? values.endTime.seconds(0) : undefined;
      const endTime: string | undefined = values.endTime ? values.endTime.format('HH:mm:ss') : undefined;
      this.nodeStore
        .fetchNodesBySearchBarParameters(false, values.nodeType, date, startTime, endTime, 0)
        .then((nodes: Node[]) => {
          this.props.handleSearch(nodes);
          return nodes;
        })
        .catch((err) => Notifications.displayError(err));
    });
  }

  private handleClearSearch(): void {
    this.nodeStore
      .fetchNodes()
      .then((nodes: Node[]) => {
        const relevantNodes: Node[] = nodes.filter((node: Node) => node.bookable);
        this.props.form.resetFields();
        this.props.handleSearch(relevantNodes);
      })
      .catch((err) => Notifications.displayError(err));
  }

  private getRoomTypeOptions(): JSX.Element[] {
    const options: JSX.Element[] = [];
    this.nodeTypeStore.nodeTypes.forEach((nodeType: NodeType) => {
      if (nodeType.id !== 1 && this.activeNodeOfThatTypeExists(nodeType)) {
        options.push(
          <Option value={nodeType.id} key={nodeType.id}>
            {nodeType.name}
          </Option>
        );
      }
    });
    return options;
  }

  private activeNodeOfThatTypeExists(nodeType: NodeType): boolean {
    const nodes: Node[] = this.nodeStore.nodes;
    let activeNodeOfThatTypeExists: boolean = false;
    nodes.forEach((node: Node) => {
      if (node.typeId === nodeType.id && node.bookable) {
        activeNodeOfThatTypeExists = true;
        return;
      }
    });
    return activeNodeOfThatTypeExists;
  }

  private disabledDates(current: moment.Moment): boolean {
    return current && current < moment().startOf('day');
  }

  private getDataFromApi(): Promise<NodeType[][]> {
    this.loadingStore.setLoadingStateToLoading();
    return Promise.all([ this.nodeTypeStore.fetchNodeTypes(), this.nodeStore.fetchNodes() ]);
  }
}

export default Form.create<{}>()(SearchBar);
