import ApiService from './ApiService';

import { Ticket, TicketCartItem, TicketsSold } from '../stores/models/models';

class TicketService extends ApiService {
  constructor() {
    super();
  }

  // Ticket

  public fetchTickets(): Promise<Ticket[]> {
    const url: string = this.getUrl(`/tickets`);
    return fetch(url, {
      method: 'GET',
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length > 0) {
          return this.jsonConvert.deserialize(responseJson.data, Ticket);
        }
        return [];
      });
  }

  public fetchTicketById(ticketId: number): Promise<Ticket> {
    const url: string = this.getUrl(`/ticket/info/${ticketId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Ticket);
      });
  }

  public fetchTicketsByEventId(eventId: number): Promise<Ticket[]> {
    const url: string = this.getUrl(`/ticket/params?eventId=${eventId}`);
    return fetch(url, {
      method: 'GET',
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length > 0) {
          return this.jsonConvert.deserialize(responseJson.data, Ticket);
        }
        return [];
      });
  }

  public fetchSoldTickets(ticketId: number): Promise<TicketsSold> {
    const url: string = this.getUrl(`/tickets/sold/${ticketId}`);
    return fetch(url, {
      method: 'GET',
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        const ticketsSold = new TicketsSold();
        ticketsSold.ticketId = responseJson.data.ticket_id;
        ticketsSold.sold = responseJson.data.sold;
        return ticketsSold;
      });
  }

  public createOrUpdateTicket(ticket: Ticket): Promise<Ticket> {
    const baseUrl: string = `/ticket`;
    const isNew: boolean = ticket.id === 0;
    const url: string = isNew ? this.getUrl(`${baseUrl}`) : this.getUrl(`${baseUrl}/info/${ticket.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonTicket = this.jsonConvert.serialize(ticket);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonTicket)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Ticket);
      });
  }

  public deleteTicket(ticketId: number): Promise<void> {
    const url: string = this.getUrl(`/ticket/info/${ticketId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders,
      method: 'DELETE'
    }).then(this.handleResponse);
  }

  // TicketCartItem

  public fetchTicketCartItems(): Promise<TicketCartItem[]> {
    const url: string = this.getUrl(`/ticketcartitems`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data) {
          return this.jsonConvert.deserialize(responseJson.data, TicketCartItem);
        }
        return [];
      });
  }

  public fetchConfirmedTicketCartItems(): Promise<TicketCartItem[]> {
    const url: string = this.getUrl(`/ticketcartitems/confirmed`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, TicketCartItem);
        }
        return [];
      });
  }

  public fetchTicketCartItemById(ticketCartItemId: number): Promise<TicketCartItem> {
    const url: string = this.getUrl(`/ticketcartitem/info/${ticketCartItemId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, TicketCartItem));
  }

  public fetchTicketCartItemsByCartId(cartId: number): Promise<TicketCartItem[]> {
    const url: string = this.getUrl(`/ticketcartitem/cartId/${cartId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data) {
          return this.jsonConvert.deserialize(responseJson.data, TicketCartItem);
        }
        return [];
      });
  }

  public fetchTicketCartItemsByCartIdAndEventId(cartId: number, eventId: number): Promise<TicketCartItem[]> {
    const url: string = this.getUrl(`/ticketcartitem/eventId/${eventId}/cartId/${cartId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data) {
          return this.jsonConvert.deserialize(responseJson.data, TicketCartItem);
        }
        return [];
      });
  }

  public fetchTicketCartItemsByTicketId(ticketId: number): Promise<TicketCartItem[]> {
    const url: string = this.getUrl(`/ticketcartitems/ticket/${ticketId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, TicketCartItem);
        }
        return [];
      });
  }

  public fetchTicketCartItemTicketPdfsByCartId(cartId: number): Promise<any> {
    const url: string = this.getUrl(`/downloadTickets/${cartId}`);
    return fetch(url, {
      method: 'GET',
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleNetworkErrors)
      .then((response) => response.blob());
  }

  public createOrUpdateTicketCartItem(ticketCartItem: TicketCartItem): Promise<TicketCartItem> {
    const baseUrl: string = `/ticketcartitem`;
    const isNew: boolean = ticketCartItem.id === 0;
    const url: string = isNew ? this.getUrl(`${baseUrl}`) : this.getUrl(`${baseUrl}/info/${ticketCartItem.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonTicketCartItem = this.jsonConvert.serialize(ticketCartItem);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonTicketCartItem)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, TicketCartItem));
  }

  public deleteTicketCartItem(ticketCartItemId: number): Promise<void> {
    const url: string = this.getUrl(`/ticketcartitem/info/${ticketCartItemId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  public deleteTicketCartItemByCartIdAndEventId(cartId: number, eventId: number): Promise<TicketCartItem[]> {
    const url: string = this.getUrl(`/ticketcartitem/cart/${cartId}/event/${eventId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, TicketCartItem);
        }
        return [];
      });
  }
}

export default TicketService;
