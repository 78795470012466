import * as React from 'react';
import { Provider } from 'mobx-react';

import DevTools from 'mobx-react-devtools';

import { rootStore } from './stores/RootStore';

import Root from './screens/website/Root';

const TARGET_ENV: string = `${process.env.TARGET_ENV}`

const appState = {
  targetEnv: TARGET_ENV,
  isDevelopment: TARGET_ENV === 'development',
  isStaging: TARGET_ENV === 'staging',
  isProduction: TARGET_ENV === 'production'
};

console.log(`[BOOKING SYSTEM WEBSITE] Application State: Target Env: ${TARGET_ENV}. Development: ${appState.isDevelopment}, Staging: ${appState.isStaging}, Production: ${appState.isProduction}`);

class WebsiteApp extends React.Component<{}, {}> {
  public constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <>
        <Provider rootStore={rootStore} appState={appState}>
          {<Root />}
        </Provider>
        {appState.isDevelopment ? <DevTools /> : null}
      </>
    );
  }
}

export default WebsiteApp;
