import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
class Config {
  @JsonProperty('navigation_bar', Boolean)
  navigationBar: boolean = false;
  @JsonProperty('top_most_row', Boolean)
  topMostRow: boolean = false;
  @JsonProperty('bottom_most_row', Boolean)
  bottomMostRow: boolean = false;
  @JsonProperty('nodes', Boolean)
  nodes: boolean = false;
  @JsonProperty('events', Boolean)
  events: boolean = false;
  @JsonProperty('show_events_tab', Boolean)
  showEventsTab: boolean = false;
  @JsonProperty('home', Boolean)
  home: boolean = false;
  @JsonProperty('search_bar', Boolean)
  searchBar: boolean = false;
  @JsonProperty('extra_room_facilities', Boolean)
  extraRoomFacilities: boolean = false;
  @JsonProperty('location', Boolean)
  location: boolean = false;
  @JsonProperty('contact_us', Boolean)
  contactUs: boolean = false;
  @JsonProperty('contact_us_link', String)
  contactUsLink: string = '';
  @JsonProperty('logo', String)
  logo: string = '';
  @JsonProperty('referer', String)
  referer: string = '';
  @JsonProperty('redirect_url', String)
  redirectUrl: string = '';
}

export { Config };
