import * as React from 'react';
import { observer } from 'mobx-react';

import { observable, action } from 'mobx';

import { FormComponentProps } from 'antd/lib/form/Form';
import { Form, Input, Button } from 'antd';

import { User } from '../../stores/models/models';
import { UserStore } from '../../stores/stores';
import { RootStore } from '../../stores/RootStore';

import { Col, Row, Container, Modal, ModalHeader, ModalBody } from 'reactstrap';

import Alerts from '../../../../core/alerts/alerts';

import './styles/editPasswordComponentStyle.css';
import { Notifications } from 'core/notifications/notifications';

const FormItem = Form.Item;

const formItemLayout = {
  colon: false
};

interface IEditPasswordComponentProps {
  userId: number;
  rootStore: RootStore;
}

@observer
class EditPasswordComponent extends React.Component<IEditPasswordComponentProps & FormComponentProps, {}> {
  @observable private successModalOpen: boolean = false;
  @observable private unseuccessfulModalOpen: boolean = false;
  private userStore: UserStore;

  constructor(props: IEditPasswordComponentProps & FormComponentProps) {
    super(props);
    this.userStore = props.rootStore.stores.user;
  }

  public render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    const user: User | undefined = this.userStore.get(this.props.userId);

    if (user === undefined) {
      throw new Error(`Error: User with ID ${this.props.userId} does not exist`);
    }

    return (
      <Container>
        <Row className="sjp-darkgrey-background sjp-pt-1 sjp-pb-1">
          <Col>
            <div className="sjp-white-title-text sjp-text-uppercase sjp-vertical-align">Change Password</div>
          </Col>
        </Row>
        <Row className="sjp-background-card-color ">
          <Col>
            <Form onSubmit={this.handleSubmit.bind(this)} style={{ textAlign: 'left' }}>
              <Row className="sjp-mt-2">
                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                  <FormItem {...formItemLayout}>
                    <h3 className="sjp-label sjp-text-uppercase"> * Current Password</h3>
                    {getFieldDecorator('password', {
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          message: 'Current Password is required'
                        }
                      ]
                    })(<Input placeholder="Enter the current password here" type="password" className="sjp-full-width sjp-no-border-radius" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                  <FormItem {...formItemLayout}>
                    <h3 className="sjp-label sjp-text-uppercase"> * new password</h3>
                    {getFieldDecorator('newPassword', {
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          message: 'Password is required'
                        }
                      ]
                    })(<Input className="sjp-full-width sjp-no-border-radius" placeholder="Enter the password again here" type="password" />)}
                  </FormItem>
                </Col>
                <Col xs="12" sm="12" md="12" lg="4" xl="4">
                  <FormItem {...formItemLayout}>
                    <h3 className="sjp-label sjp-text-uppercase"> * Confirm password</h3>
                    {getFieldDecorator('confirmPassword', {
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          message: 'Confirm Password is required'
                        }
                      ]
                    })(<Input className="sjp-full-width sjp-no-border-radius" placeholder="Enter the password again here" type="password" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.checkIfPasswordsMatch(form.getFieldsValue()['newPassword'], form.getFieldsValue()['confirmPassword']) ? null : <h5 className="sjp-small-warning-text sjp-text-uppercase">The passwords do not match</h5>}
                  {this.checkIfPasswordCorrectFormat(form.getFieldsValue()['newPassword']) ? null : (
                    <h5 className="sjp-small-warning-text sjp-text-uppercase">Please make sure your password contains at least one lowercase letter, one uppercase letter and has at least 8 characters</h5>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="text-left" xs="12" sm="12" md="12" lg="4" xl="4">
                  <FormItem>
                    <Button
                      disabled={!(this.checkIfPasswordCorrectFormat(form.getFieldValue('newPassword')) && this.checkIfPasswordsMatch(form.getFieldValue('newPassword'), form.getFieldValue('confirmPassword')))}
                      className="sjp-button sjp-button-text sjp-text-uppercase sjp-no-border-radius sjp-set-password-button"
                      type="primary"
                      htmlType="submit"
                    >
                      Set New Password
                    </Button>
                  </FormItem>
                  <Modal isOpen={this.successModalOpen} toggle={this.toggleSuccessModal.bind(this)} centered>
                    <ModalHeader toggle={this.toggleSuccessModal.bind(this)}>Success</ModalHeader>
                    <ModalBody>Your password has been updated successfully</ModalBody>
                  </Modal>
                  <Modal isOpen={this.unseuccessfulModalOpen} toggle={this.toggleUnsuccessfulModal.bind(this)} centered>
                    <ModalHeader toggle={this.toggleUnsuccessfulModal.bind(this)}>Change Unsuccessful</ModalHeader>
                    <ModalBody>Your password has not been updated. Check that your old password is correct and try again </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }

  private handleSubmit(e: any): void {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((error, values) => {
      if (error) {
        return Alerts.showWarning('Validation Error: All fields are required');
      }

      const user: User | undefined = this.userStore.get(this.props.userId);
      if (user === undefined) {
        throw new Error(`Error: User with ID ${this.props.userId} does not exist`);
      }
      this.userStore
        .updateUserPasswordWithOldPassword(user, values.password, values.newPassword, values.confirmPassword)
        .then(() => {
          this.props.form.resetFields();
          this.toggleSuccessModal();
        })
        .catch((err) => {
          this.toggleUnsuccessfulModal();
          Notifications.displayError(err);
        });
    });
  }

  private checkIfPasswordCorrectFormat(password: string | undefined): boolean {
    if (password === undefined) {
      return false;
    }
    return !(password.length < 8 || !this.hasLowerCase(password) || !this.hasUpperCase(password));
  }

  private checkIfPasswordsMatch(passwordOne: string | undefined, passwordTwo: string | undefined): boolean {
    if (passwordOne === undefined || passwordTwo === undefined) {
      return false;
    }
    return passwordOne === passwordTwo;
  }

  private hasLowerCase(string: string): boolean {
    return /[a-z]/.test(string);
  }

  private hasUpperCase(string: string): boolean {
    return /[A-Z]/.test(string);
  }

  @action('Toggle the successModalOpen variable')
  private toggleSuccessModal(): void {
    this.successModalOpen = !this.successModalOpen;
  }

  @action('Toggle the UnsuccessfulModalOpen variable')
  private toggleUnsuccessfulModal(): void {
    this.unseuccessfulModalOpen = !this.unseuccessfulModalOpen;
  }
}

export default Form.create<IEditPasswordComponentProps & FormComponentProps>()(EditPasswordComponent);
