import ApiService from './ApiService';

import { Image } from '../stores/models/models';

class ImageService extends ApiService {
  constructor() {
    super();
  }

  public fetchImages(): Promise<Image[]> {
    const url: string = this.getUrl('/upload');
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, Image);
      });
  }

  public fetchImageById(imageId: number): Promise<Image> {
    const url: string = this.getUrl(`/upload/${imageId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Image);
      });
  }
}

export default ImageService;
