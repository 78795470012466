import ApiService from './ApiService';

import { Booking, BookingFacility } from '../stores/models/models';

import { BulkBooking, BookingBulkBooking, BulkBookingResponse, BulkBookingFacilityResponse } from '../stores/models/models';

class BookingService extends ApiService {
  constructor() {
    super();
  }

  // Booking

  public fetchBookingsByNodeId(nodeId: number): Promise<Booking[]> {
    const url: string = this.getUrl(`/bookings/node/${nodeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Booking);
        }
        return [];
      });
  }

  public fetchConfirmedBookings(): Promise<Booking[]> {
    const url: string = this.getUrl(`/cartitems/confirmed`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Booking);
        }
        return [];
      });
  }

  public fetchConfirmedBookingsByNodeId(nodeId: number): Promise<Booking[]> {
    const url: string = this.getUrl(`/cartitems/confirmed/node/${nodeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Booking);
        }
        return [];
      });
  }

  public fetchBookingsByUserId(userId: number): Promise<Booking[]> {
    const url: string = this.getUrl(`/bookings/user/${userId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Booking);
        }
        return [];
      });
  }

  public fetchBookingsByCartId(cartId: number): Promise<Booking[]> {
    const url: string = this.getUrl(`/cartitems/cart/${cartId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Booking);
        }
        return [];
      });
  }

  public fetchProvisionalBookingsByUserId(userId: number): Promise<Booking[]> {
    const url: string = this.getUrl(`/cartitems/provisional/user/${userId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Booking);
        }
        return [];
      });
  }

  public fetchBookings(): Promise<Booking[]> {
    const url: string = this.getUrl(`/bookings`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Booking);
        }
        return [];
      });
  }

  public fetchBookingById(bookingId: number): Promise<Booking> {
    const url: string = this.getUrl(`/cartitems/info/${bookingId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Booking);
      });
  }

  public createOrUpdateBooking(booking: Booking): Promise<Booking> {
    const baseUrl: string = `/cartitem`;
    const isNew: boolean = booking.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}s/${booking.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonBooking = this.jsonConvert.serialize(booking);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonBooking)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Booking);
      });
  }

  public deleteBooking(bookingId: number): Promise<void> {
    const url: string = this.getUrl(`/booking/cartitem/${bookingId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  // Booking Facility

  public fetchBookingFacilities(): Promise<BookingFacility[]> {
    const url: string = this.getUrl(`/facilitycartitems`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, BookingFacility);
        }
        return [];
      });
  }

  public fetchBookingFacilityByBookingIdAndFacilityId(bookingId: number, facilityId: number): Promise<BookingFacility> {
    const url: string = this.getUrl(`/facilitycartitems/facility/${facilityId}/cartitem/${bookingId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, BookingFacility));
  }

  public fetchBookingFacilitiesByBookingId(bookingId: number): Promise<BookingFacility[]> {
    const url: string = this.getUrl(`/facilitycartitems/cartitem/${bookingId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, BookingFacility);
        }
        return [];
      });
  }

  public createBookingFacility(bookingFacility: BookingFacility): Promise<BookingFacility> {
    const url: string = this.getUrl(`/facilitycartitem`);
    const jsonBookingFacility = this.jsonConvert.serialize(bookingFacility);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonBookingFacility)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, BookingFacility);
      });
  }

  public updateBookingFacility(bookingFacility: BookingFacility): Promise<BookingFacility> {
    const url: string = this.getUrl(`/facilitycartitem/${bookingFacility.id}`);
    const jsonBookingFacility = this.jsonConvert.serialize(bookingFacility);
    return fetch(url, {
      method: 'PUT',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonBookingFacility)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, BookingFacility);
      });
  }

  public createOrUpdateBookingFacility(bookingFacility: BookingFacility): Promise<BookingFacility> {
    const baseUrl: string = `/facilitycartitem`;
    const isNew: boolean = bookingFacility.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/${bookingFacility.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonBookingFacility = this.jsonConvert.serialize(bookingFacility);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonBookingFacility)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, BookingFacility));
  }

  public deleteBookingFacility(bookingFacilityId: number): Promise<void> {
    const url: string = this.getUrl(`/facilitycartitem/${bookingFacilityId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  // Bulk Bookings

  public fetchBulkBookingById(bulkBookingId: number): Promise<BulkBooking> {
    const url: string = this.getUrl(`/bulkBooking/${bulkBookingId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, BulkBooking));
  }

  public createOrUpdateBulkBooking(bookingBulkBooking: BookingBulkBooking): Promise<BulkBookingResponse[]> {
    const baseUrl: string = `/bulkBooking`;
    const isNew: boolean = bookingBulkBooking.bulkBooking.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/${bookingBulkBooking.bulkBooking.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const payload: {} = {
      cart_item: this.jsonConvert.serialize(bookingBulkBooking.booking),
      bulk_booking: this.jsonConvert.serialize(bookingBulkBooking.bulkBooking)
    };
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(payload)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        const bulkBookingResponses: BulkBookingResponse[] = [];
        if (responseJson.data && responseJson.data.length) {
          responseJson.data.forEach((response) => {
            const bulkBookingResponse: BulkBookingResponse = new BulkBookingResponse();
            const booking: Booking = this.jsonConvert.deserialize(response.cart_item, Booking);
            bulkBookingResponse.booking = booking;
            bulkBookingResponse.error = response.error;
            bulkBookingResponses.push(bulkBookingResponse);
          });
          return bulkBookingResponses;
        }
        return [];
      });
  }

  public deleteBulkBooking(bulkBookingId: number): Promise<void> {
    const url: string = `/bulkBooking/${bulkBookingId}`;
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  // Bulk Booking Facilities

  public createOrUpdateBulkBookingFacility(bookingFacility: BookingFacility): Promise<BulkBookingFacilityResponse[]> {
    const baseUrl: string = `/bulk/facilitycartitem`;
    const isNew: boolean = bookingFacility.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/${bookingFacility.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonBookingBulkBooking = this.jsonConvert.serialize(bookingFacility);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonBookingBulkBooking)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          const bulkBookingFacilityResponses: BulkBookingFacilityResponse[] = [];
          responseJson.data.forEach((response) => {
            const bulkBookingFacilityResponse: BulkBookingFacilityResponse = new BulkBookingFacilityResponse();
            const bookingFacility: BookingFacility = this.jsonConvert.deserialize(response.facility_cart_item, BookingFacility);
            bulkBookingFacilityResponse.bookingFacility = bookingFacility;
            bulkBookingFacilityResponse.error = response.error;
            bulkBookingFacilityResponses.push(bulkBookingFacilityResponse);
          });
          return bulkBookingFacilityResponses;
        }
        return [];
      });
  }

  public deleteBulkBookingFacility(bookingFacilityId: number): Promise<void> {
    const url: string = this.getUrl(`/bulk/facilitycartitem/${bookingFacilityId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }
}

export default BookingService;
