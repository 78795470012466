import * as React from 'react';
import { SVG } from './SVG';

const Login = (props: SVG) => (
  <div className={props.className}>
    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" height="100%" width="100%">
      <g>
        <g>
          <path d="M191.55,42.93c-1.86-1.86-4.43-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93c2.64,0,5.21-1.07,7.07-2.93c1.87-1.86,2.93-4.44,2.93-7.07S193.42,44.79,191.55,42.93z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M502,438h-7v-81c0-5.523-4.477-10-10-10s-10,4.477-10,10v81h-27V154.163c15.139,1.51,27,14.32,27,29.85v89.584c0,5.523,4.477,10,10,10s10-4.477,10-10v-89.584c0-26.562-20.822-48.344-47-49.902V42c0-23.159-18.841-42-42-42H106C82.841,0,64,18.841,64,42v92.11c-26.178,1.558-47,23.34-47,49.902V438h-7c-5.523,0-10,4.477-10,10v14c0,27.57,22.43,50,50,50h412c27.57,0,50-22.43,50-50v-14C512,442.477,507.523,438,502,438z M84,60h62.484c5.523,0,10-4.477,10-10s-4.477-10-10-10H84.095C85.109,28.803,94.544,20,106,20h300c12.131,0,22,9.869,22,22v396H84V60z M37,184.012c0-15.529,11.861-28.34,27-29.85V438H37V184.012z M492,462c0,16.542-13.458,30-30,30H50c-16.542,0-30-13.458-30-30v-4h472V462z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M274,465h-36.083c-5.523,0-10,4.477-10,10s4.477,10,10,10H274c5.523,0,10-4.477,10-10S279.523,465,274,465z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M492.07,308.78c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07s4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07S493.93,310.64,492.07,308.78z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M294.257,83.298V72.131c0-21.025-17.105-38.131-38.131-38.131s-38.131,17.105-38.131,38.131v11.094C204.177,87.059,194,99.742,194,114.766v42.5c0,18.05,14.685,32.734,32.734,32.734h58.531c18.05,0,32.734-14.685,32.734-32.734v-42.5C317.999,99.835,307.947,87.216,294.257,83.298z M237.996,72.131c0-9.998,8.133-18.131,18.131-18.131c9.998,0,18.131,8.133,18.131,18.131v9.9h-36.262V72.131z M298,157.266c0,7.022-5.712,12.734-12.734,12.734h-58.531c-7.022,0-12.734-5.712-12.734-12.734v-42.5H214c0-7.022,5.712-12.734,12.734-12.734h1.262h56.262h1.008c7.022,0,12.734,5.712,12.734,12.734V157.266z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M256.127,114.5c-5.523,0-10,4.477-10,10V140c0,5.523,4.477,10,10,10s10-4.477,10-10v-15.5C266.127,118.977,261.65,114.5,256.127,114.5z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M372,210H140c-5.523,0-10,4.477-10,10v72c0,5.523,4.477,10,10,10h232c5.523,0,10-4.477,10-10v-72C382,214.477,377.523,210,372,210z M362,282H150v-52h212V282z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M195.07,248.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93S178,253.37,178,256s1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93S198,258.63,198,256S196.93,250.79,195.07,248.93z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M240.4,248.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93c2.64,0,5.21-1.07,7.07-2.93c1.87-1.86,2.93-4.44,2.93-7.07S242.27,250.79,240.4,248.93z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M285.74,248.93c-1.87-1.86-4.44-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.08,2.93c-1.86,1.86-2.92,4.44-2.92,7.07s1.06,5.21,2.92,7.07c1.87,1.86,4.44,2.93,7.08,2.93c2.63,0,5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07S287.6,250.79,285.74,248.93z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M331.07,248.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07S332.93,250.79,331.07,248.93z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M372,318H140c-5.523,0-10,4.477-10,10v72c0,5.523,4.477,10,10,10h232c5.523,0,10-4.477,10-10v-72C382,322.477,377.523,318,372,318z M362,390H150v-52h212V390z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M195.07,356.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93S178,361.37,178,364s1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93S198,366.63,198,364S196.93,358.79,195.07,356.93z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M240.4,356.93c-1.86-1.86-4.43-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93c2.64,0,5.21-1.07,7.07-2.93c1.87-1.86,2.93-4.44,2.93-7.07S242.27,358.79,240.4,356.93z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M285.74,356.93c-1.86-1.86-4.44-2.93-7.07-2.93c-2.64,0-5.21,1.07-7.07,2.93c-1.87,1.86-2.93,4.44-2.93,7.07s1.06,5.21,2.93,7.07c1.86,1.86,4.43,2.93,7.07,2.93c2.63,0,5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07S287.6,358.79,285.74,356.93z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M331.07,356.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07S332.93,358.79,331.07,356.93z" />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  </div>
);

export default Login;
