import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';

import { configure } from 'mobx';

import WebsiteApp from './WebsiteApp';

// MobX configuration
configure({
  enforceActions: true
});

//Bind the element with ID 'webApp' as the root for this Web App
const rootDOMElement = document.getElementById('website');

const render = (AppComponent: any) => {
  ReactDOM.render(
    <BrowserRouter>
      <AppContainer>
        <AppComponent />
      </AppContainer>
    </BrowserRouter>,
    rootDOMElement
  );
};

render(WebsiteApp);

if ((module as any).hot) {
  (module as any).hot.accept('./WebsiteApp', () => {
    const NextApp = require('./WebsiteApp').default;
    render(NextApp);
  });
}
