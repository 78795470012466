import Profile from './Profile';
import Cart from './Cart';
import Plus from './Plus';
import Minus from './Minus';
import Cross from './Cross';
import Cancel from './Cancel';
import Check from './Check';
import Clock from './Clock';
import Calendar from './Calendar';
import List from './List';
import Login from './Login';
import Lock from './Lock';

export { Profile, Cart, Plus, Minus, Cross, Cancel, Check, Clock, Calendar, List, Login, Lock };
