import { JsonProperty, JsonObject, JsonConvert, Any } from 'json2typescript';
import { FromTo } from './FromTo';
import { isMoment } from 'moment';
import { isUndefined } from 'util';

@JsonObject
class NodeEventType {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('node_id', Number)
  nodeId: number = 0;
  @JsonProperty('event_type_id', Number)
  eventTypeId: number = 0;
}

@JsonObject
class NodeFacility {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('node_id', Number)
  nodeId: number = 0;
  @JsonProperty('facility_id', Number)
  facilityId: number = 0;
  @JsonProperty('included', Boolean)
  included: boolean = false;
}

@JsonObject
class NodeBookable {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('node_id', Number)
  nodeId: number = 0;
  @JsonProperty('from_to', Any)
  fromTo: FromTo = new FromTo();
  @JsonProperty('day_of_week', Number)
  dayOfWeek: number = -1;
}

@JsonObject
class NodeBookableException {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('node_id') nodeId: number = 0;
  @JsonProperty('date', String)
  date: string = '';
  @JsonProperty('recurring', Boolean)
  recurring: boolean = false;
}

@JsonObject
class NodeImage {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('node_id', Number)
  nodeId: number = 0;
  @JsonProperty('image_id', Number)
  imageId: number = 0;
  @JsonProperty('featured', Boolean)
  featured: boolean = false;
}

@JsonObject
class Node {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('type', Number)
  typeId: number = 0;
  @JsonProperty('name', String)
  name: string = '';
  @JsonProperty('description', String)
  description: string = '';
  @JsonProperty('lon', Number)
  longitude: number = 0;
  @JsonProperty('lat', Number)
  latitude: number = 0;
  @JsonProperty('parent', Any)
  parentId: number | null = 1; //Parent: World
  @JsonProperty('bookable', Boolean)
  bookable: boolean = false;
  @JsonProperty('internal', Boolean)
  internal: boolean = false;
  @JsonProperty('standing_capacity', Number)
  standingCapacity: number = 0;
  @JsonProperty('seating_capacity', Number)
  seatingCapacity: number = 0;
  @JsonProperty('daily_price', Number)
  dailyPrice: number = 0;
  @JsonProperty('hourly_price', Number)
  hourlyPrice: number = 0;
  @JsonProperty('vat', Number)
  vat: number = 0;
  // Time needed between two bookings to clean up the room and set up etc in seconds
  @JsonProperty('turn_around_time', Number)
  turnaroundTime: number = 0;
  // Minimum amount of time for the length of a booking in seconds
  @JsonProperty('min_booking_time', Number)
  minBookingTime: number = 0;
}

const exampleNodeData = {
  id: 34,
  type: 3,
  name: 'Building 3',
  description: 'Red doors',
  lon: 0,
  lat: 0,
  parent: 23,
  bookable: false,
  internal: false,
  standing_capacity: 90,
  seating_capacity: 73,
  daily_price: 45,
  hourly_price: 10,
  vat: 2,
  turn_around_time: 1800,
  min_booking_time: 3600
};

const nodeFromForm = (formData: {}) => {
  const node = new Node();
  for (const key in formData) {
    if (formData.hasOwnProperty(key) && node.hasOwnProperty(key)) {
      let value = formData[key];
      if (isUndefined(value)) {
        value = node[key];
      }
      if (isMoment(value)) {
        value = value.format('YYYY-MM-DD');
      }
      if (key === 'bookable') {
        if (value === 1) {
          value = true;
        } else if (value === 0) {
          value = false;
        }
      }
      if (key === 'standingCapacity' || key === 'seatingCapacity' || key === 'minBookingTime') {
        value = parseInt(value);
      }
      if (key === 'hourlyPrice' || key === 'dailyPrice') {
        value = parseFloat(value);
      }
      if (key === 'vat') {
        value = value / 100;
      }
      node[key] = value;
    }
  }
  return node;
};

const jsonConvert: JsonConvert = new JsonConvert();
const exampleNode: Node = jsonConvert.deserialize(exampleNodeData, Node);

export { NodeFacility, NodeEventType, FromTo, NodeBookable, NodeBookableException, NodeImage, Node, exampleNodeData, exampleNode, nodeFromForm };
