import { JsonProperty, JsonObject, Any } from 'json2typescript';
import { FromTo } from './FromTo';
import { isUndefined } from 'util';

import moment from 'moment';

@JsonObject
class Event {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('name', String)
  name: string = '';
  @JsonProperty('description', String)
  description: string = '';
  @JsonProperty('node_id', Number)
  nodeId: number = 0;
  @JsonProperty('from_to', Any)
  fromTo: FromTo[] = [];
  @JsonProperty('status', Boolean)
  status: boolean = false;
  @JsonProperty('featured', Boolean)
  featured: boolean = false;
}

@JsonObject
class EventImage {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('event_id', Number)
  eventId: number = 0;
  @JsonProperty('image_id', Number)
  imageId: number = 0;
  @JsonProperty('featured', Boolean)
  featured: boolean = false;
  @JsonProperty('seating', Boolean)
  seating: boolean = false;
}

const eventFromForm = (formData: {}) => {
  const event = new Event();
  const dateTimeFrom: moment.Moment = moment();
  const dateTimeTo: moment.Moment = moment();
  for (const key in formData) {
    let value = formData[key];
    if (isUndefined(value)) {
      value = event[key];
    }
    if (key === 'status') {
      if (value === 1) {
        value = true;
      } else if (value === 0) {
        value = false;
      }
    }
    if (key === 'dateFrom') {
      dateTimeFrom.set({
        date: value.date(),
        month: value.month(),
        year: value.year()
      });
    }
    if (key === 'timeFrom') {
      dateTimeFrom.set({
        hours: value.hours(),
        minutes: value.minutes(),
        seconds: 0
      });
    }
    if (key === 'dateTo') {
      dateTimeTo.set({
        date: value.date(),
        month: value.month(),
        year: value.year()
      });
    }
    if (key === 'timeTo') {
      dateTimeTo.set({
        hours: value.hours(),
        minutes: value.minutes(),
        seconds: 0
      });
    }
    event[key] = value;
  }
  const fromTo: FromTo = new FromTo();
  fromTo.from = dateTimeFrom.format();
  fromTo.to = dateTimeTo.format();
  event.fromTo = [];
  event.fromTo.push(fromTo);
  return event;
};

export { Event, EventImage, eventFromForm };
