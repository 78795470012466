import { JsonObject, JsonProperty, JsonConvert, Any } from 'json2typescript';
import { FromTo } from './FromTo';

@JsonObject
class Roster {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('userID', Number)
  userId: number = 0;
  @JsonProperty('from_to', Any)
  fromTo: FromTo = new FromTo();
  @JsonProperty('day_of_week', Number)
  dayOfWeek: number = -1;
}

@JsonObject
class RosterNode {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('roster_id', Number)
  rosterId: number = 0;
  @JsonProperty('node_id', Number)
  nodeId: number = 0;
}

const exampleRosterDetails = {
  id: 64,
  userID: 34,
  from_to: {
    from: '2018-12-06',
    to: '2018-12-12'
  },
  day_of_week: 1
};

const jsonConvert: JsonConvert = new JsonConvert();
const exampleRoster: Roster = jsonConvert.deserialize(exampleRosterDetails, Roster);

export { Roster, RosterNode, exampleRosterDetails, exampleRoster };
