import * as React from 'react';
import { SVG } from './SVG';

const List = (props: SVG) => (
  <div className={props.className}>
    <svg version="1.1" id="Capa_1" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 511.626 511.627">
      <g>
        <g>
          <path d="M498.208,68.235c-8.945-8.947-19.701-13.418-32.261-13.418H45.682c-12.562,0-23.318,4.471-32.264,13.418C4.471,77.184,0,87.935,0,100.499v310.633c0,12.566,4.471,23.312,13.418,32.265c8.945,8.945,19.701,13.414,32.264,13.414h420.266c12.56,0,23.315-4.469,32.261-13.414c8.949-8.953,13.418-19.705,13.418-32.265V100.499C511.626,87.935,507.158,77.18,498.208,68.235z M475.078,411.125c0,2.475-0.903,4.616-2.71,6.424c-1.804,1.81-3.949,2.706-6.42,2.706H45.682c-2.474,0-4.615-0.896-6.423-2.706c-1.809-1.808-2.712-3.949-2.712-6.424V173.588c0-2.475,0.903-4.617,2.712-6.427c1.809-1.806,3.949-2.709,6.423-2.709h420.266c2.471,0,4.613,0.9,6.42,2.709c1.807,1.81,2.71,3.952,2.71,6.427V411.125L475.078,411.125z" />
          <path d="M100.5,347.179H82.228c-2.474,0-4.615,0.896-6.423,2.703c-1.807,1.811-2.712,3.953-2.712,6.427v18.271c0,2.478,0.905,4.616,2.712,6.427c1.809,1.81,3.949,2.707,6.423,2.707H100.5c2.473,0,4.615-0.897,6.423-2.707c1.807-1.811,2.712-3.949,2.712-6.427v-18.271c0-2.474-0.905-4.616-2.712-6.427C105.115,348.082,102.973,347.179,100.5,347.179z" />
          <path d="M100.5,274.081H82.228c-2.474,0-4.615,0.91-6.423,2.714c-1.807,1.811-2.712,3.953-2.712,6.424v18.273c0,2.479,0.905,4.61,2.712,6.428c1.809,1.8,3.949,2.706,6.423,2.706H100.5c2.473,0,4.615-0.906,6.423-2.706c1.807-1.817,2.712-3.949,2.712-6.428v-18.273c0-2.471-0.905-4.62-2.712-6.424C105.115,274.991,102.973,274.081,100.5,274.081z" />
          <path d="M100.5,200.998H82.228c-2.474,0-4.615,0.902-6.423,2.708c-1.807,1.812-2.712,3.949-2.712,6.423v18.276c0,2.473,0.905,4.615,2.712,6.424c1.809,1.803,3.949,2.712,6.423,2.712H100.5c2.473,0,4.615-0.905,6.423-2.712c1.807-1.809,2.712-3.951,2.712-6.424v-18.276c0-2.474-0.905-4.615-2.712-6.423C105.115,201.902,102.973,200.998,100.5,200.998z" />
          <path d="M429.399,347.179H155.313c-2.473,0-4.615,0.896-6.423,2.703c-1.807,1.811-2.712,3.953-2.712,6.427v18.271c0,2.478,0.905,4.616,2.712,6.427c1.809,1.81,3.951,2.707,6.423,2.707h274.089c2.479,0,4.617-0.897,6.428-2.707c1.803-1.811,2.707-3.949,2.707-6.427v-18.271c0-2.474-0.907-4.616-2.711-6.427C434.019,348.075,431.876,347.179,429.399,347.179z" />
          <path d="M429.399,274.081H155.313c-2.473,0-4.615,0.91-6.423,2.714c-1.807,1.811-2.712,3.953-2.712,6.424v18.273c0,2.479,0.905,4.61,2.712,6.428c1.809,1.8,3.951,2.706,6.423,2.706h274.089c2.479,0,4.617-0.906,6.428-2.706c1.803-1.817,2.707-3.949,2.707-6.428v-18.273c0-2.471-0.907-4.62-2.711-6.424C434.019,274.991,431.876,274.081,429.399,274.081z" />
          <path d="M429.399,200.998H155.313c-2.473,0-4.615,0.902-6.423,2.708c-1.807,1.812-2.712,3.949-2.712,6.423v18.276c0,2.473,0.905,4.619,2.712,6.424c1.809,1.803,3.951,2.712,6.423,2.712h274.089c2.479,0,4.624-0.905,6.428-2.712c1.803-1.809,2.707-3.951,2.707-6.424v-18.276c0-2.474-0.907-4.615-2.711-6.423C434.019,201.902,431.876,200.998,429.399,200.998z" />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  </div>
);

export default List;
