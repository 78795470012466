import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Spin } from 'antd';

import { Container } from 'reactstrap';

import { RootStore } from '../../stores/RootStore';
import { LoadingStore } from '../../stores/stores';
import LoginComponent from '../../components/website/LoginComponent';
import ForgotPasswordComponent from '../../components/website/ForgotPasswordComponent';

import './styles/loginScreenStyle.css';
import { Notifications } from 'core/notifications/notifications';

interface ILoginScreenProps {
  showForgotPassword?: boolean;
}

@inject('rootStore')
@observer
export default class LoginScreen extends React.Component<ILoginScreenProps, {}> {
  private rootStore: RootStore;
  private loadingStore: LoadingStore = new LoadingStore();
  @observable private isLoggedIn: boolean = false;
  @observable private showForgotPassword: boolean = false;

  constructor(props: any) {
    super(props);
    this.rootStore = props.rootStore;
    this.setForgotPassword(props.showForgotPassword);
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then(() => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    this.checkIfLoggedIn();
    if (this.loadingStore.stillLoading) {
      return <Spin tip="Loading Booking System..." />;
    }

    if (this.isLoggedIn) {
      window.location.assign(`${window.location.origin}/home`);
      return;
    }

    return <Container className="sjp-mt-5 sjp-mb-3">{this.showForgotPassword ? this.getForgotPasswordComponent() : this.getLoginComponent()}</Container>;
  }

  private getLoginComponent(): JSX.Element {
    return <LoginComponent rootStore={this.rootStore} handleSuccessfulLogin={this.handleSuccessfulLogin.bind(this)} toggleForgotPassword={this.toggleForgotPassword.bind(this)} />;
  }

  private getForgotPasswordComponent(): JSX.Element {
    return <ForgotPasswordComponent toggleForgotPassword={this.toggleForgotPassword.bind(this)} />;
  }

  private toggleForgotPassword(): void {
    this.setForgotPassword(!this.showForgotPassword);
  }

  @action('Set whether to show forgot password component')
  private setForgotPassword(showForgotPassword: boolean): void {
    this.showForgotPassword = showForgotPassword;
  }

  private checkIfLoggedIn(): void {
    const loggedIn: boolean = this.rootStore.stores.currentUser.currentUser !== undefined;
    this.setIsLoggedIn(loggedIn);
  }

  private handleSuccessfulLogin(loggedIn: boolean): void {
    this.setIsLoggedIn(true);
  }

  @action('Setting logged in state')
  private setIsLoggedIn(loggedIn: boolean): void {
    this.isLoggedIn = loggedIn;
  }

  private getDataFromApi(): Promise<void> {
    this.loadingStore.setLoadingStateToLoading();
    return this.rootStore.stores.currentUser.fetchUserData();
  }
}
