import { observable, action } from 'mobx';

import { Event } from './models/models';
import { RootStore } from './RootStore';

class EventEditStore {
  @observable public event: Event;
  @observable public unlimitedChecked: boolean = false;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action('Fetching Event to be edited')
  public fetchEventForEditing(eventId: number): Promise<Event> {
    if (eventId === 0) {
      const newEvent: Event = new Event();
      this.setCurrentEvent(newEvent);
      return Promise.resolve(newEvent);
    }
    return this.rootStore.stores.event.fetchEventById(eventId).then((event: Event) => {
      if (!event) {
        throw new Error(`Event with ID: ${eventId} does not exist`);
      }
      this.setCurrentEvent(event);
      return event;
    });
  }

  @action('Creating ot Updating the current event with new details')
  public createOrUpdateCurrentEvent(newEvent: Event): Promise<Event> {
    if (!this.event) {
      throw new Error(`Unable to update an event if one does not exist`);
    }
    return this.rootStore.stores.event.createOrUpdateEvent(newEvent).then((event: Event) => {
      if (!event) {
        throw new Error(`Unable to update event with ID ${this.event.id}`);
      }
      this.setCurrentEvent(event);
      return event;
    });
  }

  @action('Set Event that is currently being edited')
  private setCurrentEvent(event: Event): void {
    this.event = event;
  }
}

export { EventEditStore };
