import { JsonProperty, JsonObject, JsonConvert } from 'json2typescript';

@JsonObject
class Image {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('path', String)
  path: string = '';
  @JsonProperty('file_name', String)
  fileName: string = '';
}

const exampleImageData = {
  id: 45,
  path: '/upload',
  file_name: 'image.png'
};

const jsonConvert: JsonConvert = new JsonConvert();
const exampleImage: Image = jsonConvert.deserialize(exampleImageData, Image);

export { Image, exampleImage, exampleImageData };
