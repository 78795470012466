import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Event, EventImage, Image } from '../../stores/models/models';
import { EventStore, ImageStore, LoadingStore } from '../../stores/stores';

import { Row, Col } from 'reactstrap';
import ImgsViewer from 'react-images-viewer';

import LoadingSpinner from './LoadingSpinner';
import { Notifications } from 'core/notifications/notifications';

import './styles/eventDetailsStyle.css';

interface IEventDetailsProps {
  eventId: number;
}

@inject('rootStore')
@observer
export default class EventDetails extends React.Component<IEventDetailsProps, {}> {
  @observable private lightboxIsOpen: boolean = false;
  @observable private photoIndex: number = 0;
  private eventStore: EventStore;
  private imageStore: ImageStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.eventStore = props.rootStore.stores.event;
    this.imageStore = props.rootStore.stores.image;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((date: [Event, Image[]]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const event: Event | undefined = this.eventStore.get(this.props.eventId);

    if (event === undefined) {
      throw new Error(`Error: Event with ID ${this.props.eventId} does not exist`);
    }

    const eventImages: EventImage[] = this.eventStore.eventImages.filter((eventImage: EventImage) => eventImage.featured === false && eventImage.eventId === this.props.eventId && eventImage.seating === false);

    const imagesLocations: string[] = [];
    eventImages.forEach((eventImage: EventImage) => {
      const image: Image | undefined = this.imageStore.get(eventImage.imageId);
      if (image !== undefined) {
        imagesLocations.push(`/assets/${image.fileName}`);
      }
    });

    return (
      <Col className="sjp-pr-0">
        <Row className="sjp-text-container-center-small-devices">
          <h5 className="sjp-sub-title-text sjp-mt-2">About the Event</h5>
        </Row>
        <Row className="sjp-text-container-center-small-devices">
          <div className="sjp-description-text sjp-mr-1 sjp-mt-1 sjp-mb-2 sjp-text-container-center-small-devices">{event.description}</div>
        </Row>
        {imagesLocations.length > 0 ? (
          <div>
            <Row className="sjp-mt-2 sjp-text-container-center-small-devices">
              <h5 className="sjp-sub-title-text sjp-mb-1 ">Image Gallery</h5>
            </Row>
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="sjp-pl-0 sjp-full-width">
              <Row>
                {imagesLocations.map((imageLocation: string, index: number) => {
                  return (
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="sjp-no-padding sjp-mb-image" key={imageLocation}>
                      <img className="sjp-d-block sjp-event-img" src={imageLocation} onClick={this.handleClickImage.bind(this, index)} />
                    </Col>
                  );
                })}
              </Row>
            </Col>
            {this.lightboxIsOpen ? (
              <ImgsViewer
                imgs={imagesLocations.map((imageLocation: string) => {
                  const image: {} = { src: imageLocation };
                  return image;
                })}
                currImg={this.photoIndex}
                isOpen={this.lightboxIsOpen}
                onClickPrev={this.gotoPrevious.bind(this, imagesLocations)}
                onClickNext={this.gotoNext.bind(this, imagesLocations)}
                onClose={this.closeViewer.bind(this)}
                backdropCloseable={true}
              />
            ) : (
              undefined
            )}
          </div>
        ) : (
          undefined
        )}
      </Col>
    );
  }

  private handleClickImage(index: number): void {
    this.setLightboxIsOpen(true);
    this.setPhotoIndex(index);
  }

  private gotoPrevious(imagesLocations: string[]): void {
    this.setPhotoIndex((this.photoIndex + imagesLocations.length - 1) % imagesLocations.length);
  }

  private gotoNext(imagesLocations: string[]): void {
    this.setPhotoIndex((this.photoIndex + 1) % imagesLocations.length);
  }

  private closeViewer(): void {
    this.setLightboxIsOpen(false);
  }

  @action('Set lightbox is open')
  private setLightboxIsOpen(isOpen: boolean): void {
    this.lightboxIsOpen = isOpen;
  }

  @action('Setphoto index')
  private setPhotoIndex(index: number): void {
    this.photoIndex = index;
  }

  private getDataFromApi(): Promise<[Event, Image[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const eventPromise: Promise<Event> = this.eventStore.fetchEventById(this.props.eventId);
    const eventImagesPromise: Promise<Image[]> = this.eventStore.fetchEventImagesByEventId(this.props.eventId).then((eventImages: EventImage[]) => {
      const imagesPromises: Promise<Image>[] = eventImages.map((eventImage: EventImage) => this.imageStore.fetchImageById(eventImage.imageId));
      return Promise.all(imagesPromises);
    });
    return Promise.all([ eventPromise, eventImagesPromise ]);
  }
}
