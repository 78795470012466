import { JsonObject, JsonProperty, JsonConvert, Any } from 'json2typescript';

@JsonObject
class Cart {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('userID', Number)
  userId: number = 0;
  @JsonProperty('date_confirmed', Any)
  dateConfirmed: string | null = null;
  @JsonProperty('price', Number)
  price: number = 0;
  @JsonProperty('price_inc_vat', Number)
  priceIncludingVat: number = 0;
  @JsonProperty('uuid', String)
  uuid: string = '';
}

const exampleCartDetails = {
  id: 3,
  userID: 4,
  date_confirmed: null,
  price: 35,
  price_inc_vat: 50,
  uuid: 'blablabla'
};

const jsonConvert: JsonConvert = new JsonConvert();
const exampleCart: Cart = jsonConvert.deserialize(exampleCartDetails, Cart);

export { Cart, exampleCartDetails, exampleCart };
