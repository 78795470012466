import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Event, Cart, Ticket, TicketCartItem } from '../../stores/models/models';
import { CartStore, TicketStore, EventStore, LoadingStore } from '../../stores/stores';

import { Row, Col } from 'reactstrap';

import LoadinSpinner from './LoadingSpinner';
import { Notifications } from 'core/notifications/notifications';

import { Cancel } from '../svgIcons/icons';

interface IEventCartCardItemProps {
  eventId: number;
  cartId: number;
}

@inject('rootStore')
@observer
export default class EventCartCardItem extends React.Component<IEventCartCardItemProps, {}> {
  private cartStore: CartStore;
  private eventStore: EventStore;
  private ticketStore: TicketStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.cartStore = props.rootStore.stores.cart;
    this.eventStore = props.rootStore.stores.event;
    this.ticketStore = props.rootStore.stores.ticket;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Cart, Event, Ticket[], TicketCartItem[]]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadinSpinner />;
    }

    const event: Event | undefined = this.eventStore.get(this.props.eventId);
    if (event === undefined) {
      throw new Error(`Error: Event with ID ${this.props.eventId} does not exist`);
    }

    const tickets: Ticket[] = this.ticketStore.tickets.filter((ticket: Ticket) => ticket.eventId === this.props.eventId);
    const ticketCartItems: TicketCartItem[] = this.ticketStore.ticketCartItems.filter(
      (ticketCartItem: TicketCartItem) => ticketCartItem.cartId === this.props.cartId && tickets.find((ticket: Ticket) => ticket.id === ticketCartItem.ticketId) !== undefined
    );

    return (
      <div className="sjp-pt-2 sjp-full-width">
        <Col>
          <Row>
            <Col className="sjp-text-align-left">
              <div className="sjp-value sjp-text-uppercase sjp-mb-1">{event.name}</div>
            </Col>
          </Row>
          <Row className="sjp-pt-1 sjp-mb-1">
            <Col>
              {ticketCartItems.map((ticketCartItem: TicketCartItem) => {
                const ticket: Ticket | undefined = this.ticketStore.get(ticketCartItem.ticketId);
                if (ticket === undefined) {
                  throw new Error(`Error: Ticket ID ${ticketCartItem.ticketId} does not exist`);
                }
                return (
                  <div key={ticketCartItem.id}>
                    <Row>
                      <Col xs="6" sm="6" md="6" lg="6" xl="6" className="sjp-text-align-left" key={ticketCartItem.id}>
                        <h6 className="sjp-label sjp-text-uppercase">
                          {ticketCartItem.quantity}x {ticket.name}
                        </h6>
                      </Col>
                      <Col xs="6" sm="6" md="6" lg="6" xl="6">
                        <a onClick={this.handleDeleteTicket.bind(this, ticketCartItem.id)}>
                          <Cancel className="sjp-height-1 sjp-darkgrey-fill" />
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="sjp-text-align-left sjp-mb-1">
                        <div className="sjp-value sjp-mr-1">€ {(Math.round(ticketCartItem.priceIncludingVat * 100) / 100).toFixed(2)}</div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Col>
        <Row className="sjp-white-spacer" />
      </div>
    );
  }

  private handleDeleteTicket(ticketCartItemId: number): void {
    this.ticketStore
      .deleteTicketCartItem(ticketCartItemId)
      .then(() => {
        this.cartStore.fetchCartById(this.props.cartId);
      })
      .catch((err) => Notifications.displayError(err));
  }

  private getDataFromApi(): Promise<[Cart, Event, Ticket[], TicketCartItem[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const cartPromise: Promise<Cart> = this.cartStore.fetchCartById(this.props.cartId);
    const eventPromise: Promise<Event> = this.eventStore.fetchEventById(this.props.eventId);
    const ticketsPromise: Promise<Ticket[]> = this.ticketStore.fetchTicketsByEventId(this.props.eventId);
    const ticketCartItemsPromise: Promise<TicketCartItem[]> = this.ticketStore.fetchTicketCartItemsByCartId(this.props.cartId);
    return Promise.all([ cartPromise, eventPromise, ticketsPromise, ticketCartItemsPromise ]);
  }
}
