import * as React from 'react';
import { Provider } from 'mobx-react';

import { rootStore } from '../../stores/RootStore';

import MyLocationMap from '../../components/website/MyLocationMap';

export default class LocationScreen extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return <Provider rootStore={rootStore}>{this.getScreen()}</Provider>;
  }

  private getScreen(): JSX.Element {
    return (
      <div>
        <MyLocationMap />
      </div>
    );
  }
}
