import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Event, Node } from '../../stores/models/models';
import { EventStore, NodeStore, LoadingStore } from '../../stores/stores';
import { RootStore } from '../../stores/RootStore';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { Form, Select, DatePicker, Button, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import moment from 'moment';

import './styles/eventSearchBarStyle.less';
import { Notifications } from 'core/notifications/notifications';

const Option = Select.Option;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  colon: false
};

const dateFormat: string = 'DD-MM-YYYY';
const dateTimeFormat: string = 'YYYY-MM-DD HH:mm:ss';

interface IEventSearchBarProps {
  rootStore: RootStore;
  handleSearch: (events: Event[]) => void;
}

@observer
class EventSearchBar extends React.Component<IEventSearchBarProps & FormComponentProps, {}> {
  @observable warningModal: boolean = false;
  private eventStore: EventStore;
  private nodeStore: NodeStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: IEventSearchBarProps & FormComponentProps) {
    super(props);
    this.eventStore = props.rootStore.stores.event;
    this.nodeStore = props.rootStore.stores.node;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Event[], Node[]]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div>
        <Row className="sjp-mt-3">
          <h4 className="sjp-title-text sjp-no-margin">Book Tickets for an Event</h4>
        </Row>
        <Row className="sjp-mb-1 sjp-mt-3">
          <Form layout="inline" onSubmit={this.handleSubmit.bind(this)}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem className="sjp-full-width" {...formItemLayout}>
                <div className="sjp-label sjp-no-margin">LOCATION</div>
                {getFieldDecorator('node', {})(
                  <Select className="sjp-event-search-bar-item" style={{ width: '90%', borderRadius: 0 }} placeholder="" allowClear>
                    {this.getNodesOptions()}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem className="sjp-full-width" {...formItemLayout}>
                <div className="sjp-label sjp-no-margin">FROM</div>
                {getFieldDecorator('from', {})(<DatePicker className="sjp-event-search-bar-item" placeholder="" style={{ width: '90%', borderRadius: 0 }} format={dateFormat} disabledDate={this.disabledDates.bind(this)} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem className="sjp-full-width" {...formItemLayout}>
                <div className="sjp-label sjp-no-margin">TO</div>
                {getFieldDecorator('to', {})(<DatePicker className="sjp-event-search-bar-item" placeholder="" style={{ width: '90%', borderRadius: 0 }} format={dateFormat} disabledDate={this.disabledDates.bind(this)} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem className="sjp-full-width" {...formItemLayout}>
                <Button style={{ width: '90%', borderRadius: 0 }} className="sjp-event-search-bar-item sjp-search-bar-button sjp-search-btn sjp-button-text sjp-search-button-text-size " type="primary" htmlType="submit">
                  SEARCH
                </Button>
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
              <FormItem className="sjp-full-width" {...formItemLayout}>
                <Button style={{ width: '90%', borderRadius: 0 }} className="sjp-event-search-bar-item sjp-search-bar-button sjp-clear-search-btn sjp-button-text sjp-search-button-text-size " onClick={this.handleClearSearch.bind(this)}>
                  CLEAR
                </Button>
              </FormItem>
            </Col>
          </Form>
          <Modal isOpen={this.warningModal} toggle={this.toggleWarningModal.bind(this)} centered>
            <ModalHeader toggle={this.toggleWarningModal.bind(this)}>Warning!</ModalHeader>
            <ModalBody>Please make sure the "From" date is before the "To" date!</ModalBody>
            <ModalFooter>
              <Button className="button-one-line-text sjp-text-uppercase sjp-no-border-radius sjp-button-text sjp-button" onClick={this.toggleWarningModal.bind(this)}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </div>
    );
  }

  private handleSubmit(e: any): void {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((error, values) => {
      const nodeId: number | undefined = values.node ? values.node : undefined;
      const from: string | undefined = values.from ? values.from.set({ hours: 0, minutes: 0, seconds: 0 }).format(dateTimeFormat) : undefined;
      const to: string | undefined = values.to ? values.to.set({ hours: 23, minutes: 59, seconds: 59 }).format(dateTimeFormat) : undefined;
      if (moment(from, dateTimeFormat).isSameOrAfter(moment(to, dateTimeFormat))) {
        this.toggleWarningModal();
        return;
      }
      this.eventStore
        .fetchEventsBySearchBarParameters(nodeId, from, to)
        .then((events: Event[]) => {
          this.props.handleSearch(events);
          return events;
        })
        .catch((err) => Notifications.displayError(err));
    });
  }

  private getNodesOptions(): JSX.Element[] {
    const options: JSX.Element[] = [];
    this.nodeStore.nodes.filter((node: Node) => node.id !== 1).forEach((node: Node) => {
      if (this.hasEvent(node.id)) {
        options.push(
          <Option key={node.id} value={node.id}>
            {node.name}
          </Option>
        );
      }
    });
    return options;
  }

  private hasEvent(nodeId: number): boolean {
    let hasEvent: boolean = false;
    this.eventStore.events.forEach((event: Event) => {
      if (event.nodeId === nodeId) {
        hasEvent = true;
        return;
      }
    });
    return hasEvent;
  }

  private handleClearSearch(): void {
    this.eventStore
      .fetchActiveEvents()
      .then((events: Event[]) => {
        const relevantEvents: Event[] = events.filter((event: Event) => moment(event.fromTo[0].to).isSameOrAfter(moment()));
        this.props.form.resetFields();
        this.props.handleSearch(relevantEvents);
      })
      .catch((err) => Notifications.displayError(err));
  }

  private disabledDates(current: moment.Moment): boolean {
    return current && current < moment().startOf('day');
  }

  @action('Toggling the warning modal')
  private toggleWarningModal(): void {
    this.warningModal = !this.warningModal;
  }

  private getDataFromApi(): Promise<[Event[], Node[]]> {
    this.loadingStore.setLoadingStateToLoading();
    return Promise.all([ this.eventStore.fetchEvents(), this.nodeStore.fetchNodes() ]);
  }
}

export default Form.create<{}>()(EventSearchBar);
