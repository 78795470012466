import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { Image } from './models/models';

class ImageStore extends GenericStore<Image> {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  public get images(): Image[] {
    return this.contents;
  }

  public fetchImages(): Promise<Image[]> {
    return this.rootStore.services.image.fetchImages().then((images: Image[]) => {
      this.addOrUpdateAll(images);
      return images;
    });
  }

  public fetchImageById(imageId: number): Promise<Image> {
    return this.rootStore.services.image.fetchImageById(imageId).then((image: Image) => {
      this.addOrUpdate(image);
      return image;
    });
  }
}

export { ImageStore };
