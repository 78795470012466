import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { Node, NodeEventType, NodeFacility, NodeBookable, NodeBookableException, NodeImage } from './models/models';

class NodeStore extends GenericStore<Node> {
  private rootStore: RootStore;
  private nodeEventTypeStore = new GenericStore<NodeEventType>();
  private nodeFacilityStore = new GenericStore<NodeFacility>();
  private nodeBookableStore = new GenericStore<NodeBookable>();
  private nodeBookableExceptionStore = new GenericStore<NodeBookableException>();
  private nodeImageStore = new GenericStore<NodeImage>();

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  // Nodes

  public get nodes(): Node[] {
    return this.contents;
  }

  public fetchNodes(): Promise<Node[]> {
    return this.rootStore.services.node.fetchNodes().then((nodes: Node[]) => {
      this.addOrUpdateAll(nodes);
      return nodes;
    });
  }

  public fetchNodesWithLonLat(): Promise<Node[]> {
    return this.rootStore.services.node.fetchNodesWithLonLat().then((nodes: Node[]) => {
      this.addOrUpdateAll(nodes);
      return nodes;
    });
  }

  public fetchNodeById(nodeId: number): Promise<Node> {
    return this.rootStore.services.node.fetchNodeById(nodeId).then((node: Node) => {
      this.addOrUpdate(node);
      return node;
    });
  }

  public fetchNodesByParentId(parentId: number): Promise<Node[]> {
    return this.rootStore.services.node.fetchNodesByParentId(parentId).then((nodes: Node[]) => {
      this.addOrUpdateAll(nodes);
      return nodes;
    });
  }

  public fetchNodesByNodeTypeId(nodeTypeId: number): Promise<Node[]> {
    return this.rootStore.services.node.fetchNodesByNodeTypeId(nodeTypeId).then((nodes: Node[]) => {
      this.addOrUpdateAll(nodes);
      return nodes;
    });
  }

  public fetchNodesBySearchBarParameters(seated: boolean, nodeTypeId?: number, date?: string, startTime?: string, endTime?: string, attendees?: number): Promise<Node[]> {
    return this.rootStore.services.node.fetchNodesBySearchBarParameters(seated, nodeTypeId, date, startTime, endTime, attendees).then((nodes: Node[]) => {
      this.addOrUpdateAll(nodes);
      return nodes;
    });
  }

  public createOrUpdateNode(node: Node): Promise<Node> {
    return this.rootStore.services.node.createOrUpdateNode(node).then((node: Node) => {
      this.addOrUpdate(node);
      return node;
    });
  }

  public deleteNode(nodeId: number): Promise<void> {
    return this.rootStore.services.node.deleteNode(nodeId).then((node: Node) => {
      this.remove(nodeId);
    });
  }

  // Node Facilities

  public get nodeFacilities(): NodeFacility[] {
    return this.nodeFacilityStore.contents;
  }

  public fetchNodeFacilities(): Promise<NodeFacility[]> {
    return this.rootStore.services.node.fetchNodeFacilities().then((nodeFacilities: NodeFacility[]) => {
      this.nodeFacilityStore.addOrUpdateAll(nodeFacilities);
      return nodeFacilities;
    });
  }

  public fetchNodeFacilitiesByNodeId(nodeId: number): Promise<NodeFacility[]> {
    return this.rootStore.services.node.fetchNodeFacilitiesByNodeId(nodeId).then((nodeFacilities: NodeFacility[]) => {
      this.nodeFacilityStore.addOrUpdateAll(nodeFacilities);
      return nodeFacilities;
    });
  }

  public fetchNodeFacilitiesByFacilityId(facilityId: number): Promise<NodeFacility[]> {
    return this.rootStore.services.node.fetchNodeFacilitiesByFacilityId(facilityId).then((nodeFacilities: NodeFacility[]) => {
      this.nodeFacilityStore.addOrUpdateAll(nodeFacilities);
      return nodeFacilities;
    });
  }

  public createNodeFacility(nodeFacility: NodeFacility): Promise<NodeFacility> {
    return this.rootStore.services.node.createNodeFacility(nodeFacility).then((nodeFacility: NodeFacility) => {
      this.nodeFacilityStore.addOrUpdate(nodeFacility);
      return nodeFacility;
    });
  }

  public deleteNodeFacility(nodeFacilityId: number): Promise<void> {
    return this.rootStore.services.node.deleteNodeFacility(nodeFacilityId).then(() => {
      this.nodeFacilityStore.remove(nodeFacilityId);
    });
  }

  // Node Event Type

  public get nodeEventTypes(): NodeEventType[] {
    return this.nodeEventTypeStore.contents;
  }

  public fetchNodeEventTypes(): Promise<NodeEventType[]> {
    return this.rootStore.services.node.fetchNodeEventTypes().then((nodeEventTypes: NodeEventType[]) => {
      this.nodeEventTypeStore.addOrUpdateAll(nodeEventTypes);
      return nodeEventTypes;
    });
  }

  public fetchNodeEventTypesByNodeId(nodeId: number): Promise<NodeEventType[]> {
    return this.rootStore.services.node.fetchNodeEventTypesByNodeId(nodeId).then((nodeEventTypes: NodeEventType[]) => {
      this.nodeEventTypeStore.addOrUpdateAll(nodeEventTypes);
      return nodeEventTypes;
    });
  }

  public createNodeEventType(nodeEventType: NodeEventType): Promise<NodeEventType> {
    return this.rootStore.services.node.createNodeEventType(nodeEventType).then((nodeEventType: NodeEventType) => {
      this.nodeEventTypeStore.addOrUpdate(nodeEventType);
      return nodeEventType;
    });
  }

  public deleteNodeEventType(nodeEventTypeId: number): Promise<void> {
    return this.rootStore.services.node.deleteNodeEventType(nodeEventTypeId).then(() => {
      this.nodeEventTypeStore.remove(nodeEventTypeId);
    });
  }

  // Node Bookables

  public get nodeBookables(): NodeBookable[] {
    return this.nodeBookableStore.contents;
  }

  public fetchNodeBookables(): Promise<NodeBookable[]> {
    return this.rootStore.services.node.fetchNodeBookables().then((nodeBookables: NodeBookable[]) => {
      this.nodeBookableStore.addOrUpdateAll(nodeBookables);
      return nodeBookables;
    });
  }

  public fetchNodeBookablesByNodeId(nodeId: number): Promise<NodeBookable[]> {
    return this.rootStore.services.node.fetchNodeBookablesByNodeId(nodeId).then((nodeBookables: NodeBookable[]) => {
      this.nodeBookableStore.addOrUpdateAll(nodeBookables);
      return nodeBookables;
    });
  }

  public createOrUpdateNodeBookable(nodeBookable: NodeBookable): Promise<NodeBookable> {
    return this.rootStore.services.node.createOrUpdateNodeBookable(nodeBookable).then((nodeBookable: NodeBookable) => {
      this.nodeBookableStore.addOrUpdate(nodeBookable);
      return nodeBookable;
    });
  }

  public createNodeBookables(nodeBookables: NodeBookable[], nodeId: number, dayOfWeek: number): Promise<NodeBookable[]> {
    return this.rootStore.services.node.createNodeBookables(nodeBookables, nodeId, dayOfWeek).then((nodeBookables: NodeBookable[]) => {
      this.nodeBookableStore.addOrUpdateAll(nodeBookables);
      return nodeBookables;
    });
  }

  public updateNodeBookable(nodeBookable: NodeBookable): Promise<NodeBookable> {
    return this.rootStore.services.node.updateNodeBookable(nodeBookable).then((nodeBookable: NodeBookable) => {
      this.nodeBookableStore.addOrUpdate(nodeBookable);
      return nodeBookable;
    });
  }

  public deleteNodeBookable(nodeBookableId: number): Promise<void> {
    return this.rootStore.services.node.deleteNodeBookable(nodeBookableId).then(() => {
      this.nodeBookableStore.remove(nodeBookableId);
    });
  }

  public clearNodeBookables(): void {
    this.nodeBookableStore.clear();
  }

  // Node Bookable Exception

  public get nodeBookableExceptions(): NodeBookableException[] {
    return this.nodeBookableExceptionStore.contents;
  }

  public fetchNodeBookableExceptions(): Promise<NodeBookableException[]> {
    return this.rootStore.services.node.fetchNodeBookableExceptions().then((nodeBookableExceptions: NodeBookableException[]) => {
      this.nodeBookableExceptionStore.addOrUpdateAll(nodeBookableExceptions);
      return nodeBookableExceptions;
    });
  }

  public fetchNodeBookableExceptionsByNodeId(nodeId: number): Promise<NodeBookableException[]> {
    return this.rootStore.services.node.fetchNodeBookableExceptionsByNodeId(nodeId).then((nodeBookableExceptions: NodeBookableException[]) => {
      this.nodeBookableExceptionStore.addOrUpdateAll(nodeBookableExceptions);
      return nodeBookableExceptions;
    });
  }

  public createNodeBookableException(nodeBookableException: NodeBookableException): Promise<NodeBookableException> {
    return this.rootStore.services.node.createNodeBookableException(nodeBookableException).then((nodeBookableException: NodeBookableException) => {
      this.nodeBookableExceptionStore.addOrUpdate(nodeBookableException);
      return nodeBookableException;
    });
  }

  public updateNodeBookableException(nodeBookableException: NodeBookableException): Promise<NodeBookableException> {
    return this.rootStore.services.node.updateNodeBookableException(nodeBookableException).then((nodeBookableException: NodeBookableException) => {
      this.nodeBookableExceptionStore.addOrUpdate(nodeBookableException);
      return nodeBookableException;
    });
  }

  public deleteNodeBookableException(nodeBookableExceptionId: number): Promise<void> {
    return this.rootStore.services.node.deleteNodeBookableException(nodeBookableExceptionId).then((nodeBookableException: NodeBookableException) => {
      this.nodeBookableExceptionStore.remove(nodeBookableExceptionId);
    });
  }

  // Node Images

  public get nodeImages(): NodeImage[] {
    return this.nodeImageStore.contents;
  }

  public fetchNodeImages(): Promise<NodeImage[]> {
    return this.rootStore.services.node.fetchNodeImages().then((nodeImages: NodeImage[]) => {
      this.nodeImageStore.addOrUpdateAll(nodeImages);
      return nodeImages;
    });
  }

  public fetchNodeImagesByNodeId(nodeId: number, featured: boolean): Promise<NodeImage[]> {
    return this.rootStore.services.node.fetchNodeImagesByNodeId(nodeId, featured).then((nodeImages: NodeImage[]) => {
      this.nodeImageStore.addOrUpdateAll(nodeImages);
      return nodeImages;
    });
  }

  public createNodeImage(nodeImage: NodeImage): Promise<NodeImage> {
    return this.rootStore.services.node.createNodeImage(nodeImage).then((nodeImage: NodeImage) => {
      this.nodeImageStore.addOrUpdate(nodeImage);
      return nodeImage;
    });
  }

  public deleteNodeImage(nodeImageId: number): Promise<void> {
    return this.rootStore.services.node.deleteNodeImage(nodeImageId).then(() => {
      this.nodeImageStore.remove(nodeImageId);
    });
  }

  public deleteNodeImageByNodeIdAndImageId(nodeId: number, imageId: number): Promise<void> {
    return this.rootStore.services.node.deleteNodeImageByNodeIdAndImageId(nodeId, imageId).then((nodeImageId: number) => {
      this.nodeImageStore.remove(nodeImageId);
    });
  }
}

export { NodeStore };
