import { observable, action } from 'mobx';

import { User } from './models/models';
import { RootStore } from './RootStore';

class UserEditStore {
  @observable public user: User;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action('Fetching User to be edited')
  public fetchUserForEditing(userId: number): Promise<User> {
    if (userId === 0) {
      const newUser: User = new User();
      this.setCurrentUser(newUser);
      return Promise.resolve(newUser);
    }
    return this.rootStore.stores.user.fetchUserById(userId).then((user: User) => {
      if (!user) {
        throw new Error(`User with ID: ${userId} does not exist`);
      }
      this.setCurrentUser(user);
      return user;
    });
  }

  @action('Saving or Updating the current user with new details')
  public createOrUpdateCurrentUser(newUser: User): Promise<User | undefined> {
    if (!this.user) {
      throw new Error(`Unable to update a user if one does not exist`);
    }
    return this.rootStore.stores.user.createOrUpdateUser(newUser).then((user: User) => {
      if (!user) {
        throw new Error(`Unable to update user with ID: ${this.user.id}`);
      }
      this.setCurrentUser(user);
      return user;
    });
  }

  @action('Set User that is currently being edited')
  private setCurrentUser(user: User): void {
    this.user = user;
  }
}

export { UserEditStore };
