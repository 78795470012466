import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { NodeType } from './models/models';

class NodeTypeStore extends GenericStore<NodeType> {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  public get nodeTypes(): NodeType[] {
    return this.contents;
  }

  public fetchNodeTypes(): Promise<NodeType[]> {
    return this.rootStore.services.nodeType.fetchNodeTypes().then((nodeTypes: NodeType[]) => {
      this.addOrUpdateAll(nodeTypes);
      return nodeTypes;
    });
  }

  public fetchNodeTypeById(nodeTypeId: number): Promise<NodeType> {
    return this.rootStore.services.nodeType.fetchNodeTypeById(nodeTypeId).then((nodeType: NodeType) => {
      this.addOrUpdate(nodeType);
      return nodeType;
    });
  }

  public createNodeType(nodeType: NodeType): Promise<NodeType> {
    return this.rootStore.services.nodeType.createNodeType(nodeType).then((nodeType: NodeType) => {
      this.addOrUpdate(nodeType);
      return nodeType;
    });
  }

  public updateNodeType(nodeType: NodeType): Promise<NodeType> {
    return this.rootStore.services.nodeType.updateNodeType(nodeType).then((nodeType: NodeType) => {
      this.addOrUpdate(nodeType);
      return nodeType;
    });
  }

  public createOrUpdateNodeType(nodeType: NodeType): Promise<NodeType> {
    return this.rootStore.services.nodeType.createOrUpdateNodeType(nodeType).then((nodeType: NodeType) => {
      this.addOrUpdate(nodeType);
      return nodeType;
    });
  }

  public deleteNodeType(nodeTypeId: number): Promise<void> {
    return this.rootStore.services.nodeType.deleteNodeType(nodeTypeId).then(() => {
      this.remove(nodeTypeId);
    });
  }
}

export { NodeTypeStore };
