import * as React from 'react';

// Spinner used: https://projects.lukehaas.me/css-loaders/
import './spinners/spinner.css';

const LoadingSpinner = (props: { style?: {} }) => (
  <div>
    <div className="loader" style={props.style} />
  </div>
);

export default LoadingSpinner;
