import { observable, action } from 'mobx';

import { EventType } from './models/models';
import { RootStore } from './RootStore';

class EventTypeEditStore {
  @observable public eventType: EventType;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action('Fetching Event Type to be edited')
  public fetchEventTypeForEditing(eventTypeId: number): Promise<EventType> {
    if (eventTypeId === 0) {
      const newEventType = new EventType();
      this.setCurrentEventType(newEventType);
      return Promise.resolve(newEventType);
    }
    return this.rootStore.stores.eventType.fetchEventTypeById(eventTypeId).then((eventType: EventType) => {
      if (!eventType) {
        throw new Error(`Event Type with ID: ${eventTypeId} does not exist!`);
      }
      this.setCurrentEventType(eventType);
      return eventType;
    });
  }

  @action('Saving or Updating the current Event Type with new details')
  public createOrUpdateCurrentEventType(newEventType: EventType): Promise<EventType | undefined> {
    if (!this.eventType) {
      throw new Error(`Unable to update a Event Type if one does not exist`);
    }
    return this.rootStore.stores.eventType.createOrUpdateEventType(newEventType).then((eventType: EventType) => {
      if (!eventType) {
        throw new Error(`Unable to update Event Type with ID: ${this.eventType.id}`);
      }
      this.setCurrentEventType(eventType);
      return eventType;
    });
  }

  @action('Set Event Type that is currently being edited')
  private setCurrentEventType(eventType: EventType): void {
    this.eventType = eventType;
  }
}

export { EventTypeEditStore };
