import { JsonObject, JsonProperty, JsonConvert } from 'json2typescript';
import { isUndefined } from 'util';

@JsonObject
class Facility {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('name', String)
  name: string = '';
  @JsonProperty('description', String)
  description: string = '';
  @JsonProperty('daily_rate') dailyRate: number | null = null;
  @JsonProperty('hourly_rate') hourlyRate: number | null = null;
  @JsonProperty('unit_rate') unitRate: number | null = null;
  @JsonProperty('vat', Number)
  vat: number = 0;
  @JsonProperty('quantity_available', Number)
  quantityAvailable: number = 0;
}

const exampleFacilityDetails = {
  id: 65,
  name: 'Facility 57',
  description: 'Blue',
  daily_rate: 55,
  hourly_rate: 12,
  vat: 5,
  quantity_available: 8,
  unit_rate: 0
};

const facilityFromForm = (formData: {}) => {
  const facility = new Facility();
  for (const key in formData) {
    let value = formData[key];
    if (isUndefined(value)) {
      value = facility[key];
    }
    if (key === 'hourlyRate' || key === 'dailyRate' || key === 'quantityAvailable' || key === 'unitRate') {
      if (value === '' || value === null) {
        value = 0;
      } else {
        value = parseFloat(value);
      }
    } else if (key === 'vat') {
      value = value / 100;
    } else if (key === 'quantity') {
      if (value === true) {
        facility.quantityAvailable = -1;
      }
    }
    facility[key] = value;
  }
  return facility;
};

const jsonConvert: JsonConvert = new JsonConvert();
const exampleFacility: Facility = jsonConvert.deserialize(exampleFacilityDetails, Facility);

export { Facility, exampleFacilityDetails, exampleFacility, facilityFromForm };
