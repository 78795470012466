import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Row, Col } from 'reactstrap';

import { Event, EventImage, Image, Node } from '../../stores/models/models';
import { EventStore, ImageStore, NodeStore, LoadingStore } from '../../stores/stores';

import LoadingSpinner from './LoadingSpinner';

import moment from 'moment';

import './styles/eventDetailsCardStyle.css';
import { Notifications } from 'core/notifications/notifications';

const dateFormat: string = 'dddd DD MMMM YYYY';
const timeFormat: string = 'HH:mm';

interface IEventDetailsCardProps {
  eventId: number;
}

@inject('rootStore')
@observer
export default class EventDetailsCard extends React.Component<IEventDetailsCardProps, {}> {
  private eventStore: EventStore;
  private imageStore: ImageStore;
  private nodeStore: NodeStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.eventStore = props.rootStore.stores.event;
    this.imageStore = props.rootStore.stores.image;
    this.nodeStore = props.rootStore.stores.node;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Event, EventImage[]]) => {
        const eventImages: EventImage[] = data[1];
        if (eventImages[0] !== undefined) {
          this.imageStore
            .fetchImageById(eventImages[0].imageId)
            .then((image: Image) => {
              this.loadingStore.setLoadingStateToDone();
            })
            .catch((err) => {
              this.loadingStore.setLoadingStateToError();
              Notifications.displayError(err);
            });
        } else {
          this.loadingStore.setLoadingStateToDone();
        }
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const event: Event | undefined = this.eventStore.get(this.props.eventId);

    if (event === undefined) {
      throw Error(`Error: Event with ID ${this.props.eventId} does not exist`);
    }

    const eventImage: EventImage | undefined = this.eventStore.eventImages.find((eventImage: EventImage) => eventImage.eventId === this.props.eventId && eventImage.featured);

    let image: Image | undefined;
    if (eventImage !== undefined) {
      image = this.imageStore.get(eventImage.imageId);
    }

    let imagePath;
    if (image !== undefined) {
      imagePath = `/assets/${image.fileName}`;
    }

    const fallbackImagePath: string = '/static-images/meeting.jpg';

    const node: Node | undefined = this.nodeStore.get(event.nodeId);

    if (node === undefined) {
      throw new Error(`Error: Node with ID: ${event.nodeId} does not exist`);
    }

    return (
      <div>
        <Row className="sjp-background-card-color sjp-event-details-card-height">
          <Col xs="12" sm="12" className="sjp-event-details-details-col">
            <Col className="sjp-full-height sjp-ml-1 sjp-mr-1">
              <Row className="sjp-max-width-75">
                <p className="sjp-title-text sjp-mt-2">{event.name}</p>
              </Row>
              <Row className="sjp-mt-1 sjp-max-width-75">
                <p className="sjp-card-title sjp-mb-0 ">Date</p>
              </Row>
              <Row className="sjp-max-width-75">
                <p className="sjp-description-text sjp-mb-0 sjp-text-uppercase  sjp-max-width-75">
                  {this.isSameDay(event.fromTo[0].from, event.fromTo[0].to) ? moment(event.fromTo[0].from).format(dateFormat) : `${moment(event.fromTo[0].from).format(dateFormat)} - ${moment(event.fromTo[0].to).format(dateFormat)}`}
                </p>
              </Row>
              <Row className="sjp-mt-2 sjp-max-width-75">
                <p className="sjp-card-title sjp-mb-0 ">Location</p>
              </Row>
              <Row className="sjp-max-width-75">
                <p className="sjp-description-text sjp-mb-0 sjp-text-uppercase">{node.name}</p>
              </Row>
              <Row className="sjp-mt-2 sjp-max-width-75">
                <p className="sjp-card-title sjp-mb-0 ">Time</p>
              </Row>
              <Row className="sjp-max-width-75">
                <p className="sjp-description-text sjp-text-uppercase">Starts at {moment(event.fromTo[0].from).format(timeFormat)}</p>
              </Row>
            </Col>
          </Col>
          <Col xs="12" sm="12" className="sjp-event-details-image-col">
            <Row>
              <img src={imagePath ? imagePath : fallbackImagePath} className="sjp-d-block sjp-event-details-image sjp-event-details-card-height sjp-no-margin-left" alt="Cover Photo" />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  private isSameDay(from: string, to: string): boolean {
    return moment(from).isSame(moment(to), 'day');
  }

  private getDataFromApi(): Promise<[Event, EventImage[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const eventPromise: Promise<Event> = this.eventStore.fetchEventById(this.props.eventId).then((event: Event) => {
      return this.nodeStore.fetchNodeById(event.nodeId).then((node: Node) => {
        return event;
      });
    });
    const eventImagesPromise: Promise<EventImage[]> = this.eventStore.fetchEventImagesByEventIdWithParams(this.props.eventId, true, false);
    return Promise.all([ eventPromise, eventImagesPromise ]);
  }
}
