import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { observable, action } from 'mobx';

import { Col, Row, Nav, NavItem, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

import { User } from '../../stores/models/models';
import { Config } from '../../stores/models/Config';
import { CurrentUserStore, ConfigStore, LoadingStore } from '../../stores/stores';

import ShowAsBookingComponent from '../UI/ShowAsBookingComponent';
import ShowAsEventComponent from '../UI/ShowAsEventComponent';

import LoadingSpinner from './LoadingSpinner';

import './styles/navigationBarStyle.css';
import { Notifications } from 'core/notifications/notifications';

import { Profile, Cart } from '../svgIcons/icons';
@inject('rootStore')
@observer
export default class NavigationBar extends React.Component<{}, {}> {
  @observable private dropdownOpen: boolean;
  private currentUserStore: CurrentUserStore;
  private configStore: ConfigStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.currentUserStore = props.rootStore.stores.currentUser;
    this.configStore = props.rootStore.stores.config;
    this.setDropdownOpen(false);
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [User, Config]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        if (err.body && err.body.message.includes('not logged in')) {
          this.loadingStore.setLoadingStateToDone();
        } else {
          this.loadingStore.setLoadingStateToError();
          Notifications.displayError(err);
        }
      });
  }

  public render() {
    if (this.loadingStore.stillLoading) {
      return <LoadingSpinner />;
    } else if (!this.configStore.configuration.navigationBar) {
      return <div />;
    }

    const user: User | undefined = this.currentUserStore.currentUser;

    return (
      <div className="mt-3">
        <Row className="sjp-navbar-height sjp-bring-front navbar-colour">
          <Col className="">
            <Nav className="" justified>
              <Col md={9}>
                <Row className="">
                  {this.configStore.configuration.home ? (
                    <NavItem className="sjp-navbar-flex-vertical sjp-text-spacing">
                      <a className="sjp-nav-text" href="/home">
                        HOME
                      </a>
                    </NavItem>
                  ) : (
                    <div />
                  )}
                  <ShowAsBookingComponent>
                    <NavItem className="sjp-navbar-flex-vertical sjp-text-spacing">
                      <a href="/nodes" className="sjp-nav-text">
                        SITES
                      </a>
                    </NavItem>
                  </ShowAsBookingComponent>
                  <ShowAsEventComponent>
                    {this.configStore.configuration.showEventsTab ? (
                      <NavItem className="sjp-navbar-flex-vertical sjp-text-spacing">
                        <a href="/events" className="sjp-nav-text">
                          EVENTS
                        </a>
                      </NavItem>
                    ) : (
                      <div />
                    )}
                  </ShowAsEventComponent>
                  <ShowAsBookingComponent>
                    {this.configStore.configuration.extraRoomFacilities ? (
                      <NavItem className="sjp-navbar-flex-vertical sjp-text-spacing">
                        <a className="sjp-nav-text" href="/extra-room-facilities">
                          FACILITIES
                        </a>
                      </NavItem>
                    ) : (
                      <div />
                    )}
                  </ShowAsBookingComponent>
                  {this.configStore.configuration.location ? (
                    <NavItem className="sjp-navbar-flex-vertical sjp-text-spacing">
                      <a className="sjp-nav-text" href="/location">
                        LOCATION
                      </a>
                    </NavItem>
                  ) : (
                    <div />
                  )}
                  {this.configStore.configuration.contactUs ? (
                    <NavItem className="sjp-navbar-flex-vertical sjp-text-spacing">
                      <a className="sjp-nav-text" href="/contact-us">
                        CONTACT US
                      </a>
                    </NavItem>
                  ) : (
                    <div />
                  )}
                </Row>
              </Col>
              <Col md={{ size: 2, offset: 1 }}>
                {user ? (
                  <Row className="sjp-float-right">
                    <NavItem className="sjp-navbar-flex-vertical sjp-text-spacing">
                      <Dropdown
                        className="sjp-dropdown-icon-height"
                        isOpen={this.dropdownOpen}
                        toggle={this.toggleDropdownOpen.bind(this)}
                      >
                        <DropdownToggle tag="a" className="active sjp-nav-item">
                          <Profile className="menu-bar-icons-colour" />
                        </DropdownToggle>
                        <DropdownMenu className="sjp-navbar-dropdown-menu">
                          <a href={`/account`} className="sjp-link">
                            <DropdownItem className="sjp-navbar-dropdown-item sjp-nav-text sjp-dropdown-text-size">
                              My Account
                            </DropdownItem>
                          </a>
                          <DropdownItem divider className="sjp-navbar-dropdown-divider" />
                          <a onClick={this.handleLogout.bind(this)}>
                            <DropdownItem className="sjp-navbar-dropdown-item sjp-nav-text sjp-dropdown-text-size">
                              Logout
                            </DropdownItem>
                          </a>
                        </DropdownMenu>
                      </Dropdown>
                    </NavItem>
                    <NavItem className="sjp-navbar-flex-vertical sjp-text-spacing">
                      <a
                        className="sjp-nav-text sjp-dropdown-icon-height"
                        href={`${this.configStore.configuration.nodes ? '/cart' : '/event-cart'}`}
                      >
                        <Cart className="menu-bar-icons-colour" />
                      </a>
                    </NavItem>
                  </Row>
                ) : (
                  <Row>
                    <NavItem className="sjp-navbar-flex-vertical sjp-text-spacing ">
                      <a className="sjp-nav-text navbar-tab-colour" href="/login">
                        LOGIN
                      </a>
                    </NavItem>
                    <NavItem className="sjp-navbar-flex-vertical sjp-text-spacing">
                      <a className="sjp-nav-text navbar-tab-colour" href="/register">
                        REGISTER
                      </a>
                    </NavItem>
                  </Row>
                )}
              </Col>
            </Nav>
          </Col>
        </Row>
      </div>
    );
  }

  private handleLogout(): void {
    this.currentUserStore
      .logoutFromWebsite()
      .then(() => {
        Notifications.showSuccess('Logout Successful', '');
      })
      .catch((err) => {
        Notifications.displayError(err);
      });
  }

  @action('Toggle is dropdown open')
  private toggleDropdownOpen(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  @action('Set is dropdown open')
  private setDropdownOpen(isOpen: boolean): void {
    this.dropdownOpen = isOpen;
  }

  private getDataFromApi(): Promise<any> {
    this.loadingStore.setLoadingStateToLoading();
    return Promise.all([this.currentUserStore.fetchUserData(), this.configStore.fetchConfiguration()]);
  }
}
