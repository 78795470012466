import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { configure } from 'mobx';

import BottomMostRowApp from './BottomMostRowApp';

// MobX configuration
configure({
  enforceActions: true
});

ReactDOM.render(<BottomMostRowApp />, document.getElementById('bottom'));
