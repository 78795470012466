import ApiService from './ApiService';

import { Cart } from '../stores/models/models';

class CartService extends ApiService {
  constructor() {
    super();
  }

  public fetchCarts(): Promise<Cart[]> {
    const url: string = this.getUrl(`/carts`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, Cart);
      });
  }

  public fetchCartById(cartId: number): Promise<Cart> {
    const url: string = this.getUrl(`/cart/${cartId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Cart);
      });
  }

  public fetchProvisionalCartByUserId(userId: number): Promise<Cart> {
    const url: string = this.getUrl(`/carts/user/${userId}?confirmed=false`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data[0], Cart);
        }
        return [];
      });
  }

  public fetchConfirmedCartsByUserId(userId: number): Promise<Cart[]> {
    const url: string = this.getUrl(`/carts/user/${userId}?confirmed=true`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Cart);
        }
        return [];
      });
  }

  public confirmCart(cartId: number): Promise<void> {
    const url: string = this.getUrl(`/confirmcart/${cartId}`);
    return fetch(url, {
      method: 'PUT',
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, Cart));
  }
}

export default CartService;
