import * as React from 'react';
import { SVG } from './SVG';

const Cart = (props: SVG) => (
  <div className={props.className}>
    <svg version="1.1" id="Capa_1" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 47.445 47.446">
      <g>
        <path d="M38.271,14.645V9.331c0-3.418-2.727-6.2-6.145-6.2H15.373c-3.418,0-6.201,2.781-6.201,6.2v5.313H2.698C1.208,14.644,0,15.852,0,17.342v5.865c0,1.491,1.208,2.698,2.698,2.698h0.804L6.45,42.1c0.233,1.282,1.351,2.214,2.654,2.214h29.292c1.304,0,2.42-0.932,2.654-2.215l2.946-16.194h0.75c1.49,0,2.698-1.207,2.698-2.698v-5.865c0-1.49-1.208-2.698-2.698-2.698L38.271,14.645L38.271,14.645z M14.17,9.331c0-0.652,0.551-1.202,1.203-1.202h16.756c0.651,0,1.146,0.55,1.146,1.202v5.313H14.17V9.331z M17.902,34.672c0,1.037-0.829,1.877-1.866,1.877c-1.036,0-1.866-0.84-1.866-1.877V24.368c0-1.037,0.83-1.876,1.866-1.876c1.038,0,1.866,0.841,1.866,1.876V34.672z M25.62,34.672c0,1.037-0.83,1.877-1.866,1.877c-1.036,0-1.867-0.84-1.867-1.877V24.368c0-1.037,0.831-1.876,1.867-1.876c1.037,0,1.866,0.841,1.866,1.876V34.672z M33.338,34.672c0,1.037-0.83,1.877-1.865,1.877c-1.037,0-1.867-0.84-1.867-1.877V24.368c0-1.037,0.83-1.876,1.867-1.876c1.035,0,1.865,0.841,1.865,1.876V34.672z" />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  </div>
);

export default Cart;
