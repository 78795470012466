import ApiService from './ApiService';

import { Event, EventImage } from '../stores/models/models';

class EventService extends ApiService {
  constructor() {
    super();
  }

  // Event

  public fetchEvents(): Promise<Event[]> {
    const url: string = this.getUrl(`/events`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length > 0) {
          return this.jsonConvert.deserialize(responseJson.data, Event);
        }
        return [];
      });
  }

  public fetchActiveEvents(): Promise<Event[]> {
    const url: string = this.getUrl(`/events/active`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length > 0) {
          return this.jsonConvert.deserialize(responseJson.data, Event);
        }
        return [];
      });
  }

  public fetchFutureActiveEvents(): Promise<Event[]> {
    const url: string = this.getUrl(`/events/future?active=true`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length > 0) {
          return this.jsonConvert.deserialize(responseJson.data, Event);
        }
        return [];
      });
  }

  public fetchEventById(eventId: number): Promise<Event> {
    const url: string = this.getUrl(`/event/info/${eventId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Event);
      });
  }

  public fetchEventsBySearchBarParameters(nodeId?: number, from?: string, to?: string): Promise<Event[]> {
    let url: string = this.getUrl(`/event/params?`);
    if (nodeId) {
      url = `${url}nodeId=${nodeId}`;
    }
    if (from) {
      url = `${location ? `${url}&from=${from}` : `${url}from=${from}`}`;
    }
    if (to) {
      url = `${location || from ? `${url}&to=${to}` : `${url}to=${to}`}`;
    }

    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, Event);
        }
        return [];
      });
  }
  public createOrUpdateEvent(event: Event): Promise<Event> {
    const baseUrl: string = '/event';
    const isNew: boolean = event.id === 0;
    const url: string = isNew ? this.getUrl(`${baseUrl}`) : this.getUrl(`${baseUrl}/info/${event.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonEvent = this.jsonConvert.serialize(event);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonEvent)
    })
      .then(this.handleResponse)
      .then((jsonResponse) => {
        return this.jsonConvert.deserialize(jsonResponse.data, Event);
      });
  }

  public deleteEvent(eventId: number): Promise<void> {
    const url: string = this.getUrl(`/event/info/${eventId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  // Event Image

  public fetchEventImages(): Promise<EventImage[]> {
    const url: string = this.getUrl(`/eventimages`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length) {
          return this.jsonConvert.deserialize(responseJson.data, EventImage);
        }
        return [];
      });
  }

  public fetchEventImagesByEventIdWithParams(eventId: number, featured: boolean, seating: boolean): Promise<EventImage[]> {
    const url: string = this.getUrl(`/EventImage/event/${eventId}/params?featured=${featured}&seating=${seating}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length > 0) {
          return this.jsonConvert.deserialize(responseJson.data, EventImage);
        }
        return [];
      });
  }

  public fetchEventImagesByEventId(eventId: number): Promise<EventImage[]> {
    const url: string = this.getUrl(`/EventImage/event/${eventId}/params`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length > 0) {
          return this.jsonConvert.deserialize(responseJson.data, EventImage);
        }
        return [];
      });
  }

  public createEventImage(eventImage: EventImage): Promise<EventImage> {
    const url: string = this.getUrl(`/EventImage`);
    const jsonEventImage = this.jsonConvert.serialize(eventImage);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonEventImage)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, EventImage));
  }

  public deleteEventImage(eventImageId: number): Promise<void> {
    const url: string = this.getUrl(`/EventImage/info/${eventImageId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  public deleteEventImageByEventIdAndImageId(eventId: number, imageId: number): Promise<void> {
    const url: string = this.getUrl(`/EventImage/event/${eventId}/image/${imageId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, EventImage));
  }
}

export default EventService;
