// DO NOT CHANGE THESE CONSTANTS UNLESS THE BACKEND IS ALSO UPDATED

const USER_ROLE_ADMINISTRATOR: string = 'Administrator';
const USER_ROLE_VOLUNTEER: string = 'Volunteer';
const USER_ROLE_CLIENT: string = 'Client';

const roleValueLabelMap: Map<string, string> = new Map<string, string>([ [ USER_ROLE_ADMINISTRATOR, 'Administrator' ], [ USER_ROLE_VOLUNTEER, 'Volunteer' ], [ USER_ROLE_CLIENT, 'Client' ] ]);

class UserRole {
  public value: string;
  public label: string;

  constructor(value: string, label: string) {
    this.value = value;
    this.label = label;
  }
}

const userRoles: UserRole[] = Array.from(roleValueLabelMap.entries()).map((entry: [string, string]) => new UserRole(entry[0], entry[1]));

export { UserRole, userRoles, USER_ROLE_ADMINISTRATOR, USER_ROLE_VOLUNTEER, USER_ROLE_CLIENT };
