import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { Booking, BookingFacility, BulkBooking, BookingBulkBooking, BulkBookingResponse, BulkBookingFacilityResponse } from './models/models';

class BookingStore extends GenericStore<Booking> {
  private rootStore: RootStore;
  private bookingFacilityStore = new GenericStore<BookingFacility>();
  private bulkBookingStore = new GenericStore<BulkBooking>();

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  // Booking

  public get bookings(): Booking[] {
    return this.contents;
  }

  public fetchBookingsByNodeId(nodeId: number): Promise<Booking[]> {
    return this.rootStore.services.booking.fetchBookingsByNodeId(nodeId).then((bookings: Booking[]) => {
      this.addOrUpdateAll(bookings);
      return bookings;
    });
  }

  public fetchConfirmedBookings(): Promise<Booking[]> {
    return this.rootStore.services.booking.fetchConfirmedBookings().then((bookings: Booking[]) => {
      this.addOrUpdateAll(bookings);
      return bookings;
    });
  }

  public fetchConfirmedBookingsByNodeId(nodeId: number): Promise<Booking[]> {
    return this.rootStore.services.booking.fetchConfirmedBookingsByNodeId(nodeId).then((bookings: Booking[]) => {
      this.addOrUpdateAll(bookings);
      return bookings;
    });
  }

  public fetchBookingsByCartId(cartId: number): Promise<Booking[]> {
    return this.rootStore.services.booking.fetchBookingsByCartId(cartId).then((bookings: Booking[]) => {
      this.addOrUpdateAll(bookings);
      return bookings;
    });
  }

  public fetchBookingsByUserId(userId: number): Promise<Booking[]> {
    return this.rootStore.services.booking.fetchBookingsByUserId(userId).then((bookings: Booking[]) => {
      this.addOrUpdateAll(bookings);
      return bookings;
    });
  }

  public fetchProvisionalBookingsByUserId(userId: number): Promise<Booking[]> {
    return this.rootStore.services.booking.fetchProvisionalBookingsByUserId(userId).then((bookings: Booking[]) => {
      this.addOrUpdateAll(bookings);
      return bookings;
    });
  }

  public fetchBookings(): Promise<Booking[]> {
    return this.rootStore.services.booking.fetchBookings().then((bookings: Booking[]) => {
      this.addOrUpdateAll(bookings);
      return bookings;
    });
  }

  public fetchBookingById(bookingId: number): Promise<Booking> {
    return this.rootStore.services.booking.fetchBookingById(bookingId).then((booking: Booking) => {
      this.addOrUpdate(booking);
      return booking;
    });
  }

  public createOrUpdateBooking(booking: Booking): Promise<Booking> {
    return this.rootStore.services.booking.createOrUpdateBooking(booking).then((booking: Booking) => {
      this.addOrUpdate(booking);
      return booking;
    });
  }

  public deleteBooking(bookingId: number): Promise<void> {
    return this.rootStore.services.booking.deleteBooking(bookingId).then(() => {
      this.remove(bookingId);
    });
  }

  // BookingFacility

  public get bookingFacilities(): BookingFacility[] {
    return this.bookingFacilityStore.contents;
  }

  public fetchBookingFacilities(): Promise<BookingFacility[]> {
    return this.rootStore.services.booking.fetchBookingFacilities().then((bookingFacilities: BookingFacility[]) => {
      this.bookingFacilityStore.addOrUpdateAll(bookingFacilities);
      return bookingFacilities;
    });
  }

  public fetchBookingFacilityByBookingIdAndFacilityId(bookingId: number, facilityId: number): Promise<BookingFacility> {
    return this.rootStore.services.booking.fetchBookingFacilityByBookingIdAndFacilityId(bookingId, facilityId).then((bookingFacility: BookingFacility) => {
      this.bookingFacilityStore.addOrUpdate(bookingFacility);
      return bookingFacility;
    });
  }

  public fetchBookingFacilitiesByBookingId(bookingId: number): Promise<BookingFacility[]> {
    return this.rootStore.services.booking.fetchBookingFacilitiesByBookingId(bookingId).then((bookingFacilities: BookingFacility[]) => {
      this.bookingFacilityStore.addOrUpdateAll(bookingFacilities);
      return bookingFacilities;
    });
  }

  public createBookingFacility(bookingFacility: BookingFacility): Promise<BookingFacility> {
    return this.rootStore.services.booking.createBookingFacility(bookingFacility).then((bookingFacility: BookingFacility) => {
      this.bookingFacilityStore.addOrUpdate(bookingFacility);
      return bookingFacility;
    });
  }

  public updateBookingFacility(bookingFacility: BookingFacility): Promise<BookingFacility> {
    return this.rootStore.services.booking.updateBookingFacility(bookingFacility).then((bookingFacility: BookingFacility) => {
      this.bookingFacilityStore.addOrUpdate(bookingFacility);
      return bookingFacility;
    });
  }

  public createOrUpdateBookingFacility(bookingFacility: BookingFacility): Promise<BookingFacility> {
    return this.rootStore.services.booking.createOrUpdateBookingFacility(bookingFacility).then((bookingFacility: BookingFacility) => {
      this.bookingFacilityStore.addOrUpdate(bookingFacility);
      return bookingFacility;
    });
  }

  public deleteBookingFacility(bookingFacilityId: number): Promise<void> {
    return this.rootStore.services.booking.deleteBookingFacility(bookingFacilityId).then(() => {
      this.bookingFacilityStore.remove(bookingFacilityId);
    });
  }

  // Bulk Booking
  public get bulkBookings(): BulkBooking[] {
    return this.bulkBookingStore.contents;
  }

  public fetchBulkBookingById(bulkBookingId: number): Promise<BulkBooking> {
    return this.rootStore.services.booking.fetchBulkBookingById(bulkBookingId).then((bulkBooking: BulkBooking) => {
      this.bulkBookingStore.addOrUpdate(bulkBooking);
      return bulkBooking;
    });
  }
  public createOrUpdateBulkBooking(booking: Booking, bulkBooking: BulkBooking): Promise<BulkBookingResponse[]> {
    const bookingBulkBooking: BookingBulkBooking = new BookingBulkBooking();
    bookingBulkBooking.booking = booking;
    bookingBulkBooking.bulkBooking = bulkBooking;

    return this.rootStore.services.booking.createOrUpdateBulkBooking(bookingBulkBooking).then((bulkBookingResponses: BulkBookingResponse[]) => {
      this.clear();
      return this.fetchBookings().then((bookings: Booking[]) => {
        return bulkBookingResponses;
      });
    });
  }

  public deleteBulkBooking(bulkBookingId: number): Promise<void> {
    return this.rootStore.services.booking.deleteBulkBooking(bulkBookingId).then(() => {
      this.bulkBookingStore.remove(bulkBookingId);
    });
  }

  // Bulk Booking Facility

  public createOrUpdateBulkBookingFacility(bookingFacility: BookingFacility): Promise<BulkBookingFacilityResponse[]> {
    return this.rootStore.services.booking.createOrUpdateBulkBookingFacility(bookingFacility).then((bulkBookingFacilityResponses: BulkBookingFacilityResponse[]) => {
      this.clear();
      const bookingFacilities: BookingFacility[] = [];
      bulkBookingFacilityResponses.forEach((bulkBookingFacilityResponse: BulkBookingFacilityResponse) => {
        this.bookingFacilityStore.addOrUpdate(bulkBookingFacilityResponse.bookingFacility);
        bookingFacilities.push(bulkBookingFacilityResponse.bookingFacility);
      });
      return bulkBookingFacilityResponses;
    });
  }

  public deleteBulkBookingFacility(bookingFacilityId: number): Promise<void> {
    return this.rootStore.services.booking.deleteBulkBookingFacility(bookingFacilityId).then(() => {
      this.clear();
      return this.fetchBookingFacilities();
    });
  }
}

export { BookingStore };
