import { observable, action } from 'mobx';

import { RedeemCode } from './models/models';
import { RootStore } from './RootStore';

export class RedeemCodeEditStore {
  @observable public redeemCode: RedeemCode;
  @observable public unlimitedChecked: boolean = false;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action('Fetching the Redeem Code to be edited')
  public fetchRedeemCodeForEditing(redeemCodeId: number): Promise<RedeemCode> {
    if (redeemCodeId === 0) {
      const newRedeemCode = new RedeemCode();
      this.setCurrentRedeemCode(newRedeemCode);
      return Promise.resolve(newRedeemCode);
    }
    return this.rootStore.stores.redeemCode.fetchRedeemCodeById(redeemCodeId).then((redeemCode: RedeemCode) => {
      if (!redeemCode) {
        throw new Error(`Redeem Code with ID ${redeemCodeId} does not exist`);
      }
      this.setCurrentRedeemCode(redeemCode);
      return redeemCode;
    });
  }

  @action('Saving or Updating the current redeem code with new details')
  public createOrUpdateCurrentRedeemCode(newRedeemCode: RedeemCode): Promise<RedeemCode> {
    if (!this.redeemCode) {
      throw new Error(`Unable to update a redeem code if one does not exist`);
    }
    return this.rootStore.stores.redeemCode.createOrUpdateRedeemCode(newRedeemCode).then((redeemCode: RedeemCode) => {
      if (!redeemCode) {
        throw new Error(`Unable to update redeem code with ID: ${this.redeemCode.id}`);
      }
      this.setCurrentRedeemCode(redeemCode);
      return redeemCode;
    });
  }

  @action('Set Redeem Code that is currently being edited')
  private setCurrentRedeemCode(redeemCode: RedeemCode): void {
    this.redeemCode = redeemCode;
  }

  @action('Set unlimitedChecked observable')
  public setUnlimitedChecked(value: boolean): void {
    this.unlimitedChecked = value;
  }

  @action('Toggle the unlimited checked observable')
  public toggleUnlimitedChecked(): void {
    this.unlimitedChecked = !this.unlimitedChecked;
  }
}
