import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import { User, Cart, Booking } from '../../stores/models/models';
import { CurrentUserStore, CartStore, BookingStore, LoadingStore } from '../../stores/stores';

import BookingDetails from '../../components/website/BookingDetails';
import YourCartCard from '../../components/website/YourCartCard';

import { Col, Row, Container, Button } from 'reactstrap';

import LoadingSpinner from '../../components/website/LoadingSpinner';

import './styles/cartScreenStyle.css';
import { Notifications } from 'core/notifications/notifications';

@inject('rootStore')
@observer
export default class CartScreen extends React.Component<{}, {}> {
  private currentUserStore: CurrentUserStore;
  private cartStore: CartStore;
  private bookingStore: BookingStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.currentUserStore = props.rootStore.stores.currentUser;
    this.cartStore = props.rootStore.stores.cart;
    this.bookingStore = props.rootStore.stores.booking;
  }

  public componentDidMount() {
    this.getDataFromApi().then(() => this.loadingStore.setLoadingStateToDone()).catch((err) => {
      this.loadingStore.setLoadingStateToError();
      Notifications.displayError(err);
    });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const currentUser: User | undefined = this.currentUserStore.currentUser;

    if (currentUser === undefined) {
      return <h5 className="sjp-cart-undefined-warning">Please log in to view your cart</h5>;
    }

    const cart: Cart | undefined = this.cartStore.carts.find((cart: Cart) => cart.userId === currentUser.id);

    if (cart === undefined) {
      throw new Error(`Error: Cart with User ID ${currentUser.id} does not exist`);
    }

    return (
      <div>
        {this.bookingStore.bookings.filter((booking: Booking) => booking.cartId === cart.id).length === 0 ? (
          <Container>
            <h5 className="sjp-text-center sjp-warning-text sjp-mt-1">There are currently no bookings in you cart</h5>
            <Link to="/nodes">
              <Container className="sjp-white-background sjp-mb-3">
                <Button className="sjp-mt-1 sjp-button sjp-full-width sjp-button-text button-one-line-text sjp-no-border-radius sjp-pt-1 sjp-pb-1" type="submit">
                  BOOK A SITE?
                </Button>
              </Container>
            </Link>
          </Container>
        ) : (
          <Container className="sjp-mb-3">
            <Row className="sjp-text-container-center-small-devices">
              <h4 className="sjp-title-text sjp-mt-3 sjp-mb-1 sjp-text-container-center-small-devices">Booking Details</h4>
            </Row>
            <Row>
              <Col xs="12" sm="12" md="12" lg="9" xl="9" className="sjp-pl-0 sjp-pr-0">
                {this.bookingStore.bookings.filter((booking: Booking) => booking.cartId === cart.id).map((booking: Booking) => {
                  return <BookingDetails key={booking.id} bookingId={booking.id} />;
                })}
                <Link to="/nodes" className="sjp-full-width sjp-mt-2 ">
                  <Button className="sjp-button sjp-no-border-radius sjp-button-text button-one-line-text sjp-full-width sjp-pt-1 sjp-pb-1 sjp-mb-1" type="submit">
                    BOOK ANOTHER SITE?
                  </Button>
                </Link>
              </Col>

              <Col xs="12" sm="12" md="12" lg="3" xl="3" className="sjp-pr-0 sjp-your-cart-card-padding">
                <YourCartCard cartId={cart.id} cart={true} />
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }

  private getDataFromApi(): Promise<Booking[]> {
    this.loadingStore.setLoadingStateToLoading();
    return this.currentUserStore.fetchUserData().then((user: User) => {
      return this.cartStore.fetchProvisionalCartByUserId(user.id).then((cart: Cart) => {
        return this.bookingStore.fetchBookingsByCartId(cart.id);
      });
    });
  }
}
