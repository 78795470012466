import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Redirect } from 'react-router-dom';

import { Form, Input, Button, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import { User, UserPassword } from '../../stores/models/models';
import { UserStore } from '../../stores/stores';

import { userPasswordFromForm } from '../../stores/models/User';

import { RootStore } from '../../stores/RootStore';

import { Container, Row, Col } from 'reactstrap';

import './styles/registrationFormStyle.css';
import { Notifications } from 'core/notifications/notifications';

const FormItem = Form.Item;
const warning = Modal.warning;

interface IRegistrationFormProps {
  rootStore: RootStore;
}

@inject('rootStore')
@observer
class RegistrationForm extends React.Component<IRegistrationFormProps & FormComponentProps, {}> {
  @observable private shouldRedirect: boolean = false;
  private userStore: UserStore;

  constructor(props: IRegistrationFormProps & FormComponentProps) {
    super(props);
    this.userStore = props.rootStore.stores.user;
  }

  public render() {
    return this.getForm();
  }

  private getForm(): JSX.Element {
    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      colon: false
    };

    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div>
        {this.shouldRedirect ? (
          <Redirect to="/registration-success" />
        ) : (
          <Container className="sjp-mb-3">
            <Row className="sjp-mt-5 sjp-mb-2 sjp-text-container-center-small-devices">
              <div className="sjp-title-text">Register</div>
            </Row>
            <Row className="sjp-darkgrey-background sjp-pt-1 sjp-pb-1 mt-1">
              <Col className="sjp-white-title-text sjp-text-uppercase">Registration Form</Col>
            </Row>
            <Row className="sjp-background-card-color">
              <Col className="" xs="12" sm="12" md="12" lg="11" xl="11">
                <Form onSubmit={this.handleSubmit.bind(this)}>
                  <Row className="sjp-mt-2">
                    <Col xs="12" sm="12" md="12" lg="3" xl="3">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase"> * FIRST NAME</div>
                        {getFieldDecorator('name', {
                          initialValue: undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please input your first name'
                            }
                          ]
                        })(<Input className="sjp-no-border-radius" name="name" type="text" placeholder="Enter First Name" />)}
                      </FormItem>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="3" xl="3">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase"> * LAST NAME</div>
                        {getFieldDecorator('surname', {
                          initialValue: undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please input your last name'
                            }
                          ]
                        })(<Input className="sjp-no-border-radius" name="surname" type="text" placeholder="Enter Last Nane" />)}
                      </FormItem>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase"> * ID NUMBER</div>
                        {getFieldDecorator('idCard', {
                          initialValue: undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please input your ID Card Number'
                            }
                          ]
                        })(<Input className="sjp-no-border-radius" name="idCard" type="text" placeholder="Enter ID Number" />)}
                      </FormItem>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="3" xl="3">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase"> * CONTACT NUMBER</div>
                        {getFieldDecorator('contactNumber', {
                          initialValue: undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please input your Contact Number'
                            }
                          ]
                        })(<Input className="sjp-no-border-radius" name="contactNumber" type="text" placeholder="Enter Contact Number" />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="3" xl="3">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase"> * EMAIL ADDRESS</div>
                        {getFieldDecorator('emailAddress', {
                          initialValue: undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please input your Email Address'
                            }
                          ]
                        })(<Input className="sjp-no-border-radius" name="emailAddress" type="text" placeholder="Enter Email Address" />)}
                      </FormItem>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="8" xl="8">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase"> * POSTAL ADDRESS</div>
                        {getFieldDecorator('postalAddress', {
                          initialValue: undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please input your Postal Address'
                            }
                          ]
                        })(<Input className="sjp-no-border-radius" name="postalAddress" type="text" placeholder="Enter Postal Address" />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="3" xl="3">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase">COMPANY NAME</div>
                        {getFieldDecorator('companyName', {
                          initialValue: undefined
                        })(<Input className="sjp-no-border-radius" name="companyName" type="text" placeholder="Enter Company Name" />)}
                      </FormItem>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase">VAT NUMBER</div>
                        {getFieldDecorator('vat', {
                          initialValue: undefined
                        })(<Input className="sjp-no-border-radius" name="vat" type="text" placeholder="Enter VAT Number" />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="3" xl="3">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase">COMPANY CONTACT NUMBER</div>
                        {getFieldDecorator('companyContactNumber', {
                          initialValue: undefined
                        })(<Input className="sjp-no-border-radius" name="companyContactNumber" type="text" placeholder="Enter Company Contact Number" />)}
                      </FormItem>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="8" xl="8">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase">COMPANY ADDRESS</div>
                        {getFieldDecorator('companyPostalAddress', {
                          initialValue: undefined
                        })(<Input className="sjp-no-border-radius" name="companyPostalAddress" type="text" placeholder="Enter Company Address" />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="3" xl="3">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase">* PASSWORD</div>
                        {getFieldDecorator('passwordOne', {
                          initialValue: undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please input your Password'
                            }
                          ]
                        })(<Input className="sjp-no-border-radius" type="password" placeholder="Password" />)}
                      </FormItem>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="9" xl="9">
                      {this.checkIfPasswordCorrectFormat(form.getFieldsValue()['passwordOne']) ? null : (
                        <div className="sjp-mt-3 sjp-small-warning-text sjp-text-uppercase sjp-mb-1">Please make sure your password contains at least one lowercase letter, one uppercase letter and has at least 8 characters</div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="3" xl="3">
                      <FormItem {...formItemLayout}>
                        <div className="sjp-label sjp-text-uppercase"> * CONFIRM PASSWORD</div>
                        {getFieldDecorator('passwordTwo', {
                          initialValue: undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please confirm your Password'
                            }
                          ]
                        })(<Input className="sjp-no-border-radius" type="password" placeholder="Confirm Password" />)}
                      </FormItem>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="9" xl="9">
                      {this.checkIfPasswordsMatch(form.getFieldsValue()['passwordOne'], form.getFieldsValue()['passwordTwo']) ? null : <div className="sjp-mt-3  sjp-small-warning-text sjp-text-uppercase sjp-mb-1">The passwords do not match</div>}
                    </Col>
                  </Row>

                  <FormItem>
                    <Row className="sjp-mt-3">
                      <Col xs="12" sm="12" md="12" lg="3" xl="3">
                        <Button
                          className="sjp-full-width sjp-button sjp-no-border-radius sjp-button-text sjp-text-uppercase"
                          type="primary"
                          htmlType="submit"
                          size="large"
                          disabled={!(this.checkIfPasswordCorrectFormat(form.getFieldValue('passwordOne')) && this.checkIfPasswordsMatch(form.getFieldValue('passwordOne'), form.getFieldValue('passwordTwo')))}
                        >
                          REGISTER
                        </Button>
                      </Col>
                    </Row>
                  </FormItem>
                </Form>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }

  private checkIfPasswordCorrectFormat(password: string | undefined): boolean {
    if (password === undefined) {
      return false;
    }
    return !(password.length < 8 || !this.hasLowerCase(password) || !this.hasUpperCase(password));
  }

  private checkIfPasswordsMatch(passwordOne: string | undefined, passwordTwo: string | undefined): boolean {
    if (passwordOne === undefined || passwordTwo === undefined) {
      return false;
    }
    return passwordOne === passwordTwo;
  }

  private hasLowerCase(string: string): boolean {
    return /[a-z]/.test(string);
  }

  private hasUpperCase(string: string): boolean {
    return /[A-Z]/.test(string);
  }

  private handleSubmit(e: any): void {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((error, values) => {
      if (error) {
        warning({
          title: 'Validation Error',
          content: 'You are missing some fields. Please make sure you fill in required fields (marked by *)'
        });
        return;
      }

      if (values.passwordOne !== values.passwordTwo) {
        warning({
          title: 'Validation Error',
          content: 'The passwords you entered to not match. Please try again'
        });
        return;
      }

      const newUserPassword: UserPassword = new UserPassword();
      const userPasswordValues = Object.assign(newUserPassword, values);
      const editedUserPassword: UserPassword = userPasswordFromForm(userPasswordValues);

      return this.userStore
        .registerClient(editedUserPassword)
        .then((user: User) => {
          return Promise.resolve(user);
        })
        .then((user: User) => {
          this.setShouldRedirect(true);
        })
        .catch((err) => {
          Notifications.displayError(err);
          return Promise.reject(err);
        });
    });
  }

  @action('Set shouldRedirect')
  private setShouldRedirect(shouldRedirect: boolean): void {
    this.shouldRedirect = shouldRedirect;
  }
}

export default Form.create<{}>()(RegistrationForm);
