import { observable, computed, action } from 'mobx';
import { LoadingState } from '../enums';

interface IRedirectData {
  reason: string;
  data?: any;
  target: string;
}

class LoadingStore {
  @observable public needRedirect: boolean;
  @observable public redirectData: any; // Holds any data we want persisted through a redirect
  @observable private loadingState: LoadingState;

  constructor() {
    this.loadingState = LoadingState.Loading;
  }

  @computed
  get stillLoading(): boolean {
    return this.loadingState === LoadingState.Loading;
  }

  @computed
  get loadingComplete(): boolean {
    return this.loadingState === LoadingState.Done;
  }

  @computed
  get loadingError(): boolean {
    return this.loadingState === LoadingState.Error;
  }

  @action
  public setLoadingStateToLoading() {
    this.loadingState = LoadingState.Loading;
  }

  @action
  public setLoadingStateToDone() {
    this.loadingState = LoadingState.Done;
  }

  @action
  public setLoadingStateToError() {
    this.loadingState = LoadingState.Error;
  }

  @action
  public initiateRedirect(data: IRedirectData) {
    this.redirectData = data;
    this.needRedirect = true;
  }

  // This will also delete any redirect data we passed to avoid constant redirects
  @action
  public completeRedirect() {
    this.redirectData = null;
    this.needRedirect = false;
  }
}

const loadingStore = new LoadingStore();

export { loadingStore, LoadingStore };
