import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Node, NodeFacility, Facility, NodeImage, Image } from '../../stores/models/models';
import { NodeStore, FacilityStore, ImageStore, LoadingStore } from '../../stores/stores';

import { Row, Col, Alert } from 'reactstrap';
import ImgsViewer from 'react-images-viewer';

import LoadingSpinner from './LoadingSpinner';

import './styles/nodeDetailsStyle.css';
import { Notifications } from 'core/notifications/notifications';

import { Plus, Check } from '../svgIcons/icons';

interface INodeDetailsProps {
  nodeId: number;
}

@inject('rootStore')
@observer
export default class NodeDetails extends React.Component<INodeDetailsProps, {}> {
  @observable private lightboxIsOpen: boolean = false;
  @observable private photoIndex: number = 0;
  private nodeStore: NodeStore;
  private facilityStore: FacilityStore;
  private imageStore: ImageStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.nodeStore = props.rootStore.stores.node;
    this.facilityStore = props.rootStore.stores.facility;
    this.imageStore = props.rootStore.stores.image;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: any) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const node: Node | undefined = this.nodeStore.get(this.props.nodeId);

    if (node === undefined) {
      return <Alert color="danger">{`Error: site with ID ${this.props.nodeId} does not exist!`}</Alert>;
    }

    const includedNodeFacilities: NodeFacility[] = this.nodeStore.nodeFacilities.filter((nodeFacility: NodeFacility) => nodeFacility.included && nodeFacility.nodeId === this.props.nodeId);
    const additionalNodeFacilities: NodeFacility[] = this.nodeStore.nodeFacilities.filter((nodeFacility: NodeFacility) => !nodeFacility.included && nodeFacility.nodeId === this.props.nodeId);

    const includedFacilities: Facility[] = [];
    includedNodeFacilities.forEach((nodeFacility: NodeFacility) => {
      const facility: Facility | undefined = this.facilityStore.get(nodeFacility.facilityId);
      if (facility !== undefined) {
        includedFacilities.push(facility);
      }
    });

    const additionalFacilities: Facility[] = [];
    additionalNodeFacilities.forEach((nodeFacility: NodeFacility) => {
      const facility: Facility | undefined = this.facilityStore.get(nodeFacility.facilityId);
      if (facility !== undefined) {
        additionalFacilities.push(facility);
      }
    });

    const nodeImages: NodeImage[] = this.nodeStore.nodeImages.filter((nodeImage: NodeImage) => nodeImage.nodeId === this.props.nodeId && !nodeImage.featured);

    const imagesLocations: string[] = [];
    nodeImages.forEach((nodeImage: NodeImage) => {
      const image: Image | undefined = this.imageStore.get(nodeImage.imageId);
      if (image !== undefined) {
        imagesLocations.push(`/assets/${image.fileName}`);
      }
    });

    return (
      <Col className="sjp-pr-0">
        <Row className="sjp-text-container-center-small-devices sjp-mt-5">
          <h4 className="sjp-title-text">{node.name}</h4>
        </Row>
        <Row>
          <Col>
            <Row className="sjp-description-text sjp-text-container-center-small-devices">
              <Col className="sjp-pl-0">
                <h5 className="sjp-card-title sjp-mt-2">Description</h5>
              </Col>
            </Row>
            <Row className="sjp-text-container-center-small-devices">
              <Col className="sjp-pl-0">
                <p className="sjp-description-text sjp-mr-1">{node.description}</p>
              </Col>
            </Row>

            {imagesLocations.length > 0 ? (
              <div>
                <Row className="sjp-mt-2 sjp-text-container-center-small-devices">
                  <h5 className="sjp-card-title sjp-mb-1">Image Gallery</h5>
                </Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12" className="sjp-pl-0 sjp-full-width">
                  <Row>
                    {imagesLocations.map((imageLocation: string, index: number) => {
                      return (
                        <Col xs="12" sm="12" md="4" lg="4" xl="4" className="sjp-no-padding sjp-mb-image" key={imageLocation}>
                          <img className="sjp-d-block sjp-room-img" src={imageLocation} onClick={this.handleClickImage.bind(this, index)} />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                {this.lightboxIsOpen ? (
                  <ImgsViewer
                    imgs={imagesLocations.map((imageLocation: string) => {
                      const image: {} = { src: imageLocation };
                      return image;
                    })}
                    currImg={this.photoIndex}
                    isOpen={this.lightboxIsOpen}
                    onClickPrev={this.gotoPrevious.bind(this, imagesLocations)}
                    onClickNext={this.gotoNext.bind(this, imagesLocations)}
                    onClose={this.closeViewer.bind(this)}
                    backdropCloseable={true}
                  />
                ) : (
                  undefined
                )}
              </div>
            ) : (
              undefined
            )}

            <Row className="sjp-mt-4 sjp-text-container-center-small-devices">
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-mb-1 sjp-text-container-center-small-devices">
                  <h5 className="sjp-card-title">Pricing</h5>
                </Row>
                <Row className="sjp-text-container-center-small-devices">
                  <h6 className="sjp-value sjp-text-uppercase">Hourly Rate</h6>
                  <h6 className="sjp-label sjp-text-uppercase sjp-ml-1">€{(Math.round(node.hourlyPrice * (node.vat + 1) * 100) / 100).toFixed(2)}</h6>
                </Row>
                <Row className="sjp-text-container-center-small-devices">
                  <h6 className="sjp-value sjp-text-uppercase">Daily Rate</h6>
                  <h6 className="sjp-label sjp-text-uppercase sjp-ml-1">€{(Math.round(node.dailyPrice * (node.vat + 1) * 100) / 100).toFixed(2)}</h6>
                </Row>
                <Row className="sjp-description-text sjp-mt-1 sjp-node-details-extra-text-width sjp-text-container-center-small-devices">
                  Prices quoted include VAT. Cancellation policy: Full refund of fees for cancellation up to 24 hours prior to event.
                </Row>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-mb-1 sjp-text-container-center-small-devices">
                  <h5 className="sjp-card-title sjp-top-margin-node-details">Capacity</h5>
                </Row>
                <Row className="sjp-text-container-center-small-devices">
                  <h6 className="sjp-value sjp-text-uppercase">Standing Capacity</h6>
                  <h6 className="sjp-label sjp-text-uppercase sjp-ml-1">{node.standingCapacity} PEOPLE</h6>
                </Row>
                <Row className="sjp-text-container-center-small-devices">
                  <h6 className="sjp-value sjp-text-uppercase">Seating Capacity</h6>
                  <h6 className="sjp-label sjp-text-uppercase sjp-ml-1">{node.seatingCapacity} PEOPLE</h6>
                </Row>
              </Col>
            </Row>

            <Row className="sjp-divider sjp-mt-4 sjp-text-container-center-small-devices" />

            <Row className="sjp-mt-4 jp-align-top sjp-text-container-center-small-devices">
              <Col xs="12" sm="12" md="6" lg="6" xl="6" className="sjp-mb-2">
                <Row className="sjp-text-container-center-small-devices">
                  <h5 className="sjp-card-title">Amenities Included</h5>
                </Row>
                {includedFacilities.length > 0 ? (
                  includedFacilities.map((facility: Facility) => {
                    return (
                      <Row className="sjp-height-1 sjp-mt-1 sjp-text-container-center-small-devices" key={facility.id}>
                        <Check className="sjp-details-icon sjp-primary-fill sjp-width-1" />
                        <div className="sjp-value sjp-text-uppercase sjp-ml-1 sjp-vertical-align">{facility.name}</div>
                      </Row>
                    );
                  })
                ) : (
                  <Row className="sjp-text-container-center-small-devices">
                    <p className="sjp-description-text sjp-mt-1 sjp-node-details-extra-text-width sjp-pl-0">N/A</p>
                  </Row>
                )}
              </Col>

              <Col xs="12" sm="12" md="6" lg="6" xl="6">
                <Row className="sjp-text-container-center-small-devices">
                  <h5 className="sjp-card-title">Extra Amenities Available</h5>
                </Row>
                {additionalFacilities.length > 0 ? (
                  additionalFacilities.map((facility: Facility) => {
                    return (
                      <Row className="sjp-height-1 sjp-mt-1 sjp-text-container-center-small-devices sjp-top-margin-node-details" key={facility.id}>
                        <Plus className="sjp-details-icon sjp-primary-fill sjp-width-1" />
                        <div className="sjp-value sjp-text-uppercase sjp-ml-1 sjp-vertical-align">{facility.name}</div>
                      </Row>
                    );
                  })
                ) : (
                  <Row className="sjp-text-container-center-small-devices">
                    <p className="sjp-description-text sjp-mt-1 sjp-node-details-extra-text-width">N/A</p>
                  </Row>
                )}
                <Row className="sjp-text-container-center-small-devices">
                  <p className="sjp-description-text sjp-mt-1 sjp-node-details-extra-text-width sjp-pr-2">All extra amenities are at an additional cost to be added on check-out.</p>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }

  private handleClickImage(index: number): void {
    this.setLightboxIsOpen(true);
    this.setPhotoIndex(index);
  }

  private gotoPrevious(imagesLocations: string[]): void {
    this.setPhotoIndex((this.photoIndex + imagesLocations.length - 1) % imagesLocations.length);
  }

  private gotoNext(imagesLocations: string[]): void {
    this.setPhotoIndex((this.photoIndex + 1) % imagesLocations.length);
  }

  private closeViewer(): void {
    this.setLightboxIsOpen(false);
  }

  @action('Set lightbox is open')
  private setLightboxIsOpen(isOpen: boolean): void {
    this.lightboxIsOpen = isOpen;
  }

  @action('Setphoto index')
  private setPhotoIndex(index: number): void {
    this.photoIndex = index;
  }

  private getDataFromApi(): Promise<[Node, (NodeFacility[] | Promise<Facility>[])[], (NodeImage[] | Promise<Image>[])[]]> {
    this.loadingStore.setLoadingStateToLoading();
    const nodePromise: Promise<Node> = this.nodeStore.fetchNodeById(this.props.nodeId);
    const nodeFacilitiesPromise: Promise<(NodeFacility[] | Promise<Facility>[])[]> = this.nodeStore.fetchNodeFacilitiesByNodeId(this.props.nodeId).then((nodeFacilities: NodeFacility[]) => {
      const facilities: Promise<Facility>[] = nodeFacilities.map((nodeFacility: NodeFacility) => {
        return this.facilityStore.fetchFacilityById(nodeFacility.facilityId);
      });
      return [ nodeFacilities, facilities ];
    });

    const nodeImagePromise: Promise<(NodeImage[] | Promise<Image>[])[]> = this.nodeStore.fetchNodeImagesByNodeId(this.props.nodeId, false).then((nodeImages: NodeImage[]) => {
      const images: Promise<Image>[] = nodeImages.map((nodeImage: NodeImage) => {
        return this.imageStore.fetchImageById(nodeImage.imageId);
      });
      return [ nodeImages, images ];
    });
    return Promise.all([ nodePromise, nodeFacilitiesPromise, nodeImagePromise ]);
  }
}
