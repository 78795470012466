import * as React from 'react';
import { observer, inject } from 'mobx-react';

import { NodeStore, LoadingStore } from '../../stores/stores';
import { Node } from '../../stores/models/models';
import LoadingSpinner from 'core/loading/LoadingSpinner';
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';
import { Container } from 'reactstrap';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { Notifications } from 'core/notifications/notifications';

@inject('rootStore')
@observer
export default class MyLocationMap extends React.Component<{}, {}> {
  private nodeStore: NodeStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.nodeStore = props.rootStore.stores.node;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then(() => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading) {
      return <LoadingSpinner />;
    }
    const LocationMap: any = withScriptjs(withGoogleMap(() => this.getMap()));
    return (
      <Container fluid className="shift-up">
        <LocationMap
          mapElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `550px` }} />}
          loadingElement={<LoadingSpinner />}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBTGDk8qk3ywMhV8Qk1WlE2YDfer4KuVeQ&v=3.exp&libraries=geometry,drawing,places"
        />
      </Container>
    );
  }

  private getMap(): JSX.Element {
    // Get the nodes
    const positions = this.nodeStore.nodes.filter((node: Node) => node.latitude && node.longitude);
    // filter for those which have lon and lat
    // map over the nodes to create <Marker /> tags

    return (
      // <p>markers{markers.toString}</p>
      <GoogleMap defaultZoom={15} defaultCenter={{ lat: positions[0].latitude, lng: positions[0].longitude }}>
        <MarkerClusterer gridSize={60}>{positions.map((marker) => <Marker key={marker.id} position={{ lat: marker.latitude, lng: marker.longitude }} />)}</MarkerClusterer>
      </GoogleMap>
    );
  }

  private getDataFromApi(): Promise<Node[]> {
    this.loadingStore.setLoadingStateToLoading();
    return this.nodeStore.fetchNodesWithLonLat();
  }
}
