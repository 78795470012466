import { observable, action } from 'mobx';

// Domain Stores
import { NodeStore } from './NodeStore';
import { NodeTypeStore } from './NoodeTypeStore';
import { TypeCanHaveStore } from './TypeCanHaveStore';
import { BookingStore } from './BookingStore';
import { FacilityStore } from './FacilityStore';
import { UserStore } from './UserStore';
import { CartStore } from './CartStore';
import { RosterStore } from './RosterStore';
import { ImageStore } from './ImageStore';
import { RedeemCodeStore } from './RedeemCodeStore';
import { TicketStore } from './TicketStore';
import { EventStore } from './EventStore';
import { EventTypeStore } from './EventTypeStore';

// Edit Stores
import { NodeEditStore } from './NodeEditStore';
import { FacilityEditStore } from './FacilityEditStore';
import { BookingEditStore } from './BookingEditStore';
import { UserEditStore } from './UserEditStore';
import { RedeemCodeEditStore } from './RedeemCodeEditStore';
import { EventEditStore } from './EventEditStore';
import { TicketEditStore } from './TicketEditStore';
import { TicketSalesEditStore } from './TicketSalesEditStore';
import { EventTypeEditStore } from './EventTypeEditStore';

// UI Stores
import { UIStore } from './UIStore';
import { WebsiteUIStore } from './WebsiteStore';
import CurrentUserStore from './CurrentUserStore';
import { NodesListStore } from './NodesListStore';
import { CheckoutStore } from './CheckoutStore';
import ErrorStore from '../../../core/stores/ErrorStore';
import CongigStore from './ConfigStore';

// Services
import NodeService from '../services/NodeService';
import NodeTypeService from '../services/NodeTypeService';
import TypeCanHaveService from '../services/TypeCanHaveService';
import BookingService from '../services/BookingService';
import FacilityService from '../services/FacilityService';
import UserService from '../services/UserService';
import CartService from '../services/CartService';
import RosterService from '../services/RosterService';
import ImageService from '../services/ImageService';
import RedeemCodeService from '../services/RedeemCodeService';
import TicketService from '../services/TicketService';
import EventService from '../services/EventService';
import EventTypeService from '../services/EventTypeService';

import ConfigService from '../services/ConfigService';

class RootStore {
  @observable public stores: any = {};
  @observable public services: any = {};

  constructor() {
    this.create();
  }

  @action('Initialising the RootStore')
  public create() {
    this.stores = {
      error: new ErrorStore(),
      ui: new UIStore(this),
      websiteUi: new WebsiteUIStore(),
      currentUser: new CurrentUserStore(this),
      node: new NodeStore(this),
      nodeType: new NodeTypeStore(this),
      typeCanHave: new TypeCanHaveStore(this),
      booking: new BookingStore(this),
      facility: new FacilityStore(this),
      user: new UserStore(this),
      cart: new CartStore(this),
      roster: new RosterStore(this),
      image: new ImageStore(this),
      nodeEdit: new NodeEditStore(this),
      facilityEdit: new FacilityEditStore(this),
      bookingEdit: new BookingEditStore(this),
      userEdit: new UserEditStore(this),
      nodesList: new NodesListStore(),
      redeemCode: new RedeemCodeStore(this),
      redeemCodeEdit: new RedeemCodeEditStore(this),
      checkout: new CheckoutStore(),
      ticket: new TicketStore(this),
      event: new EventStore(this),
      eventEdit: new EventEditStore(this),
      ticketEdit: new TicketEditStore(this),
      ticketSalesEdit: new TicketSalesEditStore(this),
      config: new CongigStore(this),
      eventType: new EventTypeStore(this),
      eventTypeEdit: new EventTypeEditStore(this)
    };
    this.services = {
      node: new NodeService(),
      nodeType: new NodeTypeService(),
      typeCanHave: new TypeCanHaveService(),
      booking: new BookingService(),
      facility: new FacilityService(),
      user: new UserService(),
      cart: new CartService(),
      roster: new RosterService(),
      image: new ImageService(),
      redeemCode: new RedeemCodeService(),
      ticket: new TicketService(),
      event: new EventService(),
      config: new ConfigService(),
      eventType: new EventTypeService()
    };
  }
}

const rootStore = new RootStore();

export { rootStore, RootStore };
