import ApiService from './ApiService';

import { CartCode, RedeemCode } from '../stores/models/models';

export default class RedeemCodeService extends ApiService {
  constructor() {
    super();
  }

  // Redeem Code

  public fetchRedeemCodes(): Promise<RedeemCode[]> {
    const url: string = this.getUrl(`/redeemcodes`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length !== 0) {
          return this.jsonConvert.deserialize(responseJson.data, RedeemCode);
        }
        return [];
      });
  }

  public fetchRedeemCodeById(redeemCodeId: number): Promise<RedeemCode> {
    const url: string = this.getUrl(`/redeemcode/${redeemCodeId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, RedeemCode));
  }

  public createOrUpdateRedeemCode(redeemCode: RedeemCode): Promise<RedeemCode> {
    const baseUrl: string = `/redeemcode`;
    const isNew: boolean = redeemCode.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/${redeemCode.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonRedeemCode = this.jsonConvert.serialize(redeemCode);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonRedeemCode)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, RedeemCode);
      });
  }

  public deleteRedeemCode(redeemCodeId: number): Promise<void> {
    const url: string = this.getUrl(`/redeemcode/${redeemCodeId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }

  // Cart Code

  public fetchCartCodesByCartId(cartId: number): Promise<CartCode[]> {
    const url: string = this.getUrl(`/cartcode/cart/${cartId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data && responseJson.data.length !== 0) {
          return this.jsonConvert.deserialize(responseJson.data, CartCode);
        }
        return [];
      });
  }

  public createCartCode(cartCode: CartCode): Promise<CartCode> {
    const url: string = this.getUrl(`/cartcode`);
    const jsonCartCode = this.jsonConvert.serialize(cartCode);
    return fetch(url, {
      method: 'POST',
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonCartCode)
    })
      .then(this.handleResponse)
      .then((responseJson) => this.jsonConvert.deserialize(responseJson.data, CartCode));
  }

  public deleteCartCode(cartCodeId: number): Promise<void> {
    const url: string = this.getUrl(`/cartcode/info/${cartCodeId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }
}
