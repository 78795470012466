import { observable, action, runInAction } from 'mobx';

import ElementNotPresentError from './errors/ElementNotPresentError';

interface IGenericElement {
  id: number;
}

class GenericStore<T extends IGenericElement> {
  @observable private contentsMap: Map<number, T> = new Map<number, T>();

  public get contents(): T[] {
    return Array.from(this.contentsMap.values());
  }

  public addAll(elements: T[]): void {
    runInAction('Adding several elements to the store', () => {
      elements.forEach((element) => {
        this.addOrUpdate(element);
      });
    });
  }

  public addOrUpdateAll(elements: T[]): void {
    runInAction('Adding or Updating several elements to the store', () => {
      elements.forEach((element) => {
        this.addOrUpdate(element);
      });
    });
  }

  public get(elementId: number): T | undefined {
    return this.contentsMap.get(elementId);
  }

  @action('Adding or Updating an element in the store')
  public addOrUpdate(element: T): void {
    this.contentsMap.set(element.id, element);
  }

  @action('Removing an element from the store')
  public remove(elementId: number): void {
    this.contentsMap.delete(elementId);
  }

  @action('Clearing the store contents')
  public clear(): void {
    this.contentsMap.clear();
  }

  public exists(elementId: number): boolean {
    return this.contentsMap.has(elementId);
  }
}

export { ElementNotPresentError };
export default GenericStore;
