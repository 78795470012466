import React from 'react';
import queryString from 'query-string';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { Event, Config } from '../../stores/models/models';
import { EventStore, ConfigStore, LoadingStore } from '../../stores/stores';
import { RootStore } from '../../stores/RootStore';

import LoadingSpinner from '../../components/website/LoadingSpinner';
import EventSearchBar from '../../components/website/EventSearchBar';
import EventCard from '../../components/website/EventCard';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Container } from 'reactstrap';

import moment from 'moment';
import { Notifications } from 'core/notifications/notifications';

import './styles/eventsScreenStyle.css';

const dateFormat: string = 'DD-MM-YYYY';

interface SearchParams {
  nodeId?: number;
  from?: moment.Moment;
  to?: moment.Moment;
}

@inject('rootStore')
@observer
export default class EventsScreen extends React.Component<{}, {}> {
  @observable events: Event[] = [];
  private searchParams: SearchParams;
  private eventStore: EventStore;
  private configStore: ConfigStore;
  private rootStore: RootStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    const searchParams: any = queryString.parse(props.location.search);
    this.searchParams = this.parseParams(searchParams);
    this.eventStore = props.rootStore.stores.event;
    this.configStore = props.rootStore.stores.config;
    this.rootStore = props.rootStore;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((data: [Event[], Config]) => {
        const events: Event[] = data[0];
        const activeEvents: Event[] = events.filter((event: Event) => event.status);
        this.loadingStore.setLoadingStateToDone();
        this.setEvents(activeEvents);
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    return (
      <div className="sjp-mb-3">
        {this.configStore.configuration.searchBar ? (
          <Container fluid={true} className="sjp-meeting-search-card shift-up sjp-mb-3">
            <Container className="sjp-pl-0 sjp-pr-0">
              <EventSearchBar rootStore={this.rootStore} handleSearch={this.handleSearch.bind(this)} />
            </Container>
          </Container>
        ) : (
          undefined
        )}

        {this.events.length === 0 ? (
          <Container className="sjp-text-center sjp-mt-1">
            <h5 className="sjp-warning-text">There are no events</h5>
          </Container>
        ) : (
          <div>
            <Container>
              <Row className="sjp-mt-5 sjp-event-title-text">
                <h4 className="sjp-title-text">Events</h4>
              </Row>
            </Container>
            {this.events.map((event: Event) => {
              return (
                <Container className="sjp-mt-2" key={event.id}>
                  <div key={event.id}>
                    <EventCard eventId={event.id} />
                  </div>
                </Container>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  private handleSearch(events: Event[]): void {
    this.setEvents(events);
  }

  private parseParams(params: any): SearchParams {
    const searchParams: SearchParams = {};
    for (var key in params) {
      let value = params[key];

      if (key === 'nodeId') {
        value = parseInt(value);
      } else if (key === 'from' || key === 'to') {
        value = moment(key, dateFormat);
      }
      searchParams[key] = value;
    }
    return searchParams;
  }

  @action('Set Events')
  private setEvents(events: Event[]): void {
    const futureEvents: Event[] = events.filter((event: Event) => moment(event.fromTo[0].to).isSameOrAfter(moment()));
    this.events = futureEvents;
  }

  private getDataFromApi(): Promise<[Event[], Config]> {
    this.loadingStore.setLoadingStateToLoading();
    if (Object.keys(this.searchParams).length !== 0) {
      const location: number | undefined = this.searchParams.nodeId ? this.searchParams.nodeId : undefined;
      const from: string | undefined = this.searchParams.from ? this.searchParams.from.format(dateFormat) : undefined;
      const to: string | undefined = this.searchParams.to ? this.searchParams.to.format(dateFormat) : undefined;
      const eventsPromsie: Promise<Event[]> = this.eventStore.fetchEventsBySearchBarParameters(location, from, to).then((events: Event[]) => {
        return events;
      });
      return Promise.all([ eventsPromsie, this.configStore.fetchConfiguration() ]);
    }

    return Promise.all([ this.eventStore.fetchFutureActiveEvents(), this.configStore.fetchConfiguration() ]);
  }
}
