import ApiService from './ApiService';

import { Config } from '../stores/models/config';

class ConfigService extends ApiService {
  constructor() {
    super();
  }

  public fetchConfig(): Promise<Config> {
    const url: string = this.getUrl(`/config`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then(responseJson => {
        return this.jsonConvert.deserialize(responseJson.data, Config);
      });
  }
}

export default ConfigService;
