import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
class FromTo {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('from', String)
  from: string = '';
  @JsonProperty('to', String)
  to: string = '';
}

export { FromTo };
