import { JsonProperty, JsonObject, Any } from 'json2typescript';
import { isUndefined } from 'util';

// Ticket
@JsonObject
class Ticket {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('name', String)
  name: string = '';
  @JsonProperty('description', String)
  description: string = '';
  @JsonProperty('event_id', Any)
  eventId: number | null = null;
  @JsonProperty('node_id', Any)
  nodeId: number | null = null;
  @JsonProperty('price', Number)
  price: number = 0;
  @JsonProperty('vat', Number)
  vat: number = 0;
  @JsonProperty('unlimited_quantity', Any)
  unlimitedQuantity: boolean | null = null;
  @JsonProperty('quantity', Any)
  quantity: number | null = null;
  @JsonProperty('colour', String)
  colour: string = '';
}

// Ticket Cart Item

@JsonObject
class TicketCartItem {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('cart_id', Number)
  cartId: number = 0;
  @JsonProperty('ticket_id', Number)
  ticketId: number = 0;
  @JsonProperty('price', Number)
  price: number = 0;
  @JsonProperty('price_incl_vat', Number)
  priceIncludingVat: number = 0;
  @JsonProperty('quantity', Number)
  quantity: number = 0;
}

const ticketFromForm = (formData: {}) => {
  const ticket = new Ticket();
  for (const key in formData) {
    let value = formData[key];
    if (isUndefined(value)) {
      value = ticket[key];
    }
    if (key === 'quantity') {
      if (formData['unlimitedQuantity']) {
        value = null;
      } else if (value === '' || value === null) {
        value = 0;
      } else {
        value = parseInt(value);
      }
    } else if (key === 'price') {
      if (value === '' || value === null) {
        value = 0;
      } else {
        value = parseFloat(value);
      }
    } else if (key === 'vat') {
      value = value / 100;
    } else if (key === 'unlimitedQuantity') {
      if (value === true) {
        ticket.quantity = -1;
      } else if (value === false) {
        value = null;
      }
    } else if (key === 'nodeId' || key === 'eventId') {
      if (value === 0) {
        value = null;
      }
    }
    ticket[key] = value;
  }
  return ticket;
};

const ticketCartItemFromForm = (formData: {}) => {
  const ticketCartItem: TicketCartItem = new TicketCartItem();
  for (const key in formData) {
    let value = formData[key];
    if (isUndefined(value)) {
      value = ticketCartItem[key];
    }
    if (key === 'quantity') {
      if (value === '' || value === null) {
        value = 0;
      } else {
        value = parseInt(value);
      }
    }
    ticketCartItem[key] = value;
  }
  return ticketCartItem;
};

export { Ticket, TicketCartItem, ticketFromForm, ticketCartItemFromForm };
