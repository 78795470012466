import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { isUndefined } from 'util';
import { Booking } from './Booking';
import { BookingFacility } from './models';

@JsonObject
class BulkBooking {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('date_from', Any)
  dateFrom: string = '';
  @JsonProperty('date_to', Any)
  dateTo: string = '';
  @JsonProperty('days_of_week', String)
  daysOfWeek: string = '';
  // monthly week is a variable used to figure out how the recurrence happens
  // when the variable is set to true, it is a monthly
  // booking recurring on the day of the week (e.g. 3rd Wednesday of every week)
  // when it is set to false, it is a monthly booking on that day
  // of the month (e.g. every 12th of the month)
  // and if it is null, then it is a weekly booking
  @JsonProperty('monthly_week', Any)
  monthlyWeek: boolean | null = null;
}

@JsonObject
class BookingBulkBooking {
  @JsonProperty('cart_item', Any)
  booking: Booking = new Booking();
  @JsonProperty('bulk_booking', Any)
  bulkBooking: BulkBooking = new BulkBooking();
}

@JsonObject
class BulkBookingResponse {
  @JsonProperty('cart_item', Any)
  booking: Booking = new Booking();
  @JsonProperty('error', Any)
  error: string | null = null;
}

@JsonObject
class BulkBookingFacilityResponse {
  @JsonProperty('facility_cart_item', Any)
  bookingFacility: BookingFacility;
  @JsonProperty('error', Any)
  error: string | null = null;
}

const bulkBookingFromForm = (formData: {}) => {
  const bulkBooking = new BulkBooking();
  for (const key in formData) {
    let value = formData[key];
    if (isUndefined(value)) {
      value = bulkBooking[key];
    }
    if (key === 'dateFrom' || key === 'dateTo') {
      value = value ? value.format() : '';
    }
    if (key === 'monthly_week') {
      value = value === -1 ? null : value === 1;
    }
    if (key === 'days_of_week') {
      value = value.toString();
    }
    bulkBooking[key] = value;
  }
  return bulkBooking;
};

export { bulkBookingFromForm, BulkBooking, BookingBulkBooking, BulkBookingResponse, BulkBookingFacilityResponse };
