import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { Facility } from './models/models';

class FacilityStore extends GenericStore<Facility> {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  public get facilities(): Facility[] {
    return this.contents;
  }

  public fetchFacilities(): Promise<Facility[]> {
    return this.rootStore.services.facility.fetchFacilities().then((facilities: Facility[]) => {
      this.addOrUpdateAll(facilities);
      return facilities;
    });
  }

  public fetchFacilityById(facilityId: number): Promise<Facility> {
    return this.rootStore.services.facility.fetchFacilityById(facilityId).then((facility: Facility) => {
      this.addOrUpdate(facility);
      return facility;
    });
  }

  public createOrUpdateFacility(facility: Facility): Promise<Facility> {
    return this.rootStore.services.facility.createOrUpdateFacility(facility).then((facility: Facility) => {
      this.addOrUpdate(facility);
      return facility;
    });
  }

  public deleteFacility(facilityId: number): Promise<void> {
    return this.rootStore.services.facility.deleteFacility(facilityId).then(() => {
      this.remove(facilityId);
    });
  }
}

export { FacilityStore };
