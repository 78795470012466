type credentialTypes = 'omit' | 'same-origin' | 'include' | undefined;
type httpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

class BaseService {
    protected baseUrl: string;
    protected defaultHeaders: {};
    protected credentials?: credentialTypes;
    protected method: httpMethod;

    constructor(baseUrl: string, defaultHeaders?: {}, credentials?: credentialTypes, method?: httpMethod) {
        this.baseUrl = baseUrl;
        this.defaultHeaders = defaultHeaders || {};
        this.credentials = credentials;
        this.method = method || 'GET';
    }

    protected getUrl(url: string) {
        return `${this.baseUrl}${url}`;
    }
}

export { BaseService };
