import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Cart } from '../../stores/models/models';
import { CartStore, LoadingStore } from '../../stores/stores';

import { Container, Row, Col, Button } from 'reactstrap';

import LoadingSpinner from './LoadingSpinner';

import './styles/invoicesComponentStyle.css';

import moment from 'moment';
import { Notifications } from 'core/notifications/notifications';

const dateTimeDb: string = 'YYYY-MM-DD HH:mm:ss';
const displayDateFormat: string = 'DD/MM/YYYY';

interface IInvoicesComponentProps {
  userId: number;
}

@inject('rootStore')
@observer
export default class InvoicesComponent extends React.Component<IInvoicesComponentProps, {}> {
  private cartStore: CartStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: any) {
    super(props);
    this.cartStore = props.rootStore.stores.cart;
  }

  public componentDidMount() {
    this.getDataFromApi()
      .then((carts: Cart[]) => {
        this.loadingStore.setLoadingStateToDone();
      })
      .catch((err) => {
        this.loadingStore.setLoadingStateToError();
        Notifications.displayError(err);
      });
  }

  public render() {
    if (this.loadingStore.stillLoading || this.loadingStore.loadingError) {
      return <LoadingSpinner />;
    }

    const carts: Cart[] = this.cartStore.carts.filter((cart: Cart) => cart.dateConfirmed !== null && cart.userId === this.props.userId);

    return (
      <Container>
        <Row className="sjp-darkgrey-background sjp-pt-1 sjp-pb-1">
          <Col>
            <div className="sjp-white-title-text sjp-text-uppercase sjp-vertical-align">Invoices</div>
          </Col>
        </Row>

        {carts.length > 0 ? (
          <Row className="sjp-background-card-color">
            <Col className="sjp-mr-1 sjp-ml-1">
              <Row className="sjp-white-background sjp-mt-1 sjp-mb-1 sjp-pt-4 sjp-pb-1">
                <Col xs="3" sm="3" md="3" lg="3" xl="3">
                  <div className="sjp-value sjp-text-uppercase">INV. NO.</div>
                </Col>
                <Col xs="3" sm="3" md="3" lg="3" xl="3">
                  <div className="sjp-value sjp-text-uppercase">DATE</div>
                </Col>
                <Col xs="3" sm="3" md="3" lg="3" xl="3">
                  <div className="sjp-value sjp-text-uppercase">AMOUNT</div>
                </Col>
              </Row>
              {carts.map((cart: Cart) => {
                if (cart.dateConfirmed === null) {
                  throw new Error(`Error: Cart with ID ${cart.id} has not been confirmed and shouldn't be listed here`);
                }

                const time: string = moment(cart.dateConfirmed, dateTimeDb).format(displayDateFormat);

                return (
                  <Row key={cart.id} className="sjp-white-background sjp-mt-1 sjp-mb-1 sjp-pt-1 sjp-pb-1">
                    <Col xs="3" sm="3" md="3" lg="3" xl="3" className="sjp-value sjp-text-uppercase sjp-vertical-align">
                      <div>SJP{cart.id}</div>
                    </Col>
                    <Col xs="3" sm="3" md="3" lg="3" xl="3" className="sjp-value sjp-text-uppercase sjp-vertical-align">
                      <div>{time}</div>
                    </Col>
                    <Col xs="3" sm="3" md="3" lg="3" xl="3" className="sjp-value sjp-text-uppercase sjp-vertical-align">
                      <div>EUR {cart.priceIncludingVat}</div>
                    </Col>
                    <Col xs="3" sm="3" md="3" lg="3" xl="3" className="sjp-value sjp-text-uppercase sjp-vertical-align">
                      <a href={`/api/cartpdf/${cart.id}`} target="_blank">
                        <Button className="sjp-button-grey sjp-no-border-radius sjp-button-text sjp-text-uppercase sjp-full-width">VIEW</Button>
                      </a>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        ) : (
          <Row className="sjp-background-card-color sjp-flex">
            <h5 className="sjp-warning-text sjp-text-center sjp-mt-1 sjp-mb-1">You currently do not have any invoices</h5>
          </Row>
        )}
      </Container>
    );
  }

  private getDataFromApi(): Promise<Cart[]> {
    this.loadingStore.setLoadingStateToLoading();
    return this.cartStore.fetchConfirmedCartsByUserId(this.props.userId);
  }
}
