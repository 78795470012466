class FormValidationError extends Error {
  constructor(formErrors) {
    let errorMessage: string = '';
    for (const key in formErrors) {
      if (formErrors.hasOwnProperty(key)) {
        formErrors[key].errors.forEach((err) => {
          errorMessage += `${err.message}, `;
        });
      }
    }
    super(errorMessage);
  }
}

const handleFormErrors = (error, values): Promise<any> => {
  if (!error) {
    return Promise.resolve(values);
  }
  return Promise.reject(new FormValidationError(error));
};

export { handleFormErrors, FormValidationError };
