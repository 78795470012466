import { RootStore } from './RootStore';
import { GenericStore } from './GenericStores';
import { EventType } from './models/models';

class EventTypeStore extends GenericStore<EventType> {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  public get eventTypes(): EventType[] {
    return this.contents;
  }

  public fetchEventTypes(): Promise<EventType[]> {
    return this.rootStore.services.eventType.fetchEventTypes().then((eventTypes: EventType[]) => {
      this.addOrUpdateAll(eventTypes);
      return eventTypes;
    });
  }

  public fetchEventTypeById(eventTypeId: number): Promise<EventType> {
    return this.rootStore.services.eventType.fetchEventTypeById(eventTypeId).then((eventType: EventType) => {
      this.addOrUpdate(eventType);
      return eventType;
    });
  }

  public createOrUpdateEventType(eventType: EventType): Promise<EventType> {
    return this.rootStore.services.eventType.createOrUpdateEventType(eventType).then((eventType: EventType) => {
      this.addOrUpdate(eventType);
      return eventType;
    });
  }

  public deleteEventType(eventTypeId: number): Promise<void> {
    return this.rootStore.services.eventType.deleteEventType(eventTypeId).then(() => {
      this.remove(eventTypeId);
    });
  }
}

export { EventTypeStore };
