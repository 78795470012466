import { JsonObject, JsonProperty, JsonConvert } from 'json2typescript';

@JsonObject
class TypeCanHave {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('type_id', Number)
  typeId: number = 0;
  @JsonProperty('can_have', Number)
  canHave: number = 0;
}

const exampleTypeCanHaveDetails = {
  id: 3,
  type_id: 5,
  can_have: 2
};

const jsonConvert: JsonConvert = new JsonConvert();
const exampleTypeCanHave: TypeCanHave = jsonConvert.deserialize(exampleTypeCanHaveDetails, TypeCanHave);

export { TypeCanHave, exampleTypeCanHaveDetails, exampleTypeCanHave };
