import { Config } from './models/models';

import { RootStore } from './RootStore';
import { observable, action } from 'mobx';

export default class ConfigStore {
  @observable private config: Config = new Config();
  private rootStore: RootStore;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public get configuration(): Config {
    return this.config;
  }

  public fetchConfiguration(): Promise<Config> {
    return this.rootStore.services.config.fetchConfig().then((config: Config) => {
      this.setConfiguration(config);
      return config;
    });
  }

  @action('Setting the config variable')
  private setConfiguration(configuration: Config): void {
    this.config = configuration;
  }
}
