import { JsonProperty, JsonObject } from 'json2typescript';
import { isUndefined } from 'util';

@JsonObject
class EventType {
  @JsonProperty('id', Number)
  id: number = 0;
  @JsonProperty('name', String)
  name: string = '';
  @JsonProperty('turn_around_time', Number)
  turnAroundTime: number = 0; // this is in minutes
}

const eventTypeFromForm = (formData: {}) => {
  const eventType: EventType = new EventType();
  for (const key in formData) {
    if (formData.hasOwnProperty(key) && eventType.hasOwnProperty(key)) {
      let value = formData[key];
      if (isUndefined(value)) {
        value = eventType[key];
      }
      eventType[key] = value;
    }
  }
  return eventType;
};

export { EventType, eventTypeFromForm };
