import { observable, action } from 'mobx';
import { TicketCartItem } from './models/models';
import { RootStore } from './RootStore';

class TicketSalesEditStore {
  @observable public ticketCartItem: TicketCartItem;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action('Fetching Ticket Cart Item to be edited')
  public fetchTicketCartItemForEditing(ticketCartItemId: number): Promise<TicketCartItem> {
    if (ticketCartItemId === 0) {
      const newTicketCartItem: TicketCartItem = new TicketCartItem();
      this.setCurrentTicketCartItem(newTicketCartItem);
      return Promise.resolve(newTicketCartItem);
    }

    return this.rootStore.stores.ticket.fetchTicketCartItemById(ticketCartItemId).then((ticketCartItem: TicketCartItem) => {
      if (!ticketCartItem) {
        throw new Error(`Ticket Cart Item with ID ${ticketCartItemId} does not exist`);
      }
      this.setCurrentTicketCartItem(ticketCartItem);
      return ticketCartItem;
    });
  }

  @action('Creating or Updating the curent ticket cart item with new details')
  public createOrUpdateCurentTicketCartItem(newTicketCartItem: TicketCartItem): Promise<TicketCartItem> {
    if (!this.ticketCartItem) {
      throw new Error(`Unable to update a ticket cart item if one does not exist`);
    }
    return this.rootStore.stores.ticket.createOrUpdateTicketCartItem(newTicketCartItem).then((ticketCartItem: TicketCartItem) => {
      if (!ticketCartItem) {
        throw new Error(`Unable to update ticket cart item with ID ${this.ticketCartItem.id}`);
      }
      this.setCurrentTicketCartItem(ticketCartItem);
      return ticketCartItem;
    });
  }

  @action('Set TicketCartItem that is currently being edited')
  private setCurrentTicketCartItem(ticketCartItem: TicketCartItem): void {
    this.ticketCartItem = ticketCartItem;
  }
}

export { TicketSalesEditStore };
