import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Form, Input, Button } from 'antd';
import { Container, Row, Col } from 'reactstrap';
import { FormComponentProps } from 'antd/lib/form/Form';

import { User } from '../../stores/models/models';
import { CurrentUserStore, LoadingStore } from '../../stores/stores';
import { RootStore } from '../../stores/RootStore';

import { Notifications } from '../../../../core/notifications/notifications';

import './styles/loginComponentStyle.css';

import { Login } from '../svgIcons/icons';

const FormItem = Form.Item;
const defaultFormItemLayout: {} = {
  colon: false
};

interface ILoginComponentProps {
  rootStore: RootStore;
  handleSuccessfulLogin: VoidFunction;
  toggleForgotPassword: () => void;
}

@observer
class LoginComponent extends React.Component<FormComponentProps & ILoginComponentProps, {}> {
  private currentUserStore: CurrentUserStore;
  private loadingStore: LoadingStore = new LoadingStore();

  constructor(props: FormComponentProps & ILoginComponentProps) {
    super(props);
    this.currentUserStore = props.rootStore.stores.currentUser;
    this.loadingStore.setLoadingStateToDone();
  }

  public componentDidMount() {
    const { form } = this.props;
    form.resetFields();
  }

  public render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Container className="sjp-login-container">
        <Row className="sjp-pt-1 sjp-pb-1 sjp-background-primary">
          <Col>
            <div className="sjp-white-title-text sjp-text-uppercase">Log In</div>
          </Col>
        </Row>
        <Row className="sjp-background-card-color">
          <Col xs="8" sm="8" md="8" lg="8" xl="8">
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <Col>
                <Row className="sjp-mt-1 sjp-login-row">
                  <FormItem {...defaultFormItemLayout}>
                    <div className="sjp-label sjp-text-uppercase"> * id card</div>
                    {getFieldDecorator('idCard', {
                      rules: [
                        {
                          required: true,
                          message: 'ID Card is required'
                        }
                      ]
                    })(<Input className="sjp-no-border-radius" placeholder="Input your ID Card here" />)}
                  </FormItem>
                </Row>
                <Row className="sjp-login-row">
                  <FormItem {...defaultFormItemLayout}>
                    <div className="sjp-label sjp-text-uppercase"> * password</div>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: 'Password is required'
                        }
                      ]
                    })(
                      <div>
                        <Input className="sjp-no-border-radius" type="password" placeholder="Input your Password here" />
                      </div>
                    )}
                  </FormItem>
                </Row>
                <Row className="sjp-mb-1">
                  <a onClick={this.props.toggleForgotPassword.bind(this)}>
                    <div className="sjp-text-uppercase sjp-small-warning-text">Forgot your password? Click here</div>
                  </a>
                </Row>
                <Row className="sjp-mt-2">
                  <FormItem>
                    <Button className="sjp-button sjp-pl-2 sjp-pr-2 sjp-login-button sjp-button-text sjp-text-uppercase sjp-no-border-radius" type="primary" htmlType="submit" loading={this.loadingStore.stillLoading}>
                      <div>Login</div>
                    </Button>
                  </FormItem>
                </Row>
              </Col>
            </Form>
          </Col>
          <Col xs="4" sm="4" md="4" lg="4" xl="4">
            <Login className="sjp-login-image sjp-primary-fill" />
          </Col>
        </Row>
        <Row className="sjp-background-card-color sjp-login-divider-margin sjp-pt-1 sjp-pb-1">
          <Col>
            <Link to="/register">
              <Button className="sjp-full-width sjp-card-button sjp-no-border-radius">
                <div className="sjp-label sjp-text-uppercase">not registered? click here</div>
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  private handleSubmit(e: any) {
    e.preventDefault();
    const form = this.props.form;
    form.validateFields((error, values) => {
      if (error) {
        Notifications.showWarning('Validation Error', 'Please enter your ID Card number and your password');
        return;
      }
      this.loadingStore.setLoadingStateToLoading();
      return this.currentUserStore
        .login(values.idCard, values.password)
        .then((user: User) => {
          this.loadingStore.setLoadingStateToDone();
          form.resetFields();
          Notifications.showSuccess('Login Successful', `Welcome back, ${user.fullName}`);
          this.props.handleSuccessfulLogin();
        })
        .catch((err) => {
          this.loadingStore.setLoadingStateToError();
          Notifications.showWarning('Login Failutre', `Unable to Login: ${err.message}`);
          form.resetFields();
        });
    });
  }
}

export default Form.create<{}>()(LoginComponent);
