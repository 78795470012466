import ApiService from './ApiService';

import { Facility } from '../stores/models/models';

class FacilityService extends ApiService {
  constructor() {
    super();
  }

  public fetchFacilities(): Promise<Facility[]> {
    const url: string = this.getUrl(`/facilities`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        if (responseJson.data.length === 0) {
          return [];
        }
        return this.jsonConvert.deserialize(responseJson.data, Facility);
      });
  }

  public fetchFacilityById(facilityId: number): Promise<Facility> {
    const url: string = this.getUrl(`/facilities/info/${facilityId}`);
    return fetch(url, {
      credentials: this.credentials,
      headers: this.defaultHeaders
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Facility);
      });
  }

  public createOrUpdateFacility(facility: Facility): Promise<Facility> {
    const baseUrl: string = `/facilities`;
    const isNew: boolean = facility.id === 0;
    const url: string = isNew ? this.getUrl(baseUrl) : this.getUrl(`${baseUrl}/info/${facility.id}`);
    const method: string = isNew ? 'POST' : 'PUT';
    const jsonFacility = this.jsonConvert.serialize(facility);
    return fetch(url, {
      method: method,
      credentials: this.credentials,
      headers: this.defaultHeaders,
      body: JSON.stringify(jsonFacility)
    })
      .then(this.handleResponse)
      .then((responseJson) => {
        return this.jsonConvert.deserialize(responseJson.data, Facility);
      });
  }

  public deleteFacility(facilityId: number): Promise<void> {
    const url = this.getUrl(`/facilities/info/${facilityId}`);
    return fetch(url, {
      method: 'DELETE',
      credentials: this.credentials,
      headers: this.defaultHeaders
    }).then(this.handleResponse);
  }
}

export default FacilityService;
